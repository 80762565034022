import React from "react";
import "./ShowSfarim.css";
import ShowSfarimCommentary from "../ShowSfarimCommentary/ShowSfarimCommentary";
import ShowCommentaryTxt from "../ShowCommentaryTxt/ShowCommentaryTxt";
import { useSelector, useDispatch } from "react-redux";
import {
  getSelectedcommentary,
  getCommentaryToShow,
  getCommentarySefarim,

  getSelectedUnitText
} from "../../store/selectors/selectors";
import {
  setIsSfarimOn,
  setSelectedTextUnit,

} from "../../store/actions/pageActions";
import { ReactComponent as Plus } from "../../svg/group-4-copy-9.svg";
import { ThunkDispatch } from "@reduxjs/toolkit";

import Axios from "axios";
import config from "../../services/configService";
const ShowSfarim: React.FC = () => {
  const selectedCommentary = useSelector(getSelectedcommentary);
  const commentaryToShow = useSelector(getCommentaryToShow);
  const commentarySefarim = useSelector(getCommentarySefarim);
  const selectedUnitText = useSelector(getSelectedUnitText);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();


  // return to the choose-sfarim comp.
  const returnToChooseSfarim = () => {
    dispatch(setIsSfarimOn(false));
  };

  let txtToShow: any = setTxtToShow();


  const stringtolink = (text: any) => {
    text = text.split(' ').join('_');
    text = replaceLast(text, '_', '.');
    text = text.split(':').join('.').split('`').join('');
    return text;
  }


  // Make api to selected text
  const callToDataFromServer = () => {
    txtToShow.forEach((item: any) => {

      item.forEach(async (element: any) => {
        try {
          let text: string = '';
          if (Array.isArray(element.txt)) {
            for (var i = 0; i < element.txt.length; i++) {
              let localtext = element.txt[i];
              text = element.txt[i];

              if (Array.isArray(text) || !text.includes('`'))
                return;
              text = stringtolink(text);



              let local = `${config.shitufta_api_url}texts/${text}?commentary=0&context=0&pad=0&wrapLinks=1&multiple=0`;
              const data1 = await Axios.get(local);
              let dataHe = data1.data.he;


              element.txt[i] = dataHe + '~' + data1.data.ref;

              var localE = document.getElementById(localtext);
              if (localE)
                localE.innerHTML = dataHe;

            }
          }
          else {
            text = element.txt;
            let localtext = element.txt;
            if (Array.isArray(text) || !text.includes('`'))
              return;
            text = stringtolink(text);

            let local = `${config.shitufta_api_url}texts/${text}?commentary=0&context=0&pad=0&wrapLinks=1&multiple=0`;
            const data1 = await Axios.get(local);

            let dataHe = data1.data.he;
            element.txt = dataHe;
            var localE1 = document.getElementById(localtext);
            if (localE1)
              localE1.innerHTML = dataHe;

          }
        } catch (err) {


        }
      });



    });
  }
  callToDataFromServer();

  function replaceLast(x: any, y: any, z: any) {
    var a = x.split("");
    a[x.lastIndexOf(y)] = z;
    return a.join("");
  }

  // set the text unit selected.
  const unitTextClicked = (idx: any, event: any) => {
    event.stopPropagation();
    dispatch(setSelectedTextUnit(idx));
  };

  const isCommentaryInPage = (commentaryNameEn: string) => {
    let toReturn: any = false;
    if (
      commentaryNameEn === "mesoretHashas" ||
      commentaryNameEn === "einMishpatHashalem" ||
      commentaryNameEn === "TorahOrHashalem"
    ) {
      toReturn = commentarySefarim[commentaryNameEn].some((item: any) => {
        return item.length > 0;
      });
    } else {
      for (let item in commentarySefarim.extraBooks) {
        if (item === commentaryNameEn) {
          toReturn = true;
        }
      }
      for (let item in commentarySefarim.commentary) {
        if (item === commentaryNameEn) {
          toReturn = true;
        }
      }
    }
    return toReturn;
  };

  // decide which data to send depends on the category and commentary name.
  function setTxtToShow() {


    if (commentaryToShow === null || selectedCommentary.length === 0) return;
    let txtToReturn: any = [];


    for (let item in commentarySefarim) {

      if (commentarySefarim.hasOwnProperty(item)) {
        if (item === "commentary" || item === "extraBooks") {
          for (let commentaryItem in commentarySefarim[item]) {
            if (commentarySefarim[item].hasOwnProperty(commentaryItem)) {
              if (commentaryItem === commentaryToShow) {
                for (let itemTxt in commentarySefarim[item][commentaryItem]) {
                  if (commentarySefarim[item][commentaryItem][itemTxt] && commentarySefarim[item][commentaryItem][itemTxt].length < 1) {
                    txtToReturn.push([]);
                  }
                  else {
                    txtToReturn.push([
                      {
                        title: commentarySefarim.allDic[commentaryToShow],
                        txt: commentarySefarim[item][commentaryItem][itemTxt]
                      }
                    ]);
                  }
                }
              }
            }
          }
        } else if (item === "mesoretHashas" && commentaryToShow === item) {
          txtToReturn = commentarySefarim[item];
        } else if (item === "einMishpatHashalem" && commentaryToShow === item) {
          txtToReturn = commentarySefarim[item];
        } else if (item === "TorahOrHashalem" && commentaryToShow === item) {
          txtToReturn = commentarySefarim[item];
        }
      }

    }
    return txtToReturn;
  }

  return (
    <>
      <div className="show-sfarim">
        <div className="commentary-to-show" id="showSfarim">
          <div onClick={returnToChooseSfarim} className="return-choose-sfarim">
            <Plus className="plus-svg-show-sfarim" />
          </div>
          {selectedCommentary &&
            commentaryToShow &&
            selectedCommentary.map((item: any) => {
              // @ts-ignore
              let commentaryInPage = isCommentaryInPage(Object.keys(item)[0]);
              if (
                !commentaryInPage &&
                Object.keys(item)[0] === commentaryToShow
              ) {
                return (
                  <div
                    key={Object.keys(item)[0]}
                    className="commentary-item-selected"
                    id="commentary-item-selected"
                  >
                    <ShowSfarimCommentary
                      value={Object.values(item)}
                      isSelected={true}
                    />
                  </div>
                );
              } else if (
                Object.keys(item)[0] !== commentaryToShow &&
                !commentaryInPage
              ) {
                return (
                  <div
                    key={Object.keys(item)[0]}
                    className="commentary-item-none-in-page"
                  >
                    <ShowSfarimCommentary
                      value={Object.values(item)}
                      isSelected={false}
                    />
                  </div>
                );
              } else if (
                Object.keys(item)[0] !== commentaryToShow &&
                commentaryInPage
              ) {
                return (
                  <div
                    key={Object.keys(item)[0]}
                    className="commentary-item-none-selected"
                  >
                    <ShowSfarimCommentary
                      value={Object.values(item)}
                      isSelected={false}
                    />
                  </div>
                );
              } else {
                return (
                  <div
                    key={Object.keys(item)[0]}
                    className="commentary-item-selected"
                    id="commentary-item-selected"
                  >
                    <ShowSfarimCommentary
                      value={Object.values(item)}
                      isSelected={true}
                    />
                  </div>
                );
              }
            })}
        </div>
        <div className="txt-container" id="txt-container">
          {txtToShow &&
            txtToShow.every((item: any) => {
              return item.length === 0;
            }) && (
              <div className="none-txt-commentary-vs-selected-txt-unit">
                <p className="none-txt-commentary-vs-selected-txt-unit-text">
                  לא קיים תוכן מהספר לעמוד זה
                </p>
              </div>
            )}
          {txtToShow &&
            !txtToShow.every((item: any) => {
              return item.length === 0;
            }) &&
            txtToShow.map((item: any, idx: any) => {
              if (selectedUnitText === idx && item.length === 0) {
                return (
                  <div
                    key={idx + "noneCommentary"}
                    className="none-txt-commentary-vs-selected-txt-unit"
                  >
                    <p className="none-txt-commentary-vs-selected-txt-unit-text">
                      אין פירוש על יחידת הטקסט הנבחרת
                    </p>
                  </div>
                );
              } else {
                return "";
              }
            })}
          {txtToShow &&
            txtToShow.map((item: any, idx: any) => {
              if (item.length > 0) {


                return (
                  <div
                    id={idx + "txt"}
                    onClick={e => unitTextClicked(idx, e)}
                    key={idx + "txt"}
                    className={
                      selectedUnitText !== idx
                        ? "txt-commentary-container"
                        : "txt-commentary-container-selected"
                    }
                  >
                    <ShowCommentaryTxt key={idx + "text"} txt={item} />
                  </div>
                );
              } else {
                return "";
              }
            })}
        </div>
      </div>
    </>
  );
};

export default ShowSfarim;
