import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CKEditor } from 'ckeditor4-react';
import "./SendMail.css";
import wikiService from "../../services/wikiService";
import {
    getUserName,
} from "../../store/selectors/selectors";
import { setIsIfame } from "../../store/actions/viewActions";
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as MihlolLogo } from "../../svg/logoqq.svg";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { ThunkDispatch } from "@reduxjs/toolkit";
import { ReactComponent as LineSendMail } from "../../svg/LineSendMail.svg";

interface Props {
    user: any;
}
const SendMail: React.FC<Props> = ({ user }) => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const [isEnd, setIsEnd] = useState(true);
    const [isSend, setIsSend] = useState(true);
    const [text, setText] = useState('שלום וברכה<br/> בקשר למה שכתב כבודו');
    const userName = useSelector(getUserName);
    const [subject, setSubject] = useState(userName === 0 ? '' : `הודעה מ${userName} - שיתופתא`);
    const [progress, setProgress] = useState(0);
    const onChangesubject = (e: any) => {

        setSubject(e.target.value)
    }

    useEffect(() => {
        const timer = setInterval(() => {
            if (!isEnd) {
                setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
            }

        }, 300);

        return () => {
            clearInterval(timer);
        };

    }, [dispatch, isEnd]);

    const handleSendNew = () => {
        var contentText = text;
        var contentSubject = subject;
        if (contentText && contentText !== ''.trim() && contentText.split('&nbsp;').join('') !== 'שלום וברכה<br/> בקשר למה שכתב כבודו' && contentSubject && contentSubject !== ''.trim()) {
            let contentTextlocal = contentText.split('<p>').join('').split('<strong>').join("<b>").split('</strong>').join("</b>").split('</p>').join('').split('<br />').join('').split('&nbsp;').join('').split('<br/>').join('\n').trim();

            setIsEnd(false)
            setIsSend(true)
            wikiService.sendEmail(
                user,
                contentSubject,
                contentTextlocal
            ).then((response: any) => {

                if (response.emailuser && response.emailuser.result === 'Success') {

                    setIsSend(false)
                    setTimeout(() => {
                        dispatch(setIsIfame(''));
                        setIsEnd(true)
                    }, 3000);
                }
                else {
                    setIsEnd(true)
                }
            })
        }
    }
    const handleChangeText = (e: any) => {
        setText(e.editor.getData());

        return;
    }
    const IsIfame = (e: any) => {
        dispatch(setIsIfame(''))
        return;
    }

    return (
        <div className="send-mail">

            {<div className="send-mail-main">
                <div className="loading-indicator-loadingsvg13" key={'g'}>
                    {!isEnd && <div className="loading-indicator-send-text">{isSend ? 'שולח...' : 'נשלח בהצלחה!'}</div>}
                    {isSend && !isEnd && <CircularProgress className="loading-indicator-send-circle" color={"secondary"} thickness={1} size={"1rem"} variant="determinate" value={progress} />}
                    {!isSend && !isEnd && <CircularProgress className="loading-indicator-send-circle" color={"secondary"} thickness={1} size={"1rem"} variant="determinate" value={100} />}
                    {isSend && !isEnd && <MihlolLogo className="loading-indicator-send" />}
                    {!isSend && !isEnd && <DoneIcon style={{
                        position: 'absolute',
                        right: 13,
                        height: 13,
                        color: '#38A09D',
                    }} />}

                </div>
                {isEnd && <div>
                    <div
                        className='send-mail-input-subject-title'
                    >
                        <div className='send-mail-input-subject-title-text'>
                            {` שלח הודעה`}
                        </div>
                        <CloseIcon
                            onClick={IsIfame}
                            style={{
                                position: 'absolute',
                                left: 5,
                                cursor: 'pointer',
                                color: '#707070'
                            }}
                            className="send-mail-input-subject-close-icon"
                        />
                    </div>
                    <input
                        type='text'
                        name='subject'
                        className='send-mail-input-subject-from'
                        defaultValue={`אל:    ${user}`}
                        maxLength={100}
                        readOnly
                    >
                    </input>
                    <div className='send-mail-input-subject'>
                        {'נושא:'}
                        <input
                            type='text'
                            name='subject'
                            className='send-mail-input-subject-text'
                            placeholder='כותרת...'
                            defaultValue={userName === 0 ? '' : `הודעה מ${userName} - שיתופתא`}
                            onChange={(e) => {
                                onChangesubject(e)
                            }}
                            maxLength={100}
                        ></input>
                    </div>

                </div>}
                {
                    <div className='send-mail-line'>
                        <LineSendMail />
                    </div>
                }
                {isEnd &&
                    <CKEditor
                        editorUrl={`${window.location.origin}/ckeditor/ckeditor.js`}
                        config={{
                            className: ['swdswdswd'],
                            language: ['he'],
                            editorplaceholder: ['כתוב את ההודעה שלך...'],
                            toolbarLocation: 'bottom',
                            toolbar: [
                                { name: 'clipboard', items: ['Cut', 'Copy', 'Paste'] },
                                { name: '', items: ['Undo', 'Redo'] },
                                { name: 'tools', items: ['Maximize'] },
                            ]
                        }}

                        initData='שלום וברכה<br/> בקשר למה שכתב כבודו'
                        onBeforeCommandExec={(event) => {
                            if (event && event.data)
                                if (event && event.data && event.data.name === "paste") {
                                    event.editor._.forcePasteDialog = true;
                                }

                        }}
                        onChange={(e) => { handleChangeText(e) }}
                    />}
                {isEnd && <div className="send-mail-submit-main">
                    <div
                        className={`${(text === '' || subject === '') ? 'send-mail-submit-empty' : 'send-mail-submit'}`}
                        onClick={handleSendNew}
                    >
                        <div
                            className={`${(text === '' || subject === '') ? 'send-mail-submit-text-empty' : 'send-mail-submit-text'}`}
                        >
                            {'שלח'}
                        </div>
                    </div >
                </div>}

            </div>}
        </div>)
}

export default SendMail;