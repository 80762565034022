import { ThunkDispatch } from "redux-thunk";
import pageService from "../../services/pageService";
import * as Types from "../types/types";
import history from "../../history/history";
import store from "../store";
import wikiService from "../../services/wikiService";
//TODO move to utils functions

export const getPageData = (book: string, pageNumber: any, isLastUnittext = 0) => {

  return async (dispatch: ThunkDispatch<{}, {}, any>) => {


    dispatch({ type: Types.LOADING_START });

    if (pageNumber && (pageNumber.toString()).includes(':')) {
      pageNumber = pageNumber.replace(':', '.');
    }

    const [data, layoutData, error, category, sectionNames]: any = await pageService.getPage(
      book,
      pageNumber
    );

    if (data === undefined) {
      dispatch({ type: Types.SET_ERROR, payload: error });
      dispatch({ type: Types.LOADING_FINISHED });
    } else {

      wikiService.getUserInfo().then((res) => {
        if (res && res.userinfo.id !== 0) {
          wikiService.AllUsers(res.userinfo.name).then((response) => {
            if (response.allusers && response.allusers[0] && response.allusers[0].WatchedPage)
              dispatch({ type: Types.SET_USER_PAGE, payload: response.allusers[0].WatchedPage });
          })
          dispatch({ type: Types.SET_USER_NAME, payload: res.userinfo.name });
          dispatch({ type: Types.SET_USER_ID, payload: res.userinfo.id });
          dispatch({ type: Types.SET_USER_FOLLOWERS, payload: res.userinfo.follow });
        }
        else {
          dispatch({ type: Types.SET_USER_NAME, payload: 0 });
          dispatch({ type: Types.SET_USER_ID, payload: 0 });
        }
      });

      dispatch({ type: Types.SET_NUMBER_OF_MEFASHIM, payload: Object.keys(data.commentary).length });
      dispatch({ type: Types.SET_CATEGORY_NAME_FROM_URL, payload: category });
      dispatch({ type: Types.SET_LAYOUT_DATA, payload: layoutData });
      dispatch({ type: Types.SET_PAGE, payload: data });
      setUrl();
      dispatch({ type: Types.LOADING_FINISHED });
      if (isLastUnittext) {
        dispatch({ type: Types.SELECT_TEXT_UNIT, payload: 0 });
      }
      dispatch({ type: Types.SET_SECTION_NAMES, payload: sectionNames });

    }
  };
};

// export const getPageLayoutData = (book: string, page: any) => {
//   return async (dispatch: ThunkDispatch<{}, {}, any>) => {
//     dispatch({ type: Types.LOADING_START });
//     const data = await pageService.getLayout(book, page);
//     // console.debug(`getPageLayoutData action for ${book} ${page}`, data);
//     if (data === undefined) {
//       dispatch({ type: Types.SET_ERROR, payload: "Could Not Get Page Layout" });
//       dispatch({ type: Types.LOADING_FINISHED });
//     } else {
//       dispatch({ type: Types.SET_LAYOUT_DATA, payload: data });
//       // dispatch({ type: Types.LOADING_FINISHED });
//     }
//   };
// };

export const setSelectedTextUnit = (unitIdx: number | null) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({ type: Types.SELECT_TEXT_UNIT, payload: unitIdx });

    const element = document.getElementById(`${window.location.pathname.split('/')[1]}_${unitIdx}`);
    if (element && document.referrer && !document.referrer.includes(document.location.host)) {
      element.scrollIntoView();
    }

    setUrl();
  };
};

export const setSelectedTextUnitFromUrl = (unitIdx: number | null) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({ type: Types.SELECT_TEXT_UNIT_FROM_URL, payload: unitIdx });
  };
};


export const setTabOpenRight = (section: string) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({ type: Types.SET_TAB_OPEN_RIGHT, payload: section });
    setUrl();
  };
};
export const setCompetition = (section: string) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({ type: Types.SET_COMPETITION, payload: section });
  };
};

export const setTabOpenLeft = (section: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({ type: Types.SET_TAB_OPEN_LEFT, payload: section });
    setUrl();
  };
};

export const clearTabOpenLeft = () => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({ type: Types.CLEAR_TAB_OPEN_LEFT });
    setUrl();
  };
};

export const clearTabOpenRight = () => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({ type: Types.CLEAR_TAB_OPEN_RIGHT });
    setUrl();
  };
};

export const setCommentaryselectChecked = (selectedCommentary: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: Types.SET_SELECTED_COMMENTARY_CHECKED,
      payload: selectedCommentary
    });
    setUrl();
  };
};

export const setCommentaryToShow = (commentaryToShow: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: Types.SET_COMMENTARY_TO_SHOW,
      payload: commentaryToShow
    });
    setUrl();
  };
};

export const setCommentaryToShowFromUrl = (commentaryToShow: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: Types.SET_COMMENTARY_TO_SHOW_FROM_URL,
      payload: commentaryToShow
    });
  };
};

export const setSelectedCommentaryFromUrl = (selectedCommentary: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: Types.SET_SELECTED_COMMENTARY_FROM_URL,
      payload: selectedCommentary
    });
  };
};

export const setBookNameFromUrl = (bookName: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: Types.SET_BOOK_NAME_FROM_URL,
      payload: bookName
    });
  };
};



export const setPageNumberFromUrl = (pageNumber: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: Types.SET_PAGE_NUMBER_FROM_URL,
      payload: pageNumber
    });
  };
};



export const setIsSfarimOn = (toggle: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: Types.SET_SEE_SFARIM_ON,
      payload: toggle
    });
  };
};

export const setIsBiyur = (toggle: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: Types.SET_IS_BIYUR,
      payload: toggle
    });
  };
};

export const setAllTopics = (toggle: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: Types.SET_ALL_TOPICS,
      payload: toggle
    });
  };
};
export const setAllTopicsIds = (toggle: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: Types.SET_ALL_TOPICS_IDS,
      payload: toggle
    });
  };
};
export const setNumberOfmefarshim = (toggle: Number) => {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: Types.SET_NUMBER_OF_MEFASHIM,
      payload: toggle
    });
  };
};





function setUrl() {
  const newPageStore = store.getState().page;
  const newViewStore = store.getState().view;

  let categoryName = newPageStore.pageData.navigation.categoryName;

  let bookName;
  if (newPageStore.pageData.navigation.book) {
    bookName = newPageStore.pageData.navigation.book;
  } else {
    bookName = newPageStore.bookNameFromUrl;
  }
  if (!bookName) {
    return;
  }
  let page = '';
  if (newPageStore && newPageStore.pageData.navigation.book && newPageStore.pageData.navigation.book.includes('Chapter')) {
    page = ', Chapter' + newPageStore.pageData.navigation.book.split(', Chapter')[1];
    bookName = newPageStore.pageData.navigation.book.split(', Chapter')[0];
  }
  else if (newPageStore && newPageStore.pageData.navigation.book && newPageStore.pageData.navigation.book.includes('Section')) {
    page = ', Section' + newPageStore.pageData.navigation.book.split(', Section')[1];
    bookName = newPageStore.pageData.navigation.book.split(', Section')[0];
  }
  else if (newPageStore && newPageStore.pageData.navigation.book && newPageStore.pageData.navigation.book.includes(`Mechilta d'Miluim`)) {
    page = `, Mechilta d'Miluim` + newPageStore.pageData.navigation.book.split(`, Mechilta d'Miluim`)[1];
    bookName = newPageStore.pageData.navigation.book.split(`, Mechilta d'Miluim`)[0];
  }
  else
    if (
      bookName.includes(',') &&
      isNaN(newPageStore.pageData.navigation.pageNumber) &&
      isNaN(Number(bookName.split(' ')[bookName.split(' ').length - 1])
      )) {


      let aaa = bookName.split(' ').join('_').split(",")
      bookName = aaa[0];


      if (aaa.length === 2)
        page = ',' + aaa[1].replace(' ', '_');
      else if (aaa.length > 2)
        for (var i = 1; i < aaa.length; i++) {
          page += ',' + aaa[i].split(' ').join('_');
        }
      //page = ',' + aaa[1].replace(' ', '_') + ',' + aaa[2].replace(' ', '_');
    }
    else if (categoryName[0] !== 'Talmud') {
      page = newPageStore.pageData.navigation.pageNumber.split(' ').join('');
    }
    else {
      page = newPageStore.pageData.id.split(' ')[newPageStore.pageData.id.split(' ').length - 1]
      if (!page) {
        return;
      }
    }



  const selectedUnitText = newPageStore.selectedUnitText;
  const commentaryToShow = newPageStore.commentaryToShow;
  const selectedCommentary = newPageStore.selectedCommentary;
  const openTabRight = newViewStore.openTabsRight;
  const openTabLeft = newViewStore.openTabsLeft;
  const currentEvents = newViewStore.currentEvents;
  const statusUuid = newViewStore.statusUuid;
  const usersFeed = newViewStore.usersFeed;
  const statusFeed = newViewStore.statusFeed;
  const recentchanges = newViewStore.recentchanges;


  let baseUrl = `/${bookName}/${page}`;
  let queryParams: any = [];
  let strUrl = baseUrl;

  if (selectedUnitText !== null) {
    queryParams.push(`selectedunittext=${selectedUnitText}`);
  }
  if (commentaryToShow) {
    //queryParams.push(`commentarytoshow=${commentaryToShow}`);
  }
  if (selectedCommentary.length > 0) {
    const commentaries = selectedCommentary.map((x: any) => Object.keys(x)[0]);
    queryParams.push(`commentary=${commentaries.join(",")}`);
  }
  if (openTabRight) {
    queryParams.push(`${openTabRight}`);
  }
  if (openTabLeft) {
    queryParams.push(`${openTabLeft}`);
  }
  if (currentEvents) {
    queryParams.push(`currentEvents=${currentEvents}`);
  }
  if (statusUuid) {
    queryParams.push(`statusUuid=${statusUuid}`);
  }
  if (usersFeed) {
    queryParams.push(`users=yes`);
  }
  if (recentchanges) {
    queryParams.push(`recentchanges=open`);
  }
  if (queryParams.length > 0) {
    queryParams = queryParams.join("&");
    strUrl += "?" + queryParams;
  }

  history.push(strUrl.split(' ').join('_'));
}