import React, { useState } from "react";
import { useDispatch } from "react-redux";
import "./FormPageConnectMobile.css";
import {
  setUserProfileView,

} from "../../store/actions/viewActions";
import wikiService from "../../services/wikiService";
import { ThunkDispatch } from "@reduxjs/toolkit";

const FormPageConnectMobile: React.FC = () => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  let userName = '';
  let password = '';
  const [isError, setIsError] = useState(false);


  const onChangeUserName = (e: any) => {
    userName = e.target.value;
    if (isError) {
      setIsError(false)
    }
  }

  const onChangePassword = (e: any) => {
    password = e.target.value;
    if (isError) {
      setIsError(false)
    }
  }

  const LogInOtAccount = () => {
    wikiService.LogIn(userName, password).then((resp) => {
      if (resp === 'PASS') {
        dispatch(setUserProfileView("HomePageMobile"));
        window.location.reload();
      }
      else if (resp === 'UI') {
        dispatch(setUserProfileView("FormPageForgetNew"));
      }
      else {
        setIsError(true)
      }
    })
  }

  return (
    <>
      <div className='form-page-mobile'>
        <div className='form-page-mobile-user-name'>
          <input type="text" className='form-page-mobile-user-name-input'
            placeholder='שם משתמש'
            onChange={(e) => {
              onChangeUserName(e)
            }}
          />
        </div>
        <div className='form-page-mobile-password'>
          <input type="password" className='form-page-mobile-password-input'
            placeholder='סיסמה'
            onChange={(e) => {
              onChangePassword(e)
            }}
          />
        </div>
        <div className='form-page-mobile-connect'
          onClick={LogInOtAccount}
        >
          <div className='form-page-mobile-connect-text'>
            {'התחבר'}
          </div>
        </div>
        <div className='form-page-mobile-exist-account'>
          {'אין לך עדיין חשבון?'}
        </div>
        <div className='form-page-mobile-sign-up'
          onClick={() => {
            dispatch(setUserProfileView("FormPageCreateMobile"));
          }}
        >
          <div className='form-page-mobile-sign-up-text'>
            {'הירשם'}
          </div>
        </div>
        <div className='form-page-mobile-trial'
          onClick={() => {
            dispatch(setUserProfileView("HomePageMobile"));
          }}
        >
          {'כניסה כאורח >>'}
        </div>
      </div>

    </>
  );
}




export default FormPageConnectMobile;
