
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Templates.css";
import { CKEditor } from 'ckeditor4-react';
import { Resizable } from "re-resizable";
import {
  setCkEdittorContent
} from "../../store/actions/viewActions";
import {
  getCkEdittorContent,
  getPageTitle,
  getSelectedUnitText
} from "../../store/selectors/selectors";
import { ThunkDispatch } from "@reduxjs/toolkit";
import wikiService from "../../services/wikiService";
const Templates: React.FC = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const ckEdittorContent = useSelector(getCkEdittorContent);
  const [isNone1, setIsNone1] = useState(true);
  const [bodyNew, setBodyNew] = useState(ckEdittorContent);
  const pageTitle = useSelector(getPageTitle);
  const selectedUnitText = useSelector(getSelectedUnitText);
  const handleChangeNew = (e: any) => {
    setBodyNew(e.editor.getData());
    dispatch(setCkEdittorContent(e.editor.getData()));
    return;
  }

 /* const handleSendNew = () => {
    var content = bodyNew;
    if (content && content !== ''.trim()) {
      var container_tanach1 = document.getElementById('show-hidushim-send1');
      if (container_tanach1)
        container_tanach1.innerHTML = '';
      setIsNone1(false);

      wikiService.handleSend(
        `חידוש:` + pageTitle + (selectedUnitText === null ? '0' : selectedUnitText) + `~${pageId.split(' ').join('_')}`,
        selectedUnitText,
        bodyNew,
        pageHidushim
      ).then((res) => {
        dispatch(setPageHidushim(res?.pageHidushim));
        setIsLoaded(false)
        setIsLoaded(true)
        handleFocus(null, 'show-hidushim-send-input-none', true ? 'כתוב את החידוש שלך ...' : 'יש לבחור יחידת טקסט', 'show-hidushim-send-input-none', ' ')
        setTimeout(() => {
          scrollIntoTextUnit(res?.workflow)
          setIsNone1(true);
        }, 1000);
      })
    }
  }*/


  return (
    <>
      <div className="templates-main">
        <Resizable
          minWidth={'20%'}
          minHeight={'100%'}
          maxWidth={'80%'}
          defaultSize={{
            width: 779,
            height: 550
          }}
        >
          <div className={`templates-explanations-main`} >
            <div className={`templates-explanations-text`}>
              <b>עקרונות לכתיבת סיכום</b><br />
              1. את הסיכום נפתח בכותרת ראשית, שם נפרט מה הנושא אותו אנו מסכמים.<br />
              2. אם הסיכום ארוך ומפורט [יותר מ-800 מילים], נזדקק גם לכותרות משנה שיבדילו בין הנושאים השונים.<br />
              3. את הכתיבה נסדר כפי סדר הדורות. אם אנו מסכמים סוגיא בגמרא, בתחילה נצטט את הגמ' אותה אנו מסכמים, אח"כ את שיטות האמוראים, לאחר מכן את דעות הראשונים, ורק אח"כ את דברי האחרונים ואת היוצא להלכה.<br />
              4. כשיש בסיכום שיטות רבות ונושאים מורכבים, חובה לחלק ולסכם כל שיטה בנפרד או כל נושא בנפרד. אם הנושאים מרובים מהשיטות, נכתוב על כל נושא בנפרד ונפרט את השיטות שבו. ואם השיטות מרובות מהנושאים, נסכם כל שיטה בנפרד ונכתוב את דעתה בכל הנושאים. בסוגיות מורכבות ניתן להיעזר ב"טבלה".<br />
              5. לכל דין המופיע בסיכום נצמיד מקור. המקור צריך להיות מדויק כדי שנוכל למצוא את הדין בנקל, אך אינו חלק מהטקסט ולכן יופיע בתוך סוגריים עגולים בכיתוב מוקטן [ניתן להיעזר בכפתור "מקור"].<br />
              6. גם סיכום צריך לסכם! לאחר שכתבנו ופירטנו את כל השיטות, נכתוב את היוצא להלכה בכמה שורות קצרות, או שנציין לסעיף בשו"ע בו נכתבה ההלכה.<br />
              7. הסיכום נועד גם להקל על הזכירה, לכן נסתפק בכתיבת השיטות בקיצור נמרץ ללא ביאורים וקושיות. כל תוספת שאינה חלק מהסיכום יכולה להיכתב בהערות שוליים.

            </div>

          </div>
        </Resizable>
        <div className={`templates-CKEditor`} >
          <div className="templates-button">
            <div className="templates-button-text">{'תבנית סיכום'}
            </div>
          </div>
          <CKEditor
            editorUrl={`${window.location.origin}/ckeditor/ckeditor.js`}
            initData={ckEdittorContent}
            config={{
              height: '445',
              language: ['he'],
              editorplaceholder: ['כתוב את החידוש שלך...'],
              toolbar: [
                { name: 'clipboard', items: ['Cut', 'Copy', 'Paste'] },
                { name: '', items: ['Undo', 'Redo'] },
                { name: 'paragraph', items: ['Blockquote'] },
                { name: 'basicstyles', items: ['Bold'] },
                { name: 'tools', items: ['Maximize', 'footnotes'] },

              ]
            }}
            onChange={(e) => { handleChangeNew(e) }}
            onBeforeCommandExec={(event: any) => {
              if (event && event.data)
                if (event && event.data && event.data.name === "paste") {
                  event.editor._.forcePasteDialog = true;
                }
            }}
          />
          {
            <div
              className={`${bodyNew === '' ? 'templates-send-post-rep-empty' : 'templates-send-post-rep'}`}
              //onClick={handleSendNew}
            >
              <div
                className={`${bodyNew === '' ? 'templates-send-post-rep-text-empty' : 'templates-send-post-rep-text'}`}
              >
                {'פרסום'}
              </div>
            </div >}
        </div>





      </div >
    </>
  )
}

export default Templates;
