import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import pageReducer from "./reducers/pageReducer";
import viewReducer from "./reducers/viewReducer";
import logger from "redux-logger";
import { composeWithDevTools } from '@redux-devtools/extension';

const rootReducer = combineReducers({
  view: viewReducer,
  page: pageReducer
});
let envT = 'dev';
if (window.location.origin === 'https://shitufta.org.il' || window.location.origin === 'https://dev.test.shitufta.org.il') {
  //if (true) {
  envT = 'prod';
}
const store = createStore(
  rootReducer,
  envT === 'prod' ? composeWithDevTools(applyMiddleware(thunk, logger)) : applyMiddleware(thunk)
);

export default store;
