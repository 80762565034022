import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import isMobile from "ismobilejs";
import {
  getOpenTabsRight,
  getIsSfarimOn,
  getIsBiyur,
  getIsLoading,
  getSelectedUnitText,
  getIsBiyurScrolling,
  getCategoryName,
  getPrevPage,
  getNumberOfmefarshim
} from "../../store/selectors/selectors";
import {
  clearTabOpenRight,
  setCommentaryToShow,
  setIsSfarimOn,
  setCommentaryselectChecked,
  setTabOpenRight,
  setSelectedTextUnit,
  getPageData
} from "../../store/actions/pageActions";
import ChooseSfarim from "../ChooseSfarim/ChooseSfarim";
import ShowSfarim from "../ShowSfarim/ShowSfarim";
import "./Sfarim.css";
import { ReactComponent as LeftArrowHeaderSvg } from "../../svg/arrow.svg";
import {
  handleChange
} from "../../utils/dataUtils";
import Tooltip from '@mui/material/Tooltip';
import {
  useStyles
} from "../../utils/funcUtils";
import { ThunkDispatch } from "@reduxjs/toolkit";
const Sfarim: React.FC = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const [isExpanded, setExpanded] = useState(false);
  const selectedUnitText = useSelector(getSelectedUnitText);
  const isSfarimOn = useSelector(getIsSfarimOn);
  const isByur = useSelector(getIsBiyur);
  const categoryName = useSelector(getCategoryName);
  const tabsOpened = useSelector(getOpenTabsRight);
  const isLoading = useSelector(getIsLoading);
  const isBiyurScrolling = useSelector(getIsBiyurScrolling);
  const prevPage = useSelector(getPrevPage);
  const numberOfmefarshim = useSelector(getNumberOfmefarshim);
  const classes = useStyles();
  const handleChange1 = (bookId: string) => {

    const data = handleChange(bookId, categoryName)
    if (data && data[0] && data[1]) {
      if (0 === selectedUnitText)
        dispatch(setSelectedTextUnit(null));
      dispatch(getPageData(data[0], data[1]));
    }
  };


  useEffect(() => {
    if (tabsOpened === "mefarshim") {
      var container_tanach = document.getElementById('container_tanach');
      if (container_tanach) {
        container_tanach.setAttribute('class', 'panel-right-side-container-none');
      }
      setExpanded(true);
    } else {
      var container_tanach1 = document.getElementById('container_tanach');
      if (container_tanach1) {
        container_tanach1.setAttribute('class', 'header-nav-subpage-container-tanach');
      }
      setExpanded(false);
    }
  }, [tabsOpened]);

  // scroll to the right unit text when open and when unit text get selected.
  useEffect(() => {
    if (isLoading) return;
    if (isSfarimOn && !isBiyurScrolling) {
      const showSfarimElement = document.getElementById(selectedUnitText + "txt");
      const sectionContainer = document.getElementById("txt-container");
      if (showSfarimElement && sectionContainer) {
        if (showSfarimElement.offsetHeight > 700) {
          setTimeout(function () {
            sectionContainer.scrollTo({
              top: showSfarimElement.offsetTop - 150
            });
          }, 750);
          ;
        } else {
          showSfarimElement.scrollIntoView({
            block: "center"
          });
        }
      }
    }
  }, [isSfarimOn, selectedUnitText, isExpanded, isBiyurScrolling, isLoading]);



  const expandTabHandler = () => {
    if (isLoading) return;
    if (tabsOpened === null || tabsOpened === "biyur") {
      dispatch(setTabOpenRight("mefarshim"));
      setTimeout(function () {
        setExpanded(true);
      }, 50);
    }
  };

  const collapseTabHandler = () => {
    setExpanded(false);
    dispatch(clearTabOpenRight());
  };

  const cleanAllSelectedCommentary = (event: any) => {

    dispatch(setCommentaryselectChecked([]));
    dispatch(setCommentaryToShow(null));
    dispatch(setIsSfarimOn(false));
  };


  return (
    <>
      <div
        className={isExpanded ? "expended-container" : "not-expanded-container"}
      >
        {!isExpanded && !(isMobile().phone || isMobile().tablet) && (
          <div className="header-not-expanded">
            <Tooltip
              classes={classes}
              title={`עמוד קודם`}
              placement="left"
              arrow
            >
              <div className={prevPage === '' ? 'panel-plus-sign-svg-right-empty' : "panel-plus-sign-svg-right"}
                onClick={() => handleChange1(prevPage)}>

                {!(isMobile().phone || isMobile().tablet) && <div className="nav-arrow-button-left">
                  <LeftArrowHeaderSvg />
                </div>}
                {!(isMobile().phone || isMobile().tablet) && <div className="nav-arrow-button-left">
                  <LeftArrowHeaderSvg />
                </div>}
              </div>
            </Tooltip>

            <div className="book-page-panel-header-s"
              onClick={expandTabHandler}
            >
              <div className="book-page-panel-header-s-text">מפרשים</div>
              {false && numberOfmefarshim !== 0 && <div className="book-page-panel-header-h-number-r">{numberOfmefarshim}</div>}

            </div>
          </div>
        )}

        {isExpanded && !isSfarimOn && categoryName && (categoryName[0] !== 'Talmud' || !isByur) && (
          <>

            <div className="section-content-sfarim">
              <ChooseSfarim />
            </div>
          </>
        )}
        {isExpanded && isSfarimOn && (
          <>

            <p
              onClick={cleanAllSelectedCommentary}
              className="panel-expanded-header-title-sfarim-clean-all-txt"
            >
              נקה הכל
            </p>
            <div className="section-content-sfarim">
              <ShowSfarim />
            </div>
          </>
        )}
      </div>
      {isExpanded && !(isMobile().phone || isMobile().tablet) && (
        <div className="header-not-expanded">

          <Tooltip
            classes={classes}
            title={`עמוד קודם`}
            placement="left"
            arrow
          >
            <div className={prevPage === '' ? 'panel-plus-sign-svg-right-open-empty' : "panel-plus-sign-svg-right-open"}
              onClick={() => handleChange1(prevPage)}
            >

              {!(isMobile().phone || isMobile().tablet) && <div className="nav-arrow-button-left">
                <LeftArrowHeaderSvg />
              </div>}
              {!(isMobile().phone || isMobile().tablet) && <div className="nav-arrow-button-left">
                <LeftArrowHeaderSvg />
              </div>}
            </div>
          </Tooltip>

          <div className="book-page-panel-header-s-open"
            onClick={collapseTabHandler}
          >
            <div className="book-page-panel-header-s-text">מפרשים</div>
            {false && numberOfmefarshim !== 0 && <div className="book-page-panel-header-h-number-r">{numberOfmefarshim}</div>}

          </div>
        </div>
      )}
    </>
  );
};

export default Sfarim;
