import React from "react";

import "./NotFound.css";


interface INotFoundProps {
  text: string;
}

const NotFound: React.FC<INotFoundProps> = ({ text }) => {
  return (
    <div >
     

    </div>
  );
};

export default NotFound;
