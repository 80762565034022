import React, { useState } from "react";

import "./FormPageForgetNew.css";
import { ReactComponent as MihlolLogo } from "../../svg/logoBig.svg";
import { ReactComponent as LogoWord } from "../../svg/logoWord.svg";
import wikiService from "../../services/wikiService";

const FormPageCreateNew: React.FC = () => {
  const isError = useState(false);
  let retype = '';
  let password = '';
  const errorValue = useState('סיסמא זמנית נשלחה לאימייל שלך ');

  const onChangeRetype = (e: any) => {
    retype = e.target.value;
  }

  const onChangePassword = (e: any) => {
    password = e.target.value;
  }

  const LogInOtAccount = (ExamplePassword: any, rePassword: any) => {
    wikiService.changeauthenticationdata(ExamplePassword, rePassword)
  }


  return (
    <>
      <div className='flex'>
        <div className='form-page-logo'>
          <MihlolLogo />
        </div>
        <div className='form-page-logo-word'>
          <LogoWord />
        </div>
      </div>
      <div className='form-page-connect-forget-main'>
        <input
          type='password'
          name='password'
          autoComplete="on"
          className='form-page-username'
          placeholder='סיסמה חדשה'
          onChange={(e) => {
            onChangePassword(e)
          }}
          maxLength={40}
        >
        </input>
        <input
          type='password'
          autoComplete="on"
          name='retype'
          className='form-page-username'
          placeholder='חזרה על הסיסמה החדשה'
          onChange={(e) => {
            onChangeRetype(e)
          }}
          maxLength={40}
        >
        </input>
        {isError && <div className='form-page-connect-forget-error'>
          {errorValue as any}
        </div>}
        <div className='form-page-connect-forget'
          onClick={() => LogInOtAccount(password, retype)}
        >
          <div className='form-page-connect-forget-text'>
            {'שנה סיסמה'}
          </div>
        </div>
      </div>
    </>
  );
}

export default FormPageCreateNew;
