import React from "react";

import FormPageConnect from "../FormPageConnect/FormPageConnect";
import FormPageCreateYoma from "../FormPageCreateYoma/FormPageCreateYoma";
import FormPageForget from "../FormPageForget/FormPageForget";
import FormPageCreateNew from "../FormPageForgetNew/FormPageForgetNew";
import FormPageCreateMobile from "../FormPageCreateMobile/FormPageCreateMobile";
import { useSelector } from "react-redux";
import "./FormPage.css";
import {
  getIsUserProfile,
  getUserProfileView,
  getIsPidOpen1
} from "../../store/selectors/selectors";


import isMobile from "ismobilejs";
const FormPage: React.FC = () => {
  const isUserProfile = useSelector(getIsUserProfile);
  const userProfileView = useSelector(getUserProfileView);
  const isPidOpen1 = useSelector(getIsPidOpen1);
  return (
    <div className={!isUserProfile ? `form-page-main ${isPidOpen1 ? 'txtx' : ''}` : 
    (isMobile().phone || isMobile().tablet)&& userProfileView === 'FormPageCreateYoma' ? 'form-page-main-popup-mobile':'form-page-main-popup'}>
      {userProfileView === 'FormPageConnect' && <FormPageConnect />}
      {userProfileView === 'MenuYomaMobile' && <FormPageConnect />}
      {userProfileView === 'FormPageCreateYoma' && <FormPageCreateYoma />}
      {userProfileView === 'FormPageForget' && <FormPageForget />}
      {userProfileView === 'FormPageCreate' && <FormPageCreateMobile />}
      {userProfileView === 'FormPageForgetNew' && <FormPageCreateNew />}
    </div>
  );
}

export default FormPage;
