import { ITextUnit } from "../models/types";
import { IRenderUnit, IRenderWords, IWordUnit } from "../models/interfaces";
import config from "../services/configService";



export function augmentPageText(text: Array<string>): ITextUnit {
  return text.map(data => [data]);
}


export function getFirstWord(unitText: any) {

  let removedWord;
  let newArray = [];
  for (let i = 0; i < unitText.length; i++) {
    if (i !== 0) {
      newArray.push(unitText[i]);
    } else {
      removedWord = unitText[i][0]
        .split(" ")
        .slice(0, 1)
        .join(" ");
      let slice = 1;
      if (removedWord === 'מתני׳' || removedWord === "מתני'") {
        slice = 2;
        removedWord = unitText[i][0]
          .split(" ")
          .slice(1, 2)
          .join(" ");
      }
      let arrayWithoutFirstWord = unitText[i][0]
        .split(" ")
        .slice(slice)
        .join(" ");
      newArray.push([arrayWithoutFirstWord]);
    }
  }
  return { removedWord, newArray };
}


export function insertBoldTag(array: any): any {

  let text = array.split(" ");


  //TODO add if there is a text add big .
  if (text.length >= 1)
    if (text[0] === "" || text[0] === ".") {
      text.shift();
      if (text.length >= 1)
        return insertBoldTag(text.join(" "));
      else
        return array;
    }
    else if (array.includes("~") && array.includes("`")) {
      let text1 = array.split("`");
      insertBoldTag(text1[1]);
      return insertBoldTag(text1[0]) + " " + insertBoldTag(text1[1]);
    }

  if (!array.includes("~")) {
    var lastCharT = text[0].slice(-1);
    let chekIfImag = false;
    text[0] = "<big><strong>" + text[0] + "</strong></big>";
    if (lastCharT !== ".") {
      for (let i = 1; i < text.length; i++) {


        if (text[i].length > 1000) {
          text[i] = "~ <img " + text[i];
          text[i - 1] = text[i - 1].replace('<br>', '');
          //   text[i - 1] = text[i - 1].replace('<img', '');

          chekIfImag = true;
        }
      }

      if (!chekIfImag)
        for (let i = 1; i < text.length; i++) {
          if (text[i] === "–" || text[i] === "-") {
            text[i - 1] = text[i - 1].replace('</big>', '') + ".</big>";
            text.splice(i, 1);
            break;
          }
          if (text[i] === undefined)
            break;
          var lastChar = text[i].slice(-1);
          text[i] = "<big>" + text[i] + "</big>";
          if (lastChar === ".") {
            break;
          }
        }
    }
  }
  else if (array.includes("~")) {
    //text = array.replace("~", "").split(" ");
  }
  for (let i = 1; i < text.length; i++) {
    if (text[i] === "." || text[i] === "'") {
      text[i] = "<a></a>";
    }
    else if (text[i].replace("<big>", "").replace("</big>", "") === ".") {
      text[i - 1] = text[i - 1].replace("</big>", ".</big>");
      text.splice(i, 1);
    }
    else if (text[i] === "...") {
      text[i] = "<a></a>";
    }
  }


  return text.join(" ");
}


export function boldRashiTosafot(unitText: any, prefix: any, pageTitle: any) {


  let newArray = unitText.map((innerUnit: any, index: any) => {

    if (innerUnit && innerUnit.length < 1) {
      return innerUnit;
    } else if (innerUnit && innerUnit.length >= 1) {
      if (prefix === 'tosafot' || (unitText[index - 1] && unitText[index - 1][0] && unitText[index - 1][0].includes('הדרן עלך'))) {
        //              regex for string contained in parentheses


        return innerUnit.map((textArray: any) => {
          textArray = textArray.replace('- -', '-')
          const dafMasechet = pageTitle.split(' ').splice(-2).join(' ')
          const nameMasechet = pageTitle.replace(dafMasechet, '');

          textArray = textArray.split(`(${nameMasechet}`).join('( ')
          textArray = textArray.split(`\n`).join('')
          textArray = textArray.split(`(לקמן ${nameMasechet}`).join('(לקמן ')
          textArray = textArray.split(`(לעייל ${nameMasechet}`).join('(לעייל ')
          textArray = textArray.split(`(לעיל ${nameMasechet}`).join('(לעיל ')

          if (textArray.split('(').length > 1 && textArray.split('(').length === textArray.split(')').length) {
            const regExp = /\(([^)]+)\)/g;
            const matches = [...textArray.match(regExp)];
            matches.forEach((item: string) => {

              const array1 = textArray.split(item);
              const array2 = item.split('(').join('').split(')').join('').split(' דף')[0];
              if (array1[0].includes(array2)) {
                textArray = textArray.replace(item, item.replace(array2 + ' ', ''))
              }
              if (item.split(':').length > 1 && item.split(':)').length === 1 && item.split(': ושם').length === 1) {
                textArray = textArray.replace(item, item.split(':')[0] + ')');
              }
              // if (item.split(':').length > 1 && item.split(':)').length === 1 && item.split(': ושם').length === 1) {
              textArray = textArray.replace(item, `(<small>${item.replace('(', '').replace(')', '').split(' ').join('</small> <small>')}</small>)`);
              //}

            }
            )
          }

          if (!textArray.includes('<small>')) {

            textArray = textArray.split('(').join('(<small>').split(')').join('</small>)')
          }

          if (textArray.startsWith("מתני'") || textArray.startsWith("מתני׳")) {

            return insertBoldTag(textArray.replace(`מתני'`, ' ').replace(`מתני׳`, ' ').replace(` גמ'`, ' ').replace(` גמ׳`, ' '))
          }
          return insertBoldTag(textArray)

        });

      } else {

        return innerUnit.map((textArray: any) => {
          textArray = textArray.split(`\n`).join('')
          if (textArray.split('(').length > 1 && textArray.split('(').length === textArray.split(')').length) {
            const regExp = /\(([^)]+)\)/g;
            const dafMasechet = pageTitle.split(' ').splice(-2).join(' ')
            const nameMasechet = pageTitle.replace(dafMasechet, '');
            textArray = textArray.split(`(${nameMasechet}`).join('(')
            textArray = textArray.split(`(לקמן ${nameMasechet}`).join('(לקמן ')
            textArray = textArray.split(`(לעייל ${nameMasechet}`).join('(לעיל ')
            textArray = textArray.split(`(לעיל ${nameMasechet}`).join('(לעיל ')

            const matches = [...textArray.match(regExp)];

            matches.forEach((item: string) => {
              const array1 = textArray.split(item);
              const array2 = item.split('(').join('').split(')').join('').split(' דף')[0];
              if (array1[0].includes(array2)) {
                textArray = textArray.replace(item, item.replace(array2 + ' ', ''))
              }
              if (item.split(':').length > 1 && item.split(':)').length === 1)
                textArray = textArray.replace(item, item.split(':')[0] + ')');
              textArray = textArray.replace(item, `(<small>${item.replace('(', '').replace(')', '').split(' ').join('</small> <small>')}</small>)`);


            }
            )
          }
          if (!textArray.includes('<small>')) {
            textArray = textArray.replace('(', '(<small>').replace(')', '</small>)');
          }

          return insertBoldTag(textArray)
        });
      }
    }
    else return []
  });

  return newArray;
}


export function flatTextUnits(textUnits: ITextUnit, prefix: string): Array<IWordUnit> {
  const flatText: Array<IWordUnit> = [];
  textUnits.forEach((textUnit: Array<string>, index: number) => {


    if (textUnit)
      textUnit.forEach((line: string) => {


        if (line !== '' && prefix === "rashi" || prefix === "tosafot") {
          line = line.split(', ').join(' ');
          line = line.split('?').join('');
          line = line.split('!').join('');
          line = line.split('–').join('');
          line = line.split('.:').join(':');
          if (line.slice(-1) === '.') {
            line = line.substring(0, line.length - 1);
          }
          line = line.split('. ').join(' ');
          if (!line.includes('):'))
            line = line.split(':').join('') + ':';
          if (line.split('":').length > 1)
            line = line.split('":').join('') + ':';
          line = line.split(' ע"א').join('.');
          line = line.split(' ע"ב').join(':');
        }
        line = line.split(' :').join(':');
        line = line.split('" ').join(' ');
        line = line.split(' "').join(' ');

        line.split(" ").forEach((word: string) => {
          if (!word) {
            return;
          }
          line = line.trim();

          flatText.push({
            word,
            index
          });
        });
      });
  });
  return flatText;
}

export function flatTextUnitsT(textUnits: any): Array<IWordUnit> {
  const flatText: Array<IWordUnit> = [];
  if (typeof textUnits !== 'string')
    textUnits.forEach((word: string, index: number) => {
      if (!word) {
        return;
      }
      flatText.push({
        word,
        index
      });
    });
  else {

    textUnits.split('`').forEach((word: string, index: number) => {
      if (!word) {
        return;
      }
      flatText.push({
        word,
        index
      });
    });
  }
  return flatText;


}

export function isnumber(bookId: string) {

  return isNaN(Number(bookId.split(' ')[bookId.split(' ').length - 1]))
}

export function handleChange(bookId: string, categoryName: any) {
  if (bookId && bookId.includes('Chapter')) {
    return [bookId.split(", Chapter ")[0].trim(), ",_Chapter_" + bookId.split(", Chapter ")[1].trim()]
  }

  if (bookId && bookId.includes('Section')) {
    return [bookId.split(", Section ")[0], ",_Section_" + bookId.split(", Section ")[1]]
  }

  if (bookId && bookId.includes(',') && isnumber(bookId) &&
    (
      categoryName[0] !== 'Halakhah' ||
      (categoryName[0] === 'Halakhah' && categoryName.length === 1)) &&
    !bookId.includes('Hagahot Mordechai')) {
    const splitBookid = decodeURIComponent(bookId).split(",");
    const page = splitBookid[0];
    splitBookid.shift();
    const book = splitBookid.join(",");
    splitBookid.shift();
    return [page.trim(), ',' + book.split(" ").join("_")]
  }

  if (bookId && bookId.includes(';')) {
    const splitBookid = bookId.split(",");
    const page = splitBookid[0];
    splitBookid.shift();
    const book = splitBookid.join(";");
    splitBookid.shift()
    return [page.trim(), ',' + book.split(" ").join("_")]
  }


  if (bookId) {
    const splitBookid = bookId.split(" ");
    const page = splitBookid.pop();
    const book = splitBookid.join("_");
    if (page)
      return [book, page]
  }
}
export function generateRenderUnits(
  flatTextUnits: Array<IWordUnit>,
  renderWordsArr: Array<IRenderWords>,
  prefix: string
): Array<IRenderUnit> {
  const renderUnits: Array<IRenderUnit> = [];
  let wordIndex = 0;
  let lastUnit = 0;
  let warnOutput = false;
  renderWordsArr.forEach(renderWords => {
    if (window.location.origin !== 'https://shitufta.org.il' && wordIndex >= flatTextUnits.length) {
      if (!warnOutput) {
        warnOutput = true;
        console.warn(
          "generateRenderUnits has no more words but mapping has additional rows"
        );
      }
      return;
    }
    const renderUnit: IRenderUnit = {
      size: renderWords.size,
      rowAlign: renderWords.rowAlign,
      textAlign: renderWords.textAlign,
      isChapterEnd: renderWords.isChapterEnd,
      nudgeDiff: renderWords.nudgeDiff,
      text: [],
      block: renderWords.block,
      top: renderWords.top
    };
    let words: Array<string> = [];

    for (let i = 0; i < renderWords.words; i++) {
      const unitWord = flatTextUnits[wordIndex];
      if (prefix !== 'gmara' && unitWord && unitWord.word) {
        if (unitWord && unitWord.word && unitWord.word.startsWith('"')) {
          unitWord.word = unitWord.word.replace('"', '');
        }
        if (unitWord.word.endsWith('"')) {
          unitWord.word = unitWord.word.replace('"', '');
        }

        if (unitWord.word === '-' || unitWord.word === ';') {
          unitWord.word = '';
        }
      }


      if (unitWord && unitWord.index !== lastUnit) {
        if (words.length) {
          renderUnit.text.push({
            unit: lastUnit,
            text: words.join(" ")
          });
          words = [];
        }
        lastUnit = unitWord.index;
      }
      if (unitWord) {
        if (unitWord.word !== '') {
          words.push(unitWord.word);
        }
      }

      wordIndex++;
      if (window.location.origin !== 'https://shitufta.org.il' && wordIndex >= flatTextUnits.length && i + 1 < renderWords.words) {
        if (!warnOutput) {
          warnOutput = true;
          console.warn(
            "generateRenderUnits has no more words but mapping has additional rows"
          );
          console.log(`stopped at line ${i}`);
        }
        break; // TOOD log warn
      }
    }
    if (words.length) {


      renderUnit.text.push({
        unit: lastUnit,
        text: words.join(" ")
      });
    }
    if (renderUnit.text.length) {
      renderUnits.push(renderUnit);
    }
  });
  if (window.location.origin !== 'https://shitufta.org.il' && wordIndex < flatTextUnits.length) {
    console.warn(
      `generateRenderUnits completed but ${flatTextUnits.length -
      wordIndex} words are left out, ${prefix}`
    );
  } else if (window.location.origin !== 'https://shitufta.org.il' && !warnOutput) {
    console.info("generateRenderUnits full match, "); // TODO change to debug?
  }


  return renderUnits;
}


export function getSpanStyle(nudgeDiff: string) {
  // @ts-ignore
  let styleObj;
  const test = nudgeDiff.split(":");
  if (test[0].length === 10 || test[0].length === 11) {
    styleObj = { marginRight: Number(test[1]) + "px" };
  }
  return styleObj;
}

export function getMasoretHshas(x: any) {
  let title;
  if (x.title.includes('אורח חיים'))
    title = x.title.replace('אורח חיים', 'או"ח');
  if (x.title.includes('ספר מצוות גדול'))
    title = x.title.replace('ספר מצוות גדול', 'סמ"ג');
  if (x.title.includes('יורה דעה'))
    title = x.title.replace('יורה דעה', 'יו"ד');
  if (x.title.includes('חושן משפט'))
    title = x.title.replace('חושן משפט', 'חו"מ');
  if (x.title.includes('משנה תורה')) {
    title = x.title.replace('משנה תורה,', "מיי'");
  }


  // (x.txt.includes('Shulchan Arukh,') ? 'טוש"ע, ' : '') + x.title.split(':').join(' ').replace('משנה תורה,', "מיי'").replace("הלכות", "הל'")
  if (title)
    return title.split(':').join(' ').replace('-', ' - ');
  else {
    return x.title.split(':').join(' ').replace('-', ' - ').replace("הלכות", "הל'")
  }
}


export function getRowTopMargin(top: any) {
  let styleObj;
  if (top > 15 && top < 90)
    top = 15;
  if (top === 27)
    top = 5;

  if (top > 0) {
    styleObj = { marginTop: top + "px" };
    return styleObj;
  } else return {};
}


export const groupBy = (array: any) => {
  let groupedArray: any = [[], []];
  for (let i = 0; i < array.length; i++) {
    if (array[i].block === 1) {
      groupedArray[0].push(array[i]);
    } else if (array[i].block === 2) {
      groupedArray[1].push(array[i]);
    }
  }

  return groupedArray;
};


export const checkCondition = (
  fix: any,
  pageName: any,
  pageNumberInt: any,
  subPage: any
) => {
  let pageCondition;
  //default condition only for book
  let equalPageCondition = fix.condition.book === pageName;
  //check if there is condition for subpage
  if (fix.condition.subPage) {
    equalPageCondition =
      fix.condition.book === pageName &&
      fix.condition.pageNumber === pageNumberInt &&
      fix.condition.subPage === subPage;
    //  if dont check if there is condition for page
  } else if (fix.condition.pageNumber) {
    equalPageCondition =
      fix.condition.book === pageName &&
      fix.condition.pageNumber === pageNumberInt;
  }
  switch (fix.condition.pageNumberCondition) {
    case "eq":
      pageCondition = equalPageCondition;
      break;
    case "gt":
      pageCondition =
        fix.condition.book === pageName &&
        fix.condition.pageNumber < pageNumberInt;
      break;
    case "gte":
      pageCondition =
        (fix.condition.book === pageName &&
          fix.condition.pageNumber === pageNumberInt &&
          fix.condition.subPage === subPage) ||
        (fix.condition.book === pageName &&
          fix.condition.pageNumber < pageNumberInt);
      break;
    default:
      pageCondition = null;
  }
  return pageCondition;
};

export const manualDataFixesSfarim = (
  fixes: any,
  pageName: any,
  pageNumberInt: any,
  subPage: any
) => {
  const findFix = fixes.find((fix: any) =>
    checkCondition(fix, pageName, pageNumberInt, subPage)
  );
  let removeFromData: any = config.sfarimToAlwaysRemove;
  if (findFix === undefined) {
    // removeFromData.push("Rashi");
    return removeFromData;
  }
  if (findFix.fixes.fixSfarim) {
    return removeFromData.concat(findFix.fixes.fixSfarim);
  } else {
    // removeFromData.push("Rashi");
    return removeFromData;
  }
};

export const manualDataFixes = (
  pageNumberInt: number,
  subPage: any,
  pageObject: any,
  fixes: Array<any>
) => {

  const findFix = fixes.find((fix: any) =>
    checkCondition(fix, pageObject.navigation.book, pageNumberInt, subPage)
  );


  if (findFix === undefined) return pageObject;
  if (findFix) {
    //setting up rashi commentary
    if (findFix.fixes.Rashi) {

      pageObject.commentary.Rashi = findFix.fixes.Rashi.map(
        (name: string, index: number, array: string[]) => {
          return pageObject.commentary[name];
        }
      );
      if (findFix.fixes.Rashi.length >= 1) {
        pageObject.commentary.Rashi = pageObject.commentary.Rashi.flat();
      }
    }
    //setting up tosafot commentray
    if (findFix.fixes.Tosafot) {
      pageObject.commentary.Tosafot = findFix.fixes.Tosafot.map(
        (name: string, index: number, array: string[]) => {
          return pageObject.commentary[name];
        }
      );
      if (findFix.fixes.Tosafot.length >= 1) {
        pageObject.commentary.Tosafot = pageObject.commentary.Tosafot.flat();
      }
    }
    // removing words
    if (findFix.fixes.textToRemove) {
      const textToRemove = findFix.fixes.textToRemove;
      textToRemove.forEach((removeInfo: any) => {
        pageObject.commentary[removeInfo.commenterKey][
          removeInfo.textUnitIndex
        ][removeInfo.commentaryIndex] = "";
      });
    }
    // adding text to unit text
    if (findFix.fixes.textToInsert) {
      const textToInsert = findFix.fixes.textToInsert;
      textToInsert.forEach((textInfo: any) => {
        let text = textInfo.commentaryText;
        pageObject.commentary[textInfo.commenterKey][
          textInfo.textUnitIndex
        ].splice(textInfo.commentaryIndex, 0, text);

      });
    }

  }
  return pageObject;
};

//toto if is teanach
export function getTitleEn(titleList: any, title: string) {
  for (var key in titleList) {
    for (var key1 in titleList[key]) {
      if (title.includes(titleList[key][key1].name))
        return titleList[key][key1].nameHe;
      for (var key2 in titleList[key][key1].books) {
        if (title.includes(titleList[key][key1].books[key2].heName))
          return titleList[key][key1].books[key2].bookName;
      }
    }
  }
  return titleList;
}


export function parseBookPageToHebrewStr(name: any) {
  var smallGematria = require('small-gematria');
  let counter = 0;
  for (var i = 0; i < name.length; i++) {
    counter += smallGematria(name[i]);
  }
  return counter;
}

export function getPageEn(title: string, number: number, char = '_') {
  if (!title.includes(':')) {
    if (number === 1) {
      return title.split(`${char}`)[title.split(`${char}`).length - number].replace(/[0-9]/g, '') === 'א' ? 'a' : 'b';
    }
    if (number === 2) {
      return title.split(`${char}`)[title.split(`${char}`).length - number].replace(/[0-9]/g, '');
    }
  }
  else {
    return title.split(`${char}`)[1].split('׳').join('').split(':').join(' ').split('-').join(' ');
  }
  return title;
}