import React, { useEffect, useState } from "react";
import "./HeaderNav.css";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import config from "../../services/configService";
import SearchService from "../../services/searchServer";
import { ReactComponent as Sfarim } from "../../svg/sfarimResult.svg";
import {
  setIsUserProfile,
  setIsUserProfileOpen,
  setUserProfileView
} from "../../store/actions/viewActions";
import { ReactComponent as MihlolLogo } from "../../svg/logo.svg";
import { ReactComponent as Line } from "../../svg/line.svg";
import { ReactComponent as Profile } from "../../svg/profile.svg";
import { ReactComponent as Sfarimdown } from "../../svg/sfarim.svg";
import { ReactComponent as Mechadshim } from "../../svg/mechadshim.svg";
import { ReactComponent as Last } from "../../svg/last.svg";
import { ReactComponent as ProfileIn } from "../../svg/profileIn.svg";
import { ReactComponent as SearchIcondown } from "../../svg/searchIcon.svg";
import { ReactComponent as BellSub } from "../../svg/bellSub.svg";
import { ReactComponent as Notifications } from "../../svg/notifications.svg";
import { ReactComponent as Email } from "../../svg/email.svg";
import { ReactComponent as SearchCircle } from "../../svg/searchCircle.svg";
import {
  getIsLoading,
  getIsMenuOpen,
  getIsPidOpen,
  getIsPidOpen1,
  getIsUserProfileOpen,
  getUserid,
  getNotificationsOpen,
  getEmailOpen,
  getPageTitle,
  getNotificationsAll,
  getIsOnLinePartyOpen,
  getMyTopics,
  getIsOpenNavPage
} from "../../store/selectors/selectors";
import OpenNavYoma from "../OpenNavYoma/OpenNavYoma";
import OpenNav from "../OpenNav/OpenNav";
import OpenNav1 from "../OpenNav1/OpenNav1";
import OpenPid from "../OpenPid/OpenPid";
import OpenPid1 from "../OpenPid1/OpenPid1";
import OpenPid2 from "../OpenPid2/OpenPid2";
import Message from "../Message/Message";
import OnLineParty from "../OnLineParty/OnLineParty";
import { getPageData } from "../../store/actions/pageActions";
import {
  closeMenu,
  openMenu,
  closeMenuYoma,
  closeMenu1,
  setclosePid,
  setOpenPid,
  setclosePid1,
  setOpenPid1,
  setclosePid2,
  setNotificationsOpen,
  setEmailOpen,
  setMyTopic,
  setIsOnLineParty,
} from "../../store/actions/viewActions";
import isMobile from "ismobilejs";
import history from "../../history/history";
import UserProfileMobile from "../UserProfileMobile/UserProfileMobile";
import Tooltip from '@mui/material/Tooltip';
import {
  useStyles
} from "../../utils/funcUtils";
import { ThunkDispatch } from "@reduxjs/toolkit";
const HeaderNav = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const isLoading = useSelector(getIsLoading);
  const isMenuOpen = useSelector(getIsMenuOpen);
  const isOnLinePartyOpen = useSelector(getIsOnLinePartyOpen);
  const isPidOpen = useSelector(getIsPidOpen);
  const isPidOpen1 = useSelector(getIsPidOpen1);
  const userid = useSelector(getUserid);
  const isUserProfileOpen = useSelector(getIsUserProfileOpen);
  const notificationsOpen = useSelector(getNotificationsOpen);
  const emailOpen = useSelector(getEmailOpen);
  const bookName = useSelector(getPageTitle);
  const myTopics = useSelector(getMyTopics);
  const notificationsAll = useSelector(getNotificationsAll);
  const isOpenNavPage = useSelector(getIsOpenNavPage);
  const classes = useStyles();


  
  useEffect(() => {
    if (!isLoading) {
      setTimeout(function () {
        scrollIntoSelectedCommentaryToShow();
      }, 950);
    }
  }, [isLoading]);

  const handleToggleMenu = () => {
    if (isLoading) return;
    if (!isMenuOpen) {
      dispatch(closeMenu1());
      dispatch(setclosePid());
      dispatch(setclosePid1());
      dispatch(setclosePid2());
      dispatch(closeMenuYoma());

      dispatch(openMenu());
      history.push('?currentEvents=basic')
    } else {
      history.push('?')
      dispatch(closeMenu());
    }
  };




  const handleTogglePid = () => {
    if (isLoading) return;
    if (!isPidOpen) {
      dispatch(closeMenu1());
      dispatch(closeMenu());
      dispatch(setclosePid1());
      dispatch(setclosePid2());
      dispatch(setOpenPid());
      dispatch(closeMenuYoma());
      history.push('?users=yes')
    } else {
      dispatch(setclosePid());
      history.push('?')
    }
  };

  const handleTogglePid1 = () => {
    if (isLoading) return;
    if (!isPidOpen1) {
      dispatch(closeMenu1());
      dispatch(closeMenu());
      dispatch(setclosePid());
      dispatch(setclosePid2());
      dispatch(setOpenPid1());
      dispatch(closeMenuYoma());
      history.push(`${window.location.pathname}?recentchanges=open`);
    } else {
      dispatch(setclosePid1());
    }
    dispatch(setMyTopic(''));
  };



  function scrollIntoSelectedCommentaryToShow() {
    const showElement = document.getElementById("commentary-item-selected");
    const sectionContainer = document.getElementById("showSfarim");
    if (showElement && sectionContainer) {
      sectionContainer.scrollTo({
        behavior: "smooth",
        top: showElement.offsetTop
      });
    }
  }

  const openUserProfile = () => {
    dispatch(setUserProfileView('FormPageConnect'));
    dispatch(setIsUserProfile(true));
  };

  const openIsUserProfile = () => {
    if (notificationsOpen)
      dispatch(setNotificationsOpen(!notificationsOpen));
    if (emailOpen)
      dispatch(setEmailOpen(!emailOpen));
    dispatch(setIsUserProfileOpen(!isUserProfileOpen));
  };

  const setIsNotificationsOpen = () => {
    dispatch(setNotificationsOpen(!notificationsOpen));
    if (isUserProfileOpen)
      dispatch(setIsUserProfileOpen(!isUserProfileOpen));
    if (emailOpen)
      dispatch(setEmailOpen(!emailOpen));
  }
  const setIsEmailOpen = () => {
    dispatch(setEmailOpen(!emailOpen));
    if (isUserProfileOpen)
      dispatch(setIsUserProfileOpen(!isUserProfileOpen));
    if (notificationsOpen)
      dispatch(setNotificationsOpen(!notificationsOpen));
  }

  let countNotifications = 0;
  let countNotificationsEmail = 0;
  //@ts-ignore
  if (userid !== 0 && notificationsAll && notificationsAll.notifications) {

    const vvv = notificationsAll.filter((y: any) => {

      return y.read === 'false' && (y.type === 'flow-new-topic' || y.type === 'flow-post-reply' || y.type === 'flow-post-edited' || y.type === 'flow-topic-renamed' || y.type === 'flow-mention' || y.type === 'flow-thank');
    })
    const vvv2 = notificationsAll.filter((y: any) => {

      return y.read === 'false' && (y.type === 'welcome' || y.type === 'emailuser');
    })

    countNotifications = vvv.length;
    countNotificationsEmail = vvv2.length;
  }



  return (
    <>
      <div className="book-page-header-up"
        onClick={() => {
          if (notificationsOpen)
            dispatch(setNotificationsOpen(!notificationsOpen));
          if (emailOpen)
            dispatch(setEmailOpen(!emailOpen));
          if (isUserProfileOpen)
            dispatch(setIsUserProfileOpen(!isUserProfileOpen));
        }}
      >
        <div className="logo-svg-main">
          <Tooltip
            classes={classes}
            title={`דף הבית`}
            placement="bottom"
            arrow
          >
            {<div className="logo-svg">
              <a href='/home' className="logo-svg">
                <MihlolLogo />
              </a>
            </div>}
          </Tooltip>

        </div>
        {!(isMobile().phone || isMobile().tablet) &&
          <div className="new-menu-header-right">
            <div className="new-menu-header-right-about">
              <a href='/about'> אודות שיתופתא
              </a>
            </div>
            <div className="new-menu-header-right-line">
              <Line />
            </div>
            <div className="new-menu-header-right-work">
              <a href='/work'> איך זה עובד
              </a>
            </div>
            <div className="new-menu-header-right-line">
              <Line />
            </div>
            <div className="new-menu-header-right-contact">
              <a href='/Contact'>  צור קשר
              </a>
            </div>
            {<div className="new-menu-header-right-line">
              <Line />
            </div>}
            {false && <div className="new-menu-header-right-contact-shas">
              <a href='/Psalms'>אמירת תהילים</a>
            </div>}
            {<div className="new-menu-header-right-contact-shas">
              <a href='/ironSwords'>סיום הש"ס</a>
            </div>}
          </div>}
      </div>
      {(userid === 0) && !(isMobile().phone || isMobile().tablet) &&
        <div className="new-menu-header-right-profile"
          onClick={openUserProfile}>
          <div className='new-menu-header-right-profile-connct'>
            <Profile className="new-menu-header-right-profile-connct-icon" />
          </div>
          {<div className='new-menu-header-right-profile-connct-text'>
            {'התחבר'}
          </div>}
        </div>}
      {!(userid === 0 || userid === null) && !(isMobile().phone || isMobile().tablet) &&
        <div className="new-menu-header-right-profile">
          {/*false&&<Tooltip
            classes={classes}
            title={`חיפוש ספר, לדוגמא: ברכות כב א`}
            placement="bottom"
            arrow
          >
            {<div className="new-menu-header-right-profile-search"
              onClick={onSubmitValue}
            >
              <input className='new-menu-header-right-profile-search-input'
                placeholder="חפש ספר"
                maxLength={60}
                autoComplete={'off'}
                type="search"
                onChange={onChangeValue}
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    onSubmitValue()
                  }
                }}
              >
              </input>
            </div>}
                          <SearchIcondown className='new-menu-header-right-profile-search-icon' style={{ position: 'relative', right: 2.5, top: -0.8 }} />

              </Tooltip>*/}
          <a href="/search">
            <Tooltip
              classes={classes}
              title={`חיפוש בספרים וחידושים`}
              placement="bottom"
              arrow
            >
              <SearchIcondown className='new-menu-header-right-profile-search-icon' />
            </Tooltip>
          </a>

          <div
            onClick={setIsNotificationsOpen}>
            {countNotifications !== 0 &&
              <div className='new-menu-header-right-bell-in-not'>
                <Notifications />
                <div className='new-menu-header-right-bell-in-not-text'>
                  {countNotifications}
                </div>
              </div>}
            <Tooltip
              classes={classes}
              title={`התראות`}
              placement="bottom"
              arrow
            >
              <BellSub className="new-menu-header-right-bell" />
            </Tooltip>
          </div>
          <div
            onClick={setIsEmailOpen}>
            {countNotificationsEmail !== 0 && <div className='new-menu-header-right-bell-in-not'>
              <Notifications />
              <div className='new-menu-header-right-bell-in-not-text'>
                {countNotificationsEmail}
              </div>
            </div>}
            <Tooltip
              classes={classes}
              title={`הודעות`}
              placement="bottom"
              arrow
            >
              <Email className="new-menu-header-right-bell" />
            </Tooltip>
          </div>
          <Tooltip
            classes={classes}
            title={`חשבון`}
            placement="bottom"
            arrow
          >
            <div onClick={() => {
              openIsUserProfile()
            }}
            >
              <div >
                <ProfileIn className="new-menu-header-right-bell" />
              </div>
            </div></Tooltip>
        </div>
      }

      {!(isMobile().phone || isMobile().tablet) && (bookName) &&
        <div className="book-page-header-down"
          onClick={() => {
            if (notificationsOpen)
              dispatch(setNotificationsOpen(!notificationsOpen));
            if (emailOpen)
              dispatch(setEmailOpen(!emailOpen));
            if (isUserProfileOpen)
              dispatch(setIsUserProfileOpen(!isUserProfileOpen));
          }
          }
        >
          <Tooltip
            classes={classes}
            title={'פתיחת אוצר הספרים'}
            placement="bottom"
            arrow
          >
            <div
              onClick={() => handleToggleMenu()}
              className={!isMenuOpen ? "book-page-header-down-sfarim" : "book-page-header-down-sfarim green"
              }>
              <div className="book-page-header-down-sfarim-icon">
                <Sfarimdown />
              </div>

              אוצר הספרים
            </div>
          </Tooltip>


          <div className="book-page-header-down-line">
            <Line />
          </div>
          <Tooltip
            classes={classes}
            title={'פתיחת רשימת המחדשים'}
            placement="bottom"
            arrow
          >
            <div className={!isPidOpen ? "book-page-header-down-mechadshim" : "book-page-header-down-mechadshim green"}
              onClick={() => handleTogglePid()}
            >
              <div className="book-page-header-down-sfarim-icon">
                <Mechadshim></Mechadshim>
              </div>
              רשימת המחדשים
            </div>
          </Tooltip>

          <div className="book-page-header-down-line">
            <Line />
          </div>
          <Tooltip
            classes={classes}
            title={'פתיחת חידושים אחרונים'}
            placement="bottom"
            arrow
          >
            <div className={!isPidOpen1 ? "book-page-header-down-last" : "book-page-header-down-last green"}
              onClick={() => handleTogglePid1()}
            >
              <div className="book-page-header-down-sfarim-icon">
                <Last />
              </div>
              {
                myTopics === '' &&
                'חידושים אחרונים'
              }
              {
                myTopics === 'my' &&
                'החידושים שלי'
              }
              {
                myTopics !== '' &&
                myTopics !== 'my' &&
                'חידושי המחדש'
              }

            </div>
          </Tooltip>

          {isOnLinePartyOpen && <div className="book-page-header-down-line">
            <Line />
          </div>}
          {isOnLinePartyOpen && <div className={!isOnLinePartyOpen ? "book-page-header-down-last" : "book-page-header-down-last green"}
            onClick={() => {
              dispatch(setIsOnLineParty(false))
            }}
          >
            <div className="book-page-header-down-sfarim-icon">
              <SearchCircle />
            </div>
            {'ש"ס מחולק'}
          </div>}
        </div>}
      <OpenPid2 />
      {!isOpenNavPage && <OpenNav />}
      <OpenNavYoma />
      <OpenNav1 />
      <OpenPid />
      <OnLineParty />
      <OpenPid1 />
      <UserProfileMobile />
      <Message />
    </>
  )
};

export default HeaderNav;
