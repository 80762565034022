import React from "react";
import "./Message.css";
import { useSelector, useDispatch } from "react-redux";
import wikiService from "../../services/wikiService";
import {
  getIsLoading,
  getNotificationsAll,
  getNotificationsOpen,
  getEmailOpen,
  getAllBooks,
  getUserName,
} from "../../store/selectors/selectors";
import {
  getPageData, setTabOpenLeft
} from "../../store/actions/pageActions";
import {
  setEmailOpen,
  setNotificationsOpen
} from "../../store/actions/viewActions";
import {
  getKeyByValue,
} from "../../utils/menuUtils";
import {
  getTitleEn,
  getPageEn,
} from "../../utils/dataUtils";
import { ThunkDispatch } from "@reduxjs/toolkit";

const Message = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const isLoading = useSelector(getIsLoading);
  const notificationsAll = useSelector(getNotificationsAll);
  const userName = useSelector(getUserName);
  const notificationsOpen = useSelector(getNotificationsOpen);
  const emailOpen = useSelector(getEmailOpen);
  const allBooksNames = useSelector(getAllBooks);
  if (isLoading) return (
    <>
    </>
  );

  const getNotificationsAllList = () => {


    wikiService.makeRead();
    if (notificationsAll && notificationsAll.length)
    return notificationsAll.filter((elemen: any) =>
      (elemen.category === 'flow-discussion' || elemen.category === 'edit-thank') && elemen.title.namespace === "חידוש"
    ).map((x: any, index: number) => {
      let type = '';
      switch (x.type) {
        case 'flow-new-topic':
          type = 'כתב חידוש ';
          break;
        case 'flow-post-reply':
          type = `הגיב על:  ${x['title']['topic-title']}`;
          break;
        case 'flow-post-edited':
          type = 'ערך את';
          break;
        case 'flow-topic-renamed':
          type = 'שינה את שם הדף';
          break;
        case 'flow-mention':
          type = 'הזכיר אותך בדף';
          break;
        case 'flow-thank':
          if (x.title['topic-title'] === x['*'].body)
            type = 'מודה לך על חידושך: ';
          else
            type = 'מודה לך על הערתך: ';
          break;
        default:
          break;
      }
      return (<>
        <div key={index}
          onClick={() => { }}
        >
          {<div className={'message-menu-item-text'}
          >
            <b title={x.agent.name}>
              {x.agent.name}
            </b>
            <span className='edededed'>
              {x.timestamp.date.replace(`ה'תשפ"ד`, '')}
            </span>
            <div className='cursur'

            > <span dangerouslySetInnerHTML={{
              __html: type
            }}></span>
              {x.type === 'flow-post-reply' && <>

                <br />
                <span className='cursur-ponter' style={{ wordWrap: 'break-word' }} dangerouslySetInnerHTML={{
                  __html: 'תגובתו: ' + decodeURI(x['*']['body'])
                }}></span></>}{x.title['content'] !== null ? x.title['content'] : ' '} <div className='text-align'
                  onClick={() => {
                    let title = x.title.text.substring(x.title.text.indexOf('/') + 1, x.title.text.length);

                    const book = getTitleEn(allBooksNames, title);
                    const page = getKeyByValue(getPageEn(title, 2, ' ').replace('׳', '').replace('״', '').split(' ')[0]);
                    const subPage = getPageEn(title, 1, ' ');

                    if (page && subPage) {
                      dispatch(setEmailOpen(false));
                      dispatch(setNotificationsOpen(false));
                      dispatch(setTabOpenLeft('hidushim'));
                      if (title.includes(':')) {
                        dispatch(getPageData(book, page + '.' + getKeyByValue(getPageEn(title, 1, ' ').split('׳').join('').split('״').join('').split(' ')[1])));
                      }
                      else {
                        dispatch(getPageData(book, page + subPage));
                      }
                    }
                    else {

                      const pageT = x.title.text.split('~')[1];

                      if (pageT.includes('Pesach Haggadah')) {
                        dispatch(setEmailOpen(false));
                        dispatch(setNotificationsOpen(false));
                        dispatch(setTabOpenLeft('hidushim'));
                        dispatch(getPageData('Pesach Haggadah', pageT.replace('Pesach Haggadah', '').replace(' ', '_')));
                      }
                      else {
                        const lastIndexOf = pageT.lastIndexOf(' ');

                        dispatch(setEmailOpen(false));
                        dispatch(setNotificationsOpen(false));
                        dispatch(setTabOpenLeft('hidushim'));
                        dispatch(getPageData(pageT.substring(0, lastIndexOf), pageT.substring(lastIndexOf + 1)));

                      }
                    }
                  }}

                  style={{ color: '#38A09D', position: 'relative', cursor: 'pointer' }}
                >
                {x.title.text.split('~')[0].substring(x.title.text.indexOf('/') + 1, x.title.text.length).replace(':', ' ').replace(/[0-9]/g, '')}
              </div>
            </div>
          </div>}
        </div>
        <div className='hight-line'>
          <div className='message-menu-line'></div>
        </div>
      </>)
    })
  }

  const getEmailAllList = () => {

    wikiService.makeRead();
    if (notificationsAll && notificationsAll.length)
      return notificationsAll.filter((elemen: any) =>
        elemen.category === 'system' || elemen.category === 'emailuser' || elemen.type === 'user-rights'
      ).filter((y: any) => {
        return true;
      }).map((x: any) => {

        let type = '';
        let data = '';
        let date = '';
        switch (x.type) {
          case 'welcome':
            type = `הודעת מערכת`;
            date = x.timestamp.date;
            data = `ברוך בואך לשיתופתא ${userName}, אנחנו שמחים שבאת!`;
            break;
          case 'emailuser':
            type = x.agent.name;
            date = x.timestamp.date;
            data = x.preview;
            break;
          case 'user-rights':
            type = x.agent.name;
            date = x.timestamp.date;
            data = x['*']['compactHeader'];
            break;
          default:
            break;
        }

        return (<div key={0}>
          <span className='message-menu-item-text-mail'>
            <div className='sdsd'>
              <span className='ewewe'>
                <b>{type + ' '}</b></span>
              <span className='edededed'>
                {date.replace(`ה'תשפ"ד`, '')}
              </span>
              <br /> <div className='jjjjjj'
                dangerouslySetInnerHTML={{
                  __html: `${data}`
                }}
              >

              </div>
            </div>
          </span>
          <div className='hight-line'>
            <div className='message-menu-line'></div>
          </div>

        </div>)
      })
  }

  return (
    <div className={notificationsOpen || emailOpen ? 'message-menu' : 'message-menu-none'}>
      {notificationsOpen && <div className='message-menu-title'>
        {'התראות'}
      </div>}
      {emailOpen && <div className='message-menu-title-email'>
        {'הודעות'}
      </div>}
      <div className='message-menu-line-1'> </div>

      {emailOpen && getEmailAllList()}
      {notificationsOpen && getNotificationsAllList()}



    </div>
  );
};

export default Message;
