import React from "react";
import "./UserProfileMobile.css";
import wikiService from "../../services/wikiService";
import { ReactComponent as UserIcon } from "../../svg/userIcon.svg";
import { ReactComponent as UserProfileIconMobile } from "../../svg/userProfileIconMobile.svg";
import isMobile from "ismobilejs";
import {
  useStyles
} from "../../utils/funcUtils";
import Tooltip from '@mui/material/Tooltip';
import {
  getUserName,
  getUserFollower,
  getUserProfileView,
  getIsUserProfileOpen,
  getIsPidOpen1,
  getUserPage
} from "../../store/selectors/selectors";
import { useSelector, useDispatch } from "react-redux";
import { setOpenPid1, setMyTopic, setclosePid1, setIsUserProfileOpen } from "../../store/actions/viewActions";
import { ThunkDispatch } from "@reduxjs/toolkit";

const UserProfileMobile = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const userName = useSelector(getUserName);
  const userPage = useSelector(getUserPage);
  const userFollower = useSelector(getUserFollower);
  const userProfileView = useSelector(getUserProfileView);
  const isUserProfileOpen = useSelector(getIsUserProfileOpen);
  const isPidOpen1 = useSelector(getIsPidOpen1);
  const classes = useStyles();
  const handleTogglePid1 = () => {
    if (!isPidOpen1) {
      dispatch(setMyTopic('my'));
      dispatch(setOpenPid1());
    }
    else {
      dispatch(setclosePid1());
    }
    dispatch(setIsUserProfileOpen(!isUserProfileOpen));
  };
  const setLogOut = async () => {
    await wikiService.LogOut();
    window.location.reload();
  }
  if ((isMobile().phone || isMobile().tablet))
    return (

      <div
        className={
          'menu-profile-mobile'
        }

      >
        {userProfileView === 'UserProfileMobile' &&
          userName !== 0 &&
          <div>
            <div className='menu-icon-mobile'>
              <UserProfileIconMobile />
            </div>
            <div className='menu-name-mobile'>
              {userName}
            </div>
            <div className='menu-my-chidush-Follower-mobile'>
              {Number(userFollower) + ' עוקבים'}
            </div>
            <div className='menu-line-mobile' />
            <div className='menu-my-chidush-mobile'
              style={{ fontWeight: 500 }}
            >
              {'הגדרות'}
            </div>
            <div className='menu-line-mobile' />
            <Tooltip
              classes={classes}
              title={`הודעות`}
              placement="bottom"
              arrow
            >
              <div className='menu-my-chidush-mobile'>
                {'אודות שיתופתא'}
              </div>
            </Tooltip>
            <div className='menu-line-mobile' />

            <div className='menu-my-chidush-mobile'>
              {'איך זה עובד?'}
            </div>
            <div className='menu-line-mobile' />

            <div className='menu-my-chidush-mobile'>
              {'צור קשר'}
            </div>
            <div className='menu-line-mobile' />

            <div className='menu-my-chidush-mobile'
              onClick={() => setLogOut()}>
              {'התנתק'}
            </div>
          </div>
        }


      </div>

    );
  else
    return (
      <div
        className={isUserProfileOpen !== false ? 'menu-profile' : 'menu-profile-none'}
      >
        {
          userName !== 0 &&
          <div>
            <div className='menu-icon'>
              <UserIcon />
            </div>
            <div className='menu-name'>
              {userName}
            </div>
            <div className='menu-my-chidush-Follower'>
              {Number(userPage) !== 0 && Number(userPage) + ' חידושים '}
              <br />
              {' ' + Number(userFollower) + ' עוקבים'}
            </div>
            <div className='menu-line-1' />
            <div className='menu-my-chidush'
              onClick={() => handleTogglePid1()}
            >
              <div className='menu-my-chidush-text'>
                {' החידושים שלי'}
              </div>
            </div>

            <div className='menu-line-3' />
            <div className='menu-disconnect'
              onClick={() => setLogOut()}>
              <div className='menu-my-chidush-text'>
                {'התנתק'}
              </div>

            </div>
          </div>
        }
      </div>
    );
};

export default UserProfileMobile;
