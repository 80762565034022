import "./OpenNavList.css";
import React from "react";
import Select from "react-select";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { useSelector, useDispatch } from "react-redux";
import { parseBookPageToHebrew, parseToHebrew } from "../../utils/menuUtils";
import {
  getPageId,
  getUserProfileView,
  getMenuChapters,
  getMenuChaptersView,
  getSectionNames,
  getIsOpenNavPage,
} from "../../store/selectors/selectors";
import {
  closeMenu,
  setUserProfileView,
  setCurrentEvents,
  setMenuChaptersView,
} from "../../store/actions/viewActions";
import { clearTabOpenRight, getPageData, setCommentaryToShow, setCommentaryselectChecked, setIsSfarimOn } from "../../store/actions/pageActions";
import copy from 'copy-to-clipboard';
import { ThunkDispatch } from "@reduxjs/toolkit";

interface Props {
  menuView: string;
  chosenBookAllPages: Array<string>;
  pageLocal: string;
  contentCounts: Array<any>;
  parentPageLocal: string;
  first: number;
  sectionNamesL: any;
}

const OpenNavList: React.FC<Props> = ({ menuView, chosenBookAllPages, pageLocal, contentCounts, parentPageLocal, first, sectionNamesL }) => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const pageId = useSelector(getPageId);
  const userProfileView = useSelector(getUserProfileView);
  const chosenBookChapters = useSelector(getMenuChapters);
  const menuChaptersView = useSelector(getMenuChaptersView);
  const sectionNames = useSelector(getSectionNames);
  const isOpenNavPage = useSelector(getIsOpenNavPage);
  const getIndexTurCom = (length: number) => {
    let index = 0;
    if (length === 4) {
      index = -1;
    }
    if (parentPageLocal && parentPageLocal.includes('Orach_Chaim'))
      return 1 + index;
    if (parentPageLocal && parentPageLocal.includes('Yoreh_Deah'))
      return 2 + index;
    if (parentPageLocal && parentPageLocal.includes('Choshen_Mishpat'))
      return 3 + index;
    if (parentPageLocal && parentPageLocal.includes('Even_HaEzer'))
      return 4 + index;
    return 1


  }
  // remove the commentary from the list of commentaries that need to be shown, and if it's the last one, come back to- choose-sfarim comp.
  const removeFromCommentary = () => {
    dispatch(setCommentaryToShow(null));
    dispatch(setCommentaryselectChecked([]));
    dispatch(setIsSfarimOn(false));
    dispatch(clearTabOpenRight());
  };

  const handleSelectPage = (bookId: string, sectionNames: boolean) => {
    if (userProfileView === "MenuMobile") {
      dispatch(setUserProfileView(""));
    }


    if (bookId) {
      const bookSplit = bookId.replace('%2C', ',').split(" ").join("_").split("_");
      let page = bookSplit[bookSplit.length - 1];

      bookSplit.splice(-1);
      const book = bookSplit.join("_");
      dispatch(closeMenu());

      if (sectionNames) {
        if (Number(page) % 2 === 1) {
          page = (((Number(page) - 1) / 2) + 1).toString() + 'a';
        }
        if (Number(page) % 2 === 0) {
          page = (((Number(page)) / 2)).toString() + 'b';
        }
        dispatch(getPageData(book, page));
      }
      else {
        dispatch(getPageData(book, page));
      }
      dispatch(setCurrentEvents(''));
      removeFromCommentary();
    }
  };

  const handleDropDownMenuClick = (e: any) => {
    dispatch(setMenuChaptersView(e));
  };

  const defaultValue = () => {
    if (menuView === 'bookChosen') {
      return 'בחר פרק'
    }

    else {
      return 'בחר סימן'
    }
  }

  const getSelectMenu = () => {
    let category = '';
    if (menuView === 'bookChosen') {
      category = 'בחר פרק'
    }
    else if (menuView.includes('bookChosenTea')) {
      category = 'בחר פרשה'
    }
    else {
      category = 'בחר סימן'
    }
    let defaultValue = [{ value: "allPages", label: category }];
    if (chosenBookAllPages) {
      return defaultValue.concat(
        chosenBookChapters.map((chapter: any) => {
          return { value: chapter.chapterName, label: chapter.chapterName };
        })
      );
    }
  };

  const getIdSelectedpage = (subCat: any) => {
    if (subCat.depth === 1) {
      if (pageId === `${parentPageLocal && parentPageLocal.split(',')[0]}${subCat.title !== '' ? ', ' + subCat.title : ''}`.split('_').join(' ').trim()) {
        return "selected-page"
      }
      else {
        return `${parentPageLocal && parentPageLocal.split(',')[0]}${subCat.title !== '' ? ', ' + subCat.title : ''}`.split('_').join(' ').trim()
      }
    }
    if (subCat.depth > 1) {
      if (pageId === `${parentPageLocal && parentPageLocal.split(',')[0]}${subCat.title !== '' ? ', ' + subCat.title : ''}`.split('_').join(' ').trim()) {
        return "selected-page"
      }
      else {
        return `${parentPageLocal && parentPageLocal.split(',')[0]}${subCat.title !== '' ? ', ' + subCat.title : ''}`.split('_').join(' ').trim()
      }
    }
    return '1'
  }

  const getChapterPages = () => {

    const filterdBook = chosenBookChapters.filter((chapter: any) => {
      return chapter.chapterName === menuChaptersView;
    });

    if (filterdBook && filterdBook[0]) {
      return filterdBook[0].chapters.map((page: any) => {
        return (
          <>
            <ContextMenuTrigger id={page === pageId ? "selected-page" : page}>
              <div
                className={
                  page === pageId
                    ? "bookChosen-page-item bookChosen-current-item"
                    : "bookChosen-page-item"
                }

                key={page}
                onClick={() => handleSelectPage(page, sectionNames && sectionNames[0] === 'Daf')}
              >
                <p className="bookChosen-page-item-text">
                  {parseBookPageToHebrew(page.split(':')[0], sectionNames && sectionNames[0] === 'Daf')}
                </p>
              </div>
            </ContextMenuTrigger>
            <ContextMenu className={'context-menu'} id={page === pageId ? "selected-page" : page} >
              <MenuItem className={'context-menu-item'} onClick={() => {
                window.open(`/${page.split(' ').length === 2 ? page.split(' ')[0] : page.split(' ')[0] + ' ' + page.split(' ')[1]}/${page.split(' ').length === 2 ? page.split(' ')[1] : page.split(' ')[2]}`, 'new_window')
              }}>
                {'פתיחת קישור בכרטיסייה חדשה'}
              </MenuItem>
              <MenuItem className={'context-menu-item'} onClick={() => {
                window.open(`/${page.split(' ').length === 2 ? page.split(' ')[0] : page.split(' ')[0] + ' ' + page.split(' ')[1]}/${page.split(' ').length === 2 ? page.split(' ')[1] : page.split(' ')[2]}`, '_blank', 'toolbar=0,location=0,menubar=0')
              }} >
                {'פתיחת קישור בחלון חדש'}
              </MenuItem>
              <MenuItem className={'context-menu-item'} onClick={() => {
                copy(`${window.location.origin}/${page.split(' ').length === 2 ? page.split(' ')[0] : page.split(' ')[0] + ' ' + page.split(' ')[1]}/${page.split(' ').length === 2 ? page.split(' ')[1] : page.split(' ')[2]}`, {
                  debug: true,
                  message: 'Press #{key} to copy',
                });
              }} >
                {'העתק כתובת קישור'}
              </MenuItem>
            </ContextMenu>
          </>
        );
      });
    }
    else {
      return null
    };
  };

  const getCatPages = () => {
    for (var i = 0; i < contentCounts.length; i++) {
      if (contentCounts[i].heTitle === pageLocal) {

        return contentCounts[i].content_counts
      }
    }

  }

  const getBookChosenClassName = (subCat: any) => {
    const temp = `${parentPageLocal && parentPageLocal.split(',')[0]}${subCat.title !== '' ? ', ' + subCat.title : ''}`.split('_').join(' ').trim();
    let classname = "bookChosen-page-item ";
    if (pageId === temp) {
      classname += "bookChosen-current-item "
    }
    if (subCat.depth === 1) {
      classname += 'bookChosen-page-item-introduction '
    }
    return classname;
  }

  return (
    <div className={`openNav-bookChosen-container-wrapper ${first === 1 ? 'first' : ''}`}>
      {!menuView.includes('bookChosenTea') && !menuView.includes('bookChosenHalCom') && !menuView.includes('bookChosenHalMid') && !isOpenNavPage && first === 1 && chosenBookChapters && chosenBookChapters.length !== 0 && contentCounts && (typeof contentCounts[0] !== 'object') &&
        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          options={getSelectMenu()}
          isRtl={true}
          isSearchable={false}
          defaultValue={{ value: defaultValue(), label: defaultValue() }}
          onChange={(e: any) => handleDropDownMenuClick(e.value)}
          maxMenuHeight={200}
        />}
      {!(
        menuView.includes("bookChosenKabala") ||
        menuView.includes('bookChosenSmallMas') ||
        menuView.includes("bookChosenTosefta") ||
        menuView.includes("bookChosenTurCom") ||
        menuView.includes("bookChosenMusar") ||
        menuView.includes("bookChosenTalCom") ||
        menuView.includes('bookChosenHalCom') ||
        menuView.includes('bookChosenChasidut') ||
        menuView.includes('bookChosenComTor') ||
        menuView.includes('bookChosenLiturgy') ||
        menuView.includes('bookChosenComNach') ||
        menuView.includes('bookChosenHalMid') ||
        menuView.includes('bookChosenTeaTea') ||
        menuView.includes('bookChosenProphetsProphets') ||
        menuView.includes('bookChosenResponsa'
        )
      )
        &&
        contentCounts && //chosenBookAllPages && //chosenBookAllPages.length !== 0 &&
        //Array.from({ length: contentCounts[Number(pageLocal.split(' ')[pageLocal.split(' ').length - 1]) - 1] }, (v, i) => i).length === 0 &&
        <div className="select-chapter-openNav">
          <div className="openNav-bookChosen-container">
            {false && chosenBookAllPages && chosenBookAllPages[0] && chosenBookAllPages[0].includes('Jerusalem_Talmud') && <>
              <button
                className={
                  'Jerusalem_Talmud_Berakhot_1a' === pageId
                    ? "bookChosen-page-item bookChosen-current-item"
                    : "bookChosen-page-item"
                }
                id={'Jerusalem_Talmud_Berakhot_1a' === pageId ? "selected-page" : 'Jerusalem_Talmud_Berakhot_1a'}
                key={'Jerusalem_Talmud_Berakhot_1a'}

                onClick={() => {
                  dispatch(getPageData('Jerusalem_Talmud_Berakhot', '1a'))
                  dispatch(closeMenu());
                }}
              >
                <p className="bookChosen-page-item-text">
                  {'.א'}
                </p>
              </button>
              <button
                className={
                  'Jerusalem_Talmud_Berakhot_1a' === pageId
                    ? "bookChosen-page-item bookChosen-current-item"
                    : "bookChosen-page-item"
                }
                id={'Jerusalem_Talmud_Berakhot_1a' === pageId ? "selected-page" : 'Jerusalem_Talmud_Berakhot_1a'}
                key={'Jerusalem_Talmud_Berakhot_1a'}

                onClick={() => {
                  dispatch(getPageData('Jerusalem_Talmud_Berakhot', '1b'))
                  dispatch(closeMenu());
                }}
              >
                <p className="bookChosen-page-item-text">
                  {':א'}
                </p>
              </button></>}
            {menuChaptersView === "allPages" &&
              chosenBookAllPages &&
              chosenBookAllPages.map((page: any, index: any) => {

                if (
                  (sectionNames && sectionNames[0] === 'Daf' && contentCounts[index + 2] > 0) ||
                  (sectionNames && sectionNames[0] !== 'Daf' && contentCounts[index] > 0) ||
                  menuView === 'bookChosen' ||
                  menuView === 'bookChosenSul' ||
                  menuView === 'bookChosenMish' ||
                  menuView === 'bookChosenRam'
                ) {

                  return (
                    <>
                      <ContextMenuTrigger id={page === pageId ? "selected-page" : page}>
                        <button
                          className={
                            page === pageId
                              ? "bookChosen-page-item bookChosen-current-item"
                              : "bookChosen-page-item"
                          }
                          id={page === pageId ? "selected-page" : page}
                          key={page}

                          onClick={() => handleSelectPage(page, sectionNames && sectionNames[0] === 'Daf')}
                        >
                          <p className="bookChosen-page-item-text">
                            {parseBookPageToHebrew(page, sectionNames && sectionNames[0] === 'Daf')}
                          </p>
                        </button>
                      </ContextMenuTrigger>
                      <ContextMenu className={'context-menu'} id={page === pageId ? "selected-page" : page} >
                        <MenuItem className={'context-menu-item'} onClick={() => {
                          window.open(`/${page.split(' ').length === 2 ? page.split(' ')[0] : page.split(' ')[0] + ' ' + page.split(' ')[1]}/${page.split(' ').length === 2 ? page.split(' ')[1] : page.split(' ')[2]}`, 'new_window')
                        }} >
                          {'פתיחת קישור בכרטיסייה חדשה'}                         </MenuItem>
                        <MenuItem className={'context-menu-item'} onClick={() => {
                          window.open(`/${page.split(' ').length === 2 ? page.split(' ')[0] : page.split(' ')[0] + ' ' + page.split(' ')[1]}/${page.split(' ').length === 2 ? page.split(' ')[1] : page.split(' ')[2]}`, '_blank', 'toolbar=0,location=0,menubar=0')
                        }} >
                          {'פתיחת קישור בחלון חדש'}
                        </MenuItem>
                        <MenuItem className={'context-menu-item'} onClick={() => {
                          copy(`${window.location.origin}/${page.split(' ').length === 2 ? page.split(' ')[0] : page.split(' ')[0] + ' ' + page.split(' ')[1]}/${page.split(' ').length === 2 ? page.split(' ')[1] : page.split(' ')[2]}`, {
                            debug: true,
                            message: 'Press #{key} to copy',
                          });
                        }} >
                          {'העתק כתובת קישור'}
                        </MenuItem>
                      </ContextMenu>

                    </>
                  );
                }
                else {
                  return (<></>)
                }
              })}
            {menuChaptersView !== "allPages" && getChapterPages()}
          </div>
        </div>}

      {!(
        menuView.includes('bookChosenSmallMas') ||
        menuView.includes("bookChosenKabala") ||
        menuView.includes("bookChosenTalCom") ||
        menuView.includes("bookChosenTosefta") ||
        menuView.includes("bookChosenTurCom") ||
        menuView.includes("bookChosenMusar") ||
        menuView.includes('bookChosenComTor') ||
        menuView.includes('bookChosenLiturgy') ||
        menuView.includes('bookChosenComNach') ||
        menuView.includes('bookChosenChasidut') ||
        menuView.includes('bookChosenResponsa') ||
        menuView.includes('bookChosenHalMid') ||
        menuView.includes('bookChosenHalCom')
      ) && first === 1 && contentCounts && contentCounts.length !== 0 && menuChaptersView === "allPages" &&
        Array.from({ length: contentCounts[Number(pageLocal.split(' ')[pageLocal.split(' ').length - 1]) - 1] }, (v, i) => i).length !== 0 &&
        <div className="select-chapter-openNav">
          <div className="openNav-bookChosen-container">
            {menuChaptersView === "allPages" &&
              Array.from({ length: contentCounts[Number(pageLocal.split(' ')[pageLocal.split(' ').length - 1]) - 1] }, (v, i) => i).map((page: any) => {
                if (contentCounts[page + 1] === 0) {
                  return (<></>)
                }
                return (
                  <>
                    <ContextMenuTrigger id={page === pageId ? "selected-page" : page + 'D'}>
                      <div
                        className={
                          page === pageId
                            ? "bookChosen-page-item bookChosen-current-item"
                            : "bookChosen-page-item"
                        }
                        id={page === pageId ? "selected-page" : page}
                        key={page}
                        onClick={() => handleSelectPage(pageLocal + '.' + (page + 1), sectionNames && sectionNames[0] === 'Daf')}
                      >
                        <p className="bookChosen-page-item-text">
                          {parseBookPageToHebrew((page + 1).toString())}
                        </p>
                      </div>
                    </ContextMenuTrigger>
                    <ContextMenu className={'context-menu'} id={page === pageId ? "selected-page" : page + 'D'} >
                      <MenuItem className={'context-menu-item'} onClick={() => {
                        window.open(`/${pageLocal.split(' ')[0]}/${pageLocal.split(' ')[1]}.${page}`, 'new_window')
                      }} >
                        {'פתיחת קישור בכרטיסייה חדשה'}                         </MenuItem>
                      <MenuItem className={'context-menu-item'} onClick={() => {
                        window.open(`/${pageLocal.split(' ')[0]}/${pageLocal.split(' ')[1]}.${page}`, '_blank', 'toolbar=0,location=0,menubar=0')
                      }} >
                        {'פתיחת קישור בחלון חדש'}
                      </MenuItem>
                      <MenuItem className={'context-menu-item'} onClick={() => {
                        copy(`${window.location.origin}/${pageLocal.split(' ')[0]}/${pageLocal.split(' ')[1]}.${page}`, {
                          debug: true,
                          message: 'Press #{key} to copy',
                        });
                      }} >
                        {'העתק כתובת קישור'}
                      </MenuItem>
                    </ContextMenu>
                  </>
                );
              })}
            {menuChaptersView !== "allPages" && getChapterPages()}
          </div>
        </div>}
      {!menuView.includes('bookChosenComTor') &&
        contentCounts &&
        contentCounts.length !== 0 &&
        (typeof contentCounts[0] === 'object') && Array.from({ length: getCatPages() }, (v, i) => i).length > 0 &&
        <div className="select-chapter-openNav">
          <div className="openNav-bookChosen-container">
            {
              Array.from({ length: getCatPages() }, (v, i) => i).map((page: any) => {

                if (contentCounts[page] === 0) {
                  return (<></>)
                }
                return (
                  <>
                    <ContextMenuTrigger id={page === pageId ? "selected-page" : page + 'D'}>
                      <div
                        className={
                          page === pageId
                            ? "bookChosen-page-item bookChosen-current-item"
                            : "bookChosen-page-item"
                        }
                        id={page === pageId ? "selected-page" : page}
                        key={page}
                        onClick={() => {

                          handleSelectPage(parentPageLocal + ', ' + contentCounts[page].key + ' ' + (page + 1), sectionNames && sectionNames[0] === 'Daf')
                        }}
                      >
                        <p className="bookChosen-page-item-text">
                          {page && parseBookPageToHebrew((page + 1).toString())}
                        </p>
                      </div>
                    </ContextMenuTrigger>
                    <ContextMenu className={'context-menu'} id={page === pageId ? "selected-page" : page + 'D'} >

                      <MenuItem className={'context-menu-item'} onClick={() => {
                        window.open(`/${pageLocal.split(' ')[0]}/${pageLocal.split(' ')[1]}.${page}`, 'new_window')
                      }} >
                        {'פתיחת קישור בכרטיסייה חדשה'}                         </MenuItem>
                      <MenuItem className={'context-menu-item'} onClick={() => {
                        window.open(`/${pageLocal.split(' ')[0]}/${pageLocal.split(' ')[1]}.${page}`, '_blank', 'toolbar=0,location=0,menubar=0')
                      }} >
                        {'פתיחת קישור בחלון חדש'}
                      </MenuItem>
                      <MenuItem className={'context-menu-item'} onClick={() => {
                        copy(`${window.location.origin}/${pageLocal.split(' ')[0]}/${pageLocal.split(' ')[1]}.${page}`, {
                          debug: true,
                          message: 'Press #{key} to copy',
                        });
                      }} >
                        {'העתק כתובת קישור'}
                      </MenuItem>
                    </ContextMenu>

                  </>
                );
              })}

          </div>
        </div>}
      {contentCounts && ((
        menuView === "bookChosenRam" ||
        menuView === "bookChosenTurCom" ||
        menuView === "bookChosenYer" ||
        menuView === "bookChosenTalCom" ||
        menuView === "bookChosenComNach" ||
        menuView === "bookChosenTosefta" ||
        menuView === "bookChosenKabala"
      ) && (typeof contentCounts === 'number')) &&
        <div className="select-chapter-openNav">
          <div className="openNav-bookChosen-container">
            {Array.from({ length: contentCounts }, (v, i) => i).map((page: any) => {
              return (
                <>
                  <ContextMenuTrigger id={page === pageId ? "selected-page" : page + 'D'}>
                    <div
                      className={
                        page === pageId
                          ? "bookChosen-page-item bookChosen-current-item"
                          : "bookChosen-page-item"
                      }
                      id={page === pageId ? "selected-page" : page}
                      key={page}
                      onClick={() => handleSelectPage(parentPageLocal + ' ' + (page + 1), sectionNames && sectionNames[0] === 'Daf')}
                    >
                      <p className="bookChosen-page-item-text">
                        {parseBookPageToHebrew((page + 1).toString())}
                      </p>
                    </div>
                  </ContextMenuTrigger>
                  <ContextMenu className={'context-menu'} id={page === pageId ? "selected-page" : page + 'D'} >
                    <MenuItem className={'context-menu-item'} onClick={() => {
                      window.open(`/${pageLocal.split(' ')[0]}/${pageLocal.split(' ')[1]}.${page}`, 'new_window')
                    }} >
                      פתיחה בחלון חדש
                    </MenuItem>
                    <MenuItem className={'context-menu-item'} onClick={() => {
                      window.open(`/${pageLocal.split(' ')[0]}/${pageLocal.split(' ')[1]}.${page}`, 'new_window')
                    }} >
                      {'פתיחת קישור בכרטיסייה חדשה'}                         </MenuItem>
                    <MenuItem className={'context-menu-item'} onClick={() => {
                      window.open(`/${page.split(' ')[0]}/${page.split(' ')[1]}`, '_blank', 'toolbar=0,location=0,menubar=0')
                    }} >
                      {'פתיחת קישור בחלון חדש'}
                    </MenuItem>
                    <MenuItem className={'context-menu-item'} onClick={() => {
                      copy(`${window.location.origin}/${page.split(' ')[0]}/${page.split(' ')[1]}`, {
                        debug: true,
                        message: 'Press #{key} to copy',
                      });
                    }} >
                      {'העתק כתובת קישור'}
                    </MenuItem>
                  </ContextMenu>
                </>
              );
            })
            }
          </div>
        </div>}
      {
        (
          // menuView.includes('bookChosenSmallMas') ||
          menuView.includes("bookChosenTosefta") ||
          menuView.includes("bookChosenChasidut") ||
          menuView.includes("bookChosenYer") ||
          menuView.includes("bookChosenResponsa")
        ) &&
        contentCounts &&
        contentCounts.length > 1 &&
        (typeof contentCounts[1] === 'number') &&
        <div className="select-chapter-openNav">
          <div className="openNav-bookChosen-container">
            {contentCounts && contentCounts && contentCounts.map((subCat: any, index: any) => {
              if (contentCounts[index + 2] !== 0) {
                return (
                  <div
                    className={
                      subCat === pageId
                        ? "bookChosen-page-item bookChosen-current-item"
                        : "bookChosen-page-item"
                    }
                    id={subCat === pageId ? "selected-page" : subCat}
                    key={parentPageLocal + ' ' + index}
                    onClick={() => {

                      handleSelectPage(parentPageLocal + ' ' + (index + 1), sectionNames && sectionNames[0] === 'Daf')
                    }}
                  >
                    <p className="bookChosen-page-item-text">
                      {parseBookPageToHebrew(pageLocal.split('_').join(' ').split(' ').splice(-1) + ' ' + (index + 1), sectionNames && sectionNames[0] === 'Daf')}
                    </p>
                  </div>
                )
              }
              return (<></>)
            }
            )}
          </div>
        </div>}
      {(typeof contentCounts === 'number') && menuView !== "bookChosenLiturgy" &&
        <div
          className={
            (parentPageLocal === pageId
              ? "bookChosen-page-item bookChosen-current-item"
              : "bookChosen-page-item")
          }
          id={parentPageLocal}
          onClick={() => {
            handleSelectPage(parentPageLocal, sectionNames && sectionNames !== null && sectionNames[0] === 'Daf')
          }}
        >
          <p className="bookChosen-page-item-text">
            {parseBookPageToHebrew(parentPageLocal, sectionNames && sectionNames !== null && sectionNames[0] === 'Daf')}
          </p>
        </div>
      }
      {(typeof contentCounts === 'number' || typeof contentCounts[0] === 'number') && menuView === "bookChosenLiturgy" &&
        <div
          className={
            (parentPageLocal === pageId
              ? "bookChosen-page-item bookChosen-current-item"
              : "bookChosen-page-item")
          }
          id={parentPageLocal}
          key={(parentPageLocal + ' ' + (1)).split('_').join(' ')}
          onClick={() => {
            handleSelectPage(parentPageLocal + ' 1', sectionNames && sectionNames[0] === 'Daf')
          }}
        >
          <p className="bookChosen-page-item-text">
            {'א'}
          </p>
        </div>

      }
      {(
        menuView.includes('bookChosenSmallMas') ||
        menuView.includes("bookChosenKabala") ||
        menuView.includes("bookChosenTosefta") ||
        menuView.includes("bookChosenMusar") ||
        menuView.includes('bookChosenTalCom') ||
        menuView.includes("bookChosenChasidut") ||
        menuView.includes("bookChosenComTor") ||
        menuView.includes("bookChosenLiturgy") ||
        menuView.includes("bookChosenComNach") ||
        menuView === "bookChosenYer" ||
        menuView.includes('bookChosenHalMid') ||
        menuView.includes("bookChosenResponsa") ||
        menuView.includes('bookChosenHalCom')
      )
        && contentCounts &&
        contentCounts.length > 1 &&
        (typeof contentCounts[0] !== 'number') &&
        contentCounts.map((subCat: any) => {

          if (subCat.nodes) {
            return (<>
              {subCat.heTitle !== '' &&
                <h3 className="allbooks-list-header">
                  {subCat.heTitle}
                </h3>
              }
              {first === 1 &&
                <OpenNavList
                  menuView={menuView}
                  chosenBookAllPages={chosenBookAllPages}
                  pageLocal={pageLocal}
                  contentCounts={subCat.nodes}
                  parentPageLocal={`${parentPageLocal && parentPageLocal.split(',')[0]}%2C_${subCat.title}`}
                  first={first + 1}
                  sectionNamesL={subCat.sectionNames}
                />}
              {first > 1 &&
                <OpenNavList
                  menuView={menuView}
                  chosenBookAllPages={chosenBookAllPages}
                  pageLocal={`${pageLocal.split(',')[0]}%2C_${subCat.key}`}
                  contentCounts={subCat.nodes}
                  parentPageLocal={`${parentPageLocal}%2C_${subCat.title}`}
                  first={first + 1}
                  sectionNamesL={subCat.sectionNames}
                />
              }
            </>)
          }

          if (subCat.content_counts && (typeof subCat.content_counts !== 'number')) {


            return (<>
              {subCat.heTitle !== '' &&
                <h3 className="allbooks-list-header">
                  {subCat.heTitle}
                </h3>
              }
              <OpenNavList
                menuView={menuView}
                chosenBookAllPages={chosenBookAllPages}
                pageLocal={pageLocal}
                contentCounts={subCat.content_counts}
                parentPageLocal={`${parentPageLocal && parentPageLocal.split(',')[0]}${subCat.title !== '' ? ', ' + subCat.title : ''}`}
                first={first + 1}
                sectionNamesL={subCat.sectionNames}
              />

            </>)
          }
          if (subCat.content_counts && (typeof subCat.content_counts === 'number')) {


            return (<>
              {subCat.depth > 1 &&
                <h4 className="allbooks-list-header">{subCat.heTitle}</h4>
              }
              {
                <div
                  className={
                    getBookChosenClassName(subCat)
                  }
                  id={getIdSelectedpage(subCat)}
                  key={getIdSelectedpage(subCat)}
                  onClick={() => {
                    if (subCat.depth > 1)
                      handleSelectPage(decodeURIComponent(parentPageLocal.split('') + ' ' + subCat.title), sectionNames && sectionNames[0] === 'Daf')
                    else
                      handleSelectPage(`${parentPageLocal}${subCat.title !== '' ? ', ' + subCat.title : ''} `, sectionNames && sectionNames[0] === 'Daf')

                  }}
                >
                  {subCat.depth > 1 && <p className="bookChosen-page-item-text">
                    {parseBookPageToHebrew(parentPageLocal.split('_').join(' ').split(' ').splice(-1) + ' ' + (1), sectionNames && sectionNames[0] === 'Daf')}
                  </p>}
                  {subCat.depth === 1 && <p className="bookChosen-page-item-text">
                    {subCat.heTitle === '' ? 'תוכן' : subCat.heTitle}
                  </p>}

                </div>
              }

            </>)
          }
          return (<></>)
        })}

      {first === 1 &&
        (menuView.includes("bookChosenMusar") ||
          menuView.includes("bookChosenKabala") ||
          menuView.includes('bookChosenSmallMas') ||
          menuView.includes("bookChosenHalMid") ||
          menuView.includes("bookChosenYer") ||
          menuView.includes("bookChosenTalCom") ||
          menuView.includes('bookChosenHalCom') ||
          menuView.includes("bookChosenComTor") ||
          menuView.includes("bookChosenComNach")
        ) && contentCounts &&
        <div className="select-chapter-openNav">
          <div className="openNav-bookChosen-container">
            {contentCounts && ((typeof contentCounts[0] === 'number') || (contentCounts[0] && typeof contentCounts[0][0] === 'number')) &&
              contentCounts.map((subCat: any, index: any) => {

                if ((typeof contentCounts[index] === 'number' && contentCounts[index] !== 0) || (typeof contentCounts[index] !== 'number' && !contentCounts[index].every((item: any) => item === 0))) {
                  if (sectionNames && sectionNames[0] === 'Daf' && (index < 2) && !parentPageLocal.includes('Rif')) {
                    return (<></>)
                  }
                  if (parentPageLocal && !parentPageLocal.startsWith('Rashi_on') && !parentPageLocal.startsWith('Rosh') && !(typeof subCat === 'number') && ((menuView !== "bookChosenYer")) &&
                    parentPageLocal && (
                      parseBookPageToHebrew(parentPageLocal.split('_').join(' ').split(' ').splice(-1) + ' ' + (index - 1), sectionNames && sectionNames[0] === 'Daf') === undefined ||
                      parseBookPageToHebrew(parentPageLocal.split('_').join(' ').split(' ').splice(-1) + ' ' + (index - 1), sectionNames && sectionNames[0] === 'Daf') === '')
                  ) {
                    return (<></>)
                  }


                  return (
                    <div
                      className={
                        (parentPageLocal + ' ' + (index + 1)).split('_').join(' ') === pageId
                          ? "bookChosen-page-item bookChosen-current-item"
                          : "bookChosen-page-item"
                      }
                      id={(parentPageLocal + ' ' + (index + 1)).split('_').join(' ') === pageId ? "selected-page" : subCat}
                      key={(parentPageLocal + ' ' + (index + 1)).split('_').join(' ')}
                      onClick={() => {
                        if ((!(typeof contentCounts[index] === 'number')) && parentPageLocal)
                          handleSelectPage(parentPageLocal + ' ' + (index + 1), sectionNames && sectionNames[0] === 'Daf')
                        if ((typeof contentCounts[index] === 'number')) {
                          handleSelectPage(parentPageLocal + ' ' + (index + 1), sectionNames && sectionNames[0] === 'Daf')
                        }
                      }}
                    >
                      {((sectionNames && sectionNames[0] === 'Daf') || !(typeof contentCounts[index] === 'number')) && parentPageLocal &&
                        < p className="bookChosen-page-item-text">
                          {parseBookPageToHebrew(parentPageLocal.split('_').join(' ').split(' ').splice(-1) + ' ' + (index + 1), sectionNames && sectionNames[0] === 'Daf')}
                        </p>}
                      {
                        (!(sectionNames && sectionNames[0] === 'Daf')) && (typeof contentCounts[index] === 'number') && <p className="bookChosen-page-item-text">
                          {
                            parseToHebrew(index + 1)
                          }
                        </p>
                      }
                    </div>)
                }


                return (<></>)
              })}

          </div>
        </div>}
      {first > 1 &&
        (menuView.includes("bookChosenMusar") ||
          menuView.includes("bookChosenKabala") ||
          menuView.includes('bookChosenSmallMas') ||
          menuView.includes("bookChosenTalCom") ||
          (menuView.includes("bookChosenHalCom") && !parentPageLocal.includes('Torat_HaBayit_') && !parentPageLocal.includes('Sefer_Hamitzvot')) ||
          menuView.includes("bookChosenComTor") ||
          menuView.includes("bookChosenLiturgy") ||
          menuView.includes("bookChosenComNach")
        ) && contentCounts &&
        <div className="select-chapter-openNav">
          <div className="openNav-bookChosen-container">
            {contentCounts && contentCounts.map((subCat: any, index: any) => {

              let result: any;
              if (typeof subCat === 'object' && !subCat.depth && !subCat.nodes) {
                result = subCat.filter((word: any) => word > 0);
              }
              if ((!subCat.depth) || (result && result.length > 0) || !subCat.nodes) {

                return (
                  <div
                    className={
                      (parentPageLocal + ' ' + (index + 1)).split('_').join(' ') === pageId
                        ? "bookChosen-page-item bookChosen-current-item"
                        : "bookChosen-page-item"
                    }
                    id={(parentPageLocal + ' ' + (index + 1)).split('_').join(' ') === pageId ? "selected-page" : subCat}
                    key={(parentPageLocal + ' ' + (index + 1)).split('_').join(' ')}
                    onClick={() => {
                      handleSelectPage(parentPageLocal + ' ' + (index + 1), (sectionNames && sectionNames[0] === 'Daf') || (sectionNamesL && sectionNamesL[0] === 'Daf'))
                    }}
                  >
                    <p className="bookChosen-page-item-text">
                      {parseBookPageToHebrew(parentPageLocal.split('_').join(' ').split(' ').splice(-1) + ' ' + (index + 1), (sectionNames && sectionNames[0] === 'Daf') || (sectionNamesL && sectionNamesL[0] === 'Daf'))}
                    </p>
                  </div>
                )
              }
              return (<></>)

            })}
          </div>
        </div>
      }
      {menuView.includes('bookChosenTurCom') &&
        contentCounts &&
        (contentCounts.length === 4 || contentCounts.length === 5) &&
        <div className="select-chapter-openNav">
          <div className="openNav-bookChosen-container">
            {contentCounts && contentCounts[getIndexTurCom(contentCounts.length)] && contentCounts[getIndexTurCom(contentCounts.length)].content_counts && contentCounts[getIndexTurCom(contentCounts.length)].content_counts.map((subCat: any, index: any) => {

              if ((typeof subCat === 'number') || (subCat && subCat[0] && !subCat.every((item: any) => { return item === 0 })))
                return (
                  <div
                    className={
                      (parentPageLocal + ' ' + (index + 1)).split('_').join(' ') === pageId
                        ? "bookChosen-page-item bookChosen-current-item"
                        : "bookChosen-page-item"
                    }
                    id={(parentPageLocal + ' ' + (index + 1)).split('_').join(' ') === pageId ? "selected-page" : subCat}
                    key={(parentPageLocal + ' ' + (index + 1)).split('_').join(' ')}
                    onClick={() => {
                      handleSelectPage(parentPageLocal + ' ' + (index + 1), sectionNames && sectionNames[0] === 'Daf')
                    }}
                  >
                    <p className="bookChosen-page-item-text">
                      {parseBookPageToHebrew(pageLocal.split('_').join(' ').split(' ').splice(-1) + ' ' + (index + 1), sectionNames && sectionNames[0] === 'Daf')}
                    </p>
                  </div>
                )
              return (<></>)
            })}

            {contentCounts && contentCounts[getIndexTurCom(contentCounts.length)] && contentCounts[getIndexTurCom(contentCounts.length)].nodes && contentCounts[getIndexTurCom(contentCounts.length)].nodes.map((subCat: any) => {

              if (typeof subCat.content_counts === 'object') {
                return (<>

                  {subCat.content_counts.map((pageNumber: any, index: number) => {

                    return (<div
                      className={
                        (parentPageLocal + ' ' + (index + 1)).split('_').join(' ') === pageId
                          ? "bookChosen-page-item bookChosen-current-item"
                          : "bookChosen-page-item"
                      }
                      id={(parentPageLocal + ' ' + (index + 1)).split('_').join(' ') === pageId ? "selected-page" : subCat}
                      key={(parentPageLocal + ' ' + (index + 1)).split('_').join(' ')}
                      onClick={() => {
                        handleSelectPage(parentPageLocal + ' ' + (index + 1), sectionNames && sectionNames[0] === 'Daf')
                      }}
                    >
                      <p className="bookChosen-page-item-text">
                        {parseBookPageToHebrew(pageLocal.split('_').join(' ').split(' ').splice(-1) + ' ' + (index + 1), sectionNames && sectionNames[0] === 'Daf')}
                      </p>
                    </div>)
                  })}
                </>)
              }



            })


            }

          </div>
        </div>}
    </div >
  )
}

export default OpenNavList;
