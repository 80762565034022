import books from "../book/booksAndChapters.json";
const { basic } = books;
export const list = basic
	.flat(2)
	.map((a) => {
		if (a.books) return a.books;
		return "";
	})
	.flat()
	.map((a) => {
		if (a && (a as { bookName: string }).bookName)
			return (a as { bookName: string }).bookName.split(" ").join("_");

		if (a && (a as { heCategory: string }).heCategory)
			return (a as { heCategory: string }).heCategory.split(" ").join("_");
		return "";
	});
