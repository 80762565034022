export const hebDec: any = {
  0: "",
  1: "א",
  2: "ב",
  3: "ג",
  4: "ד",
  5: "ה",
  6: "ו",
  7: "ז",
  8: "ח",
  9: "ט",
  10: "י",
  11: "יא",
  12: "יב",
  13: "יג",
  14: "יד",
  15: "טו",
  16: "טז",
  17: "יז",
  18: "יח",
  19: "יט",

  20: "כ",
  21: "כא",
  22: "כב",
  23: "כג",
  24: "כד",
  25: "כה",
  26: "כו",
  27: "כז",
  28: "כח",
  29: "כט",
  30: "ל",

  31: "לא",
  32: "לב",
  33: "לג",
  34: "לד",
  35: "לה",
  36: "לו",
  37: "לז",
  38: "לח",
  39: "לט",
  40: "מ",
  41: "מא",
  42: "מב",
  43: "מג",
  44: "מד",
  45: "מה",
  46: "מו",
  47: "מז",
  48: "מח",
  49: "מט",
  50: "נ",

  51: "נא",
  52: "נב",
  53: "נג",
  54: "נד",
  55: "נה",
  56: "נו",
  57: "נז",
  58: "נח",
  59: "נט",
  60: "ס",
  61: "סא",
  62: "סב",
  63: "סג",
  64: "סד",
  65: "סה",
  66: "סו",
  67: "סז",
  68: "סח",
  69: "סט",
  70: "ע",
  71: "עא",
  72: "עב",
  73: "עג",
  74: "עד",
  75: "עה",
  76: "עו",
  77: "עז",
  78: "עח",
  79: "עט",
  80: "פ",
  81: "פא",
  82: "פב",
  83: "פג",
  84: "פד",
  85: "פה",
  86: "פו",
  87: "פז",
  88: "פח",
  89: "פט",
  90: "צ",
  91: "צא",
  92: "צב",
  93: "צג",
  94: "צד",
  95: "צה",
  96: "צו",
  97: "צז",
  98: "צח",
  99: "צט",
  100: "ק",
  200: "ר",
  300: "ש",
  400: "ת",
  500: "תק",
  600: "תר",
  700: "תש",
  800: "תת",
  900: "תתק"
};
export const aspeklarya: any = [
  "מטה דן",
  "הרב שאול ישראלי",
  "יחיא קאפח",
  "מודרני",
  "עכשוויות",
  "ניתוח קיסרי",
  "ניתוח פלסטי",
  "ביאליק",
  "לאונרד",
  "פונדקאות",
  'להט"ב',
  'להט"בק',
  'קשוי לילד',
  "פין",
  "אינוס",
  "סקס",
  "סקסו",
  "הומו",
  "מין",
  "זנות",
  "זונה",
  "זונות",
  "זנות",
  "לזיין",
  "פלייבוי",
  "תשוקה",
  "סרסור",
  "נערת ליווי",
  "יצאנית",
  "פריצות",
  "זימה",
  "יחסי מין",
  "יחסי-מין",
  "טרנסג'נדר",
  "לסבית",
  "מיניות",
  "אופי מיני",
  "אופן מיני",
  "אקט מיני",
  "תוכן מיני",
  "מיניים",
  "מעשים מגונים",
  "מעשיה מגונה",
  "פורנו",
  "חושפניות",
  "איידס",
  "איבר מין",
  "איברי המין",
  "מאוננים",
  "יחסי אהבה",
  "רומן",
  "עירומים",
  "אינטימי",
  "פדופיל",
  "הרמון",
  "העדפה מינית",
  "דוגמנית",
  "שדיים",
  "דגדגן",
  "מאהבת",
  "מתאהב",
  "קונדום",
  "אמצעי מניעה",
  "וסת",
  "ילדים לא חוקיים",
  "בת לא חוקי",
  "בן לא חוקי"];


export const hebDecMonth: any = {
  7: "בתשרי",
  8: "בחשוון",
  9: "בכסלו",
  10: "בטבת",
  11: "בשבט",
  12: "באדר",
  13: "באדר א'",
  14: "באדר ב'",
  1: "בניסן",
  2: "באייר",
  3: "בסיוון",
  4: "בתמוז",
  5: "באב",
  6: "באלול"
};

export function getKeyByValue(value: any) {
  const valueArray = value.split('');
  let valueresponce = 0;
  valueArray.forEach((element: any) => {
    if (Object.keys(hebDec).find(key => hebDec[key] === element))
      valueresponce = (valueresponce as number) + Number(Object.keys(hebDec).find(key => hebDec[key] === element));
  });

  return valueresponce;
}

export function getLetterByNumber(value: any) {

  if (value < 100)
    return hebDec[value];
  else {
    return hebDec[value - (value % 100)] + hebDec[value % 100];
  }
}
export const parseBookPageToHebrew = (bookpage: string, render: boolean = false) => {

  let splitBookPage = bookpage.split(" ");

  if (render) {

    if (splitBookPage.length > 2) {
      splitBookPage.shift();
    }
    // bug for yerushalmy

    let digits = splitBookPage[splitBookPage.length - 1].replace(/\D/g, '');
    var withNoDigits = splitBookPage[splitBookPage.length - 1].replace(/[0-9]/g, '');



    if (Number(digits) < 100) {
      if (withNoDigits === 'a')
        return '.' + hebDec[Number(digits)]
      if (withNoDigits === 'b')
        return ':' + hebDec[Number(digits)]
    }
    else {
      if (withNoDigits === 'a')
        return '.' + hebDec[Number(digits) - Number(digits) % 100] + hebDec[Number(digits) % 100]
      if (withNoDigits === 'b')
        return ':' + hebDec[Number(digits) - Number(digits) % 100] + hebDec[Number(digits) % 100]
    }
    if (Number(digits) % 2 === 1) {
      digits = (((Number(digits) - 1) / 2) + 1).toString();
      return '.' + hebDec[Number(digits) - Number(digits) % 100] + hebDec[Number(digits) % 100]
    }
    if (Number(digits) % 2 === 0) {
      digits = (((Number(digits) ) / 2) ).toString();
      return ':' + hebDec[Number(digits) - Number(digits) % 100] + hebDec[Number(digits) % 100]
    }
  }

  if (!bookpage.includes(':')) {

    let pageNum = Number(splitBookPage[splitBookPage.length - 1])
    if (pageNum < 100)
      return hebDec[pageNum]
    else {
      return hebDec[pageNum - pageNum % 100] + hebDec[pageNum % 100]
    }
  }
  else {
  }

};
export const parseToHebrew = (bookpage: number, render: boolean = false) => {

  return hebDec[bookpage - bookpage % 100] + hebDec[bookpage % 100]

};


export function getGmaraBigWord(textdata: any, alts: any, rashi: any, tosafot: any) {


  textdata.map((itemData: any, index: any) => {
    if (itemData[0].includes(","))
      itemData[0] = itemData[0].replace(/ *\([^)]*\) */g, " ");
    if (itemData[0].includes(`גמ'`)) {
      itemData[0] = itemData[0].replace(`גמ'`, `<b>גמ'</b>`)
      if (index > 0) {
        if (textdata[index - 1][0].charAt(textdata[index - 1][0].length - 1) !== ':')
          textdata[index - 1][0] = textdata[index - 1][0].trim() + ':'
      }

    }
    if (itemData[0].includes(`הדרן`)) {
      if (index > 0) {
        if (textdata[index - 1][0] && textdata[index - 1][0].charAt(textdata[index - 1][0].length - 1) !== ':')
          textdata[index - 1][0] = textdata[index - 1][0].trim() + ':'
      }
      textdata[index][0] = textdata[index][0].split('<br>').join('').split('</br>').join('').split('<big>').join('') + '</big></b>';
      if (textdata[index + 1]) {
        textdata[index + 1][0] = textdata[index + 1][0].replace(`מתני׳`, '');
        const firstlettr = textdata[index + 1][0].trim().split(' ')[0];
        textdata[index + 1][0] = textdata[index + 1][0].replace(firstlettr, '<b><big>' + firstlettr + '</big></b>')
      }
      let rashiIsFirst = false;
      let tosafotIsFirst = false;
      for (let indexRashi = 0; indexRashi < rashi.length; indexRashi++) {
        if (rashi[indexRashi] && rashi[indexRashi].length !== 0 && indexRashi < index) {
          rashiIsFirst = true;
          break;
        }
      }
      if (tosafot)
        for (let indexTosafot = 0; indexTosafot < tosafot.length; indexTosafot++) {
          if (tosafot[indexTosafot] && tosafot[indexTosafot].length !== 0 && indexTosafot < index) {
            tosafotIsFirst = true;
            break;
          }
        }
      rashi[index] = itemData;

      if (itemData && !itemData[0].includes('הדרן עלך')) {
        if (rashiIsFirst) {
        }
        if (tosafotIsFirst)
          tosafot[index] = itemData;
      }

      return textdata;
    }
    if (itemData[0].startsWith(`מתני'`) && textdata[index + 1] && textdata[index + 1][0].startsWith("גמ'")) {

      if (index > 0) {
        if (textdata[index - 1][0].slice(-1) !== ':') {
          textdata[index - 1][0] = textdata[index - 1][0].trim() + ':';
        }
      }
      if (alts && alts.length > 0) {
        alts.forEach((element: any) => {
          if (element !== null) {
            const testIsFirstItemData = itemData[0].split(' ');
            const testIsFirstAlts = element.he[0].split(' ');

            if (
              testIsFirstItemData &&
              testIsFirstItemData[1] &&
              testIsFirstAlts &&
              testIsFirstAlts[0] &&
              testIsFirstItemData[1] === testIsFirstAlts[0]
            )
              itemData[0] = itemData[0].replace(element.he[0], `<b><big>${element.he[0]}</big></b>`)
          }
        });
      }
      else {
        if (itemData[0].startsWith(`מתני'`) && textdata[index + 1][0].startsWith("גמ'"))
          itemData[0] = itemData[0].replace(`מתני'`, `<b>מתני'</b>`)
      }
    }
    if (itemData[0].includes(`גמ׳`)) {
      itemData[0] = itemData[0].replace(`גמ׳`, `<b>גמ׳</b>`)
      if (index > 0) {
        if (textdata[index - 1][0].charAt(textdata[index - 1][0].length - 1) !== ':') {
          if (textdata[index - 1][0].slice(-1) !== ':') {
            textdata[index - 1][0] = textdata[index - 1][0].trim() + ':';
          }
        }
      }

    }
    if (itemData[0].includes(`מתני׳`)) {
      if (index > 0) {
        if (textdata[index - 1][0].slice(-1) !== ':') {
          textdata[index - 1][0] = textdata[index - 1][0].trim() + ':';
        }
      }
      if (alts && alts.length > 0 && index === 0) {
        alts.forEach((element: any) => {
          if (element !== null) {
            itemData[0] = itemData[0].replace(`מתני׳`, ``).replace(element.he[0], `<b><big>${element.he[0]}</big></b>`)
          }
        });
      }
      else {
        itemData[0] = itemData[0].replace(`מתני׳`, `<b>מתני׳</b>`)
      }

    }
    return ''
  })
  return textdata
} 
