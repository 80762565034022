import React from "react";
import "./ShowSfarimCommentary.css";
import { ReactComponent as XSvgBlack } from "../../svg/black.svg";
import { ReactComponent as XSvgWhite } from "../../svg/white.svg";
import {
  setCommentaryselectChecked,
  setCommentaryToShow,
  setIsSfarimOn
} from "../../store/actions/pageActions";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedcommentary } from "../../store/selectors/selectors";
import { ThunkDispatch } from "@reduxjs/toolkit";

interface IShowSfarimCommentaryProps {
  value: any;
  isSelected: any;
}

const ShowSfarimCommentary: React.FC<IShowSfarimCommentaryProps> = ({
  value,
  isSelected
}) => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const selectedCommentary = useSelector(getSelectedcommentary);
  let newValue: any = Object.values(value)[0];

  // set the commentary that it's text need to br shown.
  const setCommentary = () => {
    let commentaryToSet = selectedCommentary.filter((item: any) => {

      if (Object.values(item)[0] === newValue) {
        return Object.keys(item)[0];
      }
      else {
        return ''
      }
    });
    dispatch(setCommentaryToShow(Object.keys(commentaryToSet[0])[0]));
  };

  // remove the commentary from the list of commentaries that need to be shown, and if it's the last one, come back to- choose-sfarim comp.
  const removeFromSelectedCommentary = (event: any) => {
    event.stopPropagation();
    if (selectedCommentary.length === 1) {
      dispatch(setCommentaryToShow(null));
      dispatch(setCommentaryselectChecked([]));
      dispatch(setIsSfarimOn(false));
    } else {
      let newSelectedCommentary = JSON.parse(
        JSON.stringify(selectedCommentary)
      );
      const idx = newSelectedCommentary.findIndex((item: any) => {
        return Object.values(item)[0] === value[0];
      });
      newSelectedCommentary.splice(idx, 1);
      dispatch(setCommentaryselectChecked(newSelectedCommentary));
      dispatch(setCommentaryToShow(Object.keys(newSelectedCommentary[0])[0]));
    }
  };
  if(newValue.includes('כסף משנה על משנה תורה, הלכות')){
    newValue=newValue.replace('על משנה תורה, הלכות','-')
  }
  return (
    <>
      <span className="item-name" onClick={setCommentary}>
        {newValue.length > 30 ? newValue.substring(0, 30) + "..." : newValue}
      </span>
      {isSelected && (
        <div className="x-svg-container" onClick={removeFromSelectedCommentary}>
          <XSvgWhite className="x-svg" />
        </div>
      )}
      {!isSelected && (
        <div className="x-svg-container" onClick={removeFromSelectedCommentary}>
          <XSvgBlack className="x-svg" />
        </div>
      )}
    </>
  );
};

export default ShowSfarimCommentary;
