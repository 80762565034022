import * as Types from "../types/types";
interface IPageState {
  pageData: {
    pageTitle: String;
    text: any;
    commentary: object | null;
    commentarySefarim: object | null;
    id: String;
    navigation: {
      seder: String;
      book: String;
      pageNumber: String;
      subPage: String;
      categoryName: String;
      heCategoryName: String;
      alts:any;
    };
    titles: object | null;
  };
  categoryNameFromUrl: any;
  bookNameFromUrl: any;
  pageNumberFromUrl: any;
  selectedUnitText: Number | null;
  selectedUnitTextFromUrl: Number | null;
  layoutData: object | null;
  selectedCommentary: any;
  commentaryToShow: any;
  commentaryToShowFromUrl: any;
  selectedCommentaryFromUrl: any;
  numberOfmefarshim: Number;
}

export const pageInitState = {
  pageData: {
    id: "",
    pageTitle: "",
    navigation: {
      seder: "",
      book: "",
      pageNumber: "",
      subPage: "",
      categoryName: "",
      heCategoryName: "",
      alts:undefined
    },
    commentary: null,
    commentarySefarim: null,
    titles: {},
    text: []
  },
 
  categoryNameFromUrl: null,
  bookNameFromUrl: null,
  pageNumberFromUrl: null,
  selectedUnitText: null,
  selectedUnitTextFromUrl: null,
  layoutData: null,
  selectedCommentary: [],
  commentaryToShow: null,
  commentaryToShowFromUrl: null,
  selectedCommentaryFromUrl: null,
  numberOfmefarshim:-1,
};

const pageReducer = (
  state: IPageState = pageInitState,
  action: { type: String; payload?: any }
) => {
  switch (action.type) {
    case Types.SET_PAGE:
      return {
        ...state,
        pageData: action.payload
      };
    case Types.SELECT_TEXT_UNIT:
      return {
        ...state,
        selectedUnitText: action.payload
      };
    case Types.SELECT_TEXT_UNIT_FROM_URL:
      return {
        ...state,
        selectedUnitTextFromUrl: action.payload
      };
    case Types.SET_LAYOUT_DATA:
      return {
        ...state,
        layoutData: action.payload
      };
    case Types.SET_SELECTED_COMMENTARY_CHECKED:
      return {
        ...state,
        selectedCommentary: action.payload
      };
    case Types.SET_COMMENTARY_TO_SHOW:
      return {
        ...state,
        commentaryToShow: action.payload
      };
    case Types.SET_COMMENTARY_TO_SHOW_FROM_URL:
      return {
        ...state,
        commentaryToShowFromUrl: action.payload
      };
    case Types.SET_SELECTED_COMMENTARY_FROM_URL:
      return {
        ...state,
        selectedCommentaryFromUrl: action.payload
      };
    case Types.SET_CATEGORY_NAME_FROM_URL:
      return {
        ...state,
        pageData: { ...state.pageData, categoryName: action.payload }
      };
    case Types.SET_HE_CATEGORY_NAME_FROM_URL:
      return {
        ...state,
        pageData: { ...state.pageData, heCategoryName: action.payload }
      };
    case Types.SET_ALL_BOOKS:
      return {
        ...state,
        pageData: { ...state.pageData, allbooksNames: action.payload }
      };
    case Types.SET_BOOK_NAME_FROM_URL:
      return {
        ...state,
        bookNameFromUrl: action.payload
      };
    case Types.SET_PAGE_NUMBER_FROM_URL:
      return {
        ...state,
        pageNumberFromUrl: action.payload
      };
    case Types.SET_NUMBER_OF_MEFASHIM:
      return {
        ...state,
        numberOfmefarshim: action.payload
      };
    default:
      return state;
  }
};

export default pageReducer;
