import React from "react";
import "./OpenNav1.css";
import { useDispatch, useSelector } from "react-redux";
import {
  closeMenu1
} from "../../store/actions/viewActions";
import { getPageData } from "../../store/actions/pageActions";
import {
  getIsMenuOpen1
  
} from "../../store/selectors/selectors";
import isMobile from "ismobilejs";
import {
  getParashat,
  daf
} from "../../utils/funcUtils";
import { ThunkDispatch } from "@reduxjs/toolkit";

const OpenNav1 = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const isMenuOpen1 = useSelector(getIsMenuOpen1);

  const handleViewChange = () => {
    const resp = daf();
    dispatch(getPageData(resp.book, resp.page));
    dispatch(closeMenu1());
  };

  const handleViewChangeparashat = () => {
    getParashat().then((response) => {
      let book = response.split('/')[1];
      let pageNumber = response.split('/')[2];
      dispatch(getPageData(book, pageNumber));
      dispatch(closeMenu1());
    });

  };


  return (
    <div className={isMenuOpen1 && !(isMobile().phone || isMobile().tablet) ? `header-openNav` : isMenuOpen1 && (isMobile().phone || isMobile().tablet) ? `header-openNavD` : "header-hidden"}

      id={"openNav1"}
    >


      <div className="openNav-main-container">

        <div className="openNav-choose-subject-container">
          <div
            className="openNav-choose-subject"
            onClick={() => handleViewChange()}
          >
            דף היומי
          </div>
        </div>


      </div>
      <div className="openNav-main-container">

        <div className="openNav-choose-subject-container">
          <div
            className="openNav-choose-subject"
            onClick={() => handleViewChangeparashat()}
          >
            פרשת שבוע
          </div>
        </div>


      </div>

    </div>
  );
};

export default OpenNav1;
