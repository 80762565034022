
import React from "react";
import "./FooterMobile.css";
import { ReactComponent as BooksFooterMobile } from "../../svg/BooksFooterMobile.svg";
import { ReactComponent as BooksFooterOpenMobile } from "../../svg/BooksFooterOpenMobile.svg";
import { setUserProfileView, openMenu, } from "../../store/actions/viewActions";
import { setTabOpenLeft, setTabOpenRight } from "../../store/actions/pageActions";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserProfileView,
  getOpenTabsLeft,
  getCompetition
} from "../../store/selectors/selectors";
import { ThunkDispatch } from "@reduxjs/toolkit";

const FooterMobile: React.FC = () => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const userProfileView = useSelector(getUserProfileView);
  const tabsOpened = useSelector(getOpenTabsLeft);
  const competition = useSelector(getCompetition);
  
  return (
    <div className={`footer-mobile ${competition === null ? '' : 'none'}`}>
      {
        <div className={userProfileView === "MefarshimMobile" ? 'footer-mobile-Notification-w' : 'footer-mobile-Notification'}
          onClick={() => {
            if (window.location.pathname !== '/' && window.location.pathname !== '/home') {

              if (tabsOpened !== 'mefarshim')
                dispatch(setTabOpenRight("mefarshim"));
              if (userProfileView !== "MefarshimMobile")
                dispatch(setUserProfileView("MefarshimMobile"));
              if (userProfileView === "MefarshimMobile")
                dispatch(setUserProfileView("FormPageConnect"));
            }
          }}
        >
          {'מפרשים'}
        </div>}
      <div className='footer-mobile-logo'
        onClick={() => {
          dispatch(openMenu());
          if (userProfileView !== "MenuMobile")
            dispatch(setUserProfileView("MenuMobile"));
          if (userProfileView === "MenuMobile")
            dispatch(setUserProfileView("FormPageConnect"));
        }}
      >
        {
          userProfileView !== "MenuMobile" ?
            <BooksFooterMobile /> :
            <BooksFooterOpenMobile />
        }
      </div>
      <div className={`footer-mobile-menu ${userProfileView === "HidushimMobile" ? 'ccccc' : ''}`}
        onClick={() => {
          if (window.location.pathname !== '/' && window.location.pathname !== '/home') {
            if (tabsOpened !== 'hidushim')
              dispatch(setTabOpenLeft("hidushim"));
            if (userProfileView !== "HidushimMobile")
              dispatch(setUserProfileView("HidushimMobile"));
            if (userProfileView === "HidushimMobile")
              dispatch(setUserProfileView(""));
          }
        }}
      >
        {'חידושים'}
      </div>
    </div>
  )
}

export default FooterMobile;
