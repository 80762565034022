import React, { useState } from "react";

import "./FormPageForget.css";
import { ReactComponent as MihlolLogo } from "../../svg/logoBig.svg";
import { ReactComponent as LogoWord } from "../../svg/logoWord.svg";
import wikiService from "../../services/wikiService";

const FormPageForget: React.FC = () => {
  const [isError, setIsError] = useState(false);
  let userName = '';
  const [errorValue, setErrorValue] = useState('סיסמא זמנית נשלחה לאימייל שלך ');
  const onChangeUserName = (e: any) => {
    userName = e.target.value;
  }

  const LogInOtAccount = () => {

    wikiService.reSetPassword(userName).then((response)=>{
      if (response.resetpassword && response.resetpassword.status === 'success') {
        setIsError(true)
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
      else if (response && response.error.info && response.error.info === 'A password reset email has already been sent, within the last 24 hours. To prevent abuse, only one password reset email will be sent per 24 hours.') {
        setErrorValue('לא ניתן לאפס שוב ב-24 שעות')
        setIsError(true)
        setTimeout(() => {
          setIsError(false)
        }, 2000);
      }
      else if (response && response.error.code && response.error.code === 'nosuchuser') {
        setErrorValue('שם משתמש זה לא קיים')
        setIsError(true)
        setTimeout(() => {
          setIsError(false)
        }, 2000);
      }
    })

  
  }


  return (
    <>
      <div className='flex'>
        <div className='form-page-logo'>
          <MihlolLogo />
        </div>
        <div className='form-page-logo-word'>
          <LogoWord />
        </div>
      </div>
      <div className='form-page-connect-forget-main'>
        <input
          type='text'
          name='username'
          className='form-page-username'
          placeholder='שם משתמש'
          onChange={(e) => {
            onChangeUserName(e)
          }}
          maxLength={40}
        >
        </input>
        {isError && <div className='form-page-connect-forget-error'>
          {errorValue}
        </div>}
        <div className='form-page-connect-forget'
          onClick={() => LogInOtAccount()}
        >
          <div className='form-page-connect-forget-text'>
            {'אפס סיסמה'}
          </div>
        </div>
      </div>
    </>
  );
}




export default FormPageForget;
