
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getUserid,
  getMyTopics,
  getAllBooks
} from "../../store/selectors/selectors";
import "./ShowFeed.css";
import { ReactComponent as UserIcon } from "../../svg/userIcon.svg";
import { ReactComponent as UserIconMobile } from "../../svg/UserIconMobile.svg";
import DOMPurify from 'dompurify';
import {
  setSelectedTextUnit,
  getPageData,
  setTabOpenLeft,
  setAllTopics,
} from "../../store/actions/pageActions";
import { setclosePid1, setclosePid, setMyTopic } from "../../store/actions/viewActions";
// @ts-ignore
import ShowMoreText from 'react-show-more-text';
import wikiService from "../../services/wikiService";
import { ReactComponent as Comment } from "../../svg/comment.svg";
import { ReactComponent as CommentGreen } from "../../svg/commentGreen.svg";
import {
  getKeyByValue,
} from "../../utils/menuUtils";
import {
  openComment
} from "../../utils/funcUtils";
import {
  getTitleEn,
  getPageEn,
} from "../../utils/dataUtils";
import isMobile from "ismobilejs";
import Tooltip from '@mui/material/Tooltip';
import {
  useStyles
} from "../../utils/funcUtils";
import { ThunkDispatch } from "@reduxjs/toolkit";

interface Props {
  topic: any;
  isTitle: boolean;
  revisions: any;
  posts: any;
  LikedCounts: any;
  level: number;
  statusUuid: string;
  searchText: string;
  increment: (topic: any) => void;
}


const ShowFeed: React.FC<Props> = ({ topic, isTitle, revisions, posts, LikedCounts, level, statusUuid, searchText, increment }) => {

  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const allBooksNames = useSelector(getAllBooks);
  const userid = useSelector(getUserid);
  const [items2, setItems2] = useState(LikedCounts);
  //let replys = new Map<string, string>();
  const myTopics = useSelector(getMyTopics);
  const classes = useStyles();
  const handleTogglePid = (creator: any) => {
    dispatch(setAllTopics([]))
    dispatch(setMyTopic(creator.id + '`' + creator.name));
  };
  const [isLoaded, setIsLoaded] = useState(false);
  const [replys, setReplys] = useState(new Map<string, string>());
  const [expande, setExpanded] = useState(false);
  const [isLoadedFeed, setIsLoadedFeed] = useState(false);
  const [flowV, setFlowVW] = useState(revisions);
  const [isFocus, setFocus] = useState(false);
  const incremen = (topic: any) => {
    setFlowVW(topic)
    increment(topic)
  }

  const handleChangeRep = (e: any, rep: any) => {
    const repl = replys;
    repl.set(rep, e.target.innerText);
    setReplys(repl)
  }


  const isUserLikeed = (uuid: string) => {

    for (var i = 0; i < LikedCounts.length; i++) {
      if (userid && uuid === LikedCounts[i].uuid && LikedCounts[i].userid === userid.toString())
        return true;
    }
    return false;
  }
  const makeLike = (e: any, uuid: any) => {
    if (isUserLikeed(uuid))
      return;

    let counter = '1';
    wikiService.makeLike(uuid);
    for (var i = 0; i < items2.length; i++) {
      if (uuid === items2[i].uuid) {
        items2[i].count = Number(items2[i].count) + 1;
        counter = String(Number(items2[i].count) + 1);
      }
    }
    var ffff = items2;
    ffff.push({ count: counter, uuid: uuid, userid: userid })
    setItems2(ffff);
    let classa = document.getElementById(uuid);
    let classaCount = document.getElementById(uuid + 'count');
    if (classa) {
      classa.setAttribute('class', 'sss')
    }
    if (classaCount) {

      classaCount.textContent = String(Number(classaCount.textContent) + 1);
    }
  }



  const LikedCount = (uuid: string) => {

    for (var i = 0; i < items2.length; i++) {
      if (uuid === items2[i].uuid)
        return items2[i].count;
    }
    return 0;
  }


  const handleClickRep = (workflowId: any, postId: any, event: any) => {
    var content = replys.get(postId);
    if (content && content !== '') {
      event.target.innerHTML = '';
      event.preventDefault();
      wikiService.handleClickRep(workflowId, postId, replys).then((res) => {
        incremen(res)
      })
    }
  }




  const dateFormat = (uuid: string) => {
    return openComment(uuid)

  }
  const getPostId = () => {
    if (isTitle) {
      return flowV[topic.replies[0]].postId
    }
    else {
      return topic.postId
    }
  }
  if (isTitle) {


    if (!flowV[topic.replies[0]]) {
      return (<></>)
    }
  }

  if (searchText !== ''&&!(isTitle ? flowV[topic.replies[0]] : topic).content.content.includes('b style="background-color:#e4e8e8;color:#38A09D')) {
    (isTitle ? flowV[topic.replies[0]] : topic).content.content = (isTitle ? flowV[topic.replies[0]] : topic).content.content.split(searchText).join(`<b style="background-color:#e4e8e8;color:#38A09D">${searchText}</b>`)
  }

  return (<div
    className={`recent-feed ${(isMobile().phone || isMobile().tablet) && 'recent-feed-mobile'}`}
    key={topic && topic.replies[0]}
  >

    {flowV && topic !== undefined &&
      <div>
        {<div className={isTitle ? `recent-feed-main-item-open ${(isMobile().phone || isMobile().tablet) && 'recent-feed-main-item-open-mobile'}` : ''} >
          {!(isMobile().phone || isMobile().tablet) && <div className='recent-feed-d'>
            {isTitle && <div className='recent-feed-icon-main'>
              {<div className='recent-feed-icon'>
                {!(isMobile().phone || isMobile().tablet) && <UserIcon />}
              </div>}
            </div>
            }

            <Tooltip
              classes={classes}
              title={myTopics === '' ? (topic.creator.name) : ''}
              placement="top-end"
              arrow
            >
              <div className={isTitle ? `recent-feed-person ${(isMobile().phone || isMobile().tablet) && `recent-feed-person-mobile`}` : `recent-feed-person-sub ${(isMobile().phone || isMobile().tablet) && `recent-feed-person-sub-mobile`}`}
                onClick={() => {
                  if (myTopics === '')
                    handleTogglePid(topic.creator)
                }}
              >
                {topic.creator.name}
              </div>
            </Tooltip>
            <Tooltip
              classes={classes}
              title={topic.timestamp}
              placement="top"
              arrow
            >
              <div className={`recent-feed-date`}>
                {dateFormat(topic.timestamp)}
              </div>
            </Tooltip>
          </div>}
          {(isMobile().phone || isMobile().tablet) && <div className='recent-feed-d-mobile'>
            {isTitle && <div className='recent-feed-icon-mobile'>
              {(isMobile().phone || isMobile().tablet) && <UserIconMobile />}
            </div>}
            <div>
              {<div className={isTitle ? `recent-feed-person ${(isMobile().phone || isMobile().tablet) && `recent-feed-person-mobile`}` : `recent-feed-person-sub ${(isMobile().phone || isMobile().tablet) && `recent-feed-person-sub-mobile`}`}>
                {topic.creator.name}
              </div>}
              {<Tooltip
                classes={classes}
                title={topic.timestamp}
                placement="top"
                arrow
              >
                <div className={`recent-feed-date`}>
                  {dateFormat(topic.timestamp)}
                </div>
              </Tooltip>}

            </div>

          </div>}
          <div >
            {isTitle && <div className='flex'>
              {!(isMobile().phone || isMobile().tablet) && isTitle &&
                <div className={`recent-feed-title  ${(isMobile().phone || isMobile().tablet) && 'recent-feed-title-mobile'}`}
                  onClick={() => {
                    if ((userid === 0)) {
                      return
                    }

                    let book = getTitleEn(allBooksNames, topic.Title_Parent);
                    let page = getKeyByValue(getPageEn(topic.Title_Parent, 2).replace('׳', '').replace('״', '').split(' ')[0]);
                    const subPage = getPageEn(topic.Title_Parent, 1);
                    if (page && subPage) {
                      const textUnit = topic.Title_Parent.replace(/\D/g, "");
                      dispatch(setclosePid1());
                      dispatch(setclosePid());
                      if (topic.Title_Parent.includes(':')) {
                        dispatch(getPageData(book, page + '.' + getKeyByValue(getPageEn(topic.Title_Parent, 1).split('׳').join('').split('״').join('').split(' ')[1])));
                      }
                      else {
                        dispatch(getPageData(book, page + subPage));
                      }
                      dispatch(setTabOpenLeft('hidushim'));
                      setTimeout(function () {
                        dispatch(setSelectedTextUnit(textUnit))
                      }, 7000);
                    }
                    else {
                      const pageT = topic.Title_Parent.split('~')[1];
                      if (pageT === undefined) {
                        return;
                      }
                      dispatch(setclosePid1());
                      dispatch(setclosePid());

                      if (pageT.includes('Pesach Haggadah')) {
                        dispatch(setclosePid1());
                        dispatch(setTabOpenLeft('hidushim'));
                        dispatch(getPageData('Pesach Haggadah', pageT.replace('Pesach Haggadah', '').replace(' ', '_')));
                      }
                      else {
                        const lastIndexOf = pageT.lastIndexOf('_');
                        dispatch(setTabOpenLeft('hidushim'));
                        dispatch(getPageData(pageT.substring(0, lastIndexOf), pageT.substring(lastIndexOf + 1)));
                      }
                    }
                  }}
                >
                  {topic.Title_Parent.split('~')[0].split('_').join(' ').split(':')[0].split('/')[1] &&
                    topic.Title_Parent.split('~')[0].split('_').join(' ').split('/')[1].replace(/[0-9]/g, '').replace(':', ' ')}
                  {!topic.Title_Parent.split('~')[0].split('_').join(' ').split('/')[1] &&
                    topic.Title_Parent.split('~')[0].split('_').join(' ').split('/')[0].replace(/[0-9]/g, '').replace(':', ' ')}
                </div>}
            </div>}
            {isTitle &&
              <div className={`recent-feed-line ${(isMobile().phone || isMobile().tablet) && 'recent-feed-line-mobile'}`}>
              </div>}
            <div>
              <div className={isTitle ? `recent-feed-text ${(isMobile().phone || isMobile().tablet) && 'recent-feed-text-mobile'}` : `recent-feed-text-sub-2 ${(isMobile().phone || isMobile().tablet) && 'recent-feed-text-sub-2-mobile'}`}>
                <ShowMoreText
                  lines={4}
                  more='קרא עוד>'
                  less='קרא פחות>'
                  anchorClass='ttt'
                  expanded={statusUuid === '' && searchText === '' ? false : true}
                  onClick={(expanded) => { setExpanded(expanded) }}
                  width={(isMobile().phone || isMobile().tablet) ? 480 : 590}
                >
                  {topic && (expande || !(statusUuid === '' && searchText === '')) && < div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize((isTitle ? flowV[topic.replies[0]] : topic).content.content.split("<br>").join('\n'), { ALLOWED_TAGS: ['blockquote', 'h1', 'h2', 'h3', 'big', 'b', 'strong'], FORBID_ATTR: ['data-parsoid', 'id'],ALLOWED_ATTR: ['style'] }).split('&nbsp;').join(' ')
                    }}

                  >
                  </div>}
                  {!(expande || !(statusUuid === '' && searchText === '')) &&
                    DOMPurify.sanitize((isTitle ? flowV[topic.replies[0]] : topic).content.content.split("<br>").join('\n'), { ALLOWED_TAGS: ['blockquote', 'h1', 'h2', 'h3', 'big', 'b', 'strong'], FORBID_ATTR: ['data-parsoid', 'id'],ALLOWED_ATTR: ['style'] }).split('&nbsp;').join(' ')
                  }
                </ShowMoreText>
              </div>

            </div>
            {isTitle &&
              <div className={`recent-feed-line ${(isMobile().phone || isMobile().tablet) && 'recent-feed-line-mobile-2'}`}>
              </div>}

            {<div className={isTitle ? `recent-feed-like-main ${(isMobile().phone || isMobile().tablet) && 'recent-feed-like-main-mobile'}` : `recent-feed-like-main-2 ${(isMobile().phone || isMobile().tablet) && 'recent-feed-like-main-2-mobile'}`}>
              <div className={!(isMobile().phone || isMobile().tablet) ? 'recent-feed-like' : isTitle ? 'recent-feed-like' : 'recent-feed-like-sub'}

                onClick={(e) => {
                  if (!isUserLikeed(getPostId()))
                    makeLike(e, getPostId())
                }}
              >
                {!isUserLikeed(getPostId()) && <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path id={getPostId()} d="M13.7343 7.33503C12.5215 6.38126 10.9822 4.77718 10.1425 1.17883C9.72273 0.658591 8.78982 1.43895 8.78982 1.43895C8.78982 1.43895 7.85691 2.34938 8.60324 6.68473H3.05241C2.16614 6.68473 1.46646 7.33503 1.46646 8.15875C1.46646 8.59229 1.65304 8.98247 2.0262 9.24259C2.1195 9.28594 2.07285 9.416 1.97956 9.45936C1.41981 9.71948 1 10.2831 1 10.9334C1 11.627 1.46646 12.1906 2.07285 12.4507C2.21279 12.4941 2.21279 12.6675 2.07285 12.7109C1.55975 12.971 1.23323 13.4912 1.23323 14.0548C1.23323 14.7485 1.69968 15.3121 2.35272 15.5288C2.49266 15.5722 2.49266 15.7456 2.39937 15.789C2.07285 16.0057 1.88627 16.3526 1.88627 16.7427C1.88627 17.3497 2.35272 17.9133 3.00576 18H10.7023C13.8275 18 16.5796 15.8323 16.9528 12.9276C17.2793 10.5432 15.88 8.33216 13.7343 7.33503Z" stroke="#38A09D" strokeMiterlimit="10" />
                </svg>}
                {isUserLikeed(getPostId()) && <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path id={getPostId()} className='sss' d="M13.7343 7.33503C12.5215 6.38126 10.9822 4.77718 10.1425 1.17883C9.72273 0.658591 8.78982 1.43895 8.78982 1.43895C8.78982 1.43895 7.85691 2.34938 8.60324 6.68473H3.05241C2.16614 6.68473 1.46646 7.33503 1.46646 8.15875C1.46646 8.59229 1.65304 8.98247 2.0262 9.24259C2.1195 9.28594 2.07285 9.416 1.97956 9.45936C1.41981 9.71948 1 10.2831 1 10.9334C1 11.627 1.46646 12.1906 2.07285 12.4507C2.21279 12.4941 2.21279 12.6675 2.07285 12.7109C1.55975 12.971 1.23323 13.4912 1.23323 14.0548C1.23323 14.7485 1.69968 15.3121 2.35272 15.5288C2.49266 15.5722 2.49266 15.7456 2.39937 15.789C2.07285 16.0057 1.88627 16.3526 1.88627 16.7427C1.88627 17.3497 2.35272 17.9133 3.00576 18H10.7023C13.8275 18 16.5796 15.8323 16.9528 12.9276C17.2793 10.5432 15.88 8.33216 13.7343 7.33503Z" stroke="#38A09D" strokeMiterlimit="10" />
                </svg>}
              </div>
              <div className={`recent-feed-like-text ${(isMobile().phone || isMobile().tablet) && isMobile().tablet && !isTitle && 'recent-feed-like-text-mobile'}`}
                onClick={(e) => {
                  if (!isUserLikeed(getPostId()))
                    makeLike(e, getPostId())
                }}
              >
                {'  שכוייח'}
              </div>
              <div className={`recent-feed-like-count ${(isMobile().phone || isMobile().tablet) && 'recent-feed-like-count-mobile'}`} id={getPostId() + 'count'}>
                {Number(LikedCount(getPostId())) > 0 && Number(LikedCount(getPostId()))}
              </div>
              {false && <div className={`recent-feed-comment ${(isMobile().phone || isMobile().tablet) && !isTitle && 'recent-feed-comment-mobile'}`}>
                {!isLoadedFeed && <Comment />}
                {isLoadedFeed && <CommentGreen />}

              </div>}

              {false && <div className={`recent-feed-comment-text  ${(isMobile().phone || isMobile().tablet) && 'recent-feed-comment-text-mobile'} ${(isMobile().phone || isMobile().tablet) && !isTitle && 'recent-feed-comment-text-sub-mobile'}`}
                onClick={() => {
                  if (isTitle) {
                    setIsLoadedFeed(!isLoadedFeed);
                  }
                  setIsLoaded(!isLoaded);
                }}
              >
                {'תגובה'}
              </div>}
              <div className={`recent-feed-comment-count ${(isMobile().phone || isMobile().tablet) && 'recent-feed-comment-count-mobile'}`} >
                {isTitle && ((topic.reply_count - 1) !== 0 && topic.reply_count - 1)}
                {!isTitle && topic.replies.length > 0 && topic.replies.length}
              </div>
            </div>}
            {false && (isLoaded || isTitle) && topic &&
              < div className={isTitle ? `recent-feed-reply-h ${(isMobile().phone || isMobile().tablet) && 'recent-feed-reply-h-mobile'}` : `recent-feed-reply-h-2 ${(isMobile().phone || isMobile().tablet) && 'recent-feed-reply-h-2-mobile'}`} id={getPostId()}>
                <div className={`recent-feed-reply ${(isMobile().phone || isMobile().tablet) && 'recent-feed-reply-mobile'}`}
                  data-text='תגובה...'
                  role={'textbox'}
                  contentEditable={"true"}
                  onFocus={() => { setFocus(true) }}
                  onBlur={() => { setFocus(false) }}
                  onInput={
                    (e: any) => {
                      handleChangeRep(e, getPostId())
                    }
                  }
                  onKeyPress={event => {
                    if (event.key === 'Enter' && !event.shiftKey) {
                      handleClickRep(topic.workflowId, getPostId(), event)
                    }
                  }}
                >
                </div>
              </div>}
            {isFocus && <div style={{ fontSize: 10, height: 19, position: 'relative', top: 5, right: 20, width: 100 }}>
              {isFocus && 'לחץ Enter לפרסום'}
            </div>}
          </div>
          {isTitle &&
            flowV[topic.replies[0]].replies.map((item11: any, index: number) => {

              let length = flowV[topic.replies[0]].replies.length;
              let editPost = flowV[topic.replies[0]].replies[length - index - 1];
              if (flowV[editPost] === undefined) {
                editPost = posts[flowV[topic.replies[0]].replies[length - index - 1]]

              }


              return (<>
                {(isLoadedFeed || (level < 1 && index < 2)) && topic && <div
                  style={{ right: isTitle ? (isMobile().phone || isMobile().tablet) ? 32 : 50 : 0, position: 'relative', minHeight: 110, fontSize: 14 }}
                  key={editPost}
                >
                  < ShowFeed
                    topic={flowV[editPost]}
                    LikedCounts={LikedCounts}
                    revisions={flowV}
                    posts={posts}
                    isTitle={false}
                    level={level + 1}
                    statusUuid={statusUuid}
                    searchText={searchText}
                    increment={incremen}
                  />
                </div>}

              </>
              )
            })
          }
          {false && isTitle && <div className={`feed-all-comment ${(isMobile().phone || isMobile().tablet) && 'feed-all-comment-mobile'}`}
            onClick={() => {
              setIsLoadedFeed(!isLoadedFeed);
            }}
          >
            {isLoadedFeed ? 'תגובות נבחרות>' : 'לכל התגובות>'}

          </div>}
        </div>}

        {!isTitle && revisions && topic &&
          topic.replies.map((reply: any) => {

            return (<>
              {<div
                style={{ right: 0, position: 'relative', minHeight: 110, fontSize: 14 }}
                key={reply}
              >
                < ShowFeed
                  topic={flowV[reply]}
                  LikedCounts={LikedCounts}
                  revisions={flowV}
                  posts={posts}
                  isTitle={false}
                  level={level + 1}
                  statusUuid={statusUuid}
                  searchText={searchText}
                  increment={incremen}
                />
              </div>}
            </>
            )
          })
        }
      </div>

    }


  </div >)
}

export default ShowFeed;
