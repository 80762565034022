import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { matchPath, useLocation } from "react-router";
//css style
import "./LandingPage.css";
import "../BookPage/BookPage";
import FormPage from "../FormPage/FormPage";
import FooterMobile from "../FooterMobile/FooterMobile";
import Search from "../Search/Search";
import {
  daf,
  getParashat,
  pageWikiFunc
} from "../../utils/funcUtils";
import HeaderMoblie from "../HeaderMoblie/HeaderMoblie";
//images
import { ReactComponent as WelcomeCir1 } from "../../svg/welcomeCir1.svg";
import { ReactComponent as WelcomeCir2 } from "../../svg/welcomeCir2.svg";
import { ReactComponent as WelcomeCir5 } from "../../svg/welcomeCir5.svg";
import { ReactComponent as WelcomeCir4 } from "../../svg/welcomeCir4.svg";
import { ReactComponent as CloseSign } from "../../svg/group-11.svg";
import { ReactComponent as WelcomeBook } from "../../svg/welcomeBook.svg";
import { ReactComponent as ParashatHashavua } from "../../svg/parashatHashavua.svg";
import { ReactComponent as HozarHasfurim } from "../../svg/hozarHasfurim.svg";
import { ReactComponent as LastBig } from "../../svg/lastBig.svg";
//services
import config from "../../services/configService";
//componnets
import HeaderNav from "../HeaderNav/HeaderNav";
import BookPage from "../BookPage/BookPage";

import WellcomeMoblie from "../wellcomeMoblie/wellcomeMoblie";
import HowWork from "../HowWork/HowWork";
import history from "../../history/history";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import isMobile from "ismobilejs";
import {
  getPageTitle,
  getIsLoading,
  getIsUserProfile,
  getUserProfileView,
  getUserid,
  getUserName,
  getIsMenuOpen,
  getIsTraile,
  getIsUserProfileOpen,
  getEmailOpen,
  getNotificationsOpen,
  getTopicUuid,
} from "../../store/selectors/selectors";

import {
  setIsUserProfile,
  setUserProfileView,
  openMenu,
  setIsUserProfileOpen,
  setEmailOpen,
  setNotificationsOpen,
  openMenuYoma,
  setProjectNameShasmehulak,
  setOpenPid1,
} from "../../store/actions/viewActions";
import { ThunkDispatch } from "@reduxjs/toolkit";

const LandingPage = () => {
  const location = useLocation();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const isUserProfile = useSelector(getIsUserProfile);
  const userName = useSelector(getUserName);
  const userId = useSelector(getUserid);
  const isTraile = useSelector(getIsTraile);
  const isUserProfileOpen = useSelector(getIsUserProfileOpen);
  const emailOpen = useSelector(getEmailOpen);
  const notificationsOpen = useSelector(getNotificationsOpen);
  const [parasha, setParasha] = useState('');
  const [dafYomi, setDafYomi] = useState('');

  const path = matchPath({ path: "/:book" }, location.pathname);
  const pathD = matchPath({ path: "/:book/:page" }, location.pathname);



  let { book } = (path && path.params) || (pathD && pathD.params) || { book: "", page: '' };

  let page: any = '';
  if (book === 'navSfarim') {
    page = (pathD && pathD.params && pathD.params.page) || { book: "", page: '' };
  }
  const isPidOpen = useSelector(getIsMenuOpen);
  const bookName = useSelector(getPageTitle);
  const isLoading = useSelector(getIsLoading);
  const userProfileView = useSelector(getUserProfileView);
  const statusUuid = useSelector(getTopicUuid);

  if (statusUuid === '')
    window.onload = () => {
      getParashat().then((response) => {
        setParasha(response)
      });
      const bookPage = daf();
      setDafYomi('/' + bookPage.book.replace(' ', '_') + '/' + bookPage.page)
    }



  const pageWiki = (pageEn: any) => {
    return pageWikiFunc(pageEn)
  }


  const closeUserProfile = async (event: any) => {
    let hidushimIframe = document.getElementById("hidushim-iframe");
    dispatch(setIsUserProfile(false));
    if (hidushimIframe) {
      // @ts-ignore
      hidushimIframe.src = iframeHidushimUrl;

    }
  };


  if ((
    book === 'cohen' ||
    book === 'Shechter' ||
    book === 'ironSwords' ||
    book === 'kaniyevski'
  ) && userProfileView === 'FormPageConnect') {
    dispatch(setProjectNameShasmehulak(book))
    dispatch(openMenuYoma())
    dispatch(setUserProfileView("MenuYomaMobile"));
  }
  if (isLoading) {
    return (<LoadingIndicator />)
  }
  if (statusUuid !== '') {
    dispatch(setOpenPid1())
  }

  return (
    <>

      {bookName && !isLoading &&
        <BookPage />
      }


      {(!(isMobile().phone || isMobile().tablet)) && <div>
        {!bookName && ((book && book === 'home') || !book) &&
          <div className='mmmm'
            onClick={() => {
              if (isUserProfile) {
                dispatch(setIsUserProfile(false));
              }
              if (isUserProfileOpen) {
                dispatch(setIsUserProfileOpen(false));
              }
              if (emailOpen) {
                dispatch(setEmailOpen(false));
              }
              if (notificationsOpen) {
                dispatch(setNotificationsOpen(false));
              }
            }}
          >
            <div className='fixed-b'>
              <HeaderNav />
            </div>
            {statusUuid === '' && <div className={isPidOpen ? 'flex-im-b' : 'flex-im'}
              onClick={() => {
                if (isUserProfile) {
                  dispatch(setIsUserProfile(false));
                }
                if (isUserProfileOpen) {
                  dispatch(setIsUserProfileOpen(false));
                }
                if (emailOpen) {
                  dispatch(setEmailOpen(false));
                }
                if (notificationsOpen) {
                  dispatch(setNotificationsOpen(false));
                }
              }}
            >
              {userId === null && false &&
                <div className='welcome-landingPage'>
                  {'ברוכים הבאים לשיתופתא!'}<br />
                  {'מיד נכנסים'}
                </div>}
              {(userId === 0 && isTraile) && <div className='t'>
                <div className='welcome'>
                  {'ברוכים הבאים לשיתופתא!'}
                </div>
                <div className='welcome-a'>
                  {config.subMessageHomePage}
                </div>

                <div className='welcome-b-main' >
                  <div className='welcome-b'>
                    <div className='welcome-b-icon'>
                      <WelcomeCir2 />
                    </div>
                    <div className='welcome-b-text'>
                      {'אוצר ספרים'}
                    </div>
                  </div>

                  <div className='welcome-b'>
                    <div className='welcome-b-icon'>
                      <WelcomeCir5 />
                    </div>
                    <div className='welcome-b-text'>
                      {'מאגר חידושים'}
                    </div>
                  </div>
                  <div className='welcome-b'>
                    <div className='welcome-b-icon'>
                      <WelcomeCir1 />
                    </div>

                    <div className='welcome-b-text'>
                      {'צורת הדף'}
                    </div>
                  </div>
                  <div className='welcome-b'>
                    <div className='welcome-b-icon'>
                      <WelcomeCir4 />
                    </div>
                    <div className='welcome-b-text'>
                      {'פרסום והדפסה'}
                    </div>
                  </div>
                </div>
              </div>}
              {((userId === 0) && isTraile) &&
                <div className='login'>
                  {!isUserProfile && <FormPage />}
                </div>}
            </div>}

            {statusUuid === '' && !isPidOpen && ((userId !== 0 && userId !== null) || !isTraile) &&
              <div className='t'

              >
                <div className='welcome-connect-or-trial'>

                  {!isTraile && userId === 0 ? 'שלום, אורח ' : 'שלום, ' + userName}
                  {!isTraile && <div className='welcome-trial-to-regis'
                    onClick={() => {
                      dispatch(setIsUserProfile(true))
                      dispatch(setUserProfileView('FormPageCreate'))
                    }}
                  >
                    {'הירשם >>'}
                  </div>
                  }
                </div>
                <div className='qwqw'>
                  <div className='welcome-connect-learn'>
                    <div className='welcome-connect-learn-title'>
                      {'מה תבחרו ללמוד?'}
                    </div>
                    <div className='welcome-connect-learn-button-main'>
                      <div className='welcome-connect-learn-button'   >
                        {<a href={dafYomi} id="redre">
                          <div className='welcome-connect-learn-button-icon'>
                            <WelcomeBook />
                          </div>

                          <div>
                            {'הדף היומי'}
                          </div>
                        </a>}
                      </div>
                      <div className='welcome-connect-learn-button'>
                        {<a href={parasha} id="redre_Parasha">
                          <div className='welcome-connect-learn-button-icon-b'>
                            <ParashatHashavua />
                          </div>

                          <div>
                            {'פרשת השבוע'}
                          </div>
                        </a>}
                      </div>
                      <div className='welcome-connect-learn-button'
                        onClick={() => {
                          dispatch(openMenu())
                          history.push('?currentEvents=basic')
                        }
                        }
                      >
                        <div className='welcome-connect-learn-button-icon'>
                          <HozarHasfurim />
                        </div>
                        <div>
                          {'אוצר הספרים'}
                        </div>
                      </div>
                      {<div className='welcome-connect-learn-button'
                        onClick={() => {
                          dispatch(setOpenPid1());
                        }
                        }
                      >
                        <div className='welcome-connect-learn-button-icon'>
                          <LastBig />
                        </div>
                        <div>
                          {'מאגר החידושים'}
                        </div>
                      </div>}

                    </div>
                  </div>
                </div>
              </div>}
          </div>}
        {<div
          className={
            isUserProfile
              ? "user-profile-on-container"
              : "user-profile-none-container"
          }
          id="user-profile-on-container"
        >
          <div
            className={isUserProfile ? (userProfileView === 'FormPageCreate' ? "user-profile-on-create" : "user-profile-on") : "user-profile-none"}
          >  <FormPage />
            <div className="user-profile-close">
              <div className="close-user-svg-container">
                <CloseSign
                  className="close-user-svg"
                  onClick={(e: any) => {
                    if (userProfileView === 'FormPageCreate' || userProfileView === 'FormPageForget') {
                      dispatch(setUserProfileView("FormPageConnect"));
                    }
                    else {
                      closeUserProfile(e);
                    }
                  }} />
              </div>
            </div>
          </div>
        </div>}
      </div>}

      {!(isMobile().phone || isMobile().tablet) && !bookName && !isLoading && book && book !== 'home' &&
        <div className='qwqwq'>
          <div className='fixed-b'>
            <HeaderNav />
          </div>
          <div style={{ position: 'relative', height: book !== 'Search' && book !== 'search' ? 100 : 50 }} />


          {book !== 'work'
            && <div className={`home-font${book !== 'Offline_Registration' && book !== 'Psalms' ? ' w' : ''}`}
              dangerouslySetInnerHTML={{
                __html: pageWiki(book).split('&lt;o:p&gt;&lt;/o:p&gt;').join('')
              }}>
            </div>}
          {!(isMobile().phone || isMobile().tablet) &&
            book === 'about' &&
            <div className={"mishpat-footer-container"}

              onClick={(e) => {

              }}
            >
              <a
                href={'/' + config.privacyDoc}
                className="mishpat-footer"
              >
                מדיניות הפרטיות
              </a>
              <a
                href={'/' + config.creditsDoc}
                className="mishpat-footer"
              >
                הבהרה משפטית
              </a>
            </div>}
          {book === 'work' &&
            <div className={`home-font`} style={{ minHeight: window.screen.height }}>
              <HowWork />
            </div>}

        </div>
      }
      {!isLoading && !bookName && <div >
        {(isMobile().phone || isMobile().tablet) && <HeaderMoblie />}
        {(isMobile().phone || isMobile().tablet) && !bookName && <WellcomeMoblie link={book} />}
        {(isMobile().phone || isMobile().tablet) && bookName && <WellcomeMoblie link={''} />}
        {(isMobile().phone || isMobile().tablet) && <FooterMobile />}
      </div>}
      {book && book === 'Search' && !bookName &&
        <Search />
      }
      {book && book === 'search' && !bookName &&
        <Search />
      }
    </>
  );
};

export default LandingPage;
