import React, { useState } from "react";
import "./HowWork.css";
import { ReactComponent as Plus } from "../../svg/plus.svg";
import { ReactComponent as Minus } from "../../svg/Minus.svg";
import { ReactComponent as Poto_01 } from '../../svg/01.svg';
import { ReactComponent as Poto_02 } from '../../svg/02.svg';
import { ReactComponent as Poto_03 } from '../../svg/03.svg';
import { ReactComponent as Poto_04 } from '../../svg/04.svg';
import { ReactComponent as Poto_05 } from '../../svg/05.svg';
import { ReactComponent as Poto_06 } from '../../svg/06.svg';
import { ReactComponent as Poto_07 } from '../../svg/07.svg';
import { ReactComponent as Poto_08 } from '../../svg/08.svg';
import { ReactComponent as Poto_09 } from '../../svg/09.svg';
import { ReactComponent as Poto_10 } from '../../svg/10.svg';
import { ReactComponent as Poto_11 } from '../../svg/11.svg';
import { ReactComponent as Poto_12 } from '../../svg/12.svg';
import { ReactComponent as Poto_13 } from '../../svg/13.svg';
import { ReactComponent as Poto_14 } from '../../svg/14.svg';
import { ReactComponent as Poto_15 } from '../../svg/15.svg';
import { ReactComponent as Poto_16 } from '../../svg/16.svg';
import { ReactComponent as Poto_17 } from '../../svg/17.svg';
import { ReactComponent as Poto_18 } from '../../svg/18.svg';
import { ReactComponent as Poto_19 } from '../../svg/19.svg';
import { ReactComponent as Poto_20 } from '../../svg/20.svg';
import { ReactComponent as Poto_21 } from '../../svg/21.svg';
import { ReactComponent as Poto_22 } from '../../svg/22.svg';

import { useSelector } from "react-redux";
import {
  getRegistration
} from "../../store/selectors/selectors";
import history from "../../history/history";

const HowWork: React.FC = () => {

  const registration = useSelector(getRegistration);
  const [registration1, setRegistration] = useState(registration);
  return (<>


    <div className='work-title'
      onClick={() => {
        if (registration1 === 'a') {
          setRegistration('')
          history.push('?');
        }
        if (registration1 !== 'a') {
          history.push('?registration=a');
          setRegistration('a')
        }
      }}
    >
      <div>
        {'הרשמה'}
      </div>
      <div className='work-title-icon'>
        {!registration1.includes('a') && <Plus />}
        {registration1.includes('a') && <Minus />}
      </div>
    </div>
    {registration1.includes('a') && <>
      <div className='work-title-sub-middel'
        onClick={() => {
          if (registration1 === 'a1') {
            setRegistration('a')
            history.push('?registration=a');
          }
          if (registration1 !== 'a1') {
            history.push('?registration=a1');
            setRegistration('a1')
          }
        }}
      >
        <div >
          {'איך נרשמים לשיתופתא'}
        </div>
        <div className='work-title-icon'>
          {registration1 !== 'a1' && <Plus />}
          {registration1 === 'a1' && <Minus />}
        </div>
      </div>
      {
        registration1 === 'a1' &&
        <div className='work-title-sub-middel-main'>

          <div className='poto'>
            {`ממש בקלות! לוחצים על כפתור 'הירשם' וממלאים טופס קצרצר.`}
          </div>
          <Poto_01 className="Poto_01" />
        </div>
      }
      <div className='work-title-sub-middel'
        onClick={() => {
          if (registration1 === 'a2') {
            setRegistration('a')
            history.push('?registration=a');
          }
          if (registration1 !== 'a2') {
            history.push('?registration=a2');
            setRegistration('a2')
          }

        }}
      >
        <div >
          {'יש לך כתובת דואר אלקטרוני פעילה?'}
        </div>
        <div>
          {registration1 !== 'a2' && <Plus />}
          {registration1 === 'a2' && <Minus />}
        </div>
      </div>
      {
        registration1 === 'a2' &&
        <div className='work-title-sub-middel-main'>

          <div className='poto-a2'>
            <b>{'"שם פרטי"'}</b>
            {' ו'}
            <b>{'"שם משפחה"'}</b>
            {'  - הם ישמשו גם כשם המשתמש באתר.'}

            <br />

            <b>  {'"דואר אלקטרוני"'}</b>
            {` - ודאו שהכתובת פעילה ושיש גישה אליה. הכתובת תשמש לתקשורת בין הלומדים שב'שיתופתא' ובה גם תקבלו הודעות על חידושים חדשים מהלומדים שברשימת המעקב שלכם, או על תגובות חדשות ותודות על חידוש שכתבתם.`}
            <br />
            <b> {'"מספר נייד"'}</b>

            {' - מלא כאן את הנייד שלך.'}
            <br />
            <b>  {'"סיסמה"'}</b>

            {' - בחר סיסמה עמה תתחבר לאתר.'}
            {'ולאחר מכן כתוב אותה שוב ב'}

            <b> {'"אימות סיסמה"'}</b>
            {'  לשם אימות. בכניסה הבאה לחשבון תצטרכו להקליד רק את השם שלכם והסיסמה שלכם.'}

          </div>
          <Poto_02 className="Poto_01" />
        </div>
      }
      <div className='work-title-sub-middel'
        onClick={() => {
          if (registration1 === 'a3') {
            setRegistration('a')
            history.push('?registration=a');
          }
          if (registration1 !== 'a3') {
            history.push('?registration=a3');
            setRegistration('a3')
          }

        }}
      >
        <div >
          {'לאחר שנרשמתי כיצד אני מתחבר לחשבון?'}
        </div>
        <div>
          {registration1 !== 'a3' && <Plus />}
          {registration1 === 'a3' && <Minus />}
        </div>
      </div>
      {
        registration1 === 'a3' &&
        <div className='work-title-sub-middel-main'>

          <div className='poto-a3'>
            {'מיד לאחר ההרשמה תועבר אוטומטית לתוך החשבון שלך.'}
            <br />
            {'אם תצא ממנו, תידרש בשביל כניסה חוזרת לחשבונך, לכתוב רק את שמך והסיסמה שבחרת, וללחוץ על התחבר.'}
          </div>
          <Poto_03 className="Poto_01" />
        </div>
      }
      <div className='work-title-sub-middel'
        onClick={() => {
          if (registration1 === 'a4') {
            setRegistration('a')
            history.push('?registration=a');
          }
          if (registration1 !== 'a4') {
            history.push('?registration=a4');
            setRegistration('a4')
          }

        }}
      >
        <div >
          {'שכחתי את הסיסמה. מה עושים?'}
        </div>
        <div>
          {registration1 !== 'a4' && <Plus />}
          {registration1 === 'a4' && <Minus />}
        </div>
      </div>
      {
        registration1 === 'a4' &&
        <div className='work-title-sub-middel-main'>

          <div className='poto-a3'>
            {`אל דאגה. בדף ההתחברות לאתר, מתחת לשדה 'סיסמה', יש כפתור "שכחת את הסיסמה?"`}
            <br />
            {'לחיצה עליו תאפשר לך לאפס את הסיסמה ולקבל סיסמה זמנית במייל.'}
            <br />
            {'במידה ולא הצלחת, פנה אל צוות התמיכה במייל - office@shitufta.org.il'}
          </div>
          <Poto_04 className="Poto_01" />
        </div>
      }
    </>}

    <div className='work-title-middel'
      onClick={() => {
        if (registration1 === 'b') {
          setRegistration('')
          history.push('?');
        }
        if (registration1 !== 'b') {
          history.push('?registration=b');
          setRegistration('b')
        }

      }}
    >
      <div>
        {'לימוד'}
      </div>
      <div className='work-title-icon'>
        {!registration1.includes('b') && <Plus />}
        {registration1.includes('b') && <Minus />}
      </div>
    </div>
    {registration1.includes('b') && <>
      <div className='work-title-sub-middel'
        onClick={() => {
          if (registration1 === 'b1') {
            setRegistration('b')
            history.push('?registration=b');
          }
          if (registration1 !== 'b1') {
            history.push('?registration=b1');
            setRegistration('b1')
          }

        }}
      >
        <div >
          {'מה אני יכול ללמוד?'}
        </div>
        <div>
          {registration1 !== 'b1' && <Plus />}
          {registration1 === 'b1' && <Minus />}
        </div>
      </div>
      {
        registration1 === 'b1' &&
        <div className='work-title-sub-middel-main'>

          <div className='poto'>
            {'מיד בכניסתך לאתר תוכל לבחור מה ברצונך ללמוד, הדף היומי, פרשת השבוע, עניינא דיומא או כל ספר אחר באוצר הספרים.'}

          </div>
          <Poto_05 className="Poto_01" />
        </div>
      }

      <div className='work-title-sub-middel'
        onClick={() => {
          if (registration1 === 'b3') {
            setRegistration('b')
            history.push('?registration=b');
          }
          if (registration1 !== 'b3') {
            history.push('?registration=b3');
            setRegistration('b3')
          }

        }}
      >
        <div >
          {'כיצד אני מגיע לספר מסוים?'}
        </div>
        <div>
          {registration1 !== 'b3' && <Plus />}
          {registration1 === 'b3' && <Minus />}
        </div>
      </div>
      {
        registration1 === 'b3' &&
        <div className='work-title-sub-middel-main'>

          <div className='poto-b3'>

            {'כל ספרי היסוד. חשוב לדעת שהספרים מעוצבים בצורת הדף המסורתית ועם זאת הם טקסט חי ודינמי ולא סריקה של ספר מודפס.'}

          </div>
          <Poto_06 className="Poto_01" />
        </div>
      }
      <div className='work-title-sub-middel'
        onClick={() => {
          if (registration1 === 'b4') {
            setRegistration('b')
            history.push('?registration=b');
          }
          if (registration1 !== 'b4') {
            history.push('?registration=b4');
            setRegistration('b4')
          }

        }}
      >
        <div >
          {'כיצד משתמשים בספרים ובמפרשים?'}
        </div>
        <div>
          {registration1 !== 'b4' && <Plus />}
          {registration1 === 'b4' && <Minus />}
        </div>
      </div>
      {
        registration1 === 'b4' &&
        <div className='work-title-sub-middel-main'>

          <div className='poto-b3'>

            {`כל הספרים הקיימים בשיתופתא מחולקים לקטעים לפי נושאים, כל קטע הוא לחיץ לעצמו.
 לחיצה על קטע תסמן אותו בצבע ירקרק ואף המפרשים שלצידו השייכים לקטע זה (למשל בגמרא:רש"י ותוס').
  בצד ימין יש את מדור 'מפרשים' שמחולקים גם הם על כל קטע וקטע שנבחר בספר.`}
          </div>
          <Poto_07 className="Poto_01" />
        </div>
      }
      <div className='work-title-sub-middel'
        onClick={() => {
          if (registration1 === 'b5') {
            setRegistration('b')
            history.push('?registration=b');
          }
          if (registration1 !== 'b5') {
            history.push('?registration=b5');
            setRegistration('b5')
          }

        }}
      >
        <div >
          {'איך מדפדפים לדף הבא או לדף הקודם?'}
        </div>
        <div>
          {registration1 !== 'b5' && <Plus />}
          {registration1 === 'b5' && <Minus />}
        </div>
      </div>
      {
        registration1 === 'b5' &&
        <div className='work-title-sub-middel-main'>

          <div className='poto-b3'>

            {`ישנם חיצים מימין ומשמאל הדף,
           מעל 'מפרשים' ומעל 'חידושים'.
            החץ הימני מוביל לדף הקודם ואילו השמאלי לדף הבא.`}
          </div>
          <Poto_08 className="Poto_01" />
        </div>
      }
    </>}


    <div className='work-title-middel'
      onClick={() => {
        if (registration1 === 'c') {
          setRegistration('')
          history.push('?');
        }
        if (registration1 !== 'c') {
          history.push('?registration=c');
          setRegistration('c')
        }

      }}
    >
      <div>{'מפרשים'}</div>
      <div className='work-title-icon'>
        {!registration1.includes('c') && <Plus />}
        {registration1.includes('c') && <Minus />}
      </div>
    </div>
    {registration1.includes('c') && <>
      <div className='work-title-sub-middel'
        onClick={() => {
          if (registration1 === 'c1') {
            setRegistration('c')
            history.push('?registration=c');
          }
          if (registration1 !== 'c1') {
            history.push('?registration=c1');
            setRegistration('c1')
          }

        }}
      >
        <div >
          {'היכן ממוקם מדור מפרשים?'}
        </div>
        <div>
          {registration1 !== 'c1' && <Plus />}
          {registration1 === 'c1' && <Minus />}
        </div>
      </div>
      {
        registration1 === 'c1' &&
        <div className='work-title-sub-middel-main'>

          <div className='poto-b3'>

            {`מדור מפרשים נמצא בצד ימין של הדף, לחיצה על הכרטיסייה 'מפרשים' תפתח
           את חלונית בחירת המפרשים על דף זה. ניתן לבחור את המפרשים הרצויים מתוך הרשימה הקיימת, וללחוץ על 'פתיחה'`}
          </div>
          <Poto_09 className="Poto_01" />
        </div>
      }
      <div className='work-title-sub-middel'
        onClick={() => {
          if (registration1 === 'c2') {
            setRegistration('c')
            history.push('?registration=c');
          }
          if (registration1 !== 'c2') {
            history.push('?registration=c2');
            setRegistration('c2')
          }

        }}
      >
        <div >
          {'האם ישנו ביאור קל ופשוט על הש"ס?'}
        </div>
        <div>
          {registration1 !== 'c2' && <Plus />}
          {registration1 === 'c2' && <Minus />}
        </div>
      </div>
      {
        registration1 === 'c2' &&
        <div className='work-title-sub-middel-main'>

          <div className='poto-b3'>

            {`בהחלט. תיבת הסימון הראשונה במפרשים היא 'ביאור' יש לבחור בה וללחוץ על 'פתיחה'.
           כעת כל קטע בגמרא שנבחר יופיע הביאור על אותו קטע.`}
          </div>
          <Poto_10 className="Poto_01" />
        </div>
      }
      <div className='work-title-sub-middel'
        onClick={() => {
          if (registration1 === 'c3') {
            setRegistration('c')
            history.push('?registration=c');
          }
          if (registration1 !== 'c3') {
            history.push('?registration=c3');
            setRegistration('c3')
          }

        }}
      >
        <div >
          {'ואם אני רוצה כעת ספר אחר מהמפרשים, מה עלי לעשות?'}
        </div>
        <div>
          {registration1 !== 'c3' && <Plus />}
          {registration1 === 'c3' && <Minus />}
        </div>
      </div>
      {
        registration1 === 'c3' &&
        <div className='work-title-sub-middel-main'>

          <div className='poto-b3'>

            {`ללחוץ על + ליד 'ביאור' ולבחור כל ספר מהמפרשים שקיימים המאגר, אחר כך לחץ על פתיחה.`}
          </div>
          <Poto_11 className="Poto_01" />
        </div>
      }
      <div className='work-title-sub-middel'
        onClick={() => {
          if (registration1 === 'c4') {
            setRegistration('c')
            history.push('?registration=c');
          }
          if (registration1 !== 'c4') {
            history.push('?registration=c4');
            setRegistration('c4')
          }

        }}
      >
        <div >
          {'כיצד מסירים ספר מרשימת המפרשים שבחרתי?'}
        </div>
        <div>
          {registration1 !== 'c4' && <Plus />}
          {registration1 === 'c4' && <Minus />}
        </div>
      </div>
      {
        registration1 === 'c4' &&
        <div className='work-title-sub-middel-main'>

          <div className='poto-b3'>

            {`ניתן להסיר ספרים מהרשימה על ידי הקשה על סימן ה-X המופיע ליד שם הספר.`}
          </div>
          <Poto_12 className="Poto_01" />
        </div>
      }
      <div className='work-title-sub-middel'
        onClick={() => {
          if (registration1 === 'c5') {
            setRegistration('c')
            history.push('?registration=c');
          }
          if (registration1 !== 'c5') {
            history.push('?registration=c5');
            setRegistration('c5')
          }

        }}
      >
        <div >
          {'יש עוד אפשרויות במדור מפרשים?'}
        </div>
        <div>
          {registration1 !== 'c5' && <Plus />}
          {registration1 === 'c5' && <Minus />}
        </div>
      </div>
      {
        registration1 === 'c5' &&
        <div className='work-title-sub-middel-main'>

          <div className='poto-b3'>

            {`ניתן לבחור בכל המפרשים הקיימים במאגר על ספר זה בלחיצה על 'בחר הכל'. וניתן לאפשס את הרשימה על ידי לחיצה על 'נקה הכל'.`}
          </div>
          <Poto_13 className="Poto_01" />
        </div>
      }
    </>}
    <div className='work-title-middel'
      onClick={() => {
        if (registration1 === 'd') {
          setRegistration('')
          history.push('?');
        }
        if (registration1 !== 'd') {
          history.push('?registration=d');
          setRegistration('d')
        }

      }}
    >
      <div>
        {'חידושים ותגובות'}
      </div>
      <div className='work-title-icon'>
        {!registration1.includes('d') && <Plus />}
        {registration1.includes('d') && <Minus />}
      </div>
    </div>
    {registration1.includes('d') && <>
      <div className='work-title-sub-middel'
        onClick={() => {
          if (registration1 === 'd1') {
            setRegistration('d')
            history.push('?registration=d');
          }
          if (registration1 !== 'd1') {
            history.push('?registration=d1');
            setRegistration('d1')
          }

        }}
      >
        <div >
          {'מי יכול לכתוב חידושים?'}
        </div>
        <div>
          {registration1 !== 'd1' && <Plus />}
          {registration1 === 'd1' && <Minus />}
        </div>
      </div>
      {
        registration1 === 'd1' &&
        <div className='work-title-sub-middel-main'>

          <div className='poto-b3'>

            {`על מנת לפרסם חידושים במערכת יש להירשם בשם מלא ואמיתי, 
          המערכת תבדוק את הנתונים שהוזנו בטופס ההרשמה ולאחר שהם יאושרו החידושים יוצגו במערכת ויהיה ניתן לעקוב אחריכם.`}
          </div>
          <Poto_14 className="Poto_01" />
        </div>
      }
      <div className='work-title-sub-middel'
        onClick={() => {
          if (registration1 === 'd2') {
            setRegistration('c')
            history.push('?registration=d');
          }
          if (registration1 !== 'd2') {
            history.push('?registration=d2');
            setRegistration('d2')
          }

        }}
      >
        <div >
          {'איך אני יכול להכניס את החידוש שלי למאגר?'}
        </div>
        <div>
          {registration1 !== 'd2' && <Plus />}
          {registration1 === 'd2' && <Minus />}
        </div>
      </div>
      {
        registration1 === 'd2' &&
        <div className='work-title-sub-middel-main'>

          <div className='poto-b3'>

            {`לבחור את הקטע עליו נכתב החידוש, ללחוץ על כרטיסיית 'חידושים' בצד שמאל, 
          לכתוב את החידוש, וללחוץ על פרסום זהו. כעת החידוש שלך  קיים במערכת.`}
          </div>
          <Poto_15 className="Poto_01" />
        </div>
      }
      <div className='work-title-sub-middel'
        onClick={() => {
          if (registration1 === 'd3') {
            setRegistration('c')
            history.push('?registration=d');
          }
          if (registration1 !== 'd3') {
            history.push('?registration=d3');
            setRegistration('d3')
          }

        }}
      >
        <div >
          {'האם ביכולתי להגיב על חידוש ולדון בו או להודות למחדש?'}
        </div>
        <div>
          {registration1 !== 'd3' && <Plus />}
          {registration1 === 'd3' && <Minus />}
        </div>

      </div>
      {
        registration1 === 'd3' &&
        <div className='work-title-sub-middel-main'>

          <div className='poto-b3'>

            {`בודאי. מתחת לכל חידוש יש כפתור 'תגובות' בו ניתן לראות את התגובות לחידוש זה ולכתוב תגובה חדשה, 
          וכפתור 'שכוייח' כדי להודות למחדש.`}
          </div>
          <Poto_16 className="Poto_01" />
        </div>
      }
      <div className='work-title-sub-middel'
        onClick={() => {
          if (registration1 === 'd4') {
            setRegistration('c')
            history.push('?registration=d');
          }
          if (registration1 !== 'd4') {
            history.push('?registration=d4');
            setRegistration('d4')
          }

        }}
      >
        <div >
          {'מאוד נהנתי ממחדש מסוים, האם ביכולתי לעקוב אחריו ולדעת כשהוא כותב עוד חידוש?'}
        </div>
        <div>
          {registration1 !== 'd4' && <Plus />}
          {registration1 === 'd4' && <Minus />}
        </div>

      </div>
      {
        registration1 === 'd4' &&
        <div className='work-title-sub-middel-main'>

          <div className='poto-b3'>

            {`בהחלט. לשם כך יש את 'רשימת המחדשים' שם תוכל לעקוב אחרי כל מחדש שנהנית מחידושיו ואף לשלוח לו דוא"ל דרך האתר. 
          כזכור, כתובת הדוא"ל של משתמשים אחרים אינם גלויים לך, אולם ניתן לשלוח להם דוא"ל דרך מערכת שיתופתא.`}
            <div style={{ color: '#38A09D' }}>

              {'יש לעקוב אחר מחדשים כדי להשתמש בתכונה זו.'}
            </div>

          </div>
          <Poto_17 className="Poto_01" />
        </div>
      }
      <div className='work-title-sub-middel'
        onClick={() => {
          if (registration1 === 'd5') {
            setRegistration('c')
            history.push('?registration=d');
          }
          if (registration1 !== 'd5') {
            history.push('?registration=d5');
            setRegistration('d5')
          }

        }}
      >
        <div >
          {'איך עוקבים אחרי מחדשים?'}
        </div>
        <div>
          {registration1 !== 'd5' && <Plus />}
          {registration1 === 'd5' && <Minus />}
        </div>
      </div>
      {
        registration1 === 'd5' &&
        <div className='work-title-sub-middel-main'>

          <div className='poto-b3'>

            {`בלחיצה על 'רשימת המחדשים' תפתח לכם רשימת המחדשים עם כפתורים 'שלח הודעה' כדי לשלוח דוא"ל דרך האתר ו'לעקוב' כדי 
          לעקוב אחרי חידושיו של המחדש.`}
          </div>
          <Poto_18 className="Poto_01" />
        </div>
      }
    </>}

    <div className='work-title-middel'
      onClick={() => {
        if (registration1 === 'e') {
          setRegistration('')
          history.push('?');
        }
        if (registration1 !== 'e') {
          history.push('?registration=e');
          setRegistration('e')
        }

      }}
    >
      {'חידושים אחרונים'}
      <div className='work-title-icon'>
        {!registration1.includes('e') && <Plus />}
        {registration1.includes('e') && <Minus />}
      </div>
    </div>
    {registration1.includes('e') && <>
      <div className='work-title-sub-middel'
        onClick={() => {
          if (registration1 === 'e1') {
            setRegistration('e')
            history.push('?registration=e');
          }
          if (registration1 !== 'e1') {
            history.push('?registration=e1');
            setRegistration('e1')
          }

        }}
      >
        <div >
          {'האם ניתן לראות איזה חידושים פורסמו לאחרונה במערכת?'}
        </div>
        <div>
          {registration1 !== 'e1' && <Plus />}
          {registration1 === 'e1' && <Minus />}
        </div>
      </div>
      {
        registration1 === 'e1' &&
        <div className='work-title-sub-middel-main'>

          <div className='poto-b3'>

            {`כן. ניתן לראות את החידושים האחרונים שפורסמו במערכת לפי סדר כתיבתם להודות למחדשים ולהגיב על החידושים בלחיצה על 
          כפתור 'חידושים אחרונים'.`}
          </div>
          <Poto_19 className="Poto_01" />
        </div>
      }
      <div className='work-title-sub-middel'
        onClick={() => {
          if (registration1 === 'e2') {
            setRegistration('e')
            history.push('?registration=e');
          }
          if (registration1 !== 'e2') {
            history.push('?registration=e2');
            setRegistration('e2')
          }

        }}
      >
        <div >
          {'כיצד אוכל לעבור לראות את החידושים במקומם או על הדף?'}
        </div>
        <div>
          {registration1 !== 'e2' && <Plus />}
          {registration1 === 'e2' && <Minus />}
        </div>
      </div>
      {
        registration1 === 'e2' &&
        <div className='work-title-sub-middel-main'>
          <div className='poto-b3'>
            {`ניתן לעבור לדף ולקטע עליו נכתב החידוש על ידי לחיצה על מיקום החידוש (לדוגמה: במדבר כ"ב ט"ז-י"ח).`}
          </div>
          <Poto_20 className="Poto_01" />
        </div>
      }
    </>}

    <div className={registration1.includes('f') ? 'work-title-middel' : 'work-title-sub'}
      onClick={() => {
        if (registration1 === 'f') {
          setRegistration('')
          history.push('?');
        }
        if (registration1 !== 'f') {
          history.push('?registration=f');
          setRegistration('f')
        }

      }}
    >
      {'הודעות והתראות'}
      <div className='work-title-icon'>
        {!registration1.includes('f') && <Plus />}
        {registration1.includes('f') && <Minus />}
      </div>
    </div>
    {registration1.includes('f') && <>
      <div className='work-title-sub-middel'
        onClick={() => {
          if (registration1 === 'f1') {
            setRegistration('f')
            history.push('?registration=f');
          }
          if (registration1 !== 'f1') {
            history.push('?registration=f1');
            setRegistration('f1')
          }

        }}
      >
        <div >
          {'כיצד אוכל לדעת אם מישהו הגיב לחידוש שלי או הודה עליו?'}
        </div>
        <div>
          {registration1 !== 'f1' && <Plus />}
          {registration1 === 'f1' && <Minus />}
        </div>
      </div>
      {
        registration1 === 'f1' &&
        <div className='work-title-sub-middel-main'>
          <div className='poto-b3'>
            {`בצד שמאל למעלה על אייקון הפעמון יופיע עיגול אדום עם מספר ההתראות,
           לחיצה עליו תפתח את חלון ההתראות ותציג מי הגיב לחידושים שלך ומי הודה עליהם.`}
          </div>
          <Poto_21 className="Poto_01" />
        </div>
      }
      <div className={registration1 !== 'f2' ? 'work-title-sub-middel-open' : 'work-title-sub-middel'}
        onClick={() => {
          if (registration1 === 'f2') {
            setRegistration('f')
            history.push('?registration=f');
          }
          if (registration1 !== 'f2') {
            history.push('?registration=f2');
            setRegistration('f2')
          }

        }}
      >
        <div >
          {'על הודעות מערכת גם מקבלים הודעה?'}
        </div>
        <div>
          {
            registration1 !== 'f2' &&
            <Plus />
          }
          {
            registration1 === 'f2' &&
            <Minus />
          }
        </div>
      </div>
      {
        registration1 === 'f2' &&
        <div className='work-title-sub-middel-main-open'>

          <div>
            <div className='poto-b4'>
              {`אכן כך, על אייקון המכתב יופיע עיגול אדום עם מספר ההודעות, לחיצה עליו תפתח את חלון ההודעות ותציג את הודעות המערכת.`}
            </div>
            <div className='work-title-sub-middel-helper'>
              {'זקוק להדרכה נוספות? office@shitufta.org.il'}
            </div>
          </div>
          <Poto_22 className="Poto_02" />
        </div>
      }
    </>}
  </>
  );
};

export default HowWork;
