import React from "react";
import "./HidushimMenuMobile.css";
import {
  setUserProfileView,
  setOpenPid1,
  setOpenPid,
} from "../../store/actions/viewActions";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";

const HidushimMenuMobile = () => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return (
    <div className='hidushim-menu-mobile' >
      <div className='mobile-line-mobile' />
      <div className='hidushim-menu-mobile-line-mobile' />

      <div className='hidushim-menu-mobile-text'
        onClick={() => {
          dispatch(setOpenPid1());
          dispatch(setUserProfileView("LastHidushimMobile"));
        }}
      >
        {'חידושים אחרונים'}
      </div>
      <div className='hidushim-menu-mobile-line-mobile' />
      <div className='hidushim-menu-mobile-text'
        onClick={() => {
          dispatch(setOpenPid());
          dispatch(setUserProfileView("ListOfRenewMobile"));
        }}
      >
        {'רשימת מחדשים'}
      </div>
      <div className='hidushim-menu-mobile-line-mobile' />
      <div className='hidushim-menu-mobile-text' >
        {'החידושים שלי'}
      </div>


  
      <div className='hidushim-menu-mobile-line-mobile' />
    </div>
  );
};

export default HidushimMenuMobile;
