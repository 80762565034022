
import React from "react";
import "./HeaderMoblie.css";
import { ReactComponent as MenuMobile } from "../../svg/MenuMobile.svg";
import { ReactComponent as MenuOoenMobile } from "../../svg/MenuOoenMobile.svg";
import { ReactComponent as LogoMobile } from "../../svg/IconMobile.svg";
import { ReactComponent as NotificationMobile } from "../../svg/notificationMobile.svg";
import { ReactComponent as NotificationMobileOpen } from "../../svg/notificationMobileOpen.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserid,
  getUserProfileView,
  getCompetition
} from "../../store/selectors/selectors";
import { ThunkDispatch } from "redux-thunk";
import { setUserProfileView } from "../../store/actions/viewActions";
const HeaderMoblie: React.FC = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const userid = useSelector(getUserid);
  const userProfileView = useSelector(getUserProfileView);
  const competition = useSelector(getCompetition);

  const openUserProfile = () => {
    if (userProfileView !== 'UserProfileMobile')
      dispatch(setUserProfileView("UserProfileMobile"));
    else if (userProfileView === 'UserProfileMobile')
      dispatch(setUserProfileView("FormPageConnect"));
  };

  const openHidushimProfile = () => {
    if (userProfileView === "HidushimMenuMobile")
      dispatch(setUserProfileView("FormPageConnect"));
    else {
      dispatch(setUserProfileView("HidushimMenuMobile"));
    }
  };

  return (
    <div className={`header-moblie `}>
      {competition === null && userid !== 0 &&
        <div className='header-moblie-Notification'
          onClick={openUserProfile}>
          {userProfileView === 'UserProfileMobile' ? <NotificationMobileOpen /> : <NotificationMobile />}
        </div>}
      {<div className='header-moblie-logo'>
        <a href={competition === null ? '/home' : '/kaniyevski?Competition=&utm_source=nedarim%20plus'}>
          <LogoMobile />
        </a>
      </div>}
      {competition === null &&
        <div className='header-moblie-menu'
          onClick={openHidushimProfile}>
          {userProfileView === 'HidushimMenuMobile' || userProfileView === 'LastHidushimMobile' || userProfileView === 'ListOfRenewMobile' ? <MenuOoenMobile /> : <MenuMobile />}
        </div>}
    </div>
  )
}

export default HeaderMoblie;
