import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as LineForm } from "../../svg/LineForm.svg";
import { ReactComponent as MihlolLogo } from "../../svg/logoBig.svg";
import { ReactComponent as LogoWord } from "../../svg/logoWord.svg";
import wikiService from "../../services/wikiService";

import "./FormPageConnect.css";
import {
  getIsPidOpen1
} from "../../store/selectors/selectors";
import {
  setUserProfileView,
  setIsUserProfile,
  setIsTraile
} from "../../store/actions/viewActions";
import { ThunkDispatch } from "@reduxjs/toolkit";

const FormPageConnect: React.FC = () => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const isPidOpen1 = useSelector(getIsPidOpen1);

  const [isError, setIsError] = useState(false);
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const onChangeUserName = (e: any) => {

    setUserName(e.target.value);
    if (isError) {
      setIsError(false)
    }
  }

  const onChangePassword = (e: any) => {

    setPassword(e.target.value);

    if (isError) {
      setIsError(false)
    }
  }

  const LogInOtAccount = async () => {
    const res = await wikiService.LogIn(userName,password);
    
    if (res === 'PASS') {
      window.location.reload();
    }
    else if (res === 'UI') {
      dispatch(setUserProfileView("FormPageForgetNew"));
    }
    else {
      setIsError(true)
    }
  
    }



  return (
    <form>
      {<div className='flex'>
        <div className='form-page-logo'>
          <MihlolLogo />
        </div>
        <div className='form-page-logo-word'>
          <LogoWord />
        </div>
      </div>}
      <div className='form-page-connect-first-name'>
        <input
          type='text'
          name='first-name'
          className='form-page-connect-first-name-input'
          placeholder='שם משתמש'
          onChange={(e) => {
            onChangeUserName(e)
          }}
          maxLength={40}
        ></input>
      </div>

      <div className='form-page-password'>
        <input
          type='password'
          name='password'
          className='form-page-connect-first-name-input'
          placeholder='סיסמה'
          autoComplete="on"
          onKeyDown={(e) => {
            if (e.keyCode === 13)
              LogInOtAccount()
          }}
          onChange={(e) => {
            onChangePassword(e)
          }}
          maxLength={40}
        ></input>
      </div>
      {isError && <div className='form-page-connect-error'>
        {'שם המשתמש או הסיסמה שהזנת שגויים'}
      </div>}
      <div className='form-page-forget'
        onClick={() => {
          dispatch(setUserProfileView("FormPageForget"));
        }}
      >
        {'שכחת את הסיסמה?'}
      </div>
      <div className='form-page-connect'
        onClick={() => LogInOtAccount()}

      >
        <div className='form-page-connect-text'>
          {'התחבר'}
        </div>
      </div>
      <div className='form-page-line'>
        <LineForm />
      </div>
      <div className='form-page-no-account'>
        {'אין לך עדיין חשבון?'}
      </div>
      <div className='form-page-connect'
        onClick={() => {
          dispatch(setUserProfileView("FormPageCreate"));
          dispatch(setIsUserProfile(true));
        }}
      >
        <div className='form-page-connect-text'>
          {'הירשם'}
        </div>
      </div>
      {!isPidOpen1 && <div className='form-page-visitor'
        onClick={() => {
          dispatch(setIsTraile(false));
          dispatch(setIsUserProfile(false));
        }}
      >
        {'כניסה כאורח>>'}
      </div>}
    </form>
  );
}




export default FormPageConnect;
