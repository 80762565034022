import React, { useState } from "react";
import Select from "react-select";
import "./FormPageCreateMobile.css";
//@ts-ignore
import ReCAPTCHA from "react-google-recaptcha";
import wikiService from "../../services/wikiService";
import isMobile from "ismobilejs";
const FormPageCreateMobile: React.FC = () => {

  const [isError, setIsError] = useState(false);
  const [errorValue, setErrorValue] = useState('אנא מלא את השדות החסרים');
  const [firstName, serFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phon, setPhon] = useState('');
  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const [tokenCAPTCHA, setTokenCAPTCHA] = useState('');

  const [nickname, setNickname] = useState('');
  const [createaccounttoken, setCreateaccounttoken] = useState('');
  const [tokenCAPTCHAID, setTokenCAPTCHAID] = useState('');
  const [gender, setGender] = useState('');


  const onChangeFirstName = (e: any) => {
    nameIsExist(e.target.value + ' ' + lastName)
    serFirstName(e.target.value)
  }

  const onChangelastName = (e: any) => {
    nameIsExist(firstName + ' ' + e.target.value)
    setLastName(e.target.value)
  }

  const onChangeemail = (e: any) => {
    setEmail(e.target.value);
  }

  const onChangephon = (e: any) => {
    setPhon(e.target.value);
  }

  const onChangepassword = (e: any) => {
    setPassword(e.target.value);
  }

  const onChangerePassword = (e: any) => {

    setRePassword(e.target.value);
  }


  function ValidateEmail(mail: any) {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
      return (true)
    }
    return (false)
  }

  function ValidateName(Field: string) {
    let AlphaNumericChars = new RegExp("^[\u0590-\u05FF]+$");
    if (!AlphaNumericChars.test(Field.split(' ').join(''))) {
      return false;
    } else
      return true;
  }

  async function nameIsExist(name: string) {
    const nameIsExist = await wikiService.nameIsExist(name);
    if (nameIsExist.users[0].invalid) {
      setErrorValue('אנא מלא שם תקין')
      setIsError(true)
      return false;
    }
    else if (!nameIsExist.users[0].cancreate) {
      setErrorValue('שם המשתמש שבחרת נמצא כבר בשימוש. נא לבחור שם אחר.')
      setIsError(true)
      return false;
    } else {
      setErrorValue('אנא מלא את השדות החסרים')
      setIsError(false)
    }
    return true;
   
  }

  function ValidatePhon(Field: any) {
    var AlphaNumericChars = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

    if (!AlphaNumericChars.test(Field)) {
      return false;
    } else
      return true;
  }

  const CAPTCHA = async () => {
    if (createaccounttoken !== '') {
      return tokenCAPTCHAID;
    }
    return wikiService.CAPTCHA().then((res) => {
      if (res && res.authmanagerinfo) {
        setCreateaccounttoken(res.tokens.createaccounttoken);
        setTokenCAPTCHAID(res.authmanagerinfo.requests[0].metadata.key);
        return res.authmanagerinfo.requests[0].metadata.key;
      }
      else {
        return '';
      }
    })


  }
  CAPTCHA()

  const createAccount = () => {
    if (
      ValidateName(firstName) &&
      ValidateName(lastName) &&
      ValidateEmail(email) &&
      password === rePassword &&
      rePassword !== '' &&
      tokenCAPTCHA !== '' &&
      createaccounttoken !== '' &&
      ValidatePhon(phon) &&
      gender !== '' &&
      nickname !== ''
    ) {
      setIsError(false)

      wikiService.createAccount(
        email,
        firstName,
        lastName,
        createaccounttoken,
        password,
        rePassword,
        tokenCAPTCHAID,
        tokenCAPTCHA,
        phon,
        gender,
        nickname
      ).then((res) => {
        if (res === 'PASS') {
          setErrorValue('חשבונך נוצר בהצלחה מיד תתחבר לחשבון')
          setIsError(true)
          setTimeout(() => {
            wikiService.LogIn(firstName + ' ' + lastName, password).then(() => {
              window.location.reload();
            })
          }, 2000);
        }

        if (res === 'FAIL') {

          setErrorValue(res.createaccount.message)

          setIsError(true)

          setTimeout(function () {
            window.location.reload();
          }, 200);
        }

      })

    }
    else {
      setErrorValue('')
      if (!ValidateName(firstName)) {
        setErrorValue('שם פרטי לא תקין יש למלא רק שמות בעברית בלי מספרים')
      }
      else if (!ValidateName(lastName)) {
        setErrorValue('שם משפחה לא תקין יש למלא רק שמות בעברית בלי מספרים')
      }
      else if (!ValidateEmail(email)) {
        setErrorValue('האמייל לא תקין')
      }
      else if (!ValidatePhon(phon)) {
        setErrorValue('מספר הטלפון לא תקין')
      }
      else if (password === '') {
        setErrorValue('בחר סיסמא')
      }
      else if (password !== '' && password !== rePassword) {
        setErrorValue('סיסמאות לא דומות')
      }
      else if (gender === '') {
        setErrorValue('בחר מגזר אליו אתה משתייך')
      }
      else if (nickname === '') {
        setErrorValue('בחר מגדר')
      }

      setIsError(true)
    }

  }


  const onChange = (value: string) => {
    setTokenCAPTCHA(value)
  }
  const getSelectGender = () => {
    let defaultValue = [{ value: 'male', label: 'זכר' }];

    return defaultValue.concat(

      { value: 'female', label: 'נקבה' }
    );

  };
  const getSelectMenu = () => {
    let defaultValue = [{ value: '6', label: 'ליטאי' }];

    return defaultValue.concat(
      { value: '1', label: 'חסידי' },
      { value: '2', label: 'ספרדי' },
      { value: '7', label: 'חרדי' },
      { value: '3', label: 'חרד"לי' },
      { value: '4', label: 'דתי' },
      { value: '5', label: 'מסורתי' },
      { value: '6', label: 'אחר' },
    );

  };

  const handleSector = (value: any) => {
    setNickname(value)
  }

  const handleGender = (value: any) => {
    setGender(value)
  }
  if (isMobile().phone)
    return (
      <form className='dddddd'>
        <div className='form-page-create-title-mobile'>
          {'צור חשבון'}
        </div>
        <div className='form-page-create-line-mobile' />
        <div className='form-page-create-container-mobile'>
          <div className={`form-page-create-first-name-mobile ${isError && !ValidateName(firstName) && 'red-border-mobile'}`}>
            <input
              type='text'
              name='first-name'
              className='form-page-create-first-name-input-mobile'
              placeholder='שם פרטי'

              onChange={(e) => {
                onChangeFirstName(e)
              }}
              maxLength={40}

            ></input>

            <div className={'form-page-create-required-mobile'}>
              {'*'}
            </div>
          </div>
          <div className={`form-page-create-first-name-mobile ${isError && !ValidateName(lastName) && 'red-border-mobile'}`}>
            <input
              type='text'
              name='first-name'
              className='form-page-create-first-name-input-mobile'
              placeholder='שם משפחה'

              onChange={(e) => {
                onChangelastName(e)
              }}
              maxLength={40}
            ></input>
            <div className={'form-page-create-required-mobile'}>
              {'*'}
            </div>
          </div>
          <div className={`form-page-create-first-name-mobile ${isError && !ValidateEmail(email) && 'red-border-mobile'}`}>
            <input
              type='email'
              name='email'
              className='form-page-create-first-name-input-mobile'
              placeholder='דוא"ל'
              onChange={(e) => {
                onChangeemail(e)
              }}
              maxLength={40}
              defaultValue='@gmail.com'
            ></input>
            <div className={'form-page-create-required-mobile'}>
              {'*'}
            </div>
          </div>
          <div className={`form-page-create-first-name-mobile ${isError && !ValidatePhon(phon) && 'red-border-mobile'}`}>
            <input
              type='phon'
              name='phon'
              className='form-page-create-first-name-input-mobile'
              placeholder='מספר נייד'
              onChange={(e) => {
                onChangephon(e)
              }}
              maxLength={40}
            ></input>
            <div className={'form-page-create-required-mobile'}>
              {'*'}
            </div>
          </div>
          <div className={`form-page-create-first-name-mobile ${isError && password === '' && 'red-border-mobile'}`}>
            <input
              type='password'
              name='password'
              autoComplete="on"
              placeholder='בחר סיסמה'
              className='form-page-create-first-name-input-mobile'
              onChange={(e) => { onChangepassword(e) }}
            ></input>
            <div className={'form-page-create-required-mobile'}>
              {'*'}
            </div>
          </div>

          <div className={`form-page-create-first-name-mobile ${isError && password !== rePassword && 'red-border-mobile'}`}>
            <input
              type='password'
              name='password'

              autoComplete="on"
              placeholder='אימות הסיסמה'
              className='form-page-create-first-name-input-mobile'
              onChange={(e) => { onChangerePassword(e) }}
            ></input>
            <div className={'form-page-create-required-mobile'}>
              {'*'}
            </div>
          </div>
        </div>
        <div className={`form-page-create-gender-mobile`}>
          <div className='form-page-create-gender-title-mobile-1'>
            <div className='form-page-create-gender-title-mobile'>
              {'מגדר'}
            </div>
            <div className={`form-page-create-gender-select-mobile`}>
              <Select

                classNamePrefix="form-page-create-gender-select-1-mobile"
                options={getSelectGender()}

                isRtl={true}
                isSearchable={false}
                defaultValue={{ value: 'בחר', label: 'בחר' }}
                onChange={(e: any) => handleSector(e.value)}
                maxMenuHeight={200}
              />
            </div>
          </div>
          <div className='form-page-create-gender-title-mobile-1'>
            <div className='form-page-create-gender-title-mobile'>
              {'מגזר'}
            </div>
            <div className='form-page-create-gender-select-mobile'>
              <Select
                classNamePrefix="form-page-create-gender-select-1-mobile"

                options={getSelectMenu()}
                isRtl={true}
                isSearchable={false}
                defaultValue={{ value: 'בחר', label: 'בחר' }}
                onChange={(e: any) => handleGender(e.value)}
                maxMenuHeight={200}
              />
            </div>
          </div>
        </div>
        {tokenCAPTCHAID !== '' && <div className='form-page-create-CAPTCHA-mobile'>
          <ReCAPTCHA
            sitekey={tokenCAPTCHAID}
            onChange={onChange}
            hl='he'
          />
        </div>}
        {isError && <div className='form-page-create-erro-mobiler'>
          {errorValue}
        </div>}
        <div className='form-page-create-create-button-mobile'
          onClick={createAccount}
        >
          <div className='form-page-create-create-button-text-mobile'>
            {'הירשם'}
          </div>
        </div>
        <div className='form-page-create-create-text-mobile'>
          {'בלחיצה על הירשם, אתם מסכימים ל'}
          <a href='/creditsDoc' target="_blank">
            {'תנאי השימוש'}
          </a>
          {', שלנו. ייתכן שתקבלו התראות משיתופתא, תוכלו לבטל את הסכמתכם לקבלת הודעות בכל עת.'}
        </div>
      </form>
    );
  else
    return (
      <form>
        <div className='form-page-create-title'>
          {'צור חשבון'}
        </div>
        <div className='form-page-create-line'>

        </div>
        <div className='form-page-create-container'>
          <div className={`form-page-create-first-name ${isError && !ValidateName(firstName) && 'red-border'}`}>
            <input
              type='text'
              name='first-name'
              className='form-page-create-first-name-input'
              placeholder='שם פרטי'

              onChange={(e) => {
                onChangeFirstName(e)
              }}
              maxLength={40}

            ></input>

            <div className={'form-page-create-required'}>
              {'*'}
            </div>
          </div>
          <div className={`form-page-create-first-name ${isError && !ValidateName(lastName) && 'red-border'}`}>
            <input
              type='text'
              name='first-name'
              className='form-page-create-first-name-input'
              placeholder='שם משפחה'

              onChange={(e) => {
                onChangelastName(e)
              }}
              maxLength={40}
            ></input>
            <div className={'form-page-create-required'}>
              {'*'}
            </div>
          </div>
          <div className={`form-page-create-first-name ${isError && !ValidateEmail(email) && 'red-border'}`}>
            <input
              type='email'
              name='email'
              className='form-page-create-first-name-input'
              placeholder='דוא"ל'
              defaultValue='@gmail.com'
              onChange={(e) => {
                onChangeemail(e)
              }}
              maxLength={40}
              dir='ltr'
            ></input>
            <div className={'form-page-create-required'}>
              {'*'}
            </div>
          </div>
          <div className={`form-page-create-first-name ${isError && !ValidatePhon(phon) && 'red-border'}`}>
            <input
              type='phon'
              name='phon'
              className='form-page-create-first-name-input'
              placeholder='מספר נייד'
              onChange={(e) => {
                onChangephon(e)
              }}
              maxLength={40}
              dir='ltr'
            ></input>
            <div className={'form-page-create-required'}>
              {'*'}
            </div>
          </div>
          <div className={`form-page-create-first-name ${isError && password === '' && 'red-border'}`}>
            <input
              type='password'
              name='password'
              autoComplete="on"
              placeholder='בחר סיסמה'
              className='form-page-create-first-name-input'
              onChange={(e) => { onChangepassword(e) }}
            ></input>
            <div className={'form-page-create-required'}>
              {'*'}
            </div>
          </div>

          <div className={`form-page-create-first-name ${isError && password !== rePassword && 'red-border'}`}>
            <input
              type='password'
              name='password'
              autoComplete="on"
              placeholder='אימות הסיסמה'
              className='form-page-create-first-name-input'
              onChange={(e) => { onChangerePassword(e) }}
            ></input>
            <div className={'form-page-create-required'}>
              {'*'}
            </div>
          </div>
        </div>
        <div className={`form-page-create-gender`}>
          <div className='form-page-create-gender-title'>
            {'מגדר'}
          </div>
          <div className={`form-page-create-gender-select`}>
            <Select

              classNamePrefix="form-page-create-gender-select-1"
              options={getSelectGender()}

              isRtl={true}
              isSearchable={false}
              defaultValue={{ value: 'בחר', label: 'בחר' }}
              onChange={(e: any) => handleSector(e.value)}
              maxMenuHeight={200}
            />
          </div>
          <div className='form-page-create-gender-title'>
            {'מגזר'}
          </div>
          <div className='form-page-create-gender-select'>
            <Select
              classNamePrefix="form-page-create-gender-select-1"

              options={getSelectMenu()}
              isRtl={true}
              isSearchable={false}
              defaultValue={{ value: 'בחר', label: 'בחר' }}
              onChange={(e: any) => handleGender(e.value)}
              maxMenuHeight={200}
            />
          </div>
        </div>
        {tokenCAPTCHAID !== '' && <div className='form-page-create-CAPTCHA'>
          <ReCAPTCHA
            sitekey={tokenCAPTCHAID}
            onChange={onChange}
            hl='he'
          />
        </div>}
        {isError && <div className='form-page-create-error'>
          {errorValue}
        </div>}
        <div className='form-page-create-create-button'
          onClick={createAccount}
        >
          <div className='form-page-create-create-button-text'>
            {'הירשם'}
          </div>
        </div>
        <div className='form-page-create-create-text'>
          {'בלחיצה על הירשם, אתם מסכימים ל'}
          <a href='/creditsDoc' target="_blank">
            {'תנאי השימוש'}
          </a>
          {', שלנו. ייתכן שתקבלו התראות משיתופתא, תוכלו לבטל את הסכמתכם לקבלת הודעות בכל עת.'}
        </div>
      </form>
    );
}




export default FormPageCreateMobile;
