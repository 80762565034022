
import React, { useState, useEffect, useLayoutEffect } from "react";
import "./ViewTopic.css";
import { CopyToClipboard } from 'react-copy-to-clipboard';

import DOMPurify from 'dompurify';
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as Line } from "../../svg/HidushimLine.svg";
import {
  getUserid,
  getUserName,
  getIsLoading,
} from "../../store/selectors/selectors";
import Tooltip from '@mui/material/Tooltip';
import {
  useStyles
} from "../../utils/funcUtils";
import { setOpenPid1, setMyTopic } from "../../store/actions/viewActions";
import wikiService from "../../services/wikiService";
import { ReactComponent as Comment } from "../../svg/comment.svg";
import { ReactComponent as CommentGreen } from "../../svg/commentGreen.svg";
import { ReactComponent as CommentSmall } from "../../svg/commentsSmall.svg";
import { ReactComponent as CommentSmallW } from "../../svg/commentSmallW.svg";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import LinkIcon from '@mui/icons-material/Link';
import ShowMoreText from 'react-show-more-text';
import {
  openComment
} from "../../utils/funcUtils";
import isMobile from "ismobilejs";
import { ThunkDispatch } from "@reduxjs/toolkit";

interface Props {
  topic: any;
  isUserLike: boolean;
  LikedCounts: any;
  flowV: any;
  styleTop: any;
  isTitle: boolean;
  level: boolean;
  isNone: Map<string, boolean>;
  increment: (topic: any) => void;
  replys: Map<string, string>;
}
const ViewTopic: React.FC<Props> = ({ topic, isUserLike, LikedCounts, flowV, styleTop, isTitle, level, isNone, increment, replys }) => {
  const [items1, setItems1] = useState(flowV);
  const [expande, setExpanded] = useState(false);
  const [menu, setMenu] = useState(false);
  const [copy, setCopy] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const userid = useSelector(getUserid);
  const [isNoneLocal, setIsNone] = useState(isNone.get(topic.postId));
  const [isLoaded, setIsLoaded] = useState(level);
  const [isFocus, setFocus] = useState(false);
  const [data, setData] = useState('');
  const userName = useSelector(getUserName);
  const isLoading = useSelector(getIsLoading);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const incremen = (uuid: any) => {
    setItems1(uuid)
    increment(uuid)
  }
  const classes = useStyles();
  const getTitle = () => {
    return topic.Title_Parent
  }

  const isUserLikeed = (uuid: string) => {
    if (LikedCounts)
      for (var i = 0; i < LikedCounts.length; i++) {
        if (userid && LikedCounts[i] && uuid === LikedCounts[i].uuid && LikedCounts[i].userid === userid.toString()) {
          return true;
        }
      }

    return false;
  }

  const LikedCount = (uuid: string) => {
    for (var i = 0; i < LikedCounts.length; i++) {
      if (LikedCounts[i] && uuid === LikedCounts[i].uuid) {
        return LikedCounts[i].count;
      }
    }
    return 0;
  }

  const makeLike = (uuid: any) => {
    if (isUserLike)
      return;

    wikiService.makeLike(uuid);
    for (var i = 0; i < LikedCounts.length; i++) {
      if (uuid === LikedCounts[i].uuid) {
        LikedCounts[i].count = Number(LikedCounts[i].count) + 1;
      }
    }
    let classa = document.getElementById(uuid);
    let classaCount = document.getElementById(uuid + 'count');
    if (classa) {
      classa.setAttribute('class', 'sss')
    }
    if (classaCount) {
      classaCount.textContent = String(Number(classaCount.textContent) + 1);
    }
  }

  const getPost = (postID: any) => {
    const b = items1.filter((item: any) => {
      return item.postId === postID;
    })
    return b[0];
  }
  useLayoutEffect(() => {


  }, [isLoading]);
  useEffect(() => {

  }, [items1, isLoaded, userid, isLoading]);

  const handleChangeRep = (e: any, rep: any) => {

    if (e.target.innerHTML) {
      isNone.set(rep, true);
      setIsNone(true);
    }
    else {
      isNone.set(rep, false);
      setIsNone(false);
    }

    for (var i = 0; i < e.target.children.length; i++) {
      e.target.children[i].removeAttribute('class');
      e.target.children[i].removeAttribute('style');
    }
    replys.set(rep, e.target.innerHTML);
  }

  const handleChangeData = (e: any) => {
    setData(DOMPurify.sanitize(e.target.innerHTML, { FORBID_TAGS: ['span'], FORBID_ATTR: ['style', 'class'] }));
  }

  const handleSendRep = (event: any, Topic: any, replyTo: any) => {
    var content = replys.get(replyTo);
    if (content !== '') {
      event.target.innerText = '';
      event.preventDefault();

      wikiService.handleSendRep(Topic, replyTo, content, 'חידוש:' + getTitle()).then((response) => {
        if (response) {
          incremen(response[Topic])
          setIsLoaded(true);
        }
      })

      setIsLoaded(true);
    }



  }

  const topicReplies = (replys1: any) => {

    return replys1.map((item11: any, index: number) => {

      return (<>
        {(isLoaded) &&
          <div
            style={{ right: styleTop, position: 'relative', minHeight: 110, fontSize: 14 }
            }
            key={item11}
          >
            < ViewTopic
              topic={getPost(replys1[replys1.length - index - 1])}
              isUserLike={isUserLikeed(replys1[replys1.length - index - 1])}
              LikedCounts={LikedCounts}
              flowV={items1}
              styleTop={styleTop + 10}
              isTitle={false}
              level={isLoaded}
              isNone={isNone}
              increment={incremen}
              replys={replys}
            />
          </div>}
      </>
      )
    })
  }

  const dateFormat = (uuid: string) => {
    return openComment(uuid)
  }

  const handleSend = async (e: any, postId: any, revision: any) => {
    if (data === '') {
      return;
    }
    wikiService.editPost(flowV[0].articleTitle, data, postId, revision);
  }
  if (isLoading) return (<></>);
  const handleTogglePid = (creator: any) => {
    dispatch(setMyTopic(creator.id + '`' + creator.name));
    dispatch(setOpenPid1());
  };
  return (
    <>
      {items1 && <>
        <div
          key={topic.workflowId}
          id={topic.workflowId}
          className='show-hidushim' >
          <div className='hidushim-line'>
            <Line />
          </div>
          <div className="hidushim"
            onMouseLeave={() => {
              if (menu === true)
                setMenu(false)
            }}
          >
            <div className='hidushim-person-main'
              onClick={() => {
                handleTogglePid(topic.creator)
              }}

            >
              <Tooltip
                classes={classes}
                title={`לחץ לרשימת החידושים  של המשתמש: ${topic.creator.name}`}
                placement="right"
                arrow
              >
                <div className="hidushim-person">
                  {topic.creator.name}
                </div>
              </Tooltip>
              {isTitle && items1[1].changeType !== 'edit-post' &&
                <Tooltip
                  classes={classes}
                  title={topic.timestamp}
                  placement="top"
                  arrow
                >
                  <div className={'hidushim-date'} >
                    {dateFormat(items1[1].timestamp)}
                  </div>
                </Tooltip>
              }
              {isTitle && items1[1].changeType === 'edit-post' &&
                <Tooltip
                  classes={classes}
                  title={topic.timestamp}
                  placement="top"
                  arrow
                >
                  <div className={'hidushim-date'} data-title={'התפרסם ' + dateFormat(topic.timestamp)}>
                    {items1[1].changeType === 'edit-post' && 'נערך '}
                    {dateFormat(items1[1].timestamp)}
                  </div>
                </Tooltip>
              }
              {!isTitle &&
                <Tooltip
                  classes={classes}
                  title={topic.timestamp}
                  placement="top"
                  arrow
                >
                  <div className={'hidushim-date-sub'}>
                    {topic.changeType === 'edit-post' && 'נערך '}
                    {dateFormat(topic.timestamp)}
                  </div>
                </Tooltip>}
            </div>
            {<div className={'hidushim-tree-dont'}
              onClick={() => {
                setMenu(!menu)
              }}
              onMouseOver={() => {
                if (!menu)
                  setMenu(!menu)
              }}
              style={{ backgroundColor: menu ? '#f1f8f6' : '#FFFF' }}
            >
              <div>
                <MoreHorizIcon style={{
                  position: 'relative',
                  right: 7.5,
                  height: 22,
                  color: '#38A09D',
                  top: 10,
                  width: 22
                }} />
              </div>

            </div>}
            {menu && <div className={'hidushim-tree-dont-menu-triangle ' + (isTitle ? `` : `reply-triangle`)} />}
            {menu && <div className={`hidushim-tree-dont-menu ` + (isTitle ? `` : `reply`)}

            >
              <CopyToClipboard text={window.location.origin + '/statusUuid/' + topic.workflowId}
                onCopy={() => {
                  setCopy(true)
                  setTimeout(() => {
                    setMenu(false)
                    setCopy(false)
                  }, 3000);
                }
                }>
                <div className={'hidushim-tree-dont-menu-item'}
                >
                  <LinkIcon style={{
                    position: 'relative',
                    top: 8,
                    height: 15,
                    width: 20,
                    color: '#38A09D',
                  }} />
                  {!copy ? 'העתקת הקישור' : <b>הועתק ללוח</b>}
                </div>
              </CopyToClipboard>

              {userName === topic.creator.name && <div className={'hidushim-tree-dont-menu-item'}
                onClick={() => {
                  setIsEditable(true)
                  setMenu(false)
                }}>
                <EditIcon style={{
                  position: 'relative',
                  top: 8,
                  height: 15,
                  color: '#38A09D',
                  width: 15,
                  left: 4
                }} />
                {'עריכת החידוש'}
              </div>}

            </div>}
          </div>

          {<div
            className={!isEditable ? 'hidushim-text' : 'hidushim-reply'} id={`hidushim-${items1[1].postId}`}
            role={'textbox'}
            data-text="תערוך את החידוש"
            onInput={(e) => {
              if (!isTitle)
                return handleChangeData(e)
              if (isTitle)
                return handleChangeData(e)
            }}>
            {isEditable && isTitle &&
              <div
                style={{ outline: 'none' }}
                contentEditable={"true"}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(items1[1].content.content.split("<br>").join('\n'), { ALLOWED_TAGS: ['blockquote', 'h1', 'h2', 'h3', 'big', 'b', 'strong'], FORBID_ATTR: ['data-parsoid', 'id'] }).split('&nbsp;').join(' ')
                }}>
              </div>}
            {isEditable && !isTitle &&
              <div
                style={{ outline: 'none' }}
                contentEditable={"true"}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(topic.content.content.split("<br>").join('\n'), { ALLOWED_TAGS: ['blockquote', 'h1', 'h2', 'h3', 'big', 'b', 'strong'], FORBID_ATTR: ['data-parsoid', 'id'] }).split('&nbsp;').join(' ')
                }}>
              </div>}

            {!isEditable && <ShowMoreText
              /* Default options */
              lines={3}
              more='קרא עוד>'
              less='קרא פחות>'
              className='ggg'
              anchorClass='hidushim-read-more'
              expanded={false}
              onClick={(expanded) => { setExpanded(expanded) }}
              width={(isMobile().phone || isMobile().tablet) ? 300 : 340}>
              {
                isTitle && expande &&
                <div style={{ whiteSpace: 'pre-line' }}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(items1[1].content.content, { ALLOWED_TAGS: ['blockquote', 'br', 'h1', 'h2', 'h3', 'big', 'b', 'strong'], FORBID_ATTR: ['data-parsoid', 'id'] }).split('&nbsp;').join(' ')
                  }}
                />
              }
              {isTitle && !expande && DOMPurify.sanitize(items1[1].content.content, { ALLOWED_TAGS: ['blockquote', 'br', 'h1', 'h2', 'h3', 'big', 'b', 'strong'], FORBID_ATTR: ['data-parsoid', 'id'] }).split('&nbsp;').join(' ')}
              {
                !isTitle &&
                <div style={{ whiteSpace: 'pre-line' }}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(topic.content.content, { FORBID_TAGS: ['big', 'b'], FORBID_ATTR: ['data-parsoid'] })
                  }}
                />

              }

            </ShowMoreText>}
          </div>
          }
          {isEditable && <div className='hidushim-flex-isEditable'>
            <div
              className='show-hidushim-send-post-rep-cancel'
              onClick={() => setIsEditable(false)}
            >
              <div className='show-hidushim-send-post-rep-text-cancel'>
                {'ביטול'}
              </div>
            </div>
            <div
              className='show-hidushim-send-post-rep'
              onClick={(e) => {
                topic.content.content = data;
                if (isTitle) {
                  items1[1].content.content = data;
                }

                if (data === '') {

                  return;
                }
                setIsEditable(false)
                handleSend(e, isTitle ? items1[1].postId : topic.postId, isTitle ? items1[1].revisionId : topic.revisionId)

              }
              }

            >
              <div className='show-hidushim-send-post-rep-text'>
                {'פרסום'}
              </div>
            </div>
          </div>}

          <div className='hidushim-flex'>
            <div className={isTitle ? 'hidushim-like' : 'hidushim-like-small'}
              onClick={() =>
                !isUserLikeed(isTitle ? items1[1].postId : topic.postId) && makeLike(isTitle ? items1[1].postId : topic.postId)
              }
            >
              {!isTitle && !isUserLikeed(topic.postId) && <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id={topic.postId} d="M8.56097 4.72649C7.84087 4.16544 6.92691 3.22187 6.42839 1.1052C6.17912 0.799171 5.62521 1.25821 5.62521 1.25821C5.62521 1.25821 5.07129 1.79375 5.51442 4.34396H2.21862C1.6924 4.34396 1.27696 4.72649 1.27696 5.21103C1.27696 5.46605 1.38774 5.69557 1.60931 5.84858C1.6647 5.87408 1.637 5.95059 1.58161 5.97609C1.24926 6.1291 1 6.46063 1 6.84316C1 7.2512 1.27696 7.58272 1.637 7.73573C1.72009 7.76124 1.72009 7.86324 1.637 7.88875C1.33235 8.04176 1.13848 8.34778 1.13848 8.67931C1.13848 9.08734 1.41544 9.41887 1.80318 9.54638C1.88627 9.57188 1.88627 9.67389 1.83088 9.69939C1.637 9.8269 1.52622 10.0309 1.52622 10.2604C1.52622 10.6175 1.80318 10.949 2.19092 11H6.76074C8.61636 11 10.2504 9.7249 10.472 8.01626C10.6658 6.61364 9.83497 5.31304 8.56097 4.72649Z" stroke="#38A09D" strokeMiterlimit="10" />
              </svg>}
              {!isTitle && isUserLikeed(topic.postId) && <svg width="11" height="12" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id={topic.postId} className='sss' d="M13.7343 7.33503C12.5215 6.38126 10.9822 4.77718 10.1425 1.17883C9.72273 0.658591 8.78982 1.43895 8.78982 1.43895C8.78982 1.43895 7.85691 2.34938 8.60324 6.68473H3.05241C2.16614 6.68473 1.46646 7.33503 1.46646 8.15875C1.46646 8.59229 1.65304 8.98247 2.0262 9.24259C2.1195 9.28594 2.07285 9.416 1.97956 9.45936C1.41981 9.71948 1 10.2831 1 10.9334C1 11.627 1.46646 12.1906 2.07285 12.4507C2.21279 12.4941 2.21279 12.6675 2.07285 12.7109C1.55975 12.971 1.23323 13.4912 1.23323 14.0548C1.23323 14.7485 1.69968 15.3121 2.35272 15.5288C2.49266 15.5722 2.49266 15.7456 2.39937 15.789C2.07285 16.0057 1.88627 16.3526 1.88627 16.7427C1.88627 17.3497 2.35272 17.9133 3.00576 18H10.7023C13.8275 18 16.5796 15.8323 16.9528 12.9276C17.2793 10.5432 15.88 8.33216 13.7343 7.33503Z" stroke="#38A09D" strokeMiterlimit="10" />
              </svg>}
              {isTitle && !isUserLikeed(items1[1].postId) && <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id={items1[1].postId} d="M13.7343 7.33503C12.5215 6.38126 10.9822 4.77718 10.1425 1.17883C9.72273 0.658591 8.78982 1.43895 8.78982 1.43895C8.78982 1.43895 7.85691 2.34938 8.60324 6.68473H3.05241C2.16614 6.68473 1.46646 7.33503 1.46646 8.15875C1.46646 8.59229 1.65304 8.98247 2.0262 9.24259C2.1195 9.28594 2.07285 9.416 1.97956 9.45936C1.41981 9.71948 1 10.2831 1 10.9334C1 11.627 1.46646 12.1906 2.07285 12.4507C2.21279 12.4941 2.21279 12.6675 2.07285 12.7109C1.55975 12.971 1.23323 13.4912 1.23323 14.0548C1.23323 14.7485 1.69968 15.3121 2.35272 15.5288C2.49266 15.5722 2.49266 15.7456 2.39937 15.789C2.07285 16.0057 1.88627 16.3526 1.88627 16.7427C1.88627 17.3497 2.35272 17.9133 3.00576 18H10.7023C13.8275 18 16.5796 15.8323 16.9528 12.9276C17.2793 10.5432 15.88 8.33216 13.7343 7.33503Z" stroke="#38A09D" strokeMiterlimit="10" />
              </svg>}
              {isTitle && isUserLikeed(items1[1].postId) && <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id={items1[1].postId} className='sss' d="M13.7343 7.33503C12.5215 6.38126 10.9822 4.77718 10.1425 1.17883C9.72273 0.658591 8.78982 1.43895 8.78982 1.43895C8.78982 1.43895 7.85691 2.34938 8.60324 6.68473H3.05241C2.16614 6.68473 1.46646 7.33503 1.46646 8.15875C1.46646 8.59229 1.65304 8.98247 2.0262 9.24259C2.1195 9.28594 2.07285 9.416 1.97956 9.45936C1.41981 9.71948 1 10.2831 1 10.9334C1 11.627 1.46646 12.1906 2.07285 12.4507C2.21279 12.4941 2.21279 12.6675 2.07285 12.7109C1.55975 12.971 1.23323 13.4912 1.23323 14.0548C1.23323 14.7485 1.69968 15.3121 2.35272 15.5288C2.49266 15.5722 2.49266 15.7456 2.39937 15.789C2.07285 16.0057 1.88627 16.3526 1.88627 16.7427C1.88627 17.3497 2.35272 17.9133 3.00576 18H10.7023C13.8275 18 16.5796 15.8323 16.9528 12.9276C17.2793 10.5432 15.88 8.33216 13.7343 7.33503Z" stroke="#38A09D" strokeMiterlimit="10" />
              </svg>}
            </div>
            {<div className='hidushim-like-text'
              onClick={() =>
                !isUserLikeed(topic.postId) && makeLike(isTitle ? items1[1].postId : topic.postId)
              }>

              {'  שכוייח'}
            </div>}
            <div className='hidushim-like-count' id={isTitle ? items1[1].postId + 'count' : topic.postId + 'count'}>
              {!isTitle && LikedCount(topic.postId) > 0 && LikedCount(topic.postId)}
              {isTitle && LikedCount(items1[1].postId) > 0 && LikedCount(items1[1].postId)}
            </div>
            {false && <div className={isTitle ? 'hidushim-comment' : 'hidushim-comment-small'}>
              {isTitle && !isLoaded && <Comment />}
              {isTitle && isLoaded && <CommentGreen />}


              {!isTitle && !isLoaded && <CommentSmall />}
              {!isTitle && isLoaded && <CommentSmallW />}
            </div>}
            {false && <div className={`hidushim-comment-text `}
              onClick={() => {
                setIsLoaded(!isLoaded)
              }}>
              {!isTitle ? 'תגובה' : 'תגובות'}
            </div>}
            {false && <div className={`hidushim-count ${isTitle ? 'bbb' : 'bbb'}`}>
              {isTitle && Number(topic.reply_count) - 1 > 0 && Number(topic.reply_count) - 1}
              {!isTitle && topic.replies.length > 0 && topic.replies.length}
            </div>}
          </div>
        </div>
        {((!isLoaded && !isTitle) || (isLoaded && isTitle)) &&
          <div className='hight'>
            {isTitle &&
              !isNoneLocal &&

              <div className='hidushim-send-text'>
                {'תגובה...'}
              </div>
            }
            {!isTitle &&
              !isNone.get(topic.postId) &&

              <div className='hidushim-send-text'>
                {'תגובה...'}
              </div>
            }
            <div className='hidushim-reply'
              role={'textbox'}
              contentEditable={"true"}
              onInput={(e) => {
                if (!isTitle)
                  return handleChangeRep(e, topic.postId)
                if (isTitle)
                  return handleChangeRep(e, items1[1].postId)
              }}
              onBlur={() => { setFocus(false) }}
              onFocus={() => { setFocus(true) }}
              onKeyPress={event => {

                if (event.key === 'Enter' && !event.shiftKey) {
                  if (userid !== 0 && userid !== null) {
                    if (!isTitle)
                      handleSendRep(event, topic.workflowId, topic.postId)
                    if (isTitle)
                      handleSendRep(event, topic.workflowId, flowV[1].postId)
                  }
                  else {
                    alert('התחבר כדי לפרסם את החידושים שלך');
                  }
                }
              }
              }>
            </div>
            <div style={{ fontSize: 10, height: 10, position: 'relative', top: 15, right: 40, width: 100 }}>
              {isFocus && 'לחץ Enter לפרסום'}
            </div>
          </div>}
        {!isTitle && topic.replies.length > 0 && items1[1].replies.length > 0 &&
          topic.replies.map((item11: any) => {
            return (<>
              {<div
                style={{ right: isTitle ? 20 : 0, position: 'relative', minHeight: 110, fontSize: 14 }}
                key={item11}
              >
                < ViewTopic
                  topic={getPost(item11)}
                  isUserLike={isUserLikeed(item11)}
                  LikedCounts={LikedCounts}
                  flowV={items1}
                  styleTop={styleTop + 10}
                  isTitle={false}
                  level={level}
                  isNone={isNone}
                  increment={incremen}
                  replys={replys}
                />
              </div>}
            </>
            )
          })
        }
        {isTitle && items1[1].replies.length > 0
          && topicReplies(items1[1].replies)
        }
      </>}

    </>
  )
}

export default ViewTopic;
