
import React from "react";
import { useDispatch } from "react-redux";
import "./BlockAnnonimus.css";
import { ReactComponent as BlockIcon } from "../../svg/blockIcon.svg";
import {
  setIsUserProfile,
  setUserProfileView,
  setclosePid,
  setclosePid1,
} from "../../store/actions/viewActions";
import {
  setTabOpenLeft,
} from "../../store/actions/pageActions";
import { ThunkDispatch } from "@reduxjs/toolkit";

interface Props {
  text: string;
}


const BlockAnnonimus: React.FC<Props> = ({ text }) => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const openUserProfile = () => {
    dispatch(setUserProfileView('FormPageConnect'));
    dispatch(setIsUserProfile(true));
  };

  return (
    <div className="user-anon-container"

    >
      <div
        className="user-anon-container-div-style"
        onClick={() => {
          dispatch(setTabOpenLeft(null));;
          dispatch(setclosePid())
          dispatch(setclosePid1())
        }}>
      </div>
      <div className="user-anon-on">
        <div className="user-anon-svg-and-text">
          <BlockIcon className="user-anon-svg" />
          <div className="user-anon-text"
            dangerouslySetInnerHTML={{
              __html: ` ${text}`
            }}></div>
        </div>
        <div className="user-anon-button"
          onClick={openUserProfile}
        >
          <div className="user-anon-button-text">{'התחבר'}</div>
        </div>
      </div>
    </div>
  )
}

export default BlockAnnonimus;
