import * as Types from "../types/types";

interface IViewState {
  isLoading: Boolean;
  openTabsRight: any;
  openTabsLeft: any;
  isSfarimOn: any;
  isBiyur: boolean;
  isBiyurScrolling: any;
  pid: {
    isOpen: any;
  },
  onLineParty: {
    isOpen: boolean;
  },
  pid1: {
    isOpen: any;
  },
  pid2: {
    isOpen: any;
  },
  menu: {
    isOpen: boolean;
    view: any;
  };
  menuYoma: {
    isOpen: boolean;
    view: any;
  };
  menu1: {
    isOpen: any;
  };
  isOpenNavPage: boolean;
  isOpenSearch: boolean;
  isUserProfile: boolean;
  isUserProfileOpen: boolean;
  user: {
    id: any;
    name: any;
    follow: any;
    page: any;
  }
  isArticles: string;
  isPrivate: any;
  bookListAll: any;
  projectNameShasmehulak: string | null;
  bookYoma: any;
  pageYoma: any;
  userNameYoma: any;
  isMobile: any;
  notifications: any;
  notificationsAll: any;
  notificationsOpen: boolean;
  emailOpen: boolean;
  topicsFollow: any;
  isLoaded1Hidush: boolean;
  isLoaded1Feed: boolean;
  userProfileView: string;
  isTraile: boolean;
  myTopics: string;
  registration: any;
  currentEvents: string;
  statusUuid: string;
  content_counts_menu: any;
  sectionNames: any;
  allUsers: any;
  allTopics: any;
  isIfame: any;
  pageHidushim: any;
  competition: any;
  usersFeed: any;
  recentchanges: any;
  statusFeed: any;
  resultFromSearch: Array<any>;
  isOpenTemplate: any;
  ckEdittorContent:string;
}

export const viewInitState = {
  isLoading: false,
  isUserProfile: false,
  isOpenNavPage: false,
  isUserProfileOpen: false,
  user: {
    id: null,
    name: 0,
    follow: 0,
    page: 0
  },
  isArticles: '',
  openTabsRight: null,
  openTabsLeft: null,
  isSfarimOn: false,
  isBiyur: false,
  isBiyurScrolling: false,
  pid: {
    isOpen: null
  },
  onLineParty: {
    isOpen: false
  },
  pid1: {
    isOpen: null
  },
  pid2: {
    isOpen: null
  },
  menu1: {
    isOpen: null
  },
  menu: {
    isOpen: false,
    view: "basic",
    book: null,
    heBook: null,
    chapters: null,
    allPages: null,
    chaptersView: "allPages"
  },
  menuYoma: {
    isOpen: false,
    view: "basic",
    book: null,
    heBook: null,
    chapters: null,
    allPages: null,
    chaptersView: "allPages"
  },
  projectNameShasmehulak: null,
  bookListAll: null,
  bookYoma: null,
  pageYoma: null,
  userNameYoma: null,
  isPrivate: true,
  isMobile: true,
  error: null,
  notifications: Object,
  notificationsAll: Object,
  notificationsOpen: false,
  emailOpen: false,
  topicsFollow: null,
  isLoaded1Hidush: false,
  isLoaded1Feed: false,
  userProfileView: 'FormPageConnect',
  isTraile: true,
  myTopics: '',
  registration: '',
  currentEvents: '',
  statusUuid: '',
  content_counts_menu: null,
  sectionNames: null,
  allUsers: null,
  allTopics: null,
  allTopicsIds: null,
  isIfame: '',
  pageHidushim: [],
  competition: null,
  usersFeed: '',
  recentchanges: '',
  statusFeed: '',
  resultFromSearch: [],
  isOpenTemplate: false,
  isOpenSearch: false,
  ckEdittorContent:''
};

const viewReducer = (
  state: IViewState = viewInitState,
  action: { type: String; payload?: any }
) => {
  switch (action.type) {
    case Types.LOADING_START:
      return {
        ...state,
        isLoading: true
      };
    case Types.LOADING_FINISHED:
      return {
        ...state,
        isLoading: false
      };
    case Types.SET_TAB_OPEN_RIGHT:
      return {
        ...state,
        openTabsRight: action.payload
      };
    case Types.CLEAR_TAB_OPEN_RIGHT:
      return {
        ...state,
        openTabsRight: null
      };
    case Types.SET_TAB_OPEN_LEFT:
      return {
        ...state,
        openTabsLeft: action.payload
      };
    case Types.CLEAR_TAB_OPEN_LEFT:
      return {
        ...state,
        openTabsLeft: null
      };
    case Types.SET_SEE_SFARIM_ON:
      return {
        ...state,
        isSfarimOn: action.payload
      };
    case Types.SET_IS_BIYUR:
      return {
        ...state,
        isBiyur: action.payload
      };
    case Types.SET_MENU_VIEW:
      return {
        ...state,
        menu: { ...state.menu, view: action.payload, chaptersView: "allPages" }
      };
    case Types.SET_MENU_VIEW_YOMA:
      return {
        ...state,
        menuYoma: { ...state.menuYoma, view: action.payload, chaptersView: "allPages" }
      };
    case Types.SET_MENU_BOOK:
      return {
        ...state,
        menu: { ...state.menu, book: action.payload }
      };
    case Types.SET_MENU_BOOK_YOMA:
      return {
        ...state,
        menuYoma: { ...state.menuYoma, book: action.payload }
      };
    case Types.SET_MENU_HE_BOOK:
      return {
        ...state,
        menu: { ...state.menu, heBook: action.payload }
      };
    case Types.SET_MENU_HE_BOOK_YOMA:
      return {
        ...state,
        menuYoma: { ...state.menuYoma, heBook: action.payload }
      };
    case Types.CLEAR_MENU_VIEW:
      return {
        ...state,
        menu: { ...viewInitState.menu, isOpen: true }
      };
    case Types.CLEAR_MENU_VIEW_YOMA:
      return {
        ...state,
        menuYoma: { ...viewInitState.menuYoma, isOpen: true }
      };
    case Types.SET_MENU_CHAPTERS:
      return {
        ...state,
        menu: { ...state.menu, chapters: action.payload }
      };
    case Types.SET_MENU_CHAPTERS_YOMA:
      return {
        ...state,
        menuYoma: { ...state.menuYoma, chapters: action.payload }
      };
    case Types.SET_MENU_ALLPAGES:
      return {
        ...state,
        menu: { ...state.menu, allPages: action.payload }
      };
    case Types.SET_MENU_ALLPAGES_YOMA:
      return {
        ...state,
        menuYoma: { ...state.menuYoma, allPages: action.payload }
      };
    case Types.CLOSE_MENU:
      return {
        ...state,
        menu: { ...state.menu, isOpen: false }
      };
    case Types.CLOSE_MENU_YOMA:
      return {
        ...state,
        menuYoma: { ...state.menuYoma, isOpen: false }
      };
    case Types.CLOSE_MENU1:
      return {
        ...state,
        menu1: { ...state.menu1, isOpen: false }
      };
    case Types.OPEN_MENU:
      return {
        ...state,
        menu: { ...state.menu, isOpen: true }
      };
    case Types.OPEN_MENU_YOMA:
      return {
        ...state,
        menuYoma: { ...state.menuYoma, isOpen: true }
      };
    case Types.OPEN_MENU1:
      return {
        ...state,
        menu1: { ...state.menu1, isOpen: true }
      };
    case Types.SET_CHAPTERS_VIEW:
      return {
        ...state,
        menu: { ...state.menu, chaptersView: action.payload }
      };
    case Types.SET_CHAPTERS_VIEW_YOMA:
      return {
        ...state,
        menuYoma: { ...state.menuYoma, chaptersView: action.payload }
      };
    case Types.OPEN_PID:
      return {
        ...state,
        pid: { ...state.pid, isOpen: true }
      };
    case Types.CLOSE_PID:
      return {
        ...state,
        pid: { ...state.pid, isOpen: false }
      };
    case Types.SET_ON_LINE_PARTY:
      return {
        ...state,
        onLineParty: { ...state.user, isOpen: action.payload }
      };
    case Types.OPEN_PID1:
      return {
        ...state,
        pid1: { ...state.pid1, isOpen: true }
      };
    case Types.CLOSE_PID1:
      return {
        ...state,
        pid1: { ...state.pid1, isOpen: false }
      };
    case Types.OPEN_PID2:
      return {
        ...state,
        pid2: { ...state.pid2, isOpen: true }
      };
    case Types.CLOSE_PID2:
      return {
        ...state,
        pid2: { ...state.pid2, isOpen: false }
      };
    case Types.SET_USER_PROFILE:
      return {
        ...state,
        isUserProfile: action.payload
      };
    case Types.SET_USER_PROFILE_OPEN:
      return {
        ...state,
        isUserProfileOpen: action.payload
      };
    case Types.SET_USER_NAME:
      return {
        ...state,
        user: { ...state.user, name: action.payload }
      };
    case Types.SET_USER_PAGE:
      return {
        ...state,
        user: { ...state.user, page: action.payload }
      };
    case Types.SET_USER_ID:
      return {
        ...state,
        user: { ...state.user, id: action.payload }
      };
    case Types.SET_USER_FOLLOWERS:
      return {
        ...state,
        user: { ...state.user, follow: action.payload }
      };
    case Types.SET_IS_BIYUR_SCROLLING:
      return {
        ...state,
        isBiyurScrolling: action.payload
      };
    case Types.SET_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case Types.SET_IS_PRIVETE:
      return {
        ...state,
        isPrivate: action.payload
      };
    case Types.SET_IS_MOBILE:
      return {
        ...state,
        isMobile: false
      };
    case Types.SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload
      };
    case Types.SET_NOTIFICATIONS_ALL:
      return {
        ...state,
        notificationsAll: action.payload
      };
    case Types.SET_NOTIFICATIONS_OPEN:
      return {
        ...state,
        notificationsOpen: action.payload
      };
    case Types.SET_EMAIL_OPEN:
      return {
        ...state,
        emailOpen: action.payload
      };
    case Types.SET_TOPICS_FOLLOW:
      return {
        ...state,
        topicsFollow: action.payload
      };
    case Types.SET_LOADED_HIDUSHIM:
      return {
        ...state,
        isLoaded1Hidush: action.payload
      };
    case Types.SET_LOADED_FEED:
      return {
        ...state,
        isLoaded1Feed: action.payload
      };
    case Types.SET_USER_PROFILE_VIEW:
      return {
        ...state,
        userProfileView: action.payload
      };
    case Types.SET_RTAILE:
      return {
        ...state,
        isTraile: action.payload
      };
    case Types.SET_BOOK_LIST_ALL:
      return {
        ...state,
        bookListAll: action.payload
      };
    case Types.PROJECT_NAME_SHAS_MEHULAK:
      return {
        ...state,
        projectNameShasmehulak: action.payload
      };
    case Types.SET_BOOK_YOMA:
      return {
        ...state,
        bookYoma: action.payload
      };
    case Types.SET_PAGE_YOMA:
      return {
        ...state,
        pageYoma: action.payload
      };
    case Types.SET_USER_YOMA:
      return {
        ...state,
        userNameYoma: action.payload
      };
    case Types.MY_TOPICS:
      return {
        ...state,
        myTopics: action.payload
      };
    case Types.SET_REGISTRATION:
      return {
        ...state,
        registration: action.payload
      };
    case Types.SET_CURRENT_EVENTS:
      return {
        ...state,
        currentEvents: action.payload
      };
    case Types.SET_TOPIC_UUID:
      return {
        ...state,
        statusUuid: action.payload
      };
    case Types.SET_USERS_FEED:
      return {
        ...state,
        usersFeed: action.payload
      };
    case Types.SET_RECENT_CHANGES:
      return {
        ...state,
        recentchanges: action.payload
      };
    case Types.SET_RESULTS_FROM_SEARCH:
      return {
        ...state,
        resultFromSearch: action.payload
      };
    case Types.SET_CONTENT_COUNTS_MENU:
      return {
        ...state,
        content_counts_menu: action.payload
      };
    case Types.SET_SECTION_NAMES:
      return {
        ...state,
        sectionNames: action.payload
      };
    case Types.SET_IS_OPEN_NAV_PAGE:
      return {
        ...state,
        isOpenNavPage: action.payload
      };
    case Types.SET_IS_OPEN_SEARCH:
      return {
        ...state,
        isOpenSearch: action.payload
      };
    case Types.CKEDITTOR_CONTENT:
      return {
        ...state,
        ckEdittorContent: action.payload
      };
    case Types.IS_ARTICLES:
      return {
        ...state,
        isArticles: action.payload
      };
    case Types.SET_ALL_USERS:
      return {
        ...state,
        allUsers: action.payload
      };
    case Types.SET_ALL_TOPICS:
      return {
        ...state,
        allTopics: action.payload
      };
    case Types.SET_ALL_TOPICS_IDS:
      return {
        ...state,
        allTopicsIds: action.payload
      };
    case Types.SET_IS_IFRAME:
      return {
        ...state,
        isIfame: action.payload
      };
    case Types.PAGE_HIDUSHIM:
      return {
        ...state,
        pageHidushim: action.payload
      };
    case Types.SET_COMPETITION:
      return {
        ...state,
        competition: action.payload
      };
    case Types.SET_IS_OPEN_TEMPLATES:
      return {
        ...state,
        isOpenTemplate: action.payload
      };

    default:
      return state;
  }
};

export default viewReducer;
