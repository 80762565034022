
import React, { useEffect, useState } from "react";
import config from "../../services/configService";
import { ReactComponent as LeftBakcSvg } from "../../svg/iconmonstr-arrow-right-thin.svg";
import { ReactComponent as Line } from "../../svg/line1.svg";
import "./OpenPid.css";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as MihlolLogo } from "../../svg/logoqq.svg";
import wikiService from "../../services/wikiService";
import { ReactComponent as SearchCircle } from "../../svg/searchCircle.svg";
import { ReactComponent as UserIcon } from "../../svg/userIcon.svg";
import { ReactComponent as UsersEmail } from "../../svg/usersEmail.svg";
import { ReactComponent as UsersEmailMobile } from "../../svg/UsersEmailMobile.svg";
import BlockAnnonimus from "../BlockAnnonimus/BlockAnnonimus";
import SendMail from "../SendMail/SendMail";
import {
  getIsPidOpen,
  getUserName,
  getUserid,
  getIsIfame
} from "../../store/selectors/selectors";
import CircularProgress from '@mui/material/CircularProgress';
import InfiniteScroll from 'react-infinite-scroll-component';
import isMobile from "ismobilejs";
import { setclosePid, setOpenPid1, setMyTopic, setIsIfame } from "../../store/actions/viewActions";
import { ThunkDispatch } from "@reduxjs/toolkit";
import history from "../../history/history";

const OpenPid = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const isPidOpen = useSelector(getIsPidOpen);
  const userName = useSelector(getUserName);
  const userid = useSelector(getUserid);
  const [allUsers, setAllUsers] = useState([]);
  const isIfame = useSelector(getIsIfame);
  const [continu, setContinu] = useState('');
  const [offset, setOffset] = useState(0);
  const [isEnd, setIsEnd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const MakeFollow = (isWatched: boolean, userNameT: string, ida: any, e: any) => {
    const classa = e.target.className;
    const isMobileA = (isMobile().phone || isMobile().tablet) ? '-mobile' : '';
    var par: any;
    if (classa === 'users-follow-text' + isMobileA) {
      wikiService.makeFollowing('yes', userNameT)
      e.target.className = 'users-follow-text-no';
      e.target.textContent = 'עוקב';
      par = document.getElementById(ida + 'par');
      if (par) {
        par.className = 'users-follow-no';
      }
    }
    if (classa === 'users-follow-text-no' + isMobileA) {
      wikiService.makeFollowing('no', userNameT)
      e.target.className = 'users-follow-text';
      e.target.textContent = 'לעקוב';
      par = document.getElementById(ida + 'par');
      if (par) {
        par.className = 'users-follow';
      }
    }



    var x = document.getElementById(ida);
    if (x && x.textContent) {
      if (classa === 'users-follow-text' + isMobileA) {
        let text = x.textContent.replace(' עוקבים', '');
        x.textContent = String(Number(text) + 1) + ' עוקבים';
      }
      else {
        if (classa !== 'users-follow-text' + isMobileA) {
          let text = x.textContent.replace(' עוקבים', '');
          x.textContent = String(Number(text) - 1) + ' עוקבים';
        }
      }
    }
  }

  const IsIfame = (userNaemL: any) => {
    dispatch(setIsIfame(userNaemL))
  }

  useEffect(() => {
    if (isPidOpen) {
      if (allUsers.length === 0 && continu !== 'dd') {
        setIsLoading(true)
        wikiService.AllUsers().then((resp) => {
          setOffset(200);
          if (resp.continue)
            setContinu(resp.continue.aufrom)
          setAllUsers(resp.allusers);
          setIsLoading(false)
        })
      }

    }
    const timer = setInterval(() => {
      if (!isEnd && isLoading)
        setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 40));
    }, 800);

    return () => {
      clearInterval(timer);
    };

  }, [isPidOpen, allUsers, dispatch, isEnd, isLoading, continu]);




  const getAllUsersTAllTime = () => {

    if (isPidOpen && continu !== 'dd') {
      setIsLoading(true)
      wikiService.AllUsers('', continu, offset).then((resp) => {

        if (resp.continue) {
          setContinu(resp.continue.aufrom);
          setOffset(offset + 200);
          if (allUsers) {
            //let users = allUsers + resp.allusers;
            const object3 = allUsers.concat(resp.allusers)

            setAllUsers(object3);
            setIsLoading(false)

          }
          else {
            setAllUsers(resp.allusers);
            setIsEnd(true)
            setIsLoading(false)
          }

        }
        else {

          const object3 = allUsers.concat(resp.allusers)

          setAllUsers(object3);
          setContinu('dd');
          setIsEnd(true)
          setIsLoading(false)
        }
        return;
      })
    }


  }

  const handleTogglePid = (id: any) => {
    dispatch(setMyTopic(id));
    dispatch(setOpenPid1());
  };

  const getAllUsersT = (e: any) => {
    if (!e)
      return (<></>)

    var byName = e.slice(0);

    return byName.map((x: any) =>
      <>{
        <div className={`pid ${userName === 0 || isIfame !== '' ? 'show-all-pid-open' : ``} ${(isMobile().phone || isMobile().tablet) ? 'pid-mobile' : ''}`}
          key={`${x.userid}`}
          onClick={() => {
            if ((userName === 0))
              dispatch(setclosePid())
          }}
        >
          <div className={(isMobile().phone || isMobile().tablet) ? 'bsic-mobile' : 'bsic'}
            id={`${x.userid} bsic`}
          >
            <div className={`menu-icon-r  ${(isMobile().phone || isMobile().tablet) ? 'menu-icon-r-mobile' : ''}`}>
              <UserIcon />
            </div>
            <div className='users-name-con'>
              <div className={`users-name  ${(isMobile().phone || isMobile().tablet) ? 'users-name-mobile' : ''}`}>
                {!x.name.includes('מתורתו') ? x.name.replace('הרב', '') : x.name}
              </div>
              {false && x && x.WatchedPage && (Number(x.WatchedPage) === 0) &&
                <div className={`users-followers ${(isMobile().phone || isMobile().tablet) ? 'users-followers-mobile' : ''}`} id={`${x.userid}`}>
                  {Number(x.countWatch.followers)} עוקבים
                </div>}

              {x &&
                Number(x.WatchedPage) !== 0 &&
                <div className='sjsjsj'>

                  {x && <div className={`users-followers ${(isMobile().phone || isMobile().tablet) ? 'users-followers-mobile' : ''}`}
                    onClick={() => {
                      if ((userName !== 0))
                        handleTogglePid(x.userid + '`' + x.name.replace('הרב', ''))
                    }}
                  ><div className="users-followers-text">
                      {Number(x.WatchedPage)} חידושים
                    </div>

                  </div>}
                  {false && !(isMobile().phone || isMobile().tablet) && <div className="users-book-page-header-down-line">
                    <Line />
                  </div>}
                  {false && (isMobile().phone || isMobile().tablet) && <div className="users-book-page-header-down-line-mobile">
                    <Line />
                  </div>}

                </div>
              }

            </div>
            {(isMobile().phone || isMobile().tablet) && <div>
              <div className='users-space'>
                {userName && x.isWatched && <div className={x.isWatched === "true" ? (!(isMobile().phone || isMobile().tablet) ? 'users-follow-no' : 'users-follow-no-mobile') : !(isMobile().phone || isMobile().tablet) ? 'users-follow' : 'users-follow-mobile'}
                  onClick={(e: any) => {
                    if ((userName !== 0)) {
                      MakeFollow(x.isWatched === "true", x.name, x.userid, e)
                      x.isWatched = x.isWatched === "true" ? 'false' : 'true'
                    }

                  }}
                >
                  {x.isWatched === "false" && <div className={!(isMobile().phone || isMobile().tablet) ? 'users-follow-text' : 'users-follow-text-mobile'}
                  >
                    {'לעקוב'}
                  </div>}
                  {x.isWatched === "true" && <div className={!(isMobile().phone || isMobile().tablet) ? 'users-follow-text-no' : 'users-follow-text-no-mobile'}
                  >
                    {'עוקב'}
                  </div>}
                </div>}
                {!userName && <div
                  className={x.isWatched === "true" ? (!(isMobile().phone || isMobile().tablet) ? 'users-follow-no' : 'users-follow-no-mobile') : (!(isMobile().phone || isMobile().tablet) ? 'users-follow' : 'users-follow-mobile')}
                  title='התחבר לחשבון כדי לעקוב'   >

                  <div className={!(isMobile().phone || isMobile().tablet) ? 'users-follow-text' : 'users-follow-text-mobile'}>
                    {'לעקוב'}
                  </div>
                </div>}
                {x.isWatched &&
                  <div className={`users-message ${(isMobile().phone || isMobile().tablet) ? 'users-message-mobile' : ''}`}
                    onClick={() => IsIfame(x.name)}
                  >
                    <div className={`users-email  ${(isMobile().phone || isMobile().tablet) ? 'users-email-mobile' : ''}`}>
                      {(isMobile().phone || isMobile().tablet) ?
                        <UsersEmailMobile /> :
                        <UsersEmail />}
                    </div>
                    <div className={`users-message-text ${(isMobile().phone || isMobile().tablet) ? 'users-message-text-mobile' : ''}`}>
                      {'שלח הודעה'}
                    </div>
                  </div>}
                {!x.isWatched &&
                  <div className={`users-message ${(isMobile().phone || isMobile().tablet) ? 'users-message-mobile' : ''}`}
                    title='התחבר לחשבון כדי לשלוח הודעות'
                  >
                    <div className={`users-email  ${(isMobile().phone || isMobile().tablet) ? 'users-email-mobile' : ''}`}>
                      <UsersEmail />
                    </div>
                    <div className={`users-message-text ${(isMobile().phone || isMobile().tablet) ? 'users-message-text-mobile' : ''}`}>
                      {'שלח הודעה'}
                    </div>
                  </div>
                }
              </div>

            </div>}
            {x.userid !== userid && !(isMobile().phone || isMobile().tablet) && <div className='users-space'>
              {x.isWatched &&
                <div className={`users-message ${(isMobile().phone || isMobile().tablet) ? 'users-message-mobile' : ''}`}
                  onClick={() => IsIfame(x.name)}
                >
                  <div className={`users-email  ${(isMobile().phone || isMobile().tablet) ? 'users-email-mobile' : ''}`}>
                    {(isMobile().phone || isMobile().tablet) ?
                      <UsersEmailMobile /> :
                      <UsersEmail />}
                  </div>
                  <div className={`users-message-text ${(isMobile().phone || isMobile().tablet) ? 'users-message-text-mobile' : ''}`}>
                    {'שלח הודעה'}
                  </div>
                </div>}
              {!x.isWatched &&
                <div className={`users-message ${(isMobile().phone || isMobile().tablet) ? 'users-message-mobile' : ''}`}
                  title='התחבר לחשבון כדי לשלוח הודעות'
                >
                  <div className={`users-email  ${(isMobile().phone || isMobile().tablet) ? 'users-email-mobile' : ''}`}>
                    <UsersEmail />
                  </div>
                  <div className={`users-message-text ${(isMobile().phone || isMobile().tablet) ? 'users-message-text-mobile' : ''}`}>
                    {'שלח הודעה'}
                  </div>
                </div>
              }
            </div>}
            {x.userid !== userid && !(isMobile().phone || isMobile().tablet) && userName !== 0 && x.isWatched && <div className={x.isWatched === "true" ? 'users-follow-no' : 'users-follow'}
              id={x.userid + 'par'}
            >
              {x.isWatched === "false" && <div className={'users-follow-text'}
                onClick={(e: any) => {
                  MakeFollow(x.isWatched === "true", x.name, x.userid, e)
                  x.isWatched = x.isWatched === "true" ? 'false' : 'true'
                }}
              >
                {'לעקוב'}
              </div>}
              {x.isWatched === "true" && <div className='users-follow-text-no'
                onClick={(e: any) => {
                  MakeFollow(x.isWatched === "true", x.name, x.userid, e)
                  x.isWatched = x.isWatched === "true" ? 'false' : 'true'
                }}
              >
                {'עוקב'}
              </div>}
            </div>}
            {!(isMobile().phone || isMobile().tablet) && !userName && <div
              className={x.isWatched === "true" ? 'users-follow-no' : 'users-follow'}
              title='התחבר לחשבון כדי לעקוב'   >

              <div className={'users-follow-text'}>
                {'לעקוב'}
              </div>
            </div>}
          </div>

        </div>}
      </>
    );
  }

  const sreach = (auprefix: any) => {
    if (isPidOpen) {
      wikiService.AllUsers(auprefix).then(
        (res) => {
          setIsLoading(false)
          setAllUsers(res.allusers);
          setContinu('dd');
          if (auprefix === '') {
            setContinu('');
          }
          setIsEnd(true)
          setIsLoading(true)
        }
      )
    }
  }
  const handleChange = (e: any) => {
    sreach(e.currentTarget.value);
  }


  const blockAnnonimus = () => {
    setTimeout(() => {
      return (
        <BlockAnnonimus text={`<b>התחבר או הרשם</b><br>
           על מנת לעקוב אחרי מחדשים יש להתחבר או להירשם`} />
      )
    }, 5000);
  }


  return (
    <div
      className={isPidOpen && !(isMobile().phone || isMobile().tablet) ? `header-openPid1 ` : isPidOpen && (isMobile().phone || isMobile().tablet) ? `header-openPidD` : "header-hidden"}
      id={"openPid"}
    >
      {isIfame !== '' && isPidOpen &&
        <SendMail user={window.location.origin === 'http://localhost:3000' ? 'Shitufta yosef' : isIfame}></SendMail>
      }

      <div className='onclick-close'
        onClick={() => {
          if (isIfame === '') {
            dispatch(setclosePid())
            history.push('?');
          }

        }} >
      </div>
    
      {false &&
        isIfame !== '' && isPidOpen &&
        <div>
          <div
            className='backHistory'
            onClick={() => IsIfame('')} ><LeftBakcSvg /></div>
        </div>
      }

      {//isIfame === '' &&
        isPidOpen &&
        <div className={`users-search ${userName === 0 || isIfame !== '' ? 'show-all-pid-open' : ``} ${(isMobile().phone || isMobile().tablet) ? 'users-search-mobile' : ''}`}>
          <input className={`users-search-input  ${(isMobile().phone || isMobile().tablet) ? 'users-search-input-mobile' : ''}`}
            placeholder="חפש מחדשים"
            maxLength={20}
            onChange={(e) => handleChange(e)}
            onClick={(e) => {
              if ((userName === 0))
                dispatch(setclosePid())
              else
                handleChange(e)
            }}
          >
          </input>
          <div className='users-search-circle'>
            <SearchCircle />
          </div>
        </div>}

      {
        <InfiniteScroll
          dataLength={allUsers && allUsers.length}
          hasMore={true}
          next={getAllUsersTAllTime}
          loader={
            <> <div className="loading-indicator-loadingsvg10" key={'g'}>
              {!isEnd && <CircularProgress color={"secondary"} thickness={1} size={"2rem"} variant="determinate" value={progress} />}
              {!isEnd && <MihlolLogo className="loading-indicator-gmara" />}
            </div></>
          }
          endMessage={<div>יש את כולם</div>}
          scrollableTarget="openPid"
        >
          <div className="openPid-scroll">
            {//isIfame!==''&&
              getAllUsersT(allUsers)}
          </div>
        </InfiniteScroll>}


    </div >
  );
};

export default OpenPid;
