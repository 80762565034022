import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as MihlolLogo } from "../../svg/logoqq.svg";
import CircularProgress from '@mui/material/CircularProgress';
import "./Search.css";
import { ReactComponent as Sfarim } from "../../svg/sfarimResult.svg";
import SearchIcon from '@mui/icons-material/Search';
import InfiniteScroll from 'react-infinite-scroll-component';
import SearchService from "../../services/searchServer";
import { ReactComponent as SearchCircle } from "../../svg/searchCircle.svg";
import isMobile from "ismobilejs";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import {
    getPageData,
} from "../../store/actions/pageActions";
import { aspeklarya } from "../../utils/menuUtils";

import {
    getIsOpenSearch,
} from "../../store/selectors/selectors";
import config from "../../services/configService";
import wikiService from "../../services/wikiService";
const Search: React.FC = () => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const isOpenSearch = useSelector(getIsOpenSearch);
    const [start, setStart] = useState(0);
    const [isEnd, setIsEnd] = useState(true);
    const [typeSearch, setTypeSearch] = useState(1);
    const [progress, setProgress] = useState(0);
    const [total, setTotal] = useState(-1);
    const [total2, setTotal2] = useState(-1);
    const [valueSearch, setValueSearch] = useState('');
    const [valueSearchComletion, setValueSearchComletion] = useState('');
    const [isOpenCompetion, setIsOpenCompetion] = useState(false);
    const [valueSearchList, setValueSearchList] = useState([]);
    const [resultFromSearchLocaHidushim, setResultFromSearchHidushim] = useState([]);
    const [resultFromSearchLoca, setResultFromSearchLoca] = useState([]);
    const [shouldFocus, setShouldFocus] = useState(true);
    const [isRef, setIsRef] = useState(false);
    const [ref, setRef] = useState({ book: '', page: '' });
    const inputRef = useRef<HTMLInputElement>(null);
    let removeFromData: any = config.sfarimToAlwaysRemove;
    const addToStringIfEndsWithNumber = (inputString: string, stringToAdd: string): string => {
        if (inputString && /\d$/.test(inputString)) {
            return inputString + stringToAdd;
        } else {
            return inputString;
        }
    }

    useEffect(() => {
        if (shouldFocus && inputRef && inputRef.current) {
            inputRef.current.focus();
        }
    }, [shouldFocus]);

    const onChangeValue = (e: any) => {
        if (e.target.value.trim() && e.target.value.trim() !== '') {
            setIsOpenCompetion(true)
            fetch(`${config.shitufta_api_url}name/${e.target.value.trim()}`)
                .then((response) => response.json())
                .then((json) => {

                    const result = json.completion_objects
                        .filter((item: any) => {
                            return json.lang === 'he' && item && item.type
                        })
                    if (json.is_ref) {
                        setIsRef(true)

                        setRef({ book: json.book, page: json.sections[0] })
                    }
                    else {
                        setIsRef(false)

                        setRef({ book: '', page: '' })
                    }
                    setValueSearchList(result)
                })
        }
        else {
            setValueSearchList([])
        }
        setValueSearch(e.target.value.trim())
    }
    useEffect(() => {
        if (window.location.search) {
            setValueSearch(window.location.search.split('?q=')[1]);
            sreach()
        }
        const timer = setInterval(() => {
            if (!isEnd && valueSearch !== '') {
                setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
            }
        }, 300);



        return () => {
            if (!isEnd && valueSearch !== '')
                clearInterval(timer);
        };

    }, [resultFromSearchLoca, resultFromSearchLocaHidushim]);

    const sreach = (auprefix = '') => {
        if (valueSearch) {
            wikiService.getViewTopic(1, '', [], valueSearch).then(
                (response) => {
                    // dispatch(setAllTopicsIds(response?.items2))
                    let newArray: any = [];
                    for (const prop in response?.workflowId) {
                        if (prop !== 'undefined') {
                            newArray.push(response?.workflowId[prop][0])
                        }
                    }

                    setResultFromSearchHidushim(newArray)
                }
            )
        }
    }


    const onSubmitValue = (item = '', typeSearchL = -1) => {
        wikiService.getViewTopicCount(valueSearch).then(response => {
            setTotal2(response)
        })
        setIsOpenCompetion(false);
        //sectionNames
        if (valueSearch !== '') {
            setIsEnd(false);
            // dispatch(setResultFromSearch([]));
            if ((typeSearchL === 2) || (typeSearchL === -1 && typeSearch === 2)) {
                sreach()
                setIsEnd(true);
            }
            else if ((typeSearchL === 1) || (typeSearchL === -1 && typeSearch === 1))
                SearchService.callAxios(item === '' ? valueSearch : item, 0).then(response => {
                    const hits = response.hits.hits.filter((v: any, i: any, a: any) => a.findIndex((v2: any) => (v2["_source"].ref === v["_source"].ref)) === i && v["_source"].lang === 'he')

                    setTotal(response.hits.total.value)
                    setResultFromSearchLoca(hits);

                    setIsEnd(true);
                })

        }

    }

    const getResutlt = () => {

        if (typeSearch === 1)
            return resultFromSearchLoca.map((itemRes: any, index: any) => {
                var ref = itemRes['_source'].ref;
                var lastIndex = ref.lastIndexOf(" ");
                var refBook = ref.substring(0, lastIndex);
                var refPage = ref.substring(lastIndex + 1, ref.length);


                if (!removeFromData.some((r: any) => refBook.includes(r) || r.includes(refBook))) {

                    return (
                        <div className="search-main-item">
                            <div>
                                <div className={`search-line`} />
                                <a href={`/${refBook}/${refPage.split(':')[0]}`}>
                                    <div className={`search-result-title`}>
                                        {itemRes["_source"]["heRef"].replace('שטיינזלץ ', '')}
                                    </div>
                                </a>

                                <div className={`search-line`} />
                                <div className={`search-result-body`}
                                    dangerouslySetInnerHTML={{
                                        __html: ` ${itemRes.highlight.naive_lemmatizer[0].split('<b>').join('<span style="background-color:#B0D1D0;">').split('</b>').join('</span>')}`
                                    }}
                                />

                            </div>

                        </div>)
                }

            })
        if (typeSearch === 2) {
            return resultFromSearchLocaHidushim.map((itemRes: any) => {


                const revisions = itemRes.revisions[itemRes.posts[itemRes.roots]];
                const name = revisions.creator.name;
                const content = revisions.content.plaintext;
                const title = revisions.Title_Parent;


                return (
                    <div className="search-main-item">
                        <div>
                            <div className={`search-line`} />
                            <div className={`search-result-title-main`}>
                                <a href={window.location.origin + '/statusUuid/' + itemRes.workflowId}>
                                    <div className={`search-result-title`}>
                                        {title.split(`~`)[0].split('_').join(' ').replace(/\d+/g, '')}
                                    </div>
                                </a>

                                <div className={`search-result-title-2`}>
                                    <div className={`search-result-title-2-text`}>
                                        {name}
                                    </div>
                                </div>
                            </div>

                            <div className={`search-line`} />
                            <div className={`search-result-body`}
                                dangerouslySetInnerHTML={{
                                    __html: content.split(valueSearch).join(`<span style="background-color:#B0D1D0;">${valueSearch}</span>`)
                                }}
                            />

                        </div>

                    </div>)
            })
        }

    }

    const getAddResutlt = () => {
        wikiService.getViewTopicCount(valueSearch).then(response => {
            setTotal2(response)
        })
        setIsEnd(false);

        if (typeSearch === 1) {
            setStart(start + 50);
            SearchService.callAxios(valueSearch, start).then(response => {
                const hits = response.hits.hits.filter((v: any, i: any, a: any) => a.findIndex((v2: any) => (v2["_source"].ref === v["_source"].ref)) === i && v["_source"].lang === 'he')

                setTotal(response.hits.total.value)
                setResultFromSearchLoca(resultFromSearchLoca.concat(hits));
                setIsEnd(true);
            })
        }

        else if (typeSearch === 2) {

            wikiService.getViewTopic(start + 15, '', [], valueSearch).then(
                (response) => {
                    let newArray: any = [];
                    for (const prop in response?.workflowId) {
                        if (prop !== 'undefined') {
                            newArray.push(response?.workflowId[prop][0])
                        }
                    }

                    setResultFromSearchHidushim(resultFromSearchLocaHidushim.concat(newArray));
                    setIsEnd(true);
                    setStart(start + 15);
                }
            )
        }
    }


    const containsOnlyHebrewLetters = (str: any) => {
        // The regular expression for Hebrew letters
        const hebrewLettersRegex = /^[\u0590-\u05FF\s]+$/;

        return hebrewLettersRegex.test(str);
    }

    return (<>
        {!isOpenSearch && <div
            id="search-main-list"
            className="search-main-list">
            <div className="search-search-main-top">
                <div className='search-search-main'>
                    <div className='search-search-main-back'>
                    </div>
                </div>
                <div className='search-search-main'>
                    {!(isMobile().phone || isMobile().tablet) &&
                        <div className='search-search'>
                            <div className="search-input-main">
                                <input className='search-search-input'
                                    placeholder="חיפוש"
                                    ref={inputRef}
                                    autoComplete={'off'}
                                    maxLength={50}
                                    type="search"
                                    onChange={onChangeValue}
                                    onKeyPress={event => {
                                        if (event.key === 'Enter') {
                                            setValueSearchComletion(valueSearch)
                                            onSubmitValue()
                                        }
                                    }}
                                >
                                </input>
                                <div className='search-circle' onClick={() => onSubmitValue} >
                                    <SearchCircle />
                                </div>
                            </div>

                            <div className="result-list">
                                {isRef &&
                                    <div className="result-list-item"
                                        onClick={() => {
                                            dispatch(getPageData(ref.book.split(' ').join('_'), ref.page ? ref.page : '~'));
                                        }}
                                    >
                                        <div className="result-list-item-text">
                                            {
                                                <Sfarim
                                                    style={{ color: '#dbdbdb', top: 5, position: 'relative' }}
                                                />}
                                            {` עבור ל${valueSearch}`}
                                        </div>
                                    </div>
                                }
                                {valueSearchList.length > 0 && isOpenCompetion && valueSearchList.map((item: any) => {
                                    if (containsOnlyHebrewLetters(item.title) && item.type !== 'AuthorTopic' && !aspeklarya.some((r: any) => r.includes(item.title) || item.title.includes(r)))
                                        return (
                                            <div className="result-list-item"
                                                onClick={() => {
                                                    if (item.type === 'ref') {

                                                        dispatch(getPageData(item.key.split(' ').join('_'), '~'));
                                                    }
                                                    else {
                                                        setValueSearchComletion(item.title)
                                                        onSubmitValue(item.title)
                                                    }
                                                }}
                                            >
                                                <div className="result-list-item-text">
                                                    {item.type === 'ref' &&
                                                        <Sfarim
                                                            style={{ color: '#dbdbdb', top: 5, position: 'relative' }}
                                                        />}
                                                    {item.type !== 'ref' &&
                                                        <SearchIcon
                                                            style={{ color: '#dbdbdb', top: 5, position: 'relative' }}
                                                        />} {item.title}
                                                </div>
                                            </div>)
                                })}
                            </div>
                        </div>}
                </div>


                {valueSearch !== '' && total !== -1 && <div className="search-total-results-main">
                    <div className="search-total-results">
                        {'מציג תוצאות עבור: '}
                        <span className="search-total-results-valueSearch"
                            onClick={() => {

                                onSubmitValue(valueSearchComletion)
                            }}
                        >{valueSearchComletion}

                        </span>
                        {valueSearchComletion !== valueSearch && <span
                            onClick={() => {
                                setValueSearchComletion(valueSearch)
                                onSubmitValue(valueSearch)
                            }}
                        >    חפש תוצאות עבור: </span>}
                        {valueSearchComletion !== valueSearch && <span
                            onClick={() => {
                                setValueSearchComletion(valueSearch)
                                onSubmitValue(valueSearch)
                            }}
                            className="search-total-results-valueSearch">{valueSearch}

                        </span>}
                    </div>
                </div>}
                {valueSearch !== '' && total !== -1 &&
                    <div className="search-total-results-main">
                        {
                            <div className="search-total-results-number-main">
                                <div
                                    className={`search-total-results-number ${typeSearch === 1 ? 'border-bottom' : 'top'}`}
                                    onClick={() => {
                                        setTypeSearch(1)
                                        if (valueSearchComletion !== '')
                                            onSubmitValue(valueSearchComletion, 1)
                                        else if (valueSearchComletion === '')
                                            onSubmitValue(valueSearch, 1)

                                    }}
                                >  ספרים
                                    <span className="search-total-results-number-res"> ({total} תוצאות)</span>
                                </div>
                                <div
                                    onClick={() => {
                                        setTypeSearch(2)
                                        if (valueSearchComletion !== '')
                                            onSubmitValue(valueSearchComletion, 2)
                                        else if (valueSearchComletion === '')
                                            onSubmitValue(valueSearch, 2)

                                    }}
                                    className={`search-total-results-number-2 ${typeSearch === 2 ? 'border-bottom' : 'top'} `}>
                                    {'חידושים  '}
                                    <span className="search-total-results-number-res">
                                        ({total2} תוצאות)
                                    </span>
                                </div>
                            </div>
                        }
                    </div>}
                <div className="search-main-item-list">
                    {<InfiniteScroll
                        dataLength={typeSearch === 1 ? resultFromSearchLoca.length + 10 : resultFromSearchLocaHidushim.length + 10}
                        hasMore={true}
                        next={getAddResutlt}

                        loader={
                            <> {!isEnd && <div className="loading-indicator-loadingsvg10" key={'g'}>
                                {!isEnd && <CircularProgress color={"secondary"} thickness={1} size={"2rem"} variant="determinate" value={progress} />}
                                {!isEnd && <MihlolLogo className="loading-indicator-gmara" />}
                            </div>}</>
                        }
                        scrollableTarget="search-main-item-list"
                    >
                        {getResutlt()}
                    </InfiniteScroll>}
                </div>
            </div>

        </div>}
    </>
    )
}

export default Search;