import React, { useEffect, useState } from "react";
import HeaderNav from "../HeaderNav/HeaderNav";

import Sfarim from "../Sfarim/Sfarim";
import Hidushim from "../Hidushim/Hidushim";
import CircularProgress from '@mui/material/CircularProgress';
import "./LoadingIndicator.css";
import { ReactComponent as MihlolLogo } from "../../svg/logoqq2.svg";
import { getIsLoading } from "../../store/selectors/selectors";
import { useSelector } from "react-redux";
import isMobile from "ismobilejs";
const LoadingIndicator = () => {
  const [progress, setProgress] = useState(0);
  const isLoading = useSelector(getIsLoading);

  useEffect(() => {
    //disable page form extra scroll on loading
    if (isLoading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    const timer = setInterval(() => {
      if(isLoading)
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 20));
    }, 300);
    return () => {
      clearInterval(timer);
    };
  }, [isLoading, progress]);

  return (
    <React.Fragment>
      {isLoading && (
        <div className="loading-wrapper">
          <div className="loading-indicator-loadingsvg">
            <CircularProgress color={"secondary"} thickness={1} size={"4rem"} variant="determinate" value={progress} />
            <MihlolLogo className="loading-indicator-gmara" />
          </div>
          <div className="loading-indicator-container">
            <HeaderNav />
            <div className="book-page-full-container">
              {!(isMobile().phone || isMobile().tablet) && <div className="panel-right-side-container">
                <Sfarim />
              </div>}
              <div className="loading-indicator-middle-container"></div>
              {!(isMobile().phone || isMobile().tablet) && <div className="panel-left-side-container">
                <Hidushim />
              </div>}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default LoadingIndicator;
