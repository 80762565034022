
import React, { useState } from "react";
import "./OnLineParty.css";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as UserIcon } from "../../svg/userIcon.svg";
import isMobile from "ismobilejs";
import { setclosePid, setIsOnLineParty, openMenuYoma } from "../../store/actions/viewActions";
import {
  getIsOnLinePartyOpen
} from "../../store/selectors/selectors";
import history from "../../history/history";
import { ThunkDispatch } from "@reduxjs/toolkit";

const OnLineParty = () => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const isOnLinePartyOpen = useSelector(getIsOnLinePartyOpen);
 
  const allUsers = useState(
    [
      { userid: 'ש"ס מחולק לע"נ קדושי מירון', remaining: 0 },
      { userid: 'ש"ס מחולק לע"נ אברהם בן ר\' משה', remaining: 950 },
      { userid: 'לע"נ ההרוגים בקרלין', remaining: 11 },
      { userid: 'לע"נ ר\' דב בעריש בן ר\' ישראל צבי', remaining: '200' },
    ]
  );

  const getAllUsersT = () => {
    allUsers.slice(0);

    return allUsers.map(
      (x: any) =>
        <>
          {
            <div className={`pid`} key={`${x.userid}`}>
              <div className={'bsic'}
                id={`${x.userid} bsic`}>
                <div className={`menu-icon-r`}>
                  <UserIcon />
                </div>
                <div className='users-name-con'>
                  <div className={`users-name`}>
                    {x.userid}
                  </div>
                  <div className={`users-followers ${(isMobile().phone || isMobile().tablet) ? 'users-followers-mobile' : ''}`}
                  >{'נשארו '}
                    {x.remaining} {' דפים'}
                  </div>
                </div>
                {<div className={'users-follow-no-on-line'}
                  onClick={() => {
                    dispatch(setIsOnLineParty(false))
                    dispatch(openMenuYoma())

                    history.push(`/shasMachulak/${x.userid.split(' ').join('_')}`);
                  }}>
                  {
                    <div className={!(isMobile().phone || isMobile().tablet) ? 'users-follow-text-no' : 'users-follow-text-no-mobile'}>
                      {'בחר דף'}
                    </div>
                  }
                </div>}
              </div>
            </div>}

        </>
    );
  }






  return (<>
    {isOnLinePartyOpen && <div
      className={isOnLinePartyOpen && !(isMobile().phone || isMobile().tablet) ? `header-openPid1` : isOnLinePartyOpen && (isMobile().phone || isMobile().tablet) ? `header-openPidD` : "header-hidden"}
      id={"openPid"} >
      <div className='onclick-close'
        onClick={() => {
          dispatch(setclosePid())
        }} >
      </div>

      {isOnLinePartyOpen && <div id={'AllUsers'} className='ssssss'>
        {getAllUsersT()}
        {
          <div className={`pid1`} key={11}>
            <div className={'bsic1'}
              id={`${1} bsic`}>
              <div className={`menu-icon-r`}>
                <UserIcon />
              </div>
              <div className='users-name-con'>
                <div className={`on-line-party-users-name`}>
                  <input
                    name="extraBooks"
                    className="form-page-connect-first-name-input"
                    type="text"
           
                    placeholder='שם הקמפיין'
                  />
                </div>

              </div>
              {<div className={'on-line-party-follow-no-on-line'}
                onClick={() => {
                  dispatch(setIsOnLineParty(false))
                  dispatch(openMenuYoma())
                }}>
                {
                  <div className={!(isMobile().phone || isMobile().tablet) ? 'on-line-party-follow-no-on-line-text' : 'users-follow-text-no-mobile'}>
                    {'הוסף קמפיין'}
                  </div>
                }
              </div>}
            </div>
          </div>}
      </div>}



    </div>}
  </>
  );
};

export default OnLineParty;
