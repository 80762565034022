import React, { useEffect, useState } from "react";
import { Breadcrumbs } from '@mui/material';
import Select from "react-select";
import "./OpenNavYoma.css";
import { ReactComponent as CloseSign } from "../../svg/group-11.svg";
import { ReactComponent as BookUserHidush } from "../../svg/bookUserHidushBig.svg";
import { ReactComponent as BookUserHidushSmall } from "../../svg/bookUserHidushSmall.svg";
import { ReactComponent as Kaniyevski } from "../../svg/kaniyevski.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  changeMenuViewYoma,
  setMenuBookYoma,
  setMenuChaptersViewYoma,
  setUserProfileView,
  setIsUserProfile,
  setBookYoma,
  setPageYoma,
  setIsOnLineParty
} from "../../store/actions/viewActions";
import { parseBookPageToHebrew } from "../../utils/menuUtils";
import { hebDec } from "../../utils/menuUtils";
import {
  getAllBooks,
  getBookName,
  getCurrentBook,
  getIsMenuOpenYoma,
  getMenuAllPagesYoma,
  getMenuChaptersYoma,
  getMenuChaptersViewYoma,
  getMenuViewYoma,
  getPageId,
  getUserid,
  getUserName,
  getBookListAll,
  getProjectNameShasmehulak,
  getBookYoma,
  getCompetition
} from "../../store/selectors/selectors";
import isMobile from "ismobilejs";
import wikiService from "../../services/wikiService";
import history from "../../history/history";
import config from "../../services/configService";
import { ThunkDispatch } from "@reduxjs/toolkit";

const OpenNavYoma = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const isMenuOpenYoma = useSelector(getIsMenuOpenYoma);
  const menuView = useSelector(getMenuViewYoma);
  const allBooksNames = useSelector(getAllBooks);
  const pageId = useSelector(getPageId);
  const currentBook = useSelector(getCurrentBook);
  const currentHeBook = useSelector(getBookName);
  const chosenBookChapters = useSelector(getMenuChaptersYoma);
  const chosenBookAllPages = useSelector(getMenuAllPagesYoma);
  const menuChaptersView = useSelector(getMenuChaptersViewYoma);
  const bookListAll = useSelector(getBookListAll);
  const projectNameShasmehulak = useSelector(getProjectNameShasmehulak);
  const bookYoma = useSelector(getBookYoma);
  const competition = useSelector(getCompetition);
  let countall = 0
  bookListAll.forEach((item: any) => {

    countall += Number(item.count);
  }
  )

  const [Hebook, setHeBook] = useState('');
  const [Book, setBook] = useState('');
  const [closeBunner, setCloseBunner] = useState(true);
  const [bookList, setBookList] = useState([]);
  const userId = useSelector(getUserid);
  const userName = useSelector(getUserName);


  const handleViewChange = (view: string, render = '') => {

    dispatch(changeMenuViewYoma(view));
  };


  const handleChooseBook = (heBook: string, bookName: string, bookChosen: any, seder: any = '') => {
    dispatch(setMenuBookYoma(heBook, bookName, bookChosen, seder, projectNameShasmehulak));
    handleViewChange(bookChosen);
  };


  const checkViewAllbooksOrBookChose = () => {
    if (menuView === "allbooks" || menuView === "bookChosen") {
      return true;
    } else return false;
  };

  const handleSelectPage = (bookId: string) => {
    if (userId === 0) {
      const element = document.getElementById(bookId + 'p');
      if (element) {

        if (element.innerHTML.trim() === 'בחר') {

          dispatch(setIsOnLineParty(false))
          dispatch(setPageYoma(bookId));
          dispatch(setUserProfileView('FormPageCreateYoma'));
          dispatch(setIsUserProfile(true));

        }
        else if (element.innerHTML.trim() !== 'בחר') {
          let original = element.innerHTML.trim();
          element.innerHTML = 'הדף תפוס';
          setTimeout(() => {
            element.innerHTML = original
          }, 1500);
        }

      }
      return;
    }


    wikiService.setPageToLearn(userName, bookYoma, bookId, projectNameShasmehulak).then(function (result) {

      const element = document.getElementById(bookId + 'p');
      if (result.setPageToLearn === '') {
        if (element) {
          element.innerHTML = userName;
        }
      }
      else if (result.setPageToLearn === 'page is exist') {
        if (element) {
          let original = element.innerHTML.trim();
          element.innerHTML = 'הדף תפוס';
          setTimeout(() => {
            element.innerHTML = original
          }, 1500);
        }
      }
      else if (result.setPageToLearn === 'page is DELETE') {
        if (element) {

          element.innerHTML = 'הדף כבר לא תפוס';
          setTimeout(() => {
            element.innerHTML = 'בחר'
          }, 1500);
        }
      }

      if (element && result.error) {
        element.innerHTML = 'הפעולה נכשלה אנא דווח';
        setTimeout(() => {
          element.innerHTML = 'בחר'
        }, 1500);
      }
    }).catch(function (result) {

    });;

  };



  useEffect(() => {
    if (config.projectNameShasmehulak.includes(projectNameShasmehulak) && isMenuOpenYoma && menuView === 'basic') {
      let view = 'allbooks';
      dispatch(setMenuBookYoma(Hebook, Book, view, '', projectNameShasmehulak));
    }
    else if (config.projectNameShasmehulak.includes(projectNameShasmehulak) && isMenuOpenYoma && menuView !== 'basic') {

      dispatch(setMenuBookYoma(Hebook, Book, menuView, '', projectNameShasmehulak));
    }

  }, [isMenuOpenYoma, currentBook, currentHeBook, dispatch, projectNameShasmehulak]);

  const handleDropDownMenuClick = (e: any) => {
    dispatch(setMenuChaptersViewYoma(e));
  };



  const getChapterPages = () => {

    const filterdBook = chosenBookChapters.filter((chapter: any) => {
      return chapter.chapterName === menuChaptersView;
    });
    if (filterdBook && filterdBook[0]) {


      return filterdBook[0].chapters.map((page: any) => {
        return (
          <div
            className={
              page === pageId
                ? "openNav-book-item-yoma-1 bookChosen-current-item"
                : "openNav-book-item-yoma-1"
            }
            key={page}
            onClick={() => handleSelectPage(page)}
          >
            <p className={`bookChosen-page-item-text-3 ${eeeee1(page) !== '' ? 'color' : ''}`}>
              {page.split(' ')[1].split(':')[0].replace(/[^0-9]/g, '')}
              {page / 100 >= 1 && 'ק'}
              {hebDec[page % 100]}
              <div id={page + 'p'} style={{ fontSize: 12, color: '#151515' }}> {bookList.length > 0 &&
                eeeee1(page)
              }
              </div >
            </p>
          </div>
        );
      });
    } else return null;
  };

  const eeeee = (page: any) => {
    let name = '';
    bookList.forEach(item => {
      const pageName: string = item['pageName'] ? item['pageName'] : '';

      if (pageName && pageName.split('_').join(' ') === page.split('_').join(' ')) {
        name = item['user_name']
      }
    })
    return name;
  }
  const eeeee1 = (page: any) => {
    let name = '';

    bookList.forEach(item => {
      if (item['pageName'] === page) {
        name = item['user_name']

      }
    })
    return name;
  }

  const getClassYoma = () => {

    if (isMenuOpenYoma) {
      if (!(isMobile().phone || isMobile().tablet)) {
        if (pageId)
          return `header-openNav`

        return `header-openNav-yoma`
      }
      else {
        return `header-openNavD`
      }
    }
    else {
      return "header-hidden"
    }

  }


  function isCherries(fruit: any) {

    const temp = bookListAll.find((x: any) => x.bookNmae === fruit)

    if (temp)
      return Number(temp.count)
    else
      return 0;



  }

  return (
    <div
      className={getClassYoma()}
    >
      {userId === 0 && competition === null && closeBunner && <div className={(isMobile().phone || isMobile().tablet) ? 'openNav-main-advertising-yoma-mobile' : "openNav-main-advertising-yoma"}>
        <div className="close-user-svg-container-yoma">
          <CloseSign
            className="close-user-svg"
            onClick={() => {
              setCloseBunner(!closeBunner)
            }}
          />
        </div>
        <br />
        <div className="openNav-main-advertising-yoma-text-b">{`מהו שיתופתא?`}</div><br />
        <div className="openNav-main-advertising-yoma-text">{`שיתופתא הוא מאגר חידושי תורה בו כל בן תורה יכול לכתוב את חידושיו על הסדר, והם נשמרים ומוצגים על הדף בשם אומרם. שיתופתא היא מערכת לפרסום חידושי תורה, הערות, מ"מ, סיכומים ופירושים. מהיום אין צורך להמתין עד שתוציא לאור ספר מוגמר, כבר עכשיו תוכל לפרסם את חידושי התורה שלך.`}</div><br />
        <div className="openNav-main-advertising-yoma-text">{`הצטרף לאלפי לומדי שיתופתא והוסף את חלקך במאגר חידושי התורה של כלל ישראל מכל קצוות העולם!`}</div><br />
        <div className='form-page-connect-yoma-bunner'
          onClick={() => {
            dispatch(setUserProfileView("FormPageCreate"));
            dispatch(setIsUserProfile(true));
          }}
        >
          <div className='form-page-connect-text-yoma-bunner'>
            {'הירשם עכשיו!'}
          </div>
        </div>
        <div className="openNav-main-advertising-yoma-text-2">{`שיתופתא הוקם בברכתו של מרן שר התורה זצוק"ל והביע קורת רוח רבה מהמערכת להפצת תורה ולזיכוי הרבים.`}</div><br />

        <Kaniyevski className='openNav-main-advertising-yoma-icon' />
      </div>}
      {<div className={` ${(isMobile().phone || isMobile().tablet) ? 'Breadcrumbs-yoma-mobile' : 'Breadcrumbs-yoma'}`}>
        <Breadcrumbs aria-label="breadcrumb" >

          {checkViewAllbooksOrBookChose() && (
            <div
              className="openNav-top-item-background openNav-talmud"
              onClick={() => {
                history.push(`${window.location.origin}/${projectNameShasmehulak}`);
                //history.push(projectNameShasmehulak);
                handleViewChange("allbooks")
              }}
            >
              תלמוד בבלי
            </div>
          )}
          {(menuView.includes('bookChosen')) && (
            <div className="openNav-top-item-background openNav-talmud">
              {Hebook}
            </div>
          )}
        </Breadcrumbs>
      </div>}
      {config.projectNameShasmehulak.includes(projectNameShasmehulak) && !(isMobile().phone || isMobile().tablet) && <div className='openNav-allbooks-Chosen-masechet-icon'>
        <BookUserHidush />
        {<span className="openNav-allbooks-Chosen-masechet-icon-text">
          {projectNameShasmehulak !== 'ironSwords' && <div className="openNav-allbooks-Chosen-masechet-icon-text-big">{`סיום הש"ס`}</div>}
          {projectNameShasmehulak === 'ironSwords' && <div className="openNav-allbooks-Chosen-masechet-icon-text-big-ironSwords">{`לימוד הש"ס - עמוד האש`}</div>}

          {projectNameShasmehulak === 'Shechter' && <div className="openNav-allbooks-Chosen-masechet-icon-text-small">{`לע"נ מרת נחמה תמר שחטר ע"ה`}</div>}
          {projectNameShasmehulak === 'Shechter' && <div className="openNav-allbooks-Chosen-masechet-icon-text-small">{`בת ראש הישיבה הרב נחום בויאר שיבדלחט"א`}</div>}
          {projectNameShasmehulak === 'Shechter' && <div className="openNav-allbooks-Chosen-masechet-icon-text-small">{'שנפטרה בדמי ימיה בלא שהותירה אחריה זש"ק'}</div>}
          {projectNameShasmehulak === 'ironSwords' && <div className="openNav-allbooks-Chosen-masechet-icon-text-small-ironSwords">{`כל הש"ס כל יום עד להחזרת החטופים`}</div>}
          {projectNameShasmehulak === 'ironSwords' && <div className="openNav-allbooks-Chosen-masechet-icon-text-small-ironSwords-2">{`אחינו הנתונים בשביה המקום ירחם עליהם ויוציאם השתא בעגלא ובזמן קריב`}</div>}
          {projectNameShasmehulak === 'cohen' && <div className="openNav-allbooks-Chosen-masechet-icon-text-big-2">{`לע"נ מרן חכם שלום כהן בן תופחא מלכה זצוק"ל`}</div>}
          {projectNameShasmehulak === 'cohen' && <div className="openNav-allbooks-Chosen-masechet-icon-text-small">{`ראש ישיבת פורת יוסף ונשיא מועצת חכמי התורה`}</div>}
        </span>}
      </div>}
      {!(isMobile().phone || isMobile().tablet) && <div className={'openNav-allbooks-Chosen-masechet-total'}>{`עד כה נלמדו ${countall} עמודים מתוך ${'4125'}`}</div>}
      {config.projectNameShasmehulak.includes(projectNameShasmehulak) && (isMobile().phone || isMobile().tablet) && <div className='openNav-allbooks-Chosen-masechet-icon'>
        <BookUserHidushSmall />
        <span className="openNav-allbooks-Chosen-masechet-icon-text-mobile">
          <div className="openNav-allbooks-Chosen-masechet-icon-text-big-mobile">{`סיום הש"ס`}</div>
          {projectNameShasmehulak === 'Shechter' && <div className="openNav-allbooks-Chosen-masechet-icon-text-small-mobile">{`לע"נ מרת נחמה תמר שחטר ע"ה`}</div>}
          {projectNameShasmehulak === 'Shechter' && <div className="openNav-allbooks-Chosen-masechet-icon-text-small-mobile">{`בת ראש הישיבה הרב נחום בויאר שיבדלחט"א`}</div>}
          {projectNameShasmehulak === 'Shechter' && <div className="openNav-allbooks-Chosen-masechet-icon-text-big-2-mobile">{'שנפטרה בדמי ימיה בלא שהותירה אחריה זש"ק'}</div>}
          {projectNameShasmehulak === 'ironSwords' && <div className="openNav-allbooks-Chosen-masechet-icon-text-small-mobile">{`כל הש"ס כל יום עד להחזרת החטופים`}</div>}
          {projectNameShasmehulak === 'ironSwords' && <div className="openNav-allbooks-Chosen-masechet-icon-text-small-mobile">{`אחינו הנתונים בשביה המקום ירחם עליהם ויוציאם השתא בעגלא ובזמן קריב`}</div>}
          {projectNameShasmehulak === 'cohen' && <div className="openNav-allbooks-Chosen-masechet-icon-text-big-2-mobile">{`לע"נ מרן חכם שלום כהן בן תופחא מלכה זצוק"ל`}</div>}
          {projectNameShasmehulak === 'cohen' && <div className="openNav-allbooks-Chosen-masechet-icon-text-big-2-mobile">{`ראש ישיבת פורת יוסף ונשיא מועצת חכמי התורה`}</div>}
        </span>
      </div>}
      {(isMobile().phone || isMobile().tablet) && <div className={'openNav-allbooks-Chosen-masechet-total-m'}>{`עד כה נלמדו ${countall} עמודים מתוך ${'4125'}`}</div>}

      {!config.projectNameShasmehulak.includes(projectNameShasmehulak) && !(isMobile().phone || isMobile().tablet) && <div className='openNav-allbooks-Chosen-masechet-icon'>
        <BookUserHidush />
        {<span className="openNav-allbooks-Chosen-masechet-icon-text">
          <div className="openNav-allbooks-Chosen-masechet-icon-text-big">{'הפרויקט הסתיים'}</div>
          <div className="openNav-allbooks-Chosen-masechet-icon-text-big-2">{`תודה לכל המשתתפים`}</div>
        </span>}
      </div>}
      {!config.projectNameShasmehulak.includes(projectNameShasmehulak) && (isMobile().phone || isMobile().tablet) && <div className='openNav-allbooks-Chosen-masechet-icon'>
        <BookUserHidushSmall />
        <span className="openNav-allbooks-Chosen-masechet-icon-text-mobile">
          <div className="openNav-allbooks-Chosen-masechet-icon-text-big-mobile">{'הפרויקט הסתיים'}</div>
          <div className="openNav-allbooks-Chosen-masechet-icon-text-small-mobile">{`תודה לכל המשתתפים`}</div>

        </span>
      </div>}
      {bookListAll !== null && <div className="openNav-main-container-yoma">
        {menuView === "allbooks" && (
          <div className={!(isMobile().phone || isMobile().tablet) ? "openNav-allbooks-container-yoma" : 'openNav-allbooks-container-mobile-1'}>
            {allBooksNames && allBooksNames[0].map((seder: any, index: any) => {


              return (
                <div
                  className="openNav-allbooks-list-container-yoma"
                  key={seder.name}
                >
                  {<h4 className="allbooks-list-header-yoma">{seder.name}</h4>}
                  <ul className={!(isMobile().phone || isMobile().tablet) ? "openNav-allbooks-list-yoma" : "openNav-allbooks-list-mobile-1"}>
                    {seder.books.map((book: any) => {

                      if (currentBook === book.bookName) {
                        return (
                          <li
                            key={book.bookName}
                            className={"openNav-book-item-yoma openNav-book-item-current " + ((isMobile().phone || isMobile().tablet) ? 'mobile-1 openNav-book-item-yoma-mobile' : '')}
                            onClick={() => {
                              if (index > 1) {
                                return
                              }
                              dispatch(setBookYoma(book.bookName));
                              setBook(book.bookName);
                              setHeBook(book.heName);
                              wikiService.getMasechetLearn(book.bookName, projectNameShasmehulak).then(function (result) {
                                setBookList(result.getMasechetLearn)
                              });
                              handleChooseBook(book.heName, book.bookName, "bookChosen")
                              //history.push(`${window.location.origin}/projectNameShasmehulak/${book.bookName}`);
                            }}
                          >
                            {book.heName}
                            <div className='bookChosen-page-item-text-3-yoma'>
                              {`${isCherries(book.bookName) < Number(book.countPages) ? isCherries(book.bookName) : Number(book.countPages)}  עמודים שנלמדים`}
                            </div>
                            <div className='bookChosen-page-item-text-3-yoma-black'>
                              {`${Number(book.countPages) - isCherries(book.bookName) >= 0 ? Number(book.countPages) - isCherries(book.bookName) : 0} עמודים שנותרו`}
                            </div>
                          </li>
                        );
                      } else {
                        return (
                          <li
                            key={book.bookName}
                            className={`${index < 6 ? 'openNav-book-item-yoma' : 'openNav-book-item-yoma-hid'} ` + ((isMobile().phone || isMobile().tablet) ? 'mobile-1 openNav-book-item-yoma-mobile' : '')}
                            onClick={() => {
                           
                              dispatch(setBookYoma(book.bookName));
                              setHeBook(book.heName);
                              setBook(book.bookName);
                              wikiService.getMasechetLearn(book.bookName, projectNameShasmehulak).then(function (result) {
                                setBookList(result.getMasechetLearn)
                              });

                              handleChooseBook(book.heName, book.bookName, "bookChosen")
                              if (!window.location.pathname.includes(book.bookName))
                                history.push(`${window.location.origin}/${projectNameShasmehulak}/${book.bookName}`);
                            }
                            }
                          >
                            <div className='bookChosen-page-item-text-yoma'>
                              {book.heName}
                            </div>
                            { <>
                              <div className='bookChosen-page-item-text-3-yoma'>
                                {`${isCherries(book.bookName)} עמודים שנלמדים`}
                              </div>
                              <div className='bookChosen-page-item-text-3-yoma-black'>
                                {`${(Number(book.countPages) * 2 - isCherries(book.bookName))} עמודים שנותרו`}
                              </div>
                            </>}
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div>);
            })}
          </div>
        )}
        {menuView === "bookChosen" && (
          <div className="openNav-bookChosen-container-wrapper-yoma">
            <div className="select-chapter-openNav">
              <div className="openNav-bookChosen-container">
                {menuChaptersView === "allPages" &&
                  chosenBookAllPages &&
                  chosenBookAllPages.map((page: any, index: any) => {

                    return (
                      <div
                        className={
                          page === pageId
                            ? "openNav-book-item-yoma-1 bookChosen-current-item"
                            : "openNav-book-item-yoma-1"
                        }
                        id={page === pageId ? "selected-page" : page}
                        key={page}
                        onClick={() => {
                          handleSelectPage(page)
                        }} >
                        {<p className={`bookChosen-page-item-text-3 ${eeeee(page) !== '' ? 'color' : ''}`}>
                          <div id={page + 'p'} style={{ fontSize: 12, color: '#151515' }}> {bookList.length > 0 &&
                            eeeee(page)}
                            {
                              (bookList.length === 0 || eeeee(page) === '') && 'בחר'
                            }
                          </div >
                          {parseBookPageToHebrew(page.split(':')[0], menuView === 'bookChosen')}
                        </p>}
                      </div >
                    );
                  })}
                {menuChaptersView !== "allPages" && getChapterPages()}
              </div>
            </div>
          </div>

        )}
      </div>}
      <div className="openNav-main-container-empty"></div>
    </div >
  );
};

export default OpenNavYoma;
