export const SET_PAGE = "SET_PAGE";
export const LOADING_START = "LOADING_START";
export const SET_ERROR = "SET_ERROR";
export const LOADING_FINISHED = "LOADING_FINISHED";
export const SELECT_TEXT_UNIT = "SELECT_TEXT_UNIT";
export const SELECT_TEXT_UNIT_FROM_URL = "SELECT_TEXT_UNIT_FROM_URL";
export const SELECT_COMMENTARY = "SELECT_COMMENTARY";
export const SET_TAB_OPEN_RIGHT = "SET_TAB_OPEN_RIGHT";
export const CLEAR_TAB_OPEN_RIGHT = "CLEAR_TAB_OPEN_RIGHT";
export const CLEAR_TAB_OPEN_LEFT = "CLEAR_TAB_OPEN_LEFT";
export const SET_TAB_OPEN_LEFT = "SET_TAB_OPEN_LEFT";
export const SET_SELECTED_COMMENTARY_CHECKED =
  "SET_SELECTED_COMMENTARY_CHECKED";
export const SET_SELECTED_COMMENTARY_UNCHECKED =
  "SET_SELECTED_COMMENTARY_UNCHECKED";
export const SET_LAYOUT_DATA = "SET_LAYOUT_DATA";
export const SET_SEE_SFARIM_ON = "SET_SEE_SFARIM_ON";
export const SET_IS_BIYUR = "SET_IS_BIYUR";
export const SET_COMMENTARY_TO_SHOW = "SET_COMMENTARY_TO_SHOW";
export const SET_COMMENTARY_TO_SHOW_FROM_URL =
  "SET_COMMENTARY_TO_SHOW_FROM_URL";
export const SET_MENU_VIEW = "SET_MENU_VIEW";
export const SET_MENU_HE_BOOK = "SET_MENU_HE_BOOK";
export const SET_MENU_BOOK = "SET_MENU_BOOK";
export const CLEAR_MENU_VIEW = "CLEAR_MENU_VIEW";
export const SET_BOOK_LIST_ALL = "SET_BOOK_LIST_ALL";
export const SET_MENU_CHAPTERS = "SET_MENU_CHAPTERS";
export const SET_MENU_ALLPAGES = "SET_MENU_ALLPAGES";
export const CLOSE_MENU = "CLOSE_MENU";
export const CLOSE_MENU_YOMA = "CLOSE_MENU_YOMA";
export const OPEN_MENU_YOMA = "OPEN_MENU_YOMA";
export const PROJECT_NAME_SHAS_MEHULAK = "PROJECT_NAME_SHAS_MEHULAK";
export const OPEN_MENU = "OPEN_MENU";
export const CLOSE_MENU1 = "CLOSE_MENU1";
export const OPEN_MENU1 = "OPEN_MENU1";
export const OPEN_PID = "OPEN_PID";
export const CLOSE_PID = "CLOSE_PID";
export const OPEN_PID1 = "OPEN_PID1";
export const CLOSE_PID1 = "CLOSE_PID1";
export const OPEN_PID2 = "OPEN_PID2";
export const CLOSE_PID2 = "CLOSE_PID2";
export const SET_CHAPTERS_VIEW = "SET_CHAPTERS_VIEW";
export const SET_USER_PROFILE = "SET_USER_PROFILE";
export const SET_IS_BIYUR_SCROLLING = "SET_IS_BIYUR_SCROLLING";
export const SET_SELECTED_COMMENTARY_FROM_URL =
  "SET_SELECTED_COMMENTARY_FROM_URL";
export const SET_CATEGORY_NAME_FROM_URL = "SET_CATEGORY_NAME_FROM_URL";
export const SET_HE_CATEGORY_NAME_FROM_URL = "SET_HE_CATEGORY_NAME_FROM_URL";
export const SET_BOOK_NAME_FROM_URL = "SET_BOOK_NAME_FROM_URL";
export const SET_PAGE_NUMBER_FROM_URL = "SET_PAGE_NUMBER_FROM_URL";
export const SET_USER_NAME = "SET_USER_NAME";
export const SET_USER_PAGE = "SET_USER_PAGE";
export const SET_ON_LINE_PARTY = "SET_ON_LINE_PARTY";
export const SET_USER_ID = "SET_USER_ID";
export const SET_USER_FOLLOWERS = "SET_USER_FOLLOWERS";
export const SET_IS_PRIVETE = "SET_IS_PRIVETE";
export const SET_IS_MOBILE = "SET_IS_MOBILE";
export const SET_ALL_BOOKS = "SET_ALL_BOOKS";
export const SET_USER_PROFILE_OPEN = "SET_USER_PROFILE_OPEN";
export const SET_ALL_USERS = 'SET_ALL_USERS';
export const SET_ALL_TOPICS = 'SET_ALL_TOPICS';
export const SET_IS_IFRAME = 'SET_IS_IFRAME';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SET_NOTIFICATIONS_ALL = 'SET_NOTIFICATIONS_ALL';
export const SET_NOTIFICATIONS_OPEN = 'SET_NOTIFICATIONS_OPEN';
export const SET_EMAIL_OPEN = 'SET_EMAIL_OPEN';
export const PAGE_HIDUSHIM = 'PAGE_HIDUSHIM';
export const SET_TOPICS_FOLLOW = 'SET_TOPICS_FOLLOW';
export const SET_LOADED_HIDUSHIM = 'SET_LOADED_HIDUSHIM';
export const SET_LOADED_FEED = 'SET_LOADED_FEED';
export const SET_USER_PROFILE_VIEW = 'SET_USER_PROFILE_VIEW';
export const SET_RTAILE = 'SET_RTAILE';
export const SET_MENU_VIEW_YOMA = 'SET_MENU_VIEW_YOMA';
export const SET_MENU_BOOK_YOMA = 'SET_MENU_BOOK_YOMA';
export const SET_MENU_HE_BOOK_YOMA = 'SET_MENU_HE_BOOK_YOMA';
export const CLEAR_MENU_VIEW_YOMA = 'CLEAR_MENU_VIEW_YOMA';
export const SET_MENU_CHAPTERS_YOMA = 'SET_MENU_CHAPTERS_YOMA';
export const SET_MENU_ALLPAGES_YOMA = 'SET_MENU_ALLPAGES_YOMA';
export const SET_CHAPTERS_VIEW_YOMA = 'SET_CHAPTERS_VIEW_YOMA';
export const SET_BOOK_YOMA = 'SET_BOOK_YOMA';
export const SET_PAGE_YOMA = 'SET_PAGE_YOMA';
export const SET_USER_YOMA = 'SET_USER_YOMA';
export const SET_COMPETITION = 'SET_COMPETITION';
export const MY_TOPICS = 'MY_TOPICS';
export const SET_REGISTRATION = 'SET_REGISTRATION';
export const SET_CURRENT_EVENTS = 'SET_CURRENT_EVENTS';
export const SET_TOPIC_UUID = 'SET_TOPIC_UUID';
export const SET_USERS_FEED = 'SET_USERS_FEED';
export const SET_RECENT_CHANGES = 'SET_RECENT_CHANGES';
export const SET_CONTENT_COUNTS_MENU = 'SET_CONTENT_COUNTS_MENU';
export const SET_ALL_TOPICS_IDS = 'SET_ALL_TOPICS_IDS';
export const SET_SECTION_NAMES = 'SET_SECTION_NAMES';
export const SET_IS_OPEN_NAV_PAGE = 'SET_IS_OPEN_NAV_PAGE';
export const IS_ARTICLES = 'IS_ARTICLES';
export const SET_NUMBER_OF_MEFASHIM = 'SET_NUMBER_OF_MEFASHIM';
export const SET_RESULTS_FROM_SEARCH = 'SET_RESULTS_FROM_SEARCH';
export const SET_IS_OPEN_SEARCH = 'SET_IS_OPEN_SEARCH';
export const SET_IS_OPEN_TEMPLATES = 'SET_IS_OPEN_TEMPLATES';
export const CKEDITTOR_CONTENT = 'CKEDITTOR_CONTENT';