import React from "react";
import "./OpenPid2.css";
import { useSelector } from "react-redux";
import { } from "../../store/actions/pageActions";
import {
  getIsPidOpen2
} from "../../store/selectors/selectors";
import config from "../../services/configService";
import { Toolbar } from '@mui/material';



const OpenPid2 = () => {
  const isPidOpen2 = useSelector(getIsPidOpen2);
  let selected: any = document.getElementById("lasthidushim");
  const chnageLink = (link: string, id: any) => {
    let iframePid = document.getElementById("iframe-pid2");
    let Toolbar = document.getElementById(id);
    if (iframePid && Toolbar) {
      // @ts-ignore
      let currnt = iframePid.src;
      if (currnt !== link) {
        // @ts-ignore
        iframePid.src = link;
      }
      if (selected)
        selected.className = "openPid-top-item-background";
      Toolbar.className = "openPid-top-item-background-selecte";
      selected = Toolbar;
    }
  };



  return (
    <div
      className={isPidOpen2 ? "header-openPid" : "header-hidden"}
      id={"openPid2"}
    >

      <Toolbar aria-label="Toolbar">

        <div className="openPid-top-item-background" id="message" onClick={() => chnageLink(config.aboutDoc, "message")}>
          אודות
        </div>
        <div className="openPid-top-item-background" id="iyun" onClick={() => chnageLink(config.pid1, "iyun")}>
          הדרכה
        </div>

        <div className="openPid-top-item-background-selecte" id="lasthidushim" onClick={() => chnageLink(config.pid2, "lasthidushim")}>
          הודעות המערכת
        </div>
        <div className="openPid-top-item-background" id="connect" onClick={() => chnageLink(config.pid3, "connect")}>
          צור קשר
        </div>

      </Toolbar>


    </div>
  );
};

export default OpenPid2;
