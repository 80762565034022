import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./wellcomeUserMobile.css";
import {
  getUserName,
  getUserProfileView,
  getCurrentBook,
  getPageTitle,
  getTopicUuid,
} from "../../store/selectors/selectors";
import {
  openMenu,
  openMenuYoma,
  setUserProfileView,
} from "../../store/actions/viewActions";
import {
  getPageData,
} from "../../store/actions/pageActions";
import { ReactComponent as BookOpenMobile } from "../../svg/BookOpenMobile.svg";
import { ReactComponent as BooksMobile } from "../../svg/BooksMobile.svg";
import { ReactComponent as ParashaMobile } from "../../svg/parashaMobile.svg";
import { ReactComponent as WelcomeTzuraHadafMobile } from "../../svg/welcomeTzuraHadafMobile.svg";
import UserProfileMobile from "../UserProfileMobile/UserProfileMobile";
import OpenPid1 from "../OpenPid1/OpenPid1";
import OpenPid from "../OpenPid/OpenPid";
import OpenNav from "../OpenNav/OpenNav";
import OpenNavYoma from "../OpenNavYoma/OpenNavYoma";
import Hidushim from "../Hidushim/Hidushim";
import HidushimMenuMobile from "../HidushimMenuMobile/HidushimMenuMobile";
import FormPage from "../FormPage/FormPage";

import Sfarim from "../Sfarim/Sfarim";
import {
  getParashat,
  daf
} from "../../utils/funcUtils";
import { ThunkDispatch } from "@reduxjs/toolkit";

const WellcomeUserMobile: React.FC = () => {
  const currentBook = useSelector(getCurrentBook);
  const bookName = useSelector(getPageTitle);
  const [parasha, setParasha] = useState('');
  const statusUuid = useSelector(getTopicUuid);
  window.onload = () => {
    getParashat().then((response) => {
      setParasha(response)
    });
  }
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const userProfileView = useSelector(getUserProfileView);
  const userName = useSelector(getUserName);

  function getDaf() {
    const bookPage = daf();
    dispatch(getPageData(bookPage.book, bookPage.page));
  }
  
  return (<>
    {statusUuid === '' &&
      (userProfileView === 'HomePageMobile' ||
        (userProfileView === 'FormPageConnect' && userName !== 0)) && !bookName &&
      !currentBook &&
      <div >
        <div className='wellcome-user-mobile-wellcome'>
          {`שלום, ${userName ? userName : 'אורח'}`}
        </div>
        <div className='wellcome-user-mobile-select'>
          {`מה תבחרו ללמוד?`}
        </div>
        {true && <div className='wellcome-user-mobile-botton-list'>
          <div className='wellcome-user-mobile-botton'
            onClick={getDaf}
          >
            <div className='wellcome-user-mobile-botton-icon'>
              <BookOpenMobile />
            </div>
            <div className='wellcome-user-mobile-botton-text'>
              {`הדף היומי`}
            </div>
          </div>
          <div className='wellcome-user-mobile-botton' >
            <a href={parasha} id="redre_Parasha">
              <div className='wellcome-user-mobile-botton-icon'>
                <BooksMobile />
              </div>
              <div className='wellcome-user-mobile-botton-text-a'>
                {`פרשת השבוע`}
              </div>
            </a>
          </div>
          <div className='wellcome-user-mobile-botton'>
            <div className='wellcome-user-mobile-botton-icon'>
              <ParashaMobile />
            </div>
            <div className='wellcome-user-mobile-botton-text'
              onClick={() => {
                dispatch(openMenu());
                dispatch(setUserProfileView("MenuMobile"));
              }}
            >
              {`אוצר הספרים`}
            </div>
          </div>
          {false && <div className='wellcome-user-mobile-botton'>
            <div className='wellcome-user-mobile-botton-icon-4'>
              <WelcomeTzuraHadafMobile />
            </div>
            <div className='wellcome-user-mobile-botton-text'
              onClick={() => {
                dispatch(openMenuYoma())
                dispatch(setUserProfileView("MenuYomaMobile"));
              }}
            >
              {`ש"ס מחולק`}
            </div>
          </div>}
        </div>}
      </div>}

    {userProfileView === 'UserProfileMobile' && <UserProfileMobile />}
    {userProfileView === 'LastHidushimMobile' && <OpenPid1 />}
    {userProfileView === 'ListOfRenewMobile' && <OpenPid />}
    {userProfileView === 'HidushimMenuMobile' && <HidushimMenuMobile />}
    {userProfileView === 'MenuMobile' && <OpenNav />}
    {(userProfileView === 'MenuYomaMobile' || userProfileView === 'FormPageCreateYoma') && <OpenNavYoma />}
    {userProfileView === 'FormPageCreateYoma' && <FormPage />}

    {userProfileView === 'HidushimMobile' && <Hidushim />}
    {userProfileView === 'MefarshimMobile' && <Sfarim />}
  </>
  );
}

export default WellcomeUserMobile;
