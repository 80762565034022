let config = {};
let env = process.env.REACT_APP_ENV;
/*try {

    if (env) {
        env = env.trim();

    }

    config = require(`../config/config.${env}.json`);
} catch (e) {
    console.warn(`Unable to load configuration file: config.${env}.json\``, e);
}*/
let envT = 'http://localhost:8090';
let shitufta_api_url;

//let envT = 'http://35.156.245.34';
if (window.location.origin === 'https://shitufta.org.il' || window.location.origin === 'https://dev.test.shitufta.org.il') {
    //if (true) {
    envT = 'https://shitufta-wiki.shitufta.org.il';
}

shitufta_api_url = 'https://shitufta-back.shitufta.org.il/newapi/';
const shitufta_api_url_dev = 'https://shitufta-back.shitufta.org.il/';

//envT = 'https://shitufta-wiki.shitufta.org.il';

export default {
    "shitufta_api_url": shitufta_api_url,
    "shitufta_api_url_dev": shitufta_api_url_dev,
    "layout_api": "https://shitufta-api.shitufta.org.il/api/",
    "hamichlolHidushimAfter": `${envT}/w/index.php?title=מיוחד:MyExtension/`,
    "hamichlolAyun": `${envT}/w/index.php?title=עיון:`,
    "dafHyomi": "https://shitufta-back.shitufta.org.il/calendars?timezone=Asia/Jerusalem",
    "projectNameShasmehulak": ["cohen", "Shechter", "Kaniyevski","ironSwords"],
    "formReview": "https://docs.google.com/forms/d/e/1FAIpQLSfFrwXfJAkWrWA-1ppPf0ljvjE2F026eZBs4LdqH7Bk8mk1Kg/viewform?usp=sf_link",
    "creditsDoc": "creditsDoc",
    "privacyDoc": "privacyDoc",
    "aboutDoc": `${envT}/%D7%A9%D7%99%D7%AA%D7%95%D7%A4%D7%AA%D7%90:%D7%90%D7%95%D7%93%D7%95%D7%AA`,
    "redirect": `${envT}/%D7%9E%D7%99%D7%95%D7%97%D7%93:MyExtension`,
    "pid1": `${envT}/שיתופתא:הדרכה`,
    "pid2": `${envT}/שיתופתא:הודעות_המערכת`,
    "pid3": `${envT}/שיתופתא:צור_קשר`,
    "pid4": `${envT}/מיוחד:שליחת_דואר_למשתמש/`,
    "pid6": `${envT}/w/index.php?title=מיוחד:UsersWatchList&namespace=2600&days=30`,
    "searchLink": `${shitufta_api_url}search-wrapper`,
    "pathApi": `${envT}/w/api.php?`,
    "loginreturnurl": `${envT}/`,
    "origin": window.location.origin,
    "subMessageHomePage": 'מאגר תורני ייחודי מעוצב בצורת הדף המקורית ומערכת לפרסום חידושי תורה על סדר הדף',
    "subMessageHomePageMobile": 'הצטרפו לרשת התורנית הגדולה בעולם ללימוד ושיתוף חידושי תורה.',
    "sfarimToAlwaysRemove": [
        "Reference",
        "Modern Commentary",
        // "Tosafot",// "Steinsaltz",
        "Redeeming Relevance; Genesis",
        "Redeeming Relevance; Exodus",
        "Redeeming Relevance; Leviticus",
        "Redeeming Relevance; Numbers",
        "Redeeming Relevance; Deuteronomy",
        "The Wars of God",
        "JPS",
        "Notes by Rabbi Yehoshua Hartman",
        "Matteh Dan",
        "Ahevukha Ad Mavet, Summary",
        "Ali Be'er on Revealment and Concealment in Language",
        "Commentaries on Revealment and Concealment in Language",
        "Panekha Avakesh",
        "Bein HaShitin on Halakhah and Aggadah",
        "Shadal",
        "A New Israeli Commentary on Pirkei Avot",
        "The Midrash of Philo",
        "Contemporary Halakhic Problems, Vol I",
        "Contemporary Halakhic Problems, Vol II",
        "Contemporary Halakhic Problems, Vol III",
        "Contemporary Halakhic Problems, Vol IV",
        "Contemporary Halakhic Problems, Vol V",
        "Contemporary Halakhic Problems, Vol VI",
        "Contemporary Halakhic Problems, Vol VII",
        "B'Mareh HaBazak Volume I",
        "B'Mareh HaBazak Volume II",
        "B'Mareh HaBazak Volume III",
        "B'Mareh HaBazak Volume IV",
        "B'Mareh HaBazak Volume V",
        "B'Mareh HaBazak Volume VI",
        "B'Mareh HaBazak Volume VII",
        "Kol Dodi Dofek",
        "Depths of Yonah",
        "Gan Naul",
        "Orot",
        "English Explanation of Mishnah Yevamot",
        "English Explanation of Mishnah Ketubot",
        "English Explanation of Mishnah Nedarim",
        "English Explanation of Mishnah Nazir",
        "English Explanation of Mishnah Sotah",
        "English Explanation of Mishnah Gittin",
        "English Explanation of Mishnah Kiddushin",
        "English Explanation of Mishnah Bava Kamma",
        "English Explanation of Mishnah Bava Metzia",
        "English Explanation of Mishnah Bava Batra",
        "English Explanation of Mishnah Sanhedrin",
        "English Explanation of Mishnah Makkot",
        "English Explanation of Mishnah Shevuot",
        "English Explanation of Mishnah Eduyot",
        "English Explanation of Mishnah Avodah Zarah",
        "English Explanation of Pirkei Avot",
        "English Explanation of Mishnah Horayot",
        "English Explanation of Mishnah Zevachim",
        "English Explanation of Mishnah Menachot",
        "English Explanation of Mishnah Chullin",
        "English Explanation of Mishnah Bekhorot",
        "English Explanation of Mishnah Arakhin",
        "English Explanation of Mishnah Temurah",
        "English Explanation of Mishnah Keritot",
        "English Explanation of Mishnah Meilah",
        "English Explanation of Mishnah Tamid",
        "English Explanation of Mishnah Middot",
        "English Explanation of Mishnah Kinnim",
        "English Explanation of Mishnah Kelim",
        "English Explanation of Mishnah Oholot",
        "English Explanation of Mishnah Negaim",
        "English Explanation of Mishnah Parah",
        "English Explanation of Mishnah Tahorot",
        "English Explanation of Mishnah Mikvaot",
        "English Explanation of Mishnah Niddah",
        "English Explanation of Mishnah Makhshirin",
        "English Explanation of Mishnah Zavim",
        "English Explanation of Mishnah Tevul Yom",
        "English Explanation of Mishnah Yadayim",
        "English Explanation of Mishnah Oktzin",
        "English Explanation of Mishnah Maaser Sheni",
        "English Explanation of Mishnah Demai",
        "English Explanation of Mishnah Kilayim",
        "English Explanation of Mishnah Peah",
        "English Explanation of Mishnah Berakhot",
        "English Explanation of Mishnah Sheviit",
        "English Explanation of Mishnah Terumot",
        "English Explanation of Mishnah Maasrot",
        "English Explanation of Mishnah Challah",
        "English Explanation of Mishnah Moed Katan",
        "English Explanation of Mishnah Bikkurim",
        "English Explanation of Mishnah Chagigah",
        "English Explanation of Mishnah Orlah",
        "English Explanation of Mishnah Eruvin",
        "English Explanation of Mishnah Pesachim",
        "English Explanation of Mishnah Shabbat",
        "English Explanation of Mishnah Shekalim",
        "English Explanation of Mishnah Yoma",
        "English Explanation of Mishnah Sukkah",
        "English Explanation of Mishnah Megillah",
        "English Explanation of Mishnah Beitzah",
        "English Explanation of Mishnah Taanit",
        "English Explanation of Mishnah Rosh Hashanah",
        "English Explanation of Mishnah",
        "Tosefta Beitzah (Lieberman)",
        "Mishnat Eretz Yisrael",
        "Words of Peace and Truth",
        "Maamar_Hador",
        "Abraham Cohen Footnotes to the English Translation of Masechet Berakhot",
        "The Five Books of Moses, by Everett Fox"
    ],
    "HOST": 'local.shitufta.org.il',
    "manualDataFixes": [
        {
            "condition": {
                "book": "Pesachim",
                "pageNumber": 99,
                "pageNumberCondition": "gte",
                "subPage": "b"
            },
            "fixes": {
                "Rashi": ["Rashi", "Rashbam"],
                "fixSfarim": ["Rashi", "Rashbam"]
            }
        },

        {
            "condition": {
                "book": "Bava_Batra",
                "pageNumber": 28,
                "pageNumberCondition": "gte",
                "subPage": "b"
            },
            "fixes": {
                "Rashi": ["Rashi", "Rashbam"],
                "fixSfarim": ["Rashi", "Rashbam"]
            }
        },
        {
            "condition": {
                "book": "Berakhot",
                "pageNumber": 2,
                "pageNumberCondition": "eq",
                "subPage": "b"
            },
            "fixes": {
                "textToRemove": [
                    { "commenterKey": "Rashi", "textUnitIndex": 1, "commentaryIndex": 1 },
                    { "commenterKey": "Rashi", "textUnitIndex": 12, "commentaryIndex": 3 }
                ]
            }
        },
        {
            "condition": {
                "book": "Nedarim",
                "pageNumberCondition": "eq"
            },
            "fixes": {
                "Tosafot": ["Ran"],
                "fixSfarim": ["Ran"]
            }
        },
        {
            "condition": {
                "book": "Makkot",
                "pageNumber": 23,
                "pageNumberCondition": "gte",
                "subPage": "b"
            },
            "fixes": {
                "Rashi": ["Rashi", "Rabbeinu Gershom"],
                "fixSfarim": ["Rashi", "Rabbeinu Gershom"]
            }
        }
    ]
    ,
    ...config
}