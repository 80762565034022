import React from "react";
import "./ShowCommentaryTxt.css";
import { ReactComponent as Openpanel } from "../../svg/open-panel.svg";
import { useSelector } from "react-redux";
import {
  getCommentaryToShow
} from "../../store/selectors/selectors";
interface IShowCommentaryTxtProps {
  txt: any;
}

// sometimes the text data comes in an array, because a commentary might have more then one text.

const ShowCommentaryTxt: React.FC<IShowCommentaryTxtProps> = ({ txt }) => {
  const commentaryToShow = useSelector(getCommentaryToShow);

  return (
    <>
      {txt &&
        txt.map((txtItem: any, idx: number) => {

          return (
            <div
              className="txt-commentary-inner-container"
              key={idx + "txtToShow"}
            >
              {(
                commentaryToShow === 'einMishpatHashalem' ||
                commentaryToShow === 'TorahOrHashalem' ||
                commentaryToShow === 'mesoretHashas'
              )
                &&
                <span className="show-commentary-txt-title">
                  {txtItem.title}
                </span>
              }
              {typeof txtItem.txt === "object" ? (
                txtItem.txt.map((txtCommentary: string, txtIdx: number) => {
                  let newArray: any;
                  if ((typeof txtCommentary !== 'string')) {
                    return (<></>);
                  }
                  if ((typeof txtCommentary === 'string') && txtCommentary.split('~') && txtCommentary.split('~').length > 1)
                    newArray = txtCommentary.split('~')[1].split('`').join('').split(' ');
                  else
                    newArray = txtCommentary.split('`').join('').split(' ');
                  let number = newArray.pop();

                  return (<>
                    <p
                      key={txtIdx}
                      className="show-commentary-txt"
                      dangerouslySetInnerHTML={{ __html: `${txtCommentary.includes('`') ? '' : txtCommentary.split('~')[0]} ` }}
                      id={txtCommentary}>

                    </p>
                    {txtCommentary && !txtCommentary.startsWith(`~`) &&
                      <div className="show-commentary-txt-icon"
                        onClick={() => {
                          window.open(`/${newArray.join('_')}/${number.split(':')[0]}`, 'new_window')
                        }}>
                        <Openpanel style={{ cursor: 'pointer' }}
                        >
                        </Openpanel>
                        <br />
                      </div>}
                  </>
                  );
                })
              ) : (
                <p
                  className="show-commentary-txt"
                  dangerouslySetInnerHTML={{ __html: `${txtItem.txt.includes('`') ? '' : txtItem.txt} ` }}
                  id={txtItem.txt}

                />
              )}
            </div>
          );
        })}
    </>
  );
};

export default ShowCommentaryTxt;
