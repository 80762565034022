import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as LineForm } from "../../svg/LineForm.svg";
import { ReactComponent as MihlolLogo } from "../../svg/logoBig.svg";
import { ReactComponent as LogoWord } from "../../svg/logoWord.svg";
import { ReactComponent as CloseSign } from "../../svg/group-11.svg";

import "./FormPageCreateYoma.css";
import {
  getBookYoma,
  getPageYoma,
  getProjectNameShasmehulak
} from "../../store/selectors/selectors";
import {
  setIsUserProfile,
  setUserYoma,
  setUserProfileView,
  openMenuYoma,

  changeMenuViewYoma
} from "../../store/actions/viewActions";
import wikiService from "../../services/wikiService";
import isMobile from "ismobilejs";
import { ThunkDispatch } from "@reduxjs/toolkit";

const FormPageCreateYoma: React.FC = () => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const bookYoma = useSelector(getBookYoma);
  const pageYoma = useSelector(getPageYoma);
  const projectNameShasmehulak = useSelector(getProjectNameShasmehulak);
  const [isError, setIsError] = useState(false);
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const onChangeUserName = (e: any) => {

    setUserName(e.target.value);
    if (isError) {
      setIsError(false)
    }
  }
  function ValidateEmail(mail: any) {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
      return (true)
    }
    return (false)
  }
  const onChangePassword = (e: any) => {
    if (!ValidateEmail(e.target.value)) {
      setIsError(true)
      return;
    }
    setPassword(e.target.value);
    if (isError) {
      setIsError(false)
    }
  }

  const LogInOtAccount = (projectNameShasmehulak: any) => {
    if (userName !== '' && password !== '') {
      wikiService.setPageToLearn(userName, bookYoma, pageYoma, projectNameShasmehulak, password).then(function (result) {
        dispatch(setIsUserProfile(false));
        dispatch(openMenuYoma());
        dispatch(setUserProfileView("MenuYomaMobile"));
        dispatch(changeMenuViewYoma('bookChosen'));

        dispatch(setUserYoma(userName));
        const element = document.getElementById(pageYoma + 'p');
        if (element)
          element.innerHTML = userName;

      })
    }
    else {
      setIsError(true)
    }

  }



  return (
    <>  {(isMobile().phone || isMobile().tablet) && <CloseSign
      className="close-user-svg-mobile"
      onClick={() => {
        dispatch(openMenuYoma());
        dispatch(setUserProfileView("MenuYomaMobile"));
      }}
    />}
      {<div className='flex'>
        <div className='form-page-logo'>
          <MihlolLogo />
        </div>
        <div className='form-page-logo-word'>
          <LogoWord />
        </div>
      </div>}
      <div className={(isMobile().phone || isMobile().tablet) ? 'form-page-connect-first-name-yoma-mobile' : 'form-page-connect-first-name-yoma'}>
        <input
          type='text'
          name='first-name'
          className='form-page-connect-first-name-input'
          placeholder='שם'
          onChange={(e) => {
            onChangeUserName(e)
          }}
          maxLength={15}
        ></input>
      </div>

      <div className={(isMobile().phone || isMobile().tablet) ? 'form-page-password-yoma-mobile' : 'form-page-password-yoma'}>
        <input
          type='email'
          name='email'
          defaultValue='@gmail.com'
          className='form-page-connect-first-name-input'
          placeholder='אימייל'
          onChange={(e) => {
            onChangePassword(e)
          }}
          maxLength={17}
        ></input>
      </div>
      {isError && <div className='form-page-connect-error'>
        {'שם המשתמש או האימייל לא תקין'}
      </div>}

      <div className={!(isMobile().phone || isMobile().tablet) ? `form-page-connect-yoma` : `form-page-connect-yoma-mobile`}
        onClick={() => LogInOtAccount(projectNameShasmehulak)}
      >
        <div className='form-page-connect-text'>
          {'בחר דף'}
        </div>
      </div>
      {!(isMobile().phone || isMobile().tablet) && <div className='form-page-line'>
        <LineForm />
      </div>}



    </>
  );
}




export default FormPageCreateYoma;
