import axios, { AxiosRequestConfig } from "axios";
import config from "./configService";

class SearchService {

    public async callAxios(query: string, start = 0, filters: Array<string> = []) {
        let searchLink = config.searchLink
        const options = {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            data: {
                "aggs": [],
                "field": "naive_lemmatizer",
                "filter_fields": [
                    'Talmud',
                    'Halakhah',
                    'Mishneh Torah',
                    'Tanakh',
                    'Kabbalah',
                    'Chasidut',
                    'Musar',
                    'Tosefta',
                    'Midrash',
                    'Responsa',
                    'Mishnah',
                    'Liturgy',
                    'Tanaitic'
                ],
                "filters": filters,
                "query": query,
                "size": 50,
                "slop": 10,
                "start": start,
                "sort_fields": ["pagesheetrank"],
                "sort_method": "score",
                "sort_reverse": false,
                "sort_score_missing": 0.04,
                "source_proj": true
            },
            searchLink,
            dataType: 'json'
        };


        const res: any = await axios(searchLink, options as AxiosRequestConfig)


        return res.data;
    }

}

export default new SearchService();