import React, { useEffect, useState } from "react";
import { ReactComponent as RightArrowHeaderSvg } from "../../svg/arrow.svg";
import { useDispatch, useSelector } from "react-redux";
import "./Hidushim.css";
import {
  getOpenTabsLeft,
  getPageTitle,
  getPageId,
  getIsLoading,
  getTextNumber,
  getNextPage,
  getUserid,
  getCategoryName,
  getSelectedUnitText,
  getNumberOfmefarshim
} from "../../store/selectors/selectors";
import {
  clearTabOpenLeft,
  setTabOpenLeft,
  setSelectedTextUnit,
  getPageData
} from "../../store/actions/pageActions";
import {
  setPageHidushim,
} from "../../store/actions/viewActions";
import {
  handleChange
} from "../../utils/dataUtils";
import Tooltip from '@mui/material/Tooltip';
import {
  useStyles
} from "../../utils/funcUtils";
import ShowHidushim from "../ShowHidushim/ShowHidushim";
import isMobile from "ismobilejs";
import wikiService from "../../services/wikiService";
import { ThunkDispatch } from "@reduxjs/toolkit";

const Hidushim: React.FC = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const [isExpanded, setExpanded] = useState(false);
  const textNumber = useSelector(getTextNumber);
  const tabsOpened = useSelector(getOpenTabsLeft);
  const pageTitle = useSelector(getPageTitle);
  const pageId = useSelector(getPageId);
  const isLoading = useSelector(getIsLoading);
  const nextPage = useSelector(getNextPage);
  const userid = useSelector(getUserid);
  const categoryName = useSelector(getCategoryName);
  const selectedTextUnit = useSelector(getSelectedUnitText);
  const numberOfmefarshim = useSelector(getNumberOfmefarshim);
  const classes = useStyles();
  const handleChange1 = (bookId: string) => {
    const data = handleChange(bookId, categoryName)
    if (data && data[0] && data[1]) {
      if (textNumber.length - 1 === selectedTextUnit)
        dispatch(setSelectedTextUnit(0));
      dispatch(getPageData(data[0], data[1], 1));
    }
  };




  useEffect(() => {
    const getTopicListFromServer = async () => {

      var mapPtomise = [] as any;

      if (typeof textNumber === 'string') {
        const hidushTitle = `חידוש:${pageTitle.split(' ').join('_')}~${pageId.split(' ').join('_')}`
        wikiService.viewTopicList(hidushTitle).then(x => {
          mapPtomise[0] = textNumber

        })
      }
      else
        Promise.all([textNumber.forEach((x: any, index: any) => {
          if (index === 0) {
            const hidushTitle = `חידוש:${pageTitle.split(' ').join('_')}~${pageId.split(' ').join('_')}`
            wikiService.viewTopicList(hidushTitle).then(x => {
              mapPtomise[0] = x

            })
          }

          const hidushTitle = `חידוש:${pageTitle.split(' ').join('_')}${index}~${pageId.split(' ').join('_')}`
          wikiService.viewTopicList(hidushTitle).then(x => {
            mapPtomise[Number(index + 1)] = x;
          });

        })]).then(a => {
          dispatch(setPageHidushim(mapPtomise));
        })
    }
    if (tabsOpened === "hidushim" && pageTitle) {
      setExpanded(true);

      getTopicListFromServer();

    } else {

      setExpanded(false);

    }
  }, [tabsOpened, userid, pageId, pageTitle, textNumber, dispatch]);

  const expandTabHandler = () => {
    if (isLoading) return;
    dispatch(setTabOpenLeft("hidushim"));
    setTimeout(function () {
      setExpanded(true);
    }, 0);
  };

  const collapseTabHandler = () => {
    setExpanded(false);
    dispatch(clearTabOpenLeft());
  };

  return (
    <>
      <div
        className={isExpanded ? ((isMobile().phone || isMobile().tablet) ? 'expended-container1-mobile' : "expended-container1") : "not-expanded-container wi"}
      >
        {!isExpanded && (
          <div className="header-not-expanded-h" >
            <Tooltip
              classes={classes}
              title={`עמוד הבא`}
              placement="left"
              arrow
            >
              <div className={nextPage === '' ? 'panel-plus-sign-svg-left-empty' : "panel-plus-sign-svg-left"}
                onClick={() => handleChange1(nextPage)}>
                <div className='panel-plus-sign-svg-left-arrow'>
                  <RightArrowHeaderSvg />
                </div>
                <div className='panel-plus-sign-svg-left-arrow'>
                  <RightArrowHeaderSvg />
                </div>
              </div>
            </Tooltip>

            <div className="book-page-panel-header-h"
              onClick={expandTabHandler}
            >
              <div className="book-page-panel-header-h-text">{'חידושים'}</div>
              {false && numberOfmefarshim !== 0 && <div className="book-page-panel-header-h-number-l">{numberOfmefarshim}</div>}
            </div>
          </div>
        )}


        {tabsOpened === 'hidushim' && !isLoading && <ShowHidushim />}


      </div>
      {isExpanded && !(isMobile().phone || isMobile().tablet) && (
        <div className="header-not-expanded-h-open">

          <Tooltip
            classes={classes}
            title={`עמוד הבא`}
            placement="right"
            arrow
          >
            <div className={nextPage === '' ? 'panel-plus-sign-svg-left-open-empty' : "panel-plus-sign-svg-left-open"}
              onClick={() => handleChange1(nextPage)}
            >
              <div className='panel-plus-sign-svg-left-arrow-open'>
                <RightArrowHeaderSvg />
              </div>
              <div className='panel-plus-sign-svg-left-arrow-open'>
                <RightArrowHeaderSvg />
              </div>
            </div>
          </Tooltip>
          <div className="book-page-panel-header-h-open"
            onClick={collapseTabHandler}
          >
            <div className="book-page-panel-header-h-text">{'חידושים'}</div>
            {false && numberOfmefarshim !== 0 && <div className="book-page-panel-header-h-number-l">{numberOfmefarshim}</div>}
          </div>
        </div>
      )}
    </>
  );
};

export default Hidushim;
