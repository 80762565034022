
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as LearningExperienceMobile } from "../../svg/LearningExperienceMobile.svg";
import { ReactComponent as BooksiconMobile } from "../../svg/booksiconMobile.svg";
import { ReactComponent as NetworkiconMobile } from "../../svg/networkiconMobile.svg";
import { ReactComponent as WellcomeiconMobile } from "../../svg/wellcomeiconMobile.svg";
import FormPageConnectMobile from "../FormPageConnectMobile/FormPageConnectMobile";
import WellcomeUserMobile from "../wellcomeUserMobile/wellcomeUserMobile";
import config from "../../services/configService";
import FormPageCreateMobile from "../FormPageCreateMobile/FormPageCreateMobile";
import "./wellcomeMoblie.css";
import { setUserProfileView } from "../../store/actions/viewActions";
import {
  getUserid,
  getUserProfileView,
} from "../../store/selectors/selectors";
import {
  pageWikiFunc
} from "../../utils/funcUtils";
import { ThunkDispatch } from "@reduxjs/toolkit";

interface LINK {
  link: any;
}
const WellcomeMoblie: React.FC<LINK> = (link: any) => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const userid = useSelector(getUserid);
  const userProfileView = useSelector(getUserProfileView);

  const openUserProfile = () => {
    dispatch(setUserProfileView("FormPageConnectMobile"));
  };
  const height = window.screen.height;
  const width = window.screen.width;
  const pageWiki = (pageEn: any) => {
    if (pageEn === "Offline_Registration")
      dispatch(setUserProfileView("Offline_Registration"));
    return pageWikiFunc(pageEn)
  }


  return (<>
    {<div className={userProfileView !== 'FormPageCreateMobile' ? 'wellcome-mobile' : 'wellcome-mobile-FormPageCreateMobile'}
      style={{ height: height > width ? height - 126 : width - 0 }}
    >
      {(userid === 0) &&
        userProfileView === 'FormPageConnect'
        && link.link !== 'Competition' && <div className='wellcome-icon-mobile'>
          <div className='icon-height-mobile'>
            <WellcomeiconMobile />
          </div>
          <div className='wellcome-text-mobile'>
            {'על מנת לצפות בחידושים יש להתחבר!'}
          </div>
          <div className='wellcome-sub-text-mobile'>
            {config.subMessageHomePageMobile}
          </div>
          <div className='wellcome-icon-sub-mobile'>
            <div className='wellcome-icon-sub-text-mobile'>
              <LearningExperienceMobile />
              <div>{'חווית לימוד עם צורת הדף'}</div>
            </div>
            <div className='wellcome-icon-sub-text-mobile'>
              <BooksiconMobile />
              <div> {'אוצר ספרי יסוד ומפרשים'}</div>
            </div>
            <div className='wellcome-icon-sub-text-mobile'>
              <NetworkiconMobile />
              <div>{'רשת שיתופתית לחידושי תורה'}</div>
            </div>
          </div>

          <div className='wellcome-button-mobile'>
            <div className='wellcome-button-text-mobile' onClick={openUserProfile}>
              {'התחבר או הירשם >>'}
            </div>
          </div>
        </div>}

      {userid === 0 && userProfileView === 'FormPageConnectMobile' && <FormPageConnectMobile />}
      {userProfileView === 'FormPageCreateMobile' && <FormPageCreateMobile />}
      {!(link.link === '' || link.link === 'home') && (userProfileView === 'HomePageMobile' || userProfileView === 'FormPageConnect' || userProfileView === "Offline_Registration") && link !== '' &&
        <div className={`home-font-mobile`}
          dangerouslySetInnerHTML={{
            __html: pageWiki(link.link)
          }}>
        </div>}
      {link.link !== 'Competition' && link.link !== 'Contact' && link.link !== 'howWork' && <WellcomeUserMobile />}
    </div>
    } </>
  )
}

export default WellcomeMoblie;
