
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as MihlolLogo } from "../../svg/logoqq.svg";
import Select from "react-select";
import CircularProgress from '@mui/material/CircularProgress';
import {
  getPageHidushim,
  getPageTitle,
  getPageId,
  getSelectedUnitText,
  getTextNumber,
  getIsArticles,
  getCkEdittorContent,
  getIsOpenTemplate
} from "../../store/selectors/selectors";
import { CKEditor } from 'ckeditor4-react';
import { ReactComponent as Line } from "../../svg/HidushimLine.svg";
import ViewTopic from "../ViewTopic/ViewTopic";
import {
  setPageHidushim, setIsArticles, setCkEdittorContent, setIsOpenTemplates
} from "../../store/actions/viewActions";
import {
  clearTabOpenLeft,
} from "../../store/actions/pageActions";
import {
  useStyles
} from "../../utils/funcUtils";
import groupBy from 'lodash/groupBy';
import "./ShowHidushim.css";
import { ThunkDispatch } from "@reduxjs/toolkit";
import Tooltip from '@mui/material/Tooltip';
// @ts-ignore
import ShowMoreText from 'react-show-more-text';
import wikiService from "../../services/wikiService";
import CloseIcon from '@mui/icons-material/Close';
const ShowHidushim: React.FC = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isNone1, setIsNone1] = useState(true);
  const [nickname, setNickname] = useState('default');
  const pageHidushim = useSelector(getPageHidushim);
  const isArticles = useSelector(getIsArticles);
  const [items2, setItems2] = useState(pageHidushim);
  const selectedUnitText = useSelector(getSelectedUnitText);
  const pageTitle = useSelector(getPageTitle);
  const textNumber = useSelector(getTextNumber);
  const pageId = useSelector(getPageId);
  let replys = new Map<string, boolean>();
  let replys1 = new Map<string, string>();
  const [isLoaded1, setIsLoaded1] = useState(replys);
  const [progress, setProgress] = useState(0);
  const classes = useStyles();
  const [body, setBody] = useState(new Map<string, string>());
  const ckEdittorContent = useSelector(getCkEdittorContent);
  const [bodyNew, setBodyNew] = useState(ckEdittorContent);
  const isOpenTemplate = useSelector(getIsOpenTemplate);
  const incremen = (uuid: string) => {
    isLoaded1.set(uuid, false)
    setIsLoaded1(new Map<string, boolean>())
  }

  useEffect(() => {

    let revisionsLocal = '';

    for (let flows in items2) {

      for (let topic in items2[flows]) {

        for (var post in items2[flows][topic]) {

          revisionsLocal += items2[flows][topic][post].postId + '|';
        }
      }
    }

    if (!isLoaded) {
      wikiService.getFlowinfo(revisionsLocal.split('|'), items2, '').then((respons) => {
        setItems2(respons?.items2);
        setIsLoaded(true);
      })
    }
    const timer = setInterval(() => {
      if ((!isNone1 || !isLoaded))
        setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 20));
    }, 800);

    return () => {
      clearInterval(timer);
    };

  }, [isLoaded, isLoaded1, items2, isNone1, pageHidushim, ckEdittorContent]);

  const handleFocus = (e: any, classT: any, placeholder: any, classI: any, display = '') => {
    var container_tanach = document.getElementById('show-hidushim-send');
    var container_tanach1 = document.getElementById('show-hidushim-send1');
    if (container_tanach && container_tanach1) {
      (container_tanach1 as HTMLTextAreaElement).placeholder = placeholder;

      if (display === '') {
        container_tanach1.style.display = 'none';
      }
      else {
        container_tanach1.style.display = 'flex';
      }
      container_tanach.className = classI;
    }
    container_tanach = document.getElementById('show-hidushim-send-post');
    if (container_tanach) {
      container_tanach.className = classT;
    }

  }

  const handleChange = (e: any) => {
    body.set(nickname, e.editor.getData());
    setBody(body);
    return;
  }
  const handleChangeNew = (e: any) => {
    setBodyNew(e.editor.getData());
    dispatch(setCkEdittorContent(e.editor.getData()));
    return;
  }

  const handleSend = () => {
    var content = body.get(nickname);
    if (content && content !== ''.trim()) {
      var container_tanach1 = document.getElementById('show-hidushim-send1');
      if (container_tanach1)
        container_tanach1.innerHTML = '';
      setIsNone1(false);

      wikiService.handleSend(
        `חידוש:` + pageTitle + (selectedUnitText === null ? '0' : selectedUnitText) + `~${pageId.split(' ').join('_')}`,
        selectedUnitText,
        body.get(nickname),
        pageHidushim
      ).then((res) => {
        dispatch(setPageHidushim(res?.pageHidushim));
        setIsLoaded(false)
        setIsLoaded(true)
        handleFocus(null, 'show-hidushim-send-input-none', true ? 'כתוב את החידוש שלך ...' : 'יש לבחור יחידת טקסט', 'show-hidushim-send-input-none', ' ')
        setTimeout(() => {
          scrollIntoTextUnit(res?.workflow)
          setIsNone1(true);
        }, 1000);
      })
    }
  }
  const handleSendNew = () => {
    var content = bodyNew;
    if (content && content !== ''.trim()) {
      var container_tanach1 = document.getElementById('show-hidushim-send1');
      if (container_tanach1)
        container_tanach1.innerHTML = '';
      setIsNone1(false);
      dispatch(setCkEdittorContent(''));

      wikiService.handleSend(
        `חידוש:` + pageTitle + (selectedUnitText === null ? '0' : selectedUnitText) + `~${pageId.split(' ').join('_')}`,
        selectedUnitText,
        bodyNew,
        pageHidushim
      ).then((res) => {
        dispatch(setPageHidushim(res?.pageHidushim));
        setIsLoaded(false)
        setIsLoaded(true)
        handleFocus(null, 'show-hidushim-send-input-none', true ? 'כתוב את החידוש שלך ...' : 'יש לבחור יחידת טקסט', 'show-hidushim-send-input-none', ' ')
        setTimeout(() => {
          scrollIntoTextUnit(res?.workflow)
          setIsNone1(true);
        }, 1000);
      })
    }
  }

  function scrollIntoTextUnit(workflowId: any) {

    const showSfarimElement = document.getElementById(workflowId);

    if (showSfarimElement) {

      showSfarimElement.scrollIntoView({
        block: "center"
      });
    }
  }

  const viewTopics = () => {

    return pageHidushim.map((flow: any) => {
      let empty = true;
      if (Object.keys(flow).length === 0) {
        empty = false
      }
      const a = groupBy(flow, function (n: any) {
        return n.workflowId;
      });

      if (!empty)
        return (<></>)


      return (
        <>
          {a && a['undefined'].map((x: any) => {

            if (isArticles === 'חידושים' && x && x[0] && x[0].content && x[0].content.content && x[0].content.content.length < 3000)
              return (
                <>
                  {<div style={{ right: 0, position: 'relative', minHeight: 110, fontSize: 21 }}  >
                    <ViewTopic
                      topic={x[0]}
                      isUserLike={false}
                      LikedCounts={items2}
                      flowV={x}
                      styleTop={0}
                      isTitle={true}
                      level={false}
                      isNone={new Map<string, boolean>()}
                      increment={() => incremen(x)}
                      replys={replys1}
                    />
                  </div>}
                </>
              )
            if (isArticles === 'מאמרים' && x && x[0] && x[0].content && x[0].content.content && x[0].content.content.length > 3000)
              return (
                <>
                  {<div style={{ right: 0, position: 'relative', minHeight: 110, fontSize: 21 }}  >
                    <ViewTopic
                      topic={x[0]}
                      isUserLike={false}
                      LikedCounts={items2}
                      flowV={x}
                      styleTop={0}
                      isTitle={true}
                      level={false}
                      isNone={new Map<string, boolean>()}
                      increment={() => incremen(x)}
                      replys={replys1}
                    />
                  </div>}
                </>
              )
            if (isArticles === '')
              return (
                <>
                  {<div style={{ right: 0, position: 'relative', minHeight: 110, fontSize: 21 }}  >
                    <ViewTopic
                      topic={x[0]}
                      isUserLike={false}
                      LikedCounts={items2}
                      flowV={x}
                      styleTop={0}
                      isTitle={true}
                      level={false}
                      isNone={new Map<string, boolean>()}
                      increment={() => incremen(x)}
                      replys={replys1}
                    />
                  </div>}
                </>
              )
            return (<></>)
          })}
        </>
      )
    })
  }

  const getSelectEditor = () => {
    let defaultValue = [{ value: 'default', label: 'עזרה' }];
    return defaultValue.concat(
      { value: 'group', label: 'חבורה' },
      { value: 'summary', label: 'סיכום' },
      { value: 'study', label: 'לומדות' },
      { value: 'walk', label: 'מהלך' }
    );

  };
  const handleSector = (value: any) => {
    // if (value === 'group' || value === 'default') {
    body.set(nickname, '')
    setBody(body)
    setNickname(value)
    // }

  }
  const OpenTemplates = () => {
    dispatch(setIsOpenTemplates(!isOpenTemplate));
  };
  const expandTabHandler = () => {
    dispatch(clearTabOpenLeft());
  };

  return (<>
    {!isNone1 && progress > -1 &&
      <div className="loading-indicator-loadingsvg211">
        <CircularProgress color={"secondary"} thickness={1} size={"1rem"} variant="determinate" value={progress} />
        <MihlolLogo className="loading-indicator-gmara" />
      </div>
    }
    {window.location.origin === 'http://localhost:3000' && isNone1 &&
      <div className="show-hidushim-template"
        onClick={OpenTemplates}
      >
        <div className="show-hidushim-template-text">
          {'תבנית סיכום'}
        </div>
      </div>
    }
    {false && <div className="show-hidushim-select-editor">
      <Select

        classNamePrefix="select-editor"
        options={getSelectEditor()}

        isRtl={true}
        isSearchable={true}
        defaultValue={{ value: 'default', label: 'עזרה' }}
        onChange={(e: any) => handleSector(e.value)}
        maxMenuHeight={200}
      />
    </div>}
    <div className="show-hidushim-CloseIcon"
      onClick={expandTabHandler}
    >
      <Tooltip
        classes={classes}
        title={'סגור חידושים'}
        placement="right"
        arrow
      >
        <CloseIcon
          style={{
            position: 'relative',
            color: '#707070',
            top: '3'
          }}

        />
      </Tooltip>

    </div>
    {nickname !== 'group' && isNone1 && <div className='show-hidushim-send'>
      <CKEditor
        editorUrl={`${window.location.origin}/ckeditor/ckeditor.js`}
        initData={ckEdittorContent}
        config={{
          language: ['he'],
          editorplaceholder: ['כתוב את החידוש שלך...'],
          toolbar: [
            { name: 'clipboard', items: ['Cut', 'Copy', 'Paste'] },
            { name: '', items: ['Undo', 'Redo'] },
            { name: 'paragraph', items: ['Blockquote'] },
            { name: 'basicstyles', items: ['Bold'] },
            { name: 'tools', items: ['Maximize'] },
          ]
        }}
        onBeforeCommandExec={(event) => {
          if (event && event.data)
            if (event && event.data && event.data.name === "paste") {
              event.editor._.forcePasteDialog = true;
            }

        }}
        onChange={(e) => { handleChangeNew(e) }}
      />
    </div>
    }
    {nickname === 'group' &&
      <div className='show-hidushim-send'>
        <CKEditor
          editorUrl={`${window.location.origin}/ckeditor/ckeditor.js`}
          initData={
            `<p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;[כותרת]: [כותרת משנה]</p>
          <p>[כתיבת חבורה כוללת סיכום בהיר של השיטות המרכזיות בסוגייה, ורק לאחר מכאן ניתן להוסיף את החידוש העצמי. חשוב לכתוב בקטעים קצרים ולהוסיף כותרת לחבורה. אם החבורה כוללת כותרת משנה, הפרד אותה מהכותרת באמצעות נקודתיים ורווח, כמוצג.]&nbsp;</p>
          <p>[תקציר: רצוי לפתוח את החבורה&nbsp; בפתיח סיכומי קצר (תקציר) שיתן רקע למה שהולך לקרות במהלך החבורה, מומלץ לכתוב את התקציר לאחר השלמת כתיבת החבורה כולה ]<br></p><blockquote>
          <p>[נתחיל בציטוט קטע הגמרא משנה הרלוונטי. עבור ציטוטים הכוללים למעלה מארבע שורות, הסט פנימה את הציטוט ס"מ אחד מהשוליים הימניים ואל תשתמש במרכאות. כדי להחיל עיצוב זה, בכרטיסיה 'בית', בגלריה 'סגנונות', לחץ על 'ציטוט'. עבור ציטוטים קצרים יותר, תוכל להקיף אותם במרכאות ולשלב אותם ישירות בטקסט.]</p></blockquote>
          <p>[נמשיך בכתיבה סיכומית של כל אחד מהמפרשים המרכזים שעליהם נסובה החבורה, נחדד את ההבדלים בין הפירושים. מומלץ לשלב מעין ציטוטים של מילים בודדות 'מלשון המפרש עצמו' ולסמן אותם במרכאה.]<br><br><br>[נציע פרשנות (אישית או מגדולי האחרונים) לסיבת ההבדל בשיטה (המהלך בסוגיה)]</p>
          <p>[נאתגר הצעה זו באמצעות קושיה, ממקור כלשהו או מסברא]</p>
          <p>&nbsp;<br>[כאן נרשום או הצעה חדשה להסביר את הסוגיה פער בין שיטות האמוראים אשונים  חרונים או יישוב לקושיה]&nbsp;</p>
          <p><br></p>
          <p>[נסכם בקצרה את כל המהלך של החבורה,וננסח בבהירות את המסקנה העולה ממנו.]</p>
          <p><br><br></p>
          <p>[<strong>מראי מקומות:</strong> מומלץ ציין את מראי המקומות כדלהלן: תנ"ך - (שמות כב ד), משניות – (ביצה פ"א מ"ג), בבלי - (ביצה כז.), ירושלמי - (ביצה פ"א ה"ב), רמב"ם – (יום טוב פ"ב ה"א), שו"ע - (חו"מ סי' ד ס"ד), ש"ך (חו"מ סי' עב סק"ד). לא מומלץ להוסיף גרש/גרשיים/פסיק/מקף בתוך המ"מ, לדוגמא: (שמות כ"ב ד'), (שמות כב, ד), (ביצה כ"ז), (חו"מ סי' ד'), (ס"ק כ"ד), (חו"מ סי' ד, ס"ק כד). למעט במקרים שלהלן: בשמואל, מלכים ודברי הימים מומלץ לציין גרש על אות הספר (שמואל א' ל כט). כאשר כותבים את מראה המקום ללא סוגריים מומלץ להוסיף גרש/גרשיים, לדוגמא: אמנם בסימן קכ"ב (ס"ב) כתב ... או ובדף נ' או ובדף ק"י איתא.כשיש שני מקורות או יותר, מומלץ להניח פסיק בין המקורות, לדוגמא: (ירושלמי שקלים פ"א ה"ד, פרקי דרבי אליעזר פמ"ח).</p>
          <p>&nbsp;דיבור המתחיל: כשמציינים דיבור המתחיל בדרך כלל די להביא את המילה הראשונה של&nbsp; הד"ה. במקרה שיש באותו סימן/עמוד ד"ה הפותח במילה זהה, מומלץ להוסיף מילה או שנים למנוע טעות בזיהוי. כשיש שני ד"ה זהים לגמרי, מומלץ לכתוב כך: (ד"ה אמר רבא – הראשון/השני). כאשר מביאים פוסקים שנכתבו על הסימן הנוכחי, מומלץ לציין רק את הס"ק וכדומה ולא את הסימן.]<br></p>`}
          config={{
            language: ['he'],
            extraPlugins: ['editorplaceholder'],
            editorplaceholder: ['כתוב את החידוש שלך...'],
            toolbar: [
              { name: 'clipboard', items: ['Cut', 'Copy', 'Paste'] },
              { name: '', items: ['Undo', 'Redo'] },
              { name: 'paragraph', items: ['Blockquote'] },
              { name: 'basicstyles', items: ['Bold'] },
              { name: 'tools', items: ['Maximize'] },
            ]
          }}
          style={{ borderColor: 'blue' }}

          onBeforeCommandExec={(event) => {
            if (event && event.data)
              if (event && event.data && event.data.name === "paste") {
                event.editor._.forcePasteDialog = true;
              }

          }}
          onChange={(e) => { handleChange(e) }}
        />
      </div>
    }
    {isNone1 &&
      <div
        className={`${bodyNew === '' ? 'show-hidushim-send-post-rep-empty' : 'show-hidushim-send-post-rep'}`}
        onClick={handleSendNew}
      >
        <div
          className={`${bodyNew === '' ? 'show-hidushim-send-post-rep-text-empty' : 'show-hidushim-send-post-rep-text'}`}
        >
          {'פרסום'}
        </div>
      </div >}
    {
      <div className='hidushim-line-2'>
        <Line />
      </div>
    }
    {
      <div className='show-hidushim-send-post-rep-articel-main'>
        {<div
          className={isArticles !== '' ? `show-hidushim-send-post-rep-articel-green` : `show-hidushim-send-post-rep-articel`}
          onClick={() => {
            if (isArticles !== '') {
              dispatch(setIsArticles(''));
            }
          }}
        >
          <div className={isArticles !== '' ? 'show-hidushim-send-post-rep-text-articel-green' : `show-hidushim-send-post-rep-text-articel`}>
            {'הכל'}
          </div>
        </div>
        }
        {<div
          className={isArticles !== 'מאמרים' ? `show-hidushim-send-post-rep-articel-green` : `show-hidushim-send-post-rep-articel`}
          onClick={() => {
            if (isArticles !== 'מאמרים') {
              dispatch(setIsArticles('מאמרים'));
            }
          }}
        >
          <div className={isArticles !== 'מאמרים' ? 'show-hidushim-send-post-rep-text-articel-green' : `show-hidushim-send-post-rep-text-articel`}>
            {'מאמרים'}
          </div>
        </div>
        }
        {<div
          className={isArticles !== 'חידושים' ? `show-hidushim-send-post-rep-articel-green` : `show-hidushim-send-post-rep-articel`}
          onClick={() => {
            if (isArticles !== 'חידושים') {
              dispatch(setIsArticles('חידושים'));
            }
          }}
        >
          <div className={isArticles !== 'חידושים' ? 'show-hidushim-send-post-rep-text-articel-green' : `show-hidushim-send-post-rep-text-articel`}>
            {'חידושים ומ"מ'}
          </div>
        </div>}
      </div>
    }

    {
      isLoaded && pageHidushim &&
      <div className='selected-Unit-Text-12' >
        {selectedUnitText !== null && <div className='hidushim-line'>
          <Line />
        </div>}
        {
          <div className='selected-Unit-Text'>
            <ShowMoreText
              lines={2}
              more='קרא עוד>'
              less='קרא פחות>'
              anchorClass={'lll'}
              expanded={false}
              width={480}
              className='sss'
            >
              <div dangerouslySetInnerHTML={{ __html: textNumber[selectedUnitText] }} />
            </ShowMoreText>
          </div>
        }
        {viewTopics()}
      </div>
    }
    {
      !isLoaded &&
      <div className="loading-indicator-loadingsvg2">
        <CircularProgress color={"secondary"} thickness={1} size={"2rem"} variant="determinate" value={progress} />
        <MihlolLogo className="loading-indicator-gmara" />
      </div>
    }
  </>)
}

export default ShowHidushim;
