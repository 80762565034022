import React, { useEffect, useLayoutEffect, useState } from "react";
import { Breadcrumbs } from '@mui/material';
import config from "../../services/configService";
import Axios from "axios";
import "./OpenNav.css";
import wikiService from "../../services/wikiService";
import OpenNavList from "../OpenNavList/OpenNavList";
import { useDispatch, useSelector } from "react-redux";
import {
  changeMenuView,
  clearMenuView,
  closeMenu,
  setMenuBook,
  setUserProfileView,
  setCurrentEvents,
  setNotificationsAll,
} from "../../store/actions/viewActions";
import { parseBookPageToHebrew } from "../../utils/menuUtils";
import { ReactComponent as SearchCircle } from "../../svg/searchCircle.svg";
import { ReactComponent as SquareLine } from "../../svg/square-line.svg";
import { ReactComponent as SquareLineDown } from "../../svg/square-line-down.svg";
import { getPageData, } from "../../store/actions/pageActions";
import {
  getAllBooks,
  getBookName,
  getCurrentBook,
  getHeTitleBook,
  getTitleBook,
  getIsMenuOpen,
  getMenuAllPages,
  getMenuChapters,
  getMenuChaptersView,
  getMenuView,
  getPageId,
  getCategoryName,
  getSederName,
  getUserProfileView,
  getIsLoading,
  getContentCountsMenu,
  getIsOpenNavPage,
} from "../../store/selectors/selectors";
import isMobile from "ismobilejs";
import { list } from "../../utils/booksAndChapters";
import { ThunkDispatch } from "@reduxjs/toolkit";
import history from "../../history/history";
const OpenNav = () => {

  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const isMenuOpen = useSelector(getIsMenuOpen);
  const menuView = useSelector(getMenuView);
  const allBooksNames = useSelector(getAllBooks);
  const pageId = useSelector(getPageId);
  const currentBook = useSelector(getCurrentBook);
  const currentHeBook = useSelector(getBookName);
  const chosenBookHeName = useSelector(getHeTitleBook);
  const titleBook = useSelector(getTitleBook);
  const chosenBookChapters = useSelector(getMenuChapters);
  const chosenBookAllPages = useSelector(getMenuAllPages);
  const menuChaptersView = useSelector(getMenuChaptersView);
  const categoryName = useSelector(getCategoryName);
  const sederName = useSelector(getSederName);
  const isLoading = useSelector(getIsLoading);
  const contentCounts = useSelector(getContentCountsMenu);
  const [sederLocal, setSederLocal] = useState('');
  const [pageLocal, setPageLocal] = useState(pageId);
  const [valueSearch, setValueSearch] = useState('');
  const userProfileView = useSelector(getUserProfileView);
  const [tree, setTree] = useState<any>([]);
  const isOpenNavPage = useSelector(getIsOpenNavPage);

  const clearView = () => {
    setTree([])
    dispatch(clearMenuView());
  };

  const handleViewChange = (view: string, render = '') => {

    if (render !== '')
      setSederLocal(render);
    dispatch(changeMenuView(view));

  };

  const getRenderSeder = () => {

    if (sederLocal === 'Writings')
      return 4;
    if (sederLocal === 'Prophets')
      return 3;

    return 4;
  }
  const getSederNameT = () => {

    if (sederLocal === 'Writings')
      return 'כתובים';
    if (sederLocal === 'Prophets')
      return 'נביאים';
    if (sederName === 'Writings')
      return 'כתובים';
    if (sederName === 'Prophets')
      return 'נביאים';
    else return 'V'
  }


  const handleChooseBook = (heBook: string, bookName: string, bookChosen: any, seder: any = '') => {
    dispatch(setMenuBook(heBook, bookName, bookChosen, seder));
    handleViewChange(bookChosen);
  };

  const handleChooseBookVer = (heBook: string, bookName: string, bookChosen: any, seder: any = '') => {
    handleViewChange(bookChosen);
  };

  const checkViewAllbooksOrBookChose = () => {
    if (menuView === "allbooks" || menuView === "bookChosen") {
      return true;
    } else return false;
  };
  const checkViewAllbooksOrBookChoseYer = () => {
    if (menuView === "allbooksYer" || menuView === "bookChosenYer") {
      return true;
    } else return false;
  };
  const checkViewAllbooksOrBookChoseTalCom = () => {
    if (menuView.includes("allbooksTalCom") || menuView === "bookChosenTalCom") {
      return true;
    } else return false;
  };
  const checkViewAllbooksOrBookChoseKabala = () => {
    if (menuView.includes("allbooksKabala") || menuView === "bookChosenKabala") {
      return true;
    } else return false;
  };
  const checkViewAllbooksOrBookChoseTosefta = () => {
    if (menuView.includes("allbooksTosefta") || menuView === "bookChosenTosefta") {
      return true;
    } else return false;
  };
  const checkViewAllbooksOrBookChoseLiturgy = () => {
    if (menuView.includes("allbooksLiturgy") || menuView === "bookChosenLiturgy") {
      return true;
    } else return false;
  };
  const checkViewAllbooksOrBookChoseTurCom = () => {
    if (menuView.includes("allbooksTurCom") || menuView === "bookChosenTurCom") {
      return true;
    } else return false;
  };
  const checkViewAllbooksOrBookChoseHalCom = () => {
    if (menuView.includes("allbooksHalCom") || menuView === "bookChosenHalCom") {
      return true;
    } else return false;
  };
  const checkViewAllbooksOrBookChoseChasidut = () => {
    if (menuView.includes("allbooksChasidut") || menuView === "bookChosenChasidut") {
      return true;
    } else return false;
  };
  const checkViewAllbooksOrBookChoseComTor = () => {
    if (menuView.includes("allbooksComTor") || menuView === "bookChosenComTor") {
      return true;
    } else return false;
  };
  const checkViewAllbooksOrBookChoseComNach = () => {
    if (menuView.includes("allbooksComNach") || menuView === "bookChosenComNach") {
      return true;
    } else return false;
  };
  const checkViewAllbooksOrBookChoseResponsa = () => {
    if (menuView.includes("allbooksResponsa") || menuView === "bookChosenResponsa") {
      return true;
    } else return false;
  };
  const checkViewAllbooksOrBookChoseHalMid = () => {
    if (menuView.includes("allbooksHalMid") || menuView === "bookChosenHalMid") {
      return true;
    } else return false;
  };
  const checkViewAllbooksOrBookChoseSmallMas = () => {
    if (menuView.includes("allbooksSmallMas") || menuView === "bookChosenSmallMas") {
      return true;
    } else return false;
  };
  const checkViewAllbooksOrBookChoseMusar = () => {
    if (menuView.includes("allbooksMusar") || menuView === "bookChosenMusar") {
      return true;
    } else return false;
  };

  const checkViewAllbooksOrBookChoseRam = () => {
    if (menuView === "allbooksRam" || menuView === "bookChosenRam") {
      return true;
    } else return false;
  };
  const checkViewAllbooksOrBookChoseMish = () => {
    if (menuView === "allbooksMish" || menuView === "bookChosenMish") {
      return true;
    } else return false;
  };

  const checkViewAllbooksOrBookChoseT = () => {
    if (menuView === "allbooksTea" || menuView === "bookChosenTea" || menuView === "bookChosenTeaTea") {
      return true;
    } else return false;
  };


  const checkViewAllbooksOrBookChoseS = () => {
    if (menuView === "allbooksSul" || menuView === "bookChosenSul") {
      return true;
    } else return false;
  };

  const checkViewAllbooksOrBookChoseProphets = () => {
    if (menuView === "allbooksProphets" || menuView === "bookChosenProphets" || menuView === "bookChosenProphetsProphets") {
      return true;
    } else return false;
  };
  const checkViewAllbooksOrBookChoseVer = () => {
    if (menuView === "allbooksVarious" || menuView === "allbooksVariousSub" || menuView === "allbooksVariousSubSub") {
      return true;
    } else return false;
  };
  const checkViewAllbooksOrBookChoseVerSub = () => {
    if (menuView === "allbooksVariousSub" || menuView === "allbooksVariousSubSub") {
      return true;
    } else return false;
  };
  const checkViewAllbooksOrBookChoseVerSubSub = () => {
    if (menuView === "allbooksVariousSubSub") {
      return true;
    } else return false;
  };

  const handleSelectPage = (bookId: string, categoryName: any) => {
    if (userProfileView === "MenuMobile") {
      dispatch(setUserProfileView(""));
    }
    if (bookId) {

      if (categoryName === 'Pesach_Haggadah') {
        Axios.get(
          `${config.shitufta_api_url}v2/index/${categoryName}`
        ).then(result => {

          if (result.data.schema.nodes.find((x: any) => x.key === bookId).nodes) {
            result.data.schema.nodes.find((x: any) => x.key === bookId).nodes.map((y: any) => { return { title: `,_${bookId},_${y.title.replace(' ', '_').replace(' ', '_')}`, heTitle: `${y.heTitle}` } })
            dispatch(closeMenu());
            dispatch(getPageData(categoryName, result.data.schema.nodes.find((x: any) => x.key === bookId).nodes.map((y: any) => { return { title: `,_${bookId},_${y.title.replace(' ', '_').replace(' ', '_')}`, heTitle: `${y.heTitle}` } })[0].title));
            dispatch(setCurrentEvents(''));
          }
          else {
            dispatch(closeMenu());
            dispatch(getPageData(categoryName, `,_${bookId.replace(' ', '_')}`));
            dispatch(setCurrentEvents(''));
            return;
          }

        })
      } else if (bookId) {
        const bookSplit = bookId.split(" ").join("_").split("_");
        const page = bookSplit[bookSplit.length - 1];
        bookSplit.splice(-1);
        const book = bookSplit.join("_");
        dispatch(closeMenu());
        dispatch(getPageData(book, page.split(':')[0]));
        dispatch(setCurrentEvents(''));
      }
    }
  };



  useLayoutEffect(() => {
    function scrollIntoSelectedPage() {
      if (menuView.includes("allbooks")) {
        const selectedBook = document.getElementsByClassName(
          "openNav-book-item-current"
        )[0];
        if (selectedBook) {
          setTimeout(() => {
            selectedBook.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 200);
        }
      }
      if (menuView.includes("bookChosen")) {
        const selectedPage = document.getElementById("selected-page");
        const selectedContainer = document.getElementById("openNav");
        const topPagesContainer = document.getElementsByClassName(
          "react-select-container"
        )[0];
        if (selectedPage && selectedContainer) {
          setTimeout(() => {
            selectedPage.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 200);
        } else if (!selectedPage && topPagesContainer) {
          setTimeout(() => {
            topPagesContainer.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 0);
        }
      }
    }
    scrollIntoSelectedPage();
  }, [menuView, isMenuOpen, chosenBookHeName]);




  useEffect(() => {

    const getView = () => {
      if (categoryName[2] === "Minor Tractates" || categoryName[1] === "Minor Tractates") {
        return 'bookChosenSmallMas'
      }
      if (categoryName[0] === 'Talmud') {
        if (categoryName[2].includes('on Talmud')) {
          return 'bookChosenTalCom'
        }
        if (categoryName[1] === 'Yerushalmi') {
          return 'bookChosenYer'
        }
        if (categoryName[2] === 'Acharonim on Talmud') {
          return 'bookChosenTalCom'
        }
        if (categoryName[2] === 'Rishonim on Talmud') {
          return 'bookChosenTalCom'
        }
        return 'bookChosen'
      }
      else if (categoryName[0] === 'Tanakh') {
        if (categoryName[1] === 'Torah')
          return 'bookChosenTeaTea'
        else if (categoryName[1] === 'Prophets')
          return 'bookChosenProphetsProphets'
        else if (categoryName[1] === 'Writings')
          return 'bookChosenProphetsProphets'
        else if (categoryName[1] === "Rishonim on Tanakh" || categoryName[1] === "Acharonim on Tanakh")
          return 'bookChosenComTor'
        else if (categoryName[1] === "Commentary" && (categoryName.length === 2 || categoryName.length === 3) &&
          (
            categoryName[2] !== 'Imrei Yosher' &&
            categoryName[2] !== 'Megillat Setarim' &&
            categoryName[2] !== 'Nachal Eshkol' &&
            categoryName[2] !== 'Palgei Mayim' &&
            categoryName[2] !== `Ta'alumot Chokhmah` &&
            categoryName[2] !== 'Malbim'
          ))
          return 'bookChosenComTor'
        else if (categoryName[1] === "Commentary" && categoryName[3] !== "Torah")
          return 'bookChosenComNach'
      }
      else if (categoryName[1] === 'Shulchan Arukh') {
        if (categoryName[2] === 'Commentary') {
          return 'bookChosenHalCom'
        }
        return 'bookChosenSul'
      }
      else if (categoryName[1] === 'Mishneh Torah') {
        return 'bookChosenRam'
      }
      else if (categoryName[0] === 'Halakhah' && categoryName[1] === "Tur") {
        return 'bookChosenTurCom'
      }


      else if (categoryName[0] === 'Halakhah') {
        return 'bookChosenHalCom'
      }
      else if (categoryName[0] === 'Mishnah') {
        return 'bookChosenMish'
      }
      else if (categoryName[0] === 'Kabbalah') {
        return 'bookChosenKabala'
      }
      else if (categoryName[0] === 'Tanaitic') {
        return 'bookChosenTosefta'
      }
      else if (categoryName[0] === 'Kabbalah') {
        return 'bookChosenTurCom'
      }
      else if (categoryName[0] === `Chasidut`) {
        return 'bookChosenChasidut'
      }
      else if (categoryName[0] === `Musar`) {
        return 'bookChosenMusar'
      }
      else if (categoryName[0] === `Tosefta`) {
        return 'bookChosenTosefta'
      }
      else if (categoryName[0] === `Responsa`) {
        return 'bookChosenResponsa'
      }
      else if (categoryName[0] === `Midrash`) {
        return 'bookChosenHalMid'
      }
      else if (categoryName[0] === `Liturgy` && categoryName[1] !== 'Haggadah') {
        return 'bookChosenLiturgy'
      }

    }

    if (!isLoading && currentBook && currentHeBook && categoryName !== null && (isMenuOpen || isOpenNavPage)) {
      let view = getView();
      if (categoryName[1] === 'Haggadah') {
        dispatch(setMenuBook(currentHeBook, currentBook, 'allbooksVariousSubSub'));
      }
      else {
        dispatch(setMenuBook(currentHeBook, currentBook, view));
      }
    }
    wikiService.getNotifications('list', 'read|!read', '').then((res: any) => {
      if (res && res.notifications)
        dispatch(setNotificationsAll(res.notifications.list.reverse()));
    });
  }, [currentHeBook, categoryName, currentBook, isLoading, dispatch, isMenuOpen]);





  const getChapterPages = () => {

    const filterdBook = chosenBookChapters.filter((chapter: any) => {
      return chapter.chapterName === menuChaptersView;
    });
    if (filterdBook[0]) {
      return filterdBook[0].chapters.map((page: any) => {
        return (
          <div
            className={
              page === pageId
                ? "bookChosen-page-item bookChosen-current-item"
                : "bookChosen-page-item"
            }
            key={page}
            onClick={() => handleSelectPage(page, 'talmud')}
          >
            <p className="bookChosen-page-item-text">
              {parseBookPageToHebrew(page.split(':')[0], menuView === 'bookChosen')}
            </p>
          </div>
        );
      });
    } else return null;
  };

  const getVarious = () => {
    return [
      {
        "bookName": "Tefilot",
        "heName": "תפילות"
      },
      {
        "bookName": "Various",
        "heName": "שונות"
      }
    ];
  }
  const getVariousSub = () => {
    return [
      {
        "bookName": "Haggadah",
        "heName": "הגדה של פסח"
      }
    ];
  }

  const onChangeValue = (e: any) => {
    setValueSearch(e.target.value)
  }


  const onSubmitValue = () => {
    Axios.get(`${config.shitufta_api_url}name/${encodeURIComponent(valueSearch.replace("'", ''))}`).then((res) => {

      if (res.data.is_book === true) {
        if (list.includes(res.data.book.split(' ').join('_')) || ["Genesis", "Exodus", "Numbers", "Leviticus", "Deuteronomy"].includes(res.data.book.split(' ').join('_'))) {
          if (res.data.sectionNames[0] === 'Siman' || res.data.sectionNames[1] === 'Seif')
            handleChooseBook(res.data.completions[0], res.data.book, 'bookChosenSul')
          else if (res.data.sectionNames[0] === 'Chapter' || res.data.sectionNames[1] === 'Verse')
            handleChooseBook(res.data.completions[0], res.data.book, 'bookChosenTea')
          else
            handleChooseBook(res.data.completions[0], res.data.book, 'bookChosen')
        }
      }
      else if (res.data.book && ["Genesis", "Exodus", "Numbers", "Leviticus", "Deuteronomy"].includes(res.data.book.split(' ').join('_'))) {
        dispatch(closeMenu());
        dispatch(getPageData(res.data.book, res.data.ref.split(' ')[1].split('-')[0].replace(':', '.')));
        dispatch(setCurrentEvents(''));
      }
      else if (res.data.book && list.includes(res.data.book.split(' ').join('_'))) {
        dispatch(closeMenu());
        dispatch(getPageData(res.data.book, res.data.url.split('.')[1] + (res.data.url.split('.').length > 2 ? '.' + res.data.url.split('.')[2] : '')));
        dispatch(setCurrentEvents(''));
      }
    });
    // })
  }



  const basicNemuView = () => {



    const buttons = [
      {
        book: 'תורה',
        view: "allbooksTea",
        onclick: true
      },
      {
        book: 'תלמוד בבלי',
        view: "allbooks",
        onclick: true
      },
      {
        book: 'רמב"ם ונו"כ',
        view: "allbooksRam",
        onclick: true
      },
      {
        book: 'מדרשי הלכה',
        view: "allbooksHalMid",
        onclick: true
      },
      {
        book: 'נביאים',
        view: "allbooksProphets",
        onclick: true,
        categories: 'Prophets'
      },
      {
        book: 'מפרשי הבבלי',
        view: "allbooksTalCom",
        onclick: true
      },
      {
        book: 'טור  ונו"כ',
        view: "allbooksTurCom",
        onclick: true
      },
      {
        book: 'סדר התפילה',
        view: "allbooksLiturgy",
        onclick: true
      },

      {
        book: 'כתובים',
        view: "allbooksProphets",
        onclick: true,
        categories: 'Writings'
      },
      {
        book: 'תלמוד ירושלמי',
        view: "allbooksYer",
        onclick: true
      },
      {
        book: 'שולחן ערוך',
        view: "allbooksSul",
        onclick: true
      },
      {
        book: 'ספרי מוסר',
        view: "allbooksMusar",
        onclick: true
      },
      {
        book: 'מפרשי התורה',
        view: "allbooksComTor",
        onclick: true
      },
      {
        book: 'משניות',
        view: "allbooksMish",
        onclick: true,
      },
      {
        book: 'ספרי הלכה',
        view: "allbooksHalCom",
        onclick: true
      },
      {
        book: 'ספר הזוהר',
        view: "allbooksProphets",
        onclick: false
      },
      {
        book: 'מפרשי הנ"ך',
        view: "allbooksComNach",
        onclick: true
      },
      {
        book: 'מפרשי המשנה',
        view: "allbooksSul",
        onclick: false
      },
      {
        book: 'שו"ת',
        view: "allbooksResponsa",
        onclick: false
      },
      {
        book: 'ספרי קבלה',
        view: "allbooksKabala",
        onclick: true
      },
      {
        book: 'ספרי חסידות',
        view: "allbooksChasidut",
        onclick: false
      },
      {
        book: 'מסכתות קטנות',
        view: "allbooksSmallMas",
        onclick: true
      },
      {
        book: 'ספרי מחשבה',
        view: "allbooks",
        onclick: false,
      },
      {
        book: 'תוספתא',
        view: "allbooksTosefta",
        onclick: true
      },
      {
        book: 'מדרשי אגדה',
        view: "allbooks",
        onclick: false
      },
      {
        book: 'ספרים שונים',
        view: "allbooksVarious",
        onclick: true
      },
    ]

    return buttons.map((button: any) => {
      return (
        <div key={button.book}
          className={!(isMobile().phone || isMobile().tablet) ? ` ${button.onclick === true ? 'openNav-choose-subject' : 'openNav-choose-subject-water'}` : 'openNav-choose-subject-mobile'}
          onClick={() => {
            if (button.onclick === true) {
              handleViewChange(button.view, button.categories)
            }

          }}
        >
          {button.book}
        </div>
      )
    })
  }








  /*const remuveHeTitle = (heTitle: any) => {
    if (heTitle)
      return [
        'עין אי"ה',
        'לנבוכי הדור',
        'מאמר הדור',
        'שו"ת במראה הבזק',
        'תשובות לקציני דת (אנגלית)',
        'מרכז לינדנבאום',
        'קול דודי דופק',
        'במצולות ספר יונה',
        'גן נעול',
        'אורות',
        'ענקי הרוח שלנו',
        'מאמר מזכה הרבים',
        'מילון קליין',
        'מילון יסטרוב',
        'אוצר לעזי רש"י',
        'הפלאה שבערכין על ספר',
        'פירושים מודרניים',
        'דברי שלום ואמת',
        'בעיות הלכתיות עכשוויות',
        'פדיון הרלוונטיות',
        'מדרשי פילון',
        'תשובות בעת מלחמה (אנגלית)',
        'הערות שוליים',
        "מלחמות השם יחיא קאפח"
      ].filter(e => heTitle.includes(e)).length === 0;
    else
      return false
  }*/

  const allbooksNemuView = (view: any, number: any) => {

    if (view === 'bookChosenRam') {
      return (
        <div className={!(isMobile().phone || isMobile().tablet) ? "openNav-allbooks-container-ram" : 'openNav-allbooks-container-mobile-1'}>
          {allBooksNames && allBooksNames[number].map((seder: any) => {
            return (
              <div
                className="openNav-allbooks-list-container"
                key={seder.name}
              >
                {seder.name &&
                  <h4 className="allbooks-list-header-ram">{seder.name}</h4>
                }
                <ul className={!(isMobile().phone || isMobile().tablet) ? `openNav-allbooks-list-Prophets` : "openNav-allbooks-list-mobile-1"}>
                  {seder.books.map((book: any) => {
                    if (currentBook === book.bookName) {
                      return (
                        <li
                          key={book.bookName}
                          className={`openNav-book-item-ram openNav-book-item-current ` + ((isMobile().phone || isMobile().tablet) ? ' mobile-1' : '')}
                          onClick={() => {
                            handleChooseBook(book.heName, book.bookName, view)
                          }
                          }
                        >
                          {book.heName}
                        </li>
                      );
                    } else {

                      return (
                        <li
                          key={book.bookName}
                          className={`openNav-book-item-ram openNav-book-item ` + ((isMobile().phone || isMobile().tablet) ? ' mobile-1' : '')}
                          onClick={() => {
                            handleChooseBook(book.heName, book.bookName, view)
                          }
                          }
                        >
                          {book.heName}
                        </li>
                      );
                    }
                  })}
                </ul>
              </div>
            );
          })
          }
        </div >
      )
    }
    if (
      view === 'bookChosenTalCom' ||
      view === 'bookChosenHalCom' ||
      view === 'bookChosenChasidut' ||
      view === 'bookChosenComTor' ||
      view === 'bookChosenComNach' ||
      view === 'bookChosenResponsa' ||
      view === 'bookChosenHalMid' ||
      view === 'bookChosenMusar' ||
      view === 'bookChosenTurCom' ||
      view === 'bookChosenKabala' ||
      view === 'bookChosenTosefta'
    ) {
      return (
        <div className={!(isMobile().phone || isMobile().tablet) ? "openNav-allbooks-container-ram" : 'openNav-allbooks-container-mobile-1'}>
          <div className="openNav-allbooks-list-container" >
            <ul className={!(isMobile().phone || isMobile().tablet) ? `openNav-allbooks-list-Prophets` : "openNav-allbooks-list-mobile-1"}>
              {allBooksNames && getBook(allBooksNames[number]).map((seder: any, index: any) => {

                if (seder.bookName) {
                  return (
                    < li
                      key={seder.bookName}
                      className={'openNav-book-item-ram' + (currentBook.split(',')[0] === seder.bookName ? ' openNav-book-item-current ' : '')}
                      onClick={() => {
                        handleChooseBook(seder.heName, seder.bookName, view)
                        const lTree = tree;
                        lTree.push(index)
                        setTree(lTree);
                      }
                      }
                    >
                      {seder.heName}
                    </li>
                  );
                }
                if (seder.books) {
                  return (
                    <li
                      key={seder.bookName}
                      className={`openNav-book-item-ram`}
                      onClick={() => {
                        if (view === 'bookChosenHalCom')
                          handleViewChange('allbooksHalCom' + Math.random());
                        else if (view === 'bookChosenHalCom')
                          handleViewChange('allbooksHalCom' + Math.random());
                        else if (view === 'bookChosenChasidut')
                          handleViewChange('allbooksChasidut' + Math.random());
                        else if (view === 'bookChosenComTor')
                          handleViewChange('allbooksComTor' + Math.random());
                        else if (view === 'bookChosenComNach')
                          handleViewChange('allbooksComNach' + Math.random());
                        else if (view === 'bookChosenResponsa')
                          handleViewChange('allbooksResponsa' + Math.random());
                        else if (view === 'bookChosenHalMid')
                          handleViewChange('allbooksHalMid' + Math.random());
                        else if (view === 'bookChosenMusar')
                          handleViewChange('allbooksMusar' + Math.random());
                        else if (view === 'bookChosenTalCom')
                          handleViewChange('allbooksTalCom' + Math.random());
                        else if (view === 'bookChosenKabala')
                          handleViewChange('allbooksKabala' + Math.random());
                        else if (view === 'bookChosenTosefta')
                          handleViewChange('allbooksTosefta' + Math.random());
                        else if (view === 'bookChosenTurCom')
                          handleViewChange('allbooksTurCom' + Math.random());
                        else if (view === 'bookChosenLiturgy')
                          handleViewChange('allbooksLiturgy' + Math.random());
                        const lTree = tree;
                        lTree.push(index)
                        setTree(lTree);
                      }
                      }
                    >
                      {seder.heCategory}
                    </li>
                  )
                }
                else return (<></>)
              })
              }
            </ul>
          </div>
        </div >
      )
    }
    if (
      view === 'bookChosenLiturgy'
    ) {
      return (
        <div className={!(isMobile().phone || isMobile().tablet) ? "openNav-allbooks-container-ram" : 'openNav-allbooks-container-mobile-1'}>
          <div className="openNav-allbooks-list-container" >
            <ul className={!(isMobile().phone || isMobile().tablet) ? `openNav-allbooks-list-Prophets` : "openNav-allbooks-list-mobile-1"}>
              {allBooksNames && getBook(allBooksNames[number]).map((seder: any, index: any) => {

                if (seder.bookName) {
                  return (
                    < li
                      key={seder.bookName}
                      className={'openNav-book-item-ram' + (currentBook.split(',')[0] === seder.bookName ? ' openNav-book-item-current ' : '')}
                      onClick={() => {
                        handleChooseBook(seder.heName, seder.bookName, view)
                        const lTree = tree;
                        lTree.push(index)
                        setTree(lTree);
                      }
                      }
                    >
                      {seder.heName}
                    </li>
                  );
                }
                if (seder.books) {
                  return (
                    <li
                      key={seder.bookName}
                      className={`openNav-book-item-ram`}
                      onClick={() => {
                        if (view === 'bookChosenHalCom')
                          handleViewChange('allbooksHalCom' + Math.random());
                        else if (view === 'bookChosenHalCom')
                          handleViewChange('allbooksHalCom' + Math.random());
                        else if (view === 'bookChosenChasidut')
                          handleViewChange('allbooksChasidut' + Math.random());
                        else if (view === 'bookChosenComTor')
                          handleViewChange('allbooksComTor' + Math.random());
                        else if (view === 'bookChosenComNach')
                          handleViewChange('allbooksComNach' + Math.random());
                        else if (view === 'bookChosenResponsa')
                          handleViewChange('allbooksResponsa' + Math.random());
                        else if (view === 'bookChosenHalMid')
                          handleViewChange('allbooksHalMid' + Math.random());
                        else if (view === 'bookChosenMusar')
                          handleViewChange('allbooksMusar' + Math.random());
                        else if (view === 'bookChosenTalCom')
                          handleViewChange('allbooksTalCom' + Math.random());
                        else if (view === 'bookChosenKabala')
                          handleViewChange('allbooksKabala' + Math.random());
                        else if (view === 'bookChosenTosefta')
                          handleViewChange('allbooksTosefta' + Math.random());
                        else if (view === 'bookChosenTurCom')
                          handleViewChange('allbooksTurCom' + Math.random());
                        else if (view === 'bookChosenLiturgy')
                          handleViewChange('allbooksLiturgy' + Math.random());
                        const lTree = tree;
                        lTree.push(index)
                        setTree(lTree);
                      }
                      }
                    >
                      {seder.heCategory}
                    </li>
                  )
                }
                else return (<></>)
              })
              }
            </ul>
          </div>
        </div >
      )
    }

    return (
      <div className={!(isMobile().phone || isMobile().tablet) ? "openNav-allbooks-container" : 'openNav-allbooks-container-mobile-1'}>
        {allBooksNames && allBooksNames[number].map((seder: any) => {

          return (
            <div
              className="openNav-allbooks-list-container"
              key={seder.name}
            >
              {seder.name &&
                <h4 className="allbooks-list-header">{seder.name}</h4>
              }
              <ul className={!(isMobile().phone || isMobile().tablet) ? `openNav-allbooks-list${view === 'bookChosenProphets' ? '-Prophets ' : ''}` : "openNav-allbooks-list-mobile-1"}>
                {seder.books && seder.books.map((book: any) => {
                  if (currentBook === book.bookName) {

                    return (
                      <li
                        key={book.bookName}
                        className={`openNav-book-item${view === 'bookChosenProphets' ? '-Prophets ' : ''} openNav-book-item-current ` + ((isMobile().phone || isMobile().tablet) ? ' mobile-1' : '')}
                        onClick={() => {
                          if (seder.nameHe !== 'Prophets' && seder.nameHe !== 'Writings')
                            handleChooseBook(book.heName, book.bookName, view, seder.nameHe)
                          else
                            handleChooseBook(book.heName, book.bookName, view)
                          if (view === 'bookChosenProphets')
                            setSederLocal(sederLocal)
                        }
                        }
                      >
                        {book.heName}
                      </li>
                    );
                  } else {

                    return (
                      <li
                        key={book.bookName}
                        className={`openNav-book-item${(view === 'bookChosenProphets') ? '-Prophets ' : view === 'bookChosenSmallMas' ? '-SmallMas' : ''}` + ((isMobile().phone || isMobile().tablet) ? ' mobile-1' : '')}
                        onClick={() => {
                          if (seder.nameHe !== 'Prophets' && seder.nameHe !== 'Writings')
                            handleChooseBook(book.heName, book.bookName, view, seder.nameHe)
                          else
                            handleChooseBook(book.heName, book.bookName, view)
                        }

                        }
                      >
                        {book.heName}
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
          );
        })
        }
        <div
          className={
            !(isMobile().phone || isMobile().tablet) ?
              "openNav-main-container-empty" :
              "openNav-main-container-empty"
          }
        ></div>

      </div >
    )

  }

  const getBook = (books: any) => {
    tree.map((node: any) => {
      if (books[node] && books[node].books)
        books = books[node].books;
      else {
        return books[node];
      }
      return ''
    })

    return books;
  }
  const getTitleBooke = (level: any, index: any) => {
    let books = allBooksNames[index][tree[0]]
    var i;
    for (i = 1; i < level; i++) {

      if (i + 1 < level)
        books = books.books;

    }

    if (books && books.heCategory)
      return books.heCategory;
    else if (books && books.heName) {
      return books.heName;
    }
    else if (books && books[tree[i - 2]].heCategory) {
      return books[tree[i - 2]].heCategory;
    }
    return 's'
  }
  const BreadcrumbsOne = (level: any) => {
    // Breadcrumbsדרגה ראשונה של ה

    let book = '';
    let view = '';
    if (checkViewAllbooksOrBookChose() && level === 1) {
      book = 'תלמוד בבלי';
      view = "allbooks";
    }
    if (checkViewAllbooksOrBookChoseYer() && level === 1) {
      book = 'תלמוד ירושלמי';
      view = "allbooksYer";
    }
    if (checkViewAllbooksOrBookChoseTalCom() && level === 1) {
      book = 'מפרשי הבבלי';
      view = "allbooksTalCom";
    }

    if (checkViewAllbooksOrBookChoseKabala() && level === 1) {
      book = 'קבלה';
      view = "allbooksKabala";
    }
    if (checkViewAllbooksOrBookChoseTosefta() && level === 1) {
      book = 'תוספתא';
      view = "allbooksTosefta";
    }
    if (checkViewAllbooksOrBookChoseLiturgy() && level === 1) {
      book = 'סדר התפילה';
      view = "allbooksLiturgy";
    }
    if (checkViewAllbooksOrBookChoseTurCom() && level === 1) {
      book = 'טור ונו"כ';
      view = "allbooksTurCom";
    }
    if (checkViewAllbooksOrBookChoseHalCom() && level === 1) {
      book = 'ספרי הלכה';
      view = "allbooksHalCom";
    }
    if (checkViewAllbooksOrBookChoseChasidut() && level === 1) {
      book = "ספרי חסידות";
      view = "allbooksChasidut";
    }
    if (checkViewAllbooksOrBookChoseComTor() && level === 1) {
      book = "מפרשי התורה";
      view = "allbooksComTor";
    }
    if (checkViewAllbooksOrBookChoseComNach() && level === 1) {
      book = `מפרשי הנ"ך`;
      view = "allbooksComNach";
    }
    if (checkViewAllbooksOrBookChoseResponsa() && level === 1) {
      book = `שו"ת`;
      view = "allbooksResponsa";
    }
    if (checkViewAllbooksOrBookChoseHalMid() && level === 1) {
      book = `מדרשי הלכה`;
      view = "allbooksHalMid";
    }
    if (checkViewAllbooksOrBookChoseSmallMas() && level === 1) {
      book = 'מסכתות קטנות';
      view = "allbooksSmallMas";
    }
    if (checkViewAllbooksOrBookChoseMusar() && level === 1) {
      book = 'ספרי מוסר';
      view = "allbooksMusar";
    }
    if (tree.length !== 0 && checkViewAllbooksOrBookChoseTalCom() && level !== 1 && level < tree.length + 2) {
      book = getTitleBooke(level, 10);
      view = "allbooksTalCom";
    }
    if (tree.length !== 0 && checkViewAllbooksOrBookChoseHalCom() && level !== 1 && level < tree.length + 2) {
      book = getTitleBooke(level, 11);
      view = "allbooksHalCom";
    }
    if (tree.length !== 0 && checkViewAllbooksOrBookChoseSmallMas() && level !== 1 && level < tree.length + 2) {
      book = getTitleBooke(level, 12);
      view = "allbooksSmallMas";
    }

    if (tree.length !== 0 && checkViewAllbooksOrBookChoseKabala() && level !== 1 && level < tree.length + 2) {
      book = getTitleBooke(level, 13);
      view = "allbooksKabala";
    }
    if (checkViewAllbooksOrBookChoseLiturgy() && level !== 1 && level < tree.length + 2) {
      book = getTitleBooke(level, 14);
      view = "allbooksLiturgy";
    }
    if (checkViewAllbooksOrBookChoseChasidut() && level !== 1 && level < tree.length + 2) {
      book = getTitleBooke(level, 15);
      view = "allbooksChasidut";
    }
    if (checkViewAllbooksOrBookChoseMusar() && level !== 1 && level < tree.length + 2) {
      book = getTitleBooke(level, 16);
      view = "allbooksMusar";
    }
    if (checkViewAllbooksOrBookChoseResponsa() && level !== 1 && level < tree.length + 2) {
      book = getTitleBooke(level, 17);
      view = "allbooksResponsa";
    }
    if (checkViewAllbooksOrBookChoseComTor() && level !== 1 && level < tree.length + 2) {
      book = getTitleBooke(level, 18);
      view = "allbooksComTor";
    }
    if (checkViewAllbooksOrBookChoseComNach() && level !== 1 && level < tree.length + 2) {
      book = getTitleBooke(level, 19);
      view = "allbooksComNach";
    }
    if (checkViewAllbooksOrBookChoseHalMid() && level !== 1 && level < tree.length + 2) {
      book = getTitleBooke(level, 20);
      view = "allbooksHalMid";
    }
    if (checkViewAllbooksOrBookChoseTurCom() && level !== 1 && level < tree.length + 2) {
      book = getTitleBooke(level, 21);
      view = "allbooksTurCom";
    }
    if (checkViewAllbooksOrBookChoseTosefta() && level !== 1 && level < tree.length + 2) {
      book = getTitleBooke(level, 22);
      view = "allbooksTosefta";
    }

    else if (checkViewAllbooksOrBookChoseT() && level === 1) {
      book = 'תורה';
      view = "allbooksTea";
    }
    else if (checkViewAllbooksOrBookChoseRam() && level === 1) {
      book = 'רמב"ם';
      view = "allbooksRam";
    }
    else if (checkViewAllbooksOrBookChoseMish() && level === 1) {
      book = 'משניות';
      view = "allbooksMish";
    }
    else if (checkViewAllbooksOrBookChoseS() && level === 1) {
      book = 'שולחן ערוך';
      view = "allbooksSul";
    }
    else if (checkViewAllbooksOrBookChoseProphets() && level === 1) {
      book = getSederNameT();
      view = "allbooksProphets";
    }
    else if (checkViewAllbooksOrBookChoseVer() && level === 2) {
      book = 'ספרים שונים';
      view = "allbooksVarious";
    }
    else if (checkViewAllbooksOrBookChoseVerSub() && level === 3) {
      book = 'תפילות';
      view = "allbooksVariousSub";
    }
    else if (checkViewAllbooksOrBookChoseVerSubSub() && level === 4) {
      book = 'הגדה של פסח';
      view = "allbooksVariousSub";
    }


    if (view !== '' && book !== 's')
      return (
        <div
          className={"openNav-top-item-background openNav-talmud " + ((isMobile().phone || isMobile().tablet) && 'openNav-top-item-background-mobile')}
          onClick={() => {
            handleViewChange(view)
            setTree([])
          }}
        >
          {book}
        </div>
      )
  }
  const getClassName = () => {
    if (currentHeBook === null && isMenuOpen && !(isMobile().phone || isMobile().tablet)) {
      return `header-openNav-home-page`
    }
    if (isMenuOpen && !(isMobile().phone || isMobile().tablet)) {
      return `header-openNav`
    }
    if (isMenuOpen && (isMobile().phone || isMobile().tablet)) {
      return `header-openNavD`
    }
    if (isOpenNavPage) {
      return ''
    }
    else {
      return "header-hidden"
    }
  }
  return (
    <div
      className={getClassName()}
      id={"openNav"}>
      {!(isMobile().phone || isMobile().tablet) && <div className='onclick-close'
        onClick={() => {
          history.push('?')
          dispatch(closeMenu())
        }} >
      </div>}
      {!isOpenNavPage && !(isMobile().phone || isMobile().tablet) &&
        <div className='sfarim-search'>
          <input className='sfarim-search-input'
            placeholder="חפש ספר"
            autoComplete={'off'}
            maxLength={50}
            type="search"
            onChange={onChangeValue}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                onSubmitValue()
              }
            }}
          >
          </input>
          <div
            className='sfarim-search-circle'
            onClick={onSubmitValue}
          >
            <SearchCircle style={{ top: 10, position: 'relative' }} />
          </div>

        </div>}

      <div className={!(isMobile().phone || isMobile().tablet) ? "openNav-half-page-center" : "openNav-half-page-center-mobile"}>
        {!isOpenNavPage && <div className={!(isMobile().phone || isMobile().tablet) ? `header-openNav-top ${currentHeBook === null ? 'header-openNav-top-home-page' : ''}` : ''}>
          <div className={!(isMobile().phone || isMobile().tablet) ? "openNav-text-wrapper" : 'openNav-text-wrapper'}>
            <Breadcrumbs aria-label="breadcrumb">
              <div
                className={"openNav-top-item-background " + ((isMobile().phone || isMobile().tablet) && 'openNav-top-item-background-mobile')}
                onClick={() => clearView()}
              >
                {'אוצר ספרים'}
              </div>

              {BreadcrumbsOne(1)}
              {BreadcrumbsOne(2)}
              {BreadcrumbsOne(3)}
              {BreadcrumbsOne(4)}


              {menuView.includes('bookChosen') && (//
                <div className={"openNav-top-item-background openNav-talmud " + ((isMobile().phone || isMobile().tablet) && 'openNav-top-item-background-mobile')}
                  onClick={() => {

                    if (menuView.includes('bookChosenTeaTea'))
                      handleViewChange("bookChosenTea", sederName)
                    if (menuView.includes('bookChosenProphetsProphets'))
                      handleViewChange("bookChosenProphets")

                  }}
                >
                  {chosenBookHeName && !chosenBookHeName.includes('ברורה') && chosenBookHeName.replace('משנה', '').replace('שטיינזלץ', '').replace('תלמוד ירושלמי', '')}
                  {chosenBookHeName && chosenBookHeName.includes('ברורה') && chosenBookHeName}
                </div>
              )}

              {(menuView.includes('bookChosenTeaTea') || menuView.includes('bookChosenProphetsProphets')) && (
                <div className={"openNav-top-item-background openNav-talmud " + ((isMobile().phone || isMobile().tablet) && 'openNav-top-item-background-mobile')}
                >
                  {pageLocal && pageLocal.split(' ').length !== 1 && 'פרק ' + parseBookPageToHebrew(pageLocal)}
                  {pageLocal && pageLocal.split(' ').length === 1 && pageLocal}
                </div>
              )}


            </Breadcrumbs>
          </div>
        </div>}
        {!isOpenNavPage && !(isMobile().phone || isMobile().tablet) && (menuView === 'basic') &&
          <div className={!(isMobile().phone || isMobile().tablet) ? 'header-open-nav-icon' : 'header-open-nav-icon-mobile'}>
            <SquareLineDown />
            {<div className={!(isMobile().phone || isMobile().tablet) ? 'header-open-nav-icon-text' : 'header-open-nav-icon-text-mobile'}>
              {'אוצר הספרים'}
            </div>}
            {<div className={!(isMobile().phone || isMobile().tablet) ? 'header-open-nav-icon-text-2' : 'header-open-nav-icon-text-mobile'}>
              <br />
            </div>}
          </div>}
        {!isOpenNavPage && !(isMobile().phone || isMobile().tablet) && (menuView === 'bookChosenMish' || menuView === 'bookChosenHalCom' || menuView === 'bookChosenHalCom' || menuView === 'bookChosenRam' || menuView === 'bookChosen' || menuView === 'bookChosenTea' || menuView === 'bookChosenSul' || menuView === 'bookChosenProphets') &&
          <div className={!(isMobile().phone || isMobile().tablet) ? 'header-open-nav-icon' : 'header-open-nav-icon-mobile'}>
            {chosenBookHeName && (menuView === 'bookChosenMish' || menuView === 'bookChosenHalCom' || menuView === 'bookChosenHalCom' || menuView === 'bookChosen' || menuView === 'bookChosenTea' || menuView === 'bookChosenSul' || menuView === 'bookChosenProphets') && <SquareLineDown />}
            {chosenBookHeName && (menuView === 'bookChosenRam') && <SquareLine />}
            {chosenBookHeName && <span className={!(isMobile().phone || isMobile().tablet) ? 'header-open-nav-icon-text-empty' : 'header-open-nav-icon-text-mobile'}>
              {menuView === 'bookChosen' && 'מסכת ' + chosenBookHeName}
              {menuView === 'bookChosenRam' && chosenBookHeName && 'הלכות ' + chosenBookHeName.replace('משנה תורה, הלכות', '')}
              {menuView === 'bookChosenHalCom' && chosenBookHeName && chosenBookHeName}
              {menuView === 'bookChosenTalCom' && chosenBookHeName && chosenBookHeName}
              {menuView === 'bookChosenKabala' && chosenBookHeName && chosenBookHeName}
              {menuView === 'bookChosenTosefta' && chosenBookHeName && chosenBookHeName}
              {menuView === 'bookChosenTurCom' && chosenBookHeName && chosenBookHeName}
              {menuView === 'bookChosenHalMid' && chosenBookHeName && chosenBookHeName}
              {menuView === 'bookChosenMish' && chosenBookHeName && 'משניות ' + chosenBookHeName.replace('משנה', '')}

              {menuView === 'bookChosenTea' && 'פרשת ' + chosenBookHeName}
              {menuView === 'bookChosenSul' && chosenBookHeName}
              {menuView === 'bookChosenProphets' && 'ספר ' + chosenBookHeName}
            </span>}
            {<div className={!(isMobile().phone || isMobile().tablet) ? 'header-open-nav-icon-text-2' : 'header-open-nav-icon-text-mobile'}>
              <br />
            </div>}
          </div>}
        {!isOpenNavPage && !(isMobile().phone || isMobile().tablet) &&
          (
            menuView === 'allbooksChasidut' ||
            menuView === 'allbooksYer' ||
            menuView === 'allbooksComTor' ||
            menuView === 'allbooksComNach' ||
            menuView === 'allbooksResponsa' ||
            menuView === 'allbooksHalMid' ||
            menuView === 'allbooksMish' ||
            menuView === 'allbooksRam' ||
            menuView === 'allbooks' ||
            menuView === 'allbooksTea' ||
            menuView === 'allbooksSul' ||
            menuView === 'allbooksProphets' ||
            menuView === 'allbooksTalCom' ||
            menuView === 'allbooksKabala' ||
            menuView === 'allbooksTosefta' ||
            menuView === 'allbooksLiturgy' ||
            menuView === 'allbooksTurCom' ||
            menuView === 'allbooksHalCom' ||
            menuView === 'allbooksMusar' ||
            menuView === 'allbooksSmallMas'
          ) &&
          <div className={!(isMobile().phone || isMobile().tablet) ? 'header-open-nav-icon' : 'header-open-nav-icon-mobile'}>
            {(
              menuView === 'allbooksSmallMas' ||
              menuView === 'allbooksYer' ||
              menuView === 'allbooksChasidut' ||
              menuView === 'allbooksComTor' ||
              menuView === 'allbooksComNach' ||
              menuView === 'allbooksResponsa' ||
              menuView === 'allbooksHalMid' ||
              menuView === 'allbooksMish' ||
              menuView === 'allbooksTalCom' ||
              menuView === 'allbooksKabala' ||
              menuView === 'allbooksLiturgy' ||
              menuView === 'allbooksTosefta' ||
              menuView === 'allbooksTurCom' ||
              menuView === 'allbooksHalCom' ||
              menuView === 'allbooksRam' ||
              menuView === 'allbooks' ||
              menuView === 'allbooksTea' ||
              menuView === 'allbooksSul' ||
              menuView === 'allbooksMusar' ||
              menuView === 'allbooksProphets'
            ) && <SquareLineDown />}
            {<div className={!(isMobile().phone || isMobile().tablet) ? 'header-open-nav-icon-text' : 'header-open-nav-icon-text-mobile'}>
              {menuView === 'allbooks' && 'תלמוד בבלי'}
              {menuView === 'allbooksYer' && 'תלמוד ירושלמי'}
              {menuView === 'allbooksMish' && 'משנה'}
              {menuView === 'allbooksChasidut' && "סדר התפילה"}
              {menuView === 'allbooksComTor' && "מפרשי התורה"}
              {menuView === 'allbooksComNach' && `מפרשי הנ"ך`}
              {menuView === 'allbooksResponsa' && `שו"ת`}
              {menuView === 'allbooksHalMid' && `מדרשי הלכה`}
              {menuView === 'allbooksHalCom' && 'ספרי ההלכה'}
              {menuView === 'allbooksTalCom' && 'מפרשי הבבלי'}
              {menuView === 'allbooksKabala' && 'קבלה'}
              {menuView === 'allbooksLiturgy' && 'סדר התפילה'}
              {menuView === 'allbooksTosefta' && 'תוספתא'}
              {menuView === 'allbooksTurCom' && 'טור ונו"כ'}
              {menuView === 'allbooksSmallMas' && 'מסכתות קטנות'}
              {menuView === 'allbooksMusar' && 'ספרי מוסר'}
              {(menuView === 'allbooksTea' || menuView === 'allbooksProphets') && 'מקראות גדולות'}
              {menuView === 'allbooksSul' && 'שולחן ערוך'}
              {menuView === 'allbooksRam' && 'משנה תורה להרמב"ם'}
              <br />
              {false && <span style={{ fontSize: 27, position: 'relative', top: -50 }}>{'מהדורת רוזנבוים'}</span>}
            </div>}


          </div>}
        <div
          className={!(isMobile().phone || isMobile().tablet) ? "openNav-main-container" : "openNav-main-container-mobile"}>
          {(menuView === "basic") && (
            <div className={!(isMobile().phone || isMobile().tablet) ? "openNav-choose-subject-container" : "openNav-choose-subject-container-mobile"}>
              {basicNemuView()}
              <div className={!(isMobile().phone || isMobile().tablet) ? "openNav-main-container-empty" : "openNav-main-container-empty-mobile"}></div>
            </div>
          )}

          {menuView === "allbooks" && allbooksNemuView("bookChosen", 0)}
          {menuView === "allbooksYer" && allbooksNemuView("bookChosenYer", 5)}
          {menuView.includes("allbooksTalCom") && allbooksNemuView("bookChosenTalCom", 10)}


          {menuView === "allbooksRam" && allbooksNemuView("bookChosenRam", 8)}
          {menuView === "allbooksMish" && allbooksNemuView("bookChosenMish", 9)}
          {menuView.includes("allbooksHalCom") && allbooksNemuView("bookChosenHalCom", 11)}
          {menuView.includes("allbooksSmallMas") && allbooksNemuView("bookChosenSmallMas", 12)}
          {menuView.includes("allbooksKabala") && allbooksNemuView("bookChosenKabala", 13)}
          {menuView.includes("allbooksLiturgy") && allbooksNemuView("bookChosenLiturgy", 14)}
          {menuView.includes("allbooksChasidut") && allbooksNemuView("bookChosenChasidut", 15)}
          {menuView.includes("allbooksMusar") && allbooksNemuView("bookChosenMusar", 16)}
          {menuView.includes("allbooksResponsa") && allbooksNemuView("bookChosenResponsa", 17)}
          {menuView.includes("allbooksComTor") && allbooksNemuView("bookChosenComTor", 18)}
          {menuView.includes("allbooksComNach") && allbooksNemuView("bookChosenComNach", 19)}
          {menuView.includes("allbooksHalMid") && allbooksNemuView("bookChosenHalMid", 20)}
          {menuView.includes("allbooksTurCom") && allbooksNemuView("bookChosenTurCom", 21)}
          {menuView.includes("allbooksTosefta") && allbooksNemuView("bookChosenTosefta", 22)}
          {menuView === "allbooksTea" && allbooksNemuView("bookChosenTea", 1)}
          {menuView === "allbooksSul" && allbooksNemuView("bookChosenSul", 2)}
          {menuView === "allbooksProphets" && allbooksNemuView("bookChosenProphets", getRenderSeder())}


          {(
            menuView === "bookChosen" ||
            (menuView === "bookChosenRam" && (typeof contentCounts !== 'number')) ||
            menuView === "bookChosenMish" ||
            menuView === "bookChosenSul") &&
            <OpenNavList
              menuView={menuView}
              chosenBookAllPages={chosenBookAllPages}
              pageLocal={pageLocal}
              contentCounts={[]}
              parentPageLocal={titleBook}
              first={1}
              sectionNamesL={null}
            ></OpenNavList>}
          {(
            menuView === "bookChosenYer" ||
            menuView === "bookChosenTeaTea" ||
            menuView === "bookChosenTalCom" ||
            menuView === "bookChosenKabala" ||
            menuView === "bookChosenTosefta" ||
            menuView === "bookChosenTurCom" ||
            menuView === "bookChosenChasidut" ||
            menuView === "bookChosenComTor" ||
            menuView === "bookChosenComNach" ||
            menuView === "bookChosenLiturgy" ||
            menuView === "bookChosenResponsa" ||
            menuView === "bookChosenHalMid" ||
            menuView === "bookChosenMusar" ||
            (menuView === "bookChosenRam" && (typeof contentCounts === 'number')) ||
            menuView === "bookChosenSmallMas" ||
            menuView === "bookChosenHalCom" ||
            menuView === "bookChosenProphetsProphets"
          ) &&
            <OpenNavList
              menuView={menuView}
              chosenBookAllPages={chosenBookAllPages}
              pageLocal={pageLocal}
              contentCounts={contentCounts}
              parentPageLocal={titleBook}
              first={1}
              sectionNamesL={null}
            ></OpenNavList>
          }
          {menuView === "bookChosenTea" && (
            <div className="openNav-bookChosen-container-wrapper">

              <div className="select-chapter-openNav">
                <div className="openNav-bookChosen-container">
                  {menuChaptersView === "allPages" &&
                    chosenBookAllPages &&
                    chosenBookAllPages.map((x: any) => x.split(':')[0]).filter((elem: any, index: any, self: any) => index === self.indexOf(elem)).map((page: any) => {
                      return (
                        <div
                          className={
                            page === pageId
                              ? "bookChosen-page-item bookChosen-current-item"
                              : "bookChosen-page-item"
                          }
                          id={page === pageId ? "selected-page" : page}
                          key={page}
                          onClick={() => {
                            dispatch(changeMenuView("bookChosenTeaTea"))
                            setPageLocal(page)
                          }
                          }
                        >
                          <p className="bookChosen-page-item-text">
                            {parseBookPageToHebrew(page)}
                          </p>
                        </div>
                      );
                    })}
                  {menuChaptersView !== "allPages" && getChapterPages()}
                </div>
              </div>
            </div>
          )}

          {menuView === "bookChosenProphets" && (
            <div className="openNav-bookChosen-container-wrapper">
              <div className="select-chapter-openNav">
                <div className="openNav-bookChosen-container">
                  {menuChaptersView === "allPages" &&
                    chosenBookAllPages &&
                    chosenBookAllPages.map((page: any) => {
                      return (
                        <div
                          className={
                            page === pageId
                              ? "bookChosen-page-item bookChosen-current-item"
                              : "bookChosen-page-item"
                          }
                          id={page === pageId ? "selected-page" : page}
                          key={page}
                          onClick={() => {
                            dispatch(changeMenuView("bookChosenProphetsProphets"))
                            setPageLocal(page)
                          }}
                        >
                          <p className="bookChosen-page-item-text">
                            {parseBookPageToHebrew(page)}
                          </p>
                        </div>
                      );
                    })}
                  {menuChaptersView !== "allPages" && getChapterPages()}
                </div>
              </div>
            </div>
          )}

          {menuView === "allbooksVarious" && (
            <div className="openNav-allbooks-container">
              {<div className="openNav-allbooks-list-container" >
                <ul className="openNav-allbooks-list-Prophets">
                  {getVarious().map((book: any) => {
                    return (
                      <li
                        key={book.bookName}
                        className={`openNav-book-item-Prophets ${book.bookName === 'Various' ? 'color' : ''}`}
                        onClick={() => {
                          book.bookName === 'Tefilot' && handleChooseBookVer(book.heName, book.bookName, "allbooksVariousSub");
                          book.bookName === 'TodaySaffairs' && handleChooseBookVer(book.heName, book.bookName, "allbooksYomaSub");
                        }
                        }
                      >
                        {book.heName}
                      </li>
                    );

                  })}
                </ul>
              </div>

              }
            </div>
          )}
          {menuView === "allbooksVariousSub" && (
            <div className="openNav-allbooks-container">
              {
                <div
                  className="openNav-allbooks-list-container"
                >
                  <ul className="openNav-allbooks-list-Prophets">
                    {getVariousSub().map((book: any) => {
                      return (
                        <li
                          key={book.bookName}
                          className="openNav-book-item-Prophets"
                          onClick={() =>
                            handleChooseBook(book.heName, book.bookName, "allbooksVariousSubSub")
                          }
                        >
                          {book.heName}
                        </li>
                      );

                    })}
                  </ul>
                </div>

              }
            </div>
          )}
          {menuView === "allbooksYomaSub" && (
            <div className="openNav-allbooks-container">
              {<div className="openNav-allbooks-list-container">
                <ul className="openNav-allbooks-list-yoma">
                  {allBooksNames[7].map((seder: any) => {

                    return (
                      <div
                        className="openNav-allbooks-list-container"
                        key={seder.name}
                      >
                        <h4 className="allbooks-list-header">{seder.name}</h4>
                        <ul className={!(isMobile().phone || isMobile().tablet) ? "openNav-allbooks-list" : "openNav-allbooks-list-mobile-1"}>
                          {seder.books.map((book: any) => {
                            if (currentBook === book.bookName) {
                              return (
                                <li
                                  key={book.bookName}
                                  className="openNav-book-item openNav-book-item-current"
                                  onClick={() =>
                                    handleChooseBook(book.heName, book.bookName, "bookChosenTea", seder.nameHe)
                                  }
                                >
                                  {book.heName}
                                </li>
                              );
                            } else {
                              return (
                                <li
                                  key={book.bookName}
                                  className={"openNav-book-item " + ((isMobile().phone || isMobile().tablet) ? ' mobile-1' : '')}
                                  onClick={() =>
                                    handleChooseBook(book.heName, book.bookName, "bookChosenTea", seder.nameHe)
                                  }
                                >
                                  {book.heName}
                                </li>
                              );
                            }
                          })}
                        </ul>
                      </div>
                    );
                  })}
                </ul>
              </div>
              }
            </div>
          )}
          {menuView === "allbooksVariousSubSub" && (
            <div className="openNav-allbooks-container">
              {<div className="openNav-allbooks-list-container">
                <ul className="openNav-allbooks-list-Prophets">
                  {allBooksNames[6][0].books.map((book: any) => {
                    return (
                      <li
                        key={book.bookName}
                        className="openNav-book-item-Prophets"
                        onClick={() =>
                          handleSelectPage(`${book.bookName}`, 'Pesach_Haggadah')
                        }
                      >
                        {book.heName}
                      </li>
                    );

                  })}
                </ul>
              </div>

              }
              <div className={!(isMobile().phone || isMobile().tablet) ? "openNav-main-container-empty" : "openNav-main-container-empty-mobile"}
              ></div>

            </div>
          )}
        </div>
      </div>
    </div >
  );
};

export default OpenNav;
