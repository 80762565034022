import React, { useState } from "react";
import "./ChooseSfarim.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getCommentarySefarim,
  getSelectedcommentary,
  getSelectedUnitText,
  getCategoryName,
  getLayoutData,
  getBookName,
  getPageId,
  getLicense,
  getMenuView
} from "../../store/selectors/selectors";
import { ReactComponent as About } from "../../svg/info.svg";
import {
  setCommentaryselectChecked,
  clearTabOpenRight,
  setCommentaryToShow,
  setIsSfarimOn
} from "../../store/actions/pageActions";
import isMobile from "ismobilejs";
import Tooltip from '@mui/material/Tooltip';
import {
  useStyles
} from "../../utils/funcUtils";
import { ThunkDispatch } from "@reduxjs/toolkit";
import CloseIcon from '@mui/icons-material/Close';
const ChooseSfarim: React.FC = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const [expande, setExpanded] = useState(false);
  const selectedUnitText = useSelector(getSelectedUnitText);
  const license = useSelector(getLicense);
  const commentarySefarim = useSelector(getCommentarySefarim);
  const selectedCommentary = useSelector(getSelectedcommentary);
  const categoryName = useSelector(getCategoryName);
  const renderWords = useSelector(getLayoutData);
  const bookName = useSelector(getBookName);
  const pageId = useSelector(getPageId);
  const menuView = useSelector(getMenuView);
  const kisurimDictionary: any = {
    mesoretHashas: (menuView === "bookChosenTeaTea" || menuView === "bookChosenProphetsProphets") ? 'תולדות אהרן השלם' : 'מסורת הש"ס השלם',
    einMishpatHashalem: "עין משפט השלם",
    TorahOrHashalem: "תורה אור השלם"
  };
  const classes = useStyles();
  // check if data checkbox is checked or not.
  const handleChecked = (keyItem: any, valueItem: any) => {
    let jsonItem = JSON.parse(JSON.stringify({ [keyItem]: valueItem }));
    return selectedCommentary.find(
      (commentaryItem: any) =>
        Object.keys(commentaryItem)[0] === Object.keys(jsonItem)[0]
    );
  };

  // set the value in string.
  const setValue = (keyName: any, valueName: any) => {
    return JSON.stringify({ [keyName]: valueName });
  };

  // set the specific commentary to be default when show-sfarim is open, and move the comp to show-sfarim.
  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (selectedCommentary.length > 0) {
      dispatch(setCommentaryToShow(Object.keys(selectedCommentary[0])[0]));
      dispatch(setIsSfarimOn(true));
    }
  };

  // do toggle for the data checkboxes.
  const handleChange = (event: any) => {
    let jsonValue = JSON.parse(event.target.value);
    let newSelectedCommentary = JSON.parse(JSON.stringify(selectedCommentary));
    if (event.target.checked) {
      newSelectedCommentary.push(jsonValue);
      dispatch(setCommentaryselectChecked(newSelectedCommentary));
    } else if (!event.target.checked) {
      dispatch(
        setCommentaryselectChecked(
          newSelectedCommentary.filter((item: any) => {
            return JSON.stringify(item) !== JSON.stringify(jsonValue);
          })
        )
      );
    }
  };

  // check the default property for the all-mefarshim input.
  const isAllMefarsim = () => {
    let res: any = [];
    let biyur = false;
    selectedCommentary.forEach((item: any) => {
      Object.keys(commentarySefarim.commentary).forEach(
        (commentaryItem: any) => {
          if (commentaryItem === 'Steinsaltz')
            biyur = true;
          if (commentaryItem === Object.keys(item)[0]) {
            res.push(commentaryItem);
          }
        }
      );
    });
    if (biyur)
      return res.length + 1 === Object.keys(commentarySefarim.commentary).length;
    else if (commentarySefarim) {
      return res.length === Object.keys(commentarySefarim.commentary).length;
    }
  };

  // check the default property for the all-extra-books input.
  const isAllExtraBooks = () => {
    let res: any = [];
    selectedCommentary.forEach((item: any) => {
      Object.keys(commentarySefarim.extraBooks).forEach(
        (commentaryItem: any) => {
          if (commentaryItem === Object.keys(item)[0]) {
            res.push(commentaryItem);
          }
        }
      );
    });
    if (commentarySefarim) {
      return res.length === Object.keys(commentarySefarim.extraBooks).length;
    }
  };

  // do check on all the mefarshim checkbox and make sure that there will be no double.
  const checkSelectedAllMefarshim = (event: any) => {
    let newSelectedCommentary = JSON.parse(JSON.stringify(selectedCommentary));
    const allInputsEl = document.querySelectorAll("input");
    if (event.target.checked) {
      allInputsEl.forEach(item => {

        if (item.name === "commentary" && item.id !== "Steinsaltz") {
          item.checked = true;
          if (selectedCommentary.length === 0) {
            newSelectedCommentary.push(JSON.parse(item.value));
          } else {

            newSelectedCommentary = newSelectedCommentary.filter(
              (itemSelectedCommentary: any) => {


                return (
                  Object.values(itemSelectedCommentary)[0] !==
                  Object.values(JSON.parse(item.value))[0]
                );
              }
            );
            newSelectedCommentary.push(JSON.parse(item.value));
          }
        }
      });
    } else if (!event.target.checked) {
      allInputsEl.forEach(item => {
        if (item.name === "commentary") {
          item.checked = false;
        }
      });
      Object.keys(commentarySefarim.commentary).forEach(itemCommentary => {
        newSelectedCommentary = newSelectedCommentary.filter((item: any) => {
          return itemCommentary !== Object.keys(item)[0];
        });
      });
    }
    dispatch(setCommentaryselectChecked(newSelectedCommentary));
  };

  // do check on all the extra-books checkbox and make sure that there will be no double.
  const checkSelectedAllExtraBooks = (event: any) => {
    let newSelectedCommentary = JSON.parse(JSON.stringify(selectedCommentary));
    const allInputsEl = document.querySelectorAll("input");
    if (event.target.checked) {
      allInputsEl.forEach(item => {
        if (item.name === "extraBooks") {
          item.checked = true;
          if (selectedCommentary.length === 0) {
            newSelectedCommentary.push(JSON.parse(item.value));
          } else {
            newSelectedCommentary = newSelectedCommentary.filter(
              (itemSelectedCommentary: any) => {
                return (
                  Object.values(itemSelectedCommentary)[0] !==
                  Object.values(JSON.parse(item.value))[0]
                );
              }
            );
            newSelectedCommentary.push(JSON.parse(item.value));
          }
        }
      });
    } else if (!event.target.checked) {
      allInputsEl.forEach(item => {
        if (item.name === "extraBooks") {
          item.checked = false;
        }
      });
      Object.keys(commentarySefarim.extraBooks).forEach(itemCommentary => {
        newSelectedCommentary = newSelectedCommentary.filter((item: any) => {
          return itemCommentary !== Object.keys(item)[0];
        });
      });
    }
    dispatch(setCommentaryselectChecked(newSelectedCommentary));
  };
  const expandTabHandler = () => {
    dispatch(clearTabOpenRight());
  };

  return (
    <>
      <div className="choose-mefarshim">
        <form className="choose-mefarshim-form" onSubmit={handleSubmit}>
          <div className="selection-container">
            <div className="choose-mefarshim-CloseIcon"
              onClick={expandTabHandler}
            >
              <Tooltip
                classes={classes}
                title={'סגור מפרשים'}
                placement="right"
                arrow
              >
                <CloseIcon
                  style={{
                    position: 'relative',
                    color: '#707070',
                    top: '3'
                  }}

                />
              </Tooltip>

            </div>
            <div className={!(isMobile().phone || isMobile().tablet) ? "sub-sfarim-about" : "sub-sfarim-mobile"}
              onClick={async () => {
                setExpanded(!expande);
              }}
            >
              <div>
                <Tooltip
                  classes={classes}
                  title={'אודות הטקסט'}
                  placement="right"
                  arrow
                >
                  <div className='choose-mefarshim-about-icon'>
                    <About />
                  </div>
                </Tooltip>

                {expande && categoryName &&
                  <div className='choose-mefarshim-about-text'>
                    <b><big>
                      {
                        (categoryName[1] === 'Haggadah' || categoryName[0] === 'Tanakh' || categoryName[1] === 'Shulchan Arukh' || categoryName[0] === 'Talmud') &&
                        'אודות הספר הזה'
                      }
                    </big>

                    </b>
                    {(categoryName[1] === 'Haggadah' || categoryName[0] === 'Tanakh' || categoryName[1] === 'Shulchan Arukh' || categoryName[0] === 'Talmud') &&
                      <br />
                    }
                    {
                      categoryName[0] === 'Tanakh' &&
                      'הַתַּנַ"ךְ הוא שם כולל לשלשת קבוצות ספרי ה"תורה שבכתב" - תורה, נביאים וכתובים ("תנ"ך" בראשי תיבות). התנ"ך כולל בתוכו את חמשת חומשי התורה, שמונת ספרי הנביאים (ארבעה נביאים ראשונים וארבעה נביאים אחרונים), ואחד עשר ספרי ה\'כתובים\'. עשרים וארבעה ספרים אלו הם דברי האלקים לעם ישראל, שנאמרו ונכתבו במהלך הדורות בנבואה או ברוח הקודש, הספרים כוללים את ציוויי ה\' ליחידים ולציבור, ליהודים ולנכרים, לשעה ולדורות. התנ"ך כולל גם את סיפור עיקרי ההתרחשויות שאירעו מבריאת העולם עד כתיבתו, כמו גם נבואות רבות על העתיד להתרחש לאחר מכן.'
                    }
                    {
                      categoryName[1] === 'Shulchan Arukh' && categoryName[3] === 'Mishnah Berurah' &&
                      `משנה ברורה הוא חיבור הלכתי של רבי ישראל מאיר הכהן מראדין ("החפץ חיים"), ונחשב לאחד מחיבורי ההלכה המרכזיים והחשובים ביותר, בעיקר בעדות אשכנז[1]. מאז כתיבתו, התקבל הספר כספר פסיקה מרכזי ביהדות אשכנז. ביטוי לכך ניתן למצוא בדבריו של החזון איש: "ההוראה המקובלת מפי רבותינו אשר מפיהם אנו חיים כמו מרן הבית יוסף, המגן אברהם והמשנה ברורה, היא הוראה מקוימת כמו מפי סנהדרין בלשכת הגזית"[2]. וכך גם הרב אברהם יצחק הכהן קוק כותב עליו "הלכה כמותו בכל מקום" ('ההד' כרך ט' חוברת א' ירושלים תרצ"ד).`}
                    {
                      categoryName[1] === 'Shulchan Arukh' && categoryName.length === 2 &&
                      'שולחן ערוך הוא ספר הלכה שכתב רבי יוסף קארו בצפת בשנת 1563, ונדפס לראשונה בעיר ונציה, במהלך שנת 1565 (ה\'שכ"ה–ה\'שכ"ו). הספר נחשב אחד החיבורים החשובים בעולם ההלכתי היהודי, והוא דה פקטו משמש כעמוד תווך המעגן ומעצב את כל אורח החיים היהודי, על פי ההלכה הפסוקה.'
                    }
                    {
                      categoryName[0] === 'Talmud' &&
                      'תלמוד הבבלי הוא הספר המרכזי בתורה שבעל פה שמהווה בסיס לכל הספרות ההלכתית לדורות הבאים אחריו. חיבור שבו מסוכמים דברי ההלכה והאגדה המרכזיים של האמוראים - חכמי ישראל בתקופה שלאחר חתימת המשנה, בין אלו שהתגוררו בבבל ובין המתגוררים בארץ ישראל. חיבור זה נכתב בעיקרו כפרשנות והעמקה לימודית על דברי דורות קודמים של חכמים, דהיינו על המשנה ועל הברייתות, בצורה של ביאור והרחבה. על פי סדר ששת סדרי המשנה'
                    }
                    {
                      categoryName[1] === 'Haggadah' &&
                      `הַגָּדָה שֶׁל פֶּסַח היא קובץ מדרשים, מזמורי תהילים, דברי חז"ל, ברכות, תפילות ופיוטים, שנוצר כדי לאומרו בליל הסדר - הלילה הראשון של חג הפסח מסביב לסעודת החג, לקיים את מצוות "והגדת לבנך" וסיפור יציאת מצרים. הקובץ נקרא "הגדה" על פי לשון הפסוק "והגדת לבנך". ההגדה גם מאגדת את שאר מצוות ליל הסדר - כגון קידוש ושתית ארבע כוסות יין, אכילת מצה, מרור, כרפס, חרוסת ואפיקומן כזכר לקרבן פסח. נוהל עריכת הסדר מופיע במשנה, במסכת פסחים, פרק עשירי.`
                    }
                    <br />

                    {
                      categoryName[0] === 'Talmud' &&
                      <a href='https://www.hamichlol.org.il/%D7%AA%D7%9C%D7%9E%D7%95%D7%93_%D7%91%D7%91%D7%9C%D7%99' rel="noopener noreferrer" target='_blank' style={{ position: 'relative', right: 170 }}>
                        {'לערך המלא במכלול>>'}
                      </a>
                    }
                    {
                      (categoryName[0] === 'Tanakh') &&
                      <a href='https://www.hamichlol.org.il/תנ"ך' target='_blank' rel="noopener noreferrer" style={{ position: 'relative', right: 170 }}>
                        {'לערך המלא במכלול>>'}
                      </a>
                    }
                    {
                      categoryName[1] === 'Shulchan Arukh' && categoryName[3] !== 'Mishnah Berurah' &&
                      <a href='https://www.hamichlol.org.il/שולחן ערוך' target='_blank' rel="noopener noreferrer" style={{ position: 'relative', right: 170 }}>
                        {'לערך המלא במכלול>>'}
                      </a>
                    }
                    {
                      categoryName[3] === 'Mishnah Berurah' &&
                      <a href='https://www.hamichlol.org.il/משנה ברורה' target='_blank' rel="noopener noreferrer" style={{ position: 'relative', right: 170 }}>
                        {'לערך המלא במכלול>>'}
                      </a>
                    }
                  </div>}
                <br />
                {expande &&
                  <div className='choose-mefarshim-about-text'>
                    <big><b>{'מהדורה בשיתופתא'}</b></big>
                    {(categoryName[1] === 'Haggadah' || categoryName[0] === 'Tanakh' || categoryName[1] === 'Shulchan Arukh' || categoryName[0] === 'Talmud') &&
                      <br />
                    }
                    {
                      categoryName[0] === 'Tanakh' &&
                      'מקור טקסט וצורת הדף:'
                    }
                    {(categoryName[0] === 'Tanakh') && <span style={{ color: '#38A09D' }}>
                      {' מכון חכמת התורה'}
                    </span>
                    }
                    {
                      (categoryName[0] === 'Tanakh' || categoryName[1] === 'Haggadah') &&
                      'מקור: '
                    }
                    {
                      (categoryName[0] === 'Tanakh') &&
                      <a href={'http://www.tanach.us/Tanach.xml'} rel="noopener noreferrer" target='_blank'>
                        {'tanach.us'}
                      </a>
                    }
                    {
                      categoryName[1] === 'Haggadah' &&
                      <a href={'http://www.daat.ac.il/daat/shabat/pesach/hagada-2.htm'} rel="noopener noreferrer" target='_blank'>
                        {'daat.ac.il'}
                      </a>
                    }
                    {(categoryName[1] === 'Shulchan Arukh') &&
                      'מקור: '
                    }
                    {categoryName[1] === 'Shulchan Arukh' &&
                      <a href={'http://primo.nli.org.il/primo_library/libweb/action/dlDisplay.do?vid=NLI&docId=NNL_ALEPH002097773'} rel="noopener noreferrer" target='_blank'>
                        {'primo.nli.org.il'}
                      </a>
                    }
                    {
                      (categoryName[1] === 'Haggadah' || categoryName[0] === 'Tanakh' || categoryName[1] === 'Shulchan Arukh' || categoryName[0] === 'Talmud') &&
                      <br />
                    }
                    {(categoryName[0] === 'Talmud') &&
                      'רשיון טקסט וצורת הדף: '
                    }
                    {(categoryName[0] === 'Talmud') && <span style={{ color: '#38A09D' }}>
                      {'כל הזכויות שמורות'}
                    </span>
                    }
                    {
                      (categoryName[0] === 'Tanakh' || categoryName[1] === 'Shulchan Arukh' || categoryName[1] === 'Haggadah') &&
                      'רשיון: '
                    }
                    {
                      (categoryName[1] === 'Haggadah' || categoryName[0] === 'Tanakh') &&
                      <a href={license === 'CC-BY-NC' ? 'https://creativecommons.org/licenses/by-nc/3.0/' : license === 'CC-BY-SA' ? 'https://creativecommons.org/licenses/by-sa/3.0/' : 'https://www.hamichlol.org.il/רשות_הציבור'} rel="noopener noreferrer" target='_blank'>
                        {license === 'Public Domain' && 'נחלת הכלל'}
                        {license !== 'Public Domain' && license}

                      </a>
                    }
                    {
                      (categoryName[1] === 'Shulchan Arukh') &&
                      <a href={'https://www.hamichlol.org.il/רשות_הציבור'} rel="noopener noreferrer" target='_blank'>
                        {'נחלת הכלל'}
                      </a>
                    }
                    {(categoryName[0] === 'Tanakh' || categoryName[1] === 'Shulchan Arukh' || categoryName[0] === 'Talmud' || categoryName[1] === 'Haggadah') &&
                      <br />
                    }

                    {categoryName[0] === 'Talmud' &&
                      `ביאור: `
                    }
                    {categoryName[0] === 'Talmud' && <a href={`https://www.hamichlol.org.il/הרב_עדין_אבן_ישראל`} rel="noopener noreferrer" target='_blank'>
                      {' הרב עדין אבן ישראל'}
                    </a>

                    }
                    {categoryName[0] === 'Talmud' &&
                      <br />
                    }
                    {
                      `קישוריות ${(bookName === 'חושן משפט') || (bookName === 'אבן העזר') ? 'ודיגיטציה' : ''}:`
                    }
                    <a href={`https://www.sefaria.org.il/${pageId}`} rel="noopener noreferrer" target='_blank'>
                      {'sefaria.org.il'}
                    </a>
                    <br />
                    {
                      `רשיון קישוריות ${(bookName === 'חושן משפט') || (bookName === 'אבן העזר') ? 'ודיגיטציה' : categoryName[0] === 'Talmud' ? 'וביאור' : ''}: `
                    }
                    <a href={'https://creativecommons.org/licenses/by-nc/3.0/'} rel="noopener noreferrer" target='_blank'>
                      {'CC-BY-SA'}
                    </a>

                  </div>}
                <br />
              </div>
            </div>
            {categoryName && categoryName[0] === 'Talmud' && categoryName[1] === 'Bavli' && renderWords && <div className={!(isMobile().phone || isMobile().tablet) ? "sub-sfarim" : "sub-sfarim-mobile"}>
              <div className="sub-sfarim-title-container">
                <span className="sub-sfarim-title">ביאור</span>
              </div>
              <hr className="sub-sfarim-open-line" />
            </div>}
            {commentarySefarim &&
              commentarySefarim.commentary &&
              commentarySefarim.commentaryDic &&
              Object.keys(commentarySefarim.commentary).filter(
                (x: any) => x === 'Steinsaltz'
              ).map((item: any) => {
                let check = handleChecked(
                  item,
                  commentarySefarim.commentaryDic[item]
                );

                return (
                  <div key={item} className="commentary-he-name-container 2">
                    {selectedUnitText === null ||
                      (commentarySefarim.commentary[item][selectedUnitText] &&
                        commentarySefarim.commentary[item][selectedUnitText]
                          .length > 0) ? (
                      <label
                        title={commentarySefarim.commentaryDic[item].replace('שטיינזלץ', '').replace('(מאת שמואל צבי גליק)', '')}
                        className="commentary-he-name"
                      >
                        <input
                          className="commentary-he-name-input"
                          type="checkbox"
                          onChange={handleChange}
                          id={item}
                          name="commentary"
                          value={setValue(
                            item,
                            commentarySefarim.commentaryDic[item]
                          )}
                          defaultChecked={check}
                        />
                        {commentarySefarim.commentaryDic[item].length > 30
                          ? commentarySefarim.commentaryDic[item].substring(
                            0,
                            30
                          ) + "..."
                          : commentarySefarim.commentaryDic[item].replace('שטיינזלץ', '').replace('(מאת שמואל צבי גליק)', '')}
                      </label>
                    ) : (
                      <label
                        title={commentarySefarim.commentaryDic[item]}
                        className="commentary-he-name-none-select"
                      >
                        <input
                          name="commentary"
                          className="commentary-he-name-input"
                          type="checkbox"
                          onChange={handleChange}
                          id={item}
                          value={setValue(
                            item,
                            commentarySefarim.commentaryDic[item]
                          )}
                          defaultChecked={check}
                        />
                        {commentarySefarim.commentaryDic[item].length > 30
                          ? commentarySefarim.commentaryDic[item].substring(
                            0,
                            30
                          ) + "..."
                          : commentarySefarim.commentaryDic[item].replace('שטיינזלץ', '').replace('(מאת שמואל צבי גליק)', '')}
                      </label>
                    )}
                    <br />
                  </div>
                );
              })}


            <div className={!(isMobile().phone || isMobile().tablet) ? "sub-sfarim" : "sub-sfarim-mobile"}>
              <div className="sub-sfarim-title-container">
                <span className="sub-sfarim-title">קישורים</span>
              </div>
              <hr className="sub-sfarim-open-line" />
            </div>
            <div>
              {kisurimDictionary &&
                commentarySefarim &&
                Object.keys(kisurimDictionary).map(name => {
                  let isKishurimCounter = 0;
                  let check = handleChecked(name, kisurimDictionary[name]);
                  let isKishurim = commentarySefarim[name].every(
                    (kishurimInnerItem: any) => {
                      return kishurimInnerItem.length === 0;
                    }
                  );
                  if (isKishurim) {
                    isKishurimCounter++;
                    if (
                      isKishurimCounter < Object.keys(kisurimDictionary).length
                    ) {
                      return "";
                    } else {
                      return (
                        <p className="no-relevant-kishurim">
                          לא קיימים קישורים לעמוד זה
                        </p>
                      );
                    }
                  } else {

                    return (
                      <div key={name} className="commentary-he-name-container 1">
                        {selectedUnitText === null ||
                          (commentarySefarim[name][selectedUnitText] &&
                            commentarySefarim[name][selectedUnitText].length >
                            0) ? (
                          <label
                            title={kisurimDictionary[name].replace('שטיינזלץ', '').replace('(מאת שמואל צבי גליק)', '')}
                            className="commentary-he-name"
                          >
                            <input
                              className="commentary-he-name-input"
                              type="checkbox"
                              onChange={handleChange}
                              id={name}
                              value={setValue(name, kisurimDictionary[name].replace('שטיינזלץ', '').replace('(מאת שמואל צבי גליק)', ''))}
                              defaultChecked={check}
                            />
                            {kisurimDictionary[name].replace('שטיינזלץ', '').replace('(מאת שמואל צבי גליק)', '')}
                          </label>
                        ) : (
                          <label
                            title={kisurimDictionary[name]}
                            className="commentary-he-name-none-select"
                          >
                            <input
                              className="commentary-he-name-input"
                              type="checkbox"
                              onChange={handleChange}
                              id={name}
                              value={setValue(name, kisurimDictionary[name])}
                              defaultChecked={check}
                            />
                            {kisurimDictionary[name].replace('שטיינזלץ', '').replace('(מאת שמואל צבי גליק)', '')}
                          </label>
                        )}
                        <br />
                      </div>
                    );
                  }
                })}
            </div>
            <div className={!(isMobile().phone || isMobile().tablet) ? "sub-sfarim" : "sub-sfarim-mobile"}>
              <div className="sub-sfarim-title-container">
                <span className="sub-sfarim-title">מפרשים</span>
              </div>
              <hr className="sub-sfarim-open-line" />
            </div>
            <div>
              {commentarySefarim &&
                Object.keys(commentarySefarim.commentary).length > 0 ? (
                <div className="choose-all-commentary-container">
                  <label className="container-checkbox">
                    <input
                      className="choose-all-commentary-input"
                      type="checkbox"
                      onChange={checkSelectedAllMefarshim}
                      // @ts-ignore
                      checked={isAllMefarsim()}
                    />
                    <span className="checkmark" />
                    בחר הכל
                  </label>
                </div>
              ) : (
                <p className="no-relevant-kishurim">
                  לא קיימים קישורים לעמוד זה
                </p>
              )}
              {commentarySefarim &&
                commentarySefarim.commentary &&
                commentarySefarim.commentaryDic &&
                Object.keys(commentarySefarim.commentary).filter(
                  (x: any) => x !== 'Steinsaltz'
                ).map((item: any) => {
                  let check = handleChecked(
                    item,
                    commentarySefarim.commentaryDic[item]
                  );

                  return (
                    <div key={item} className="commentary-he-name-container 2">
                      {selectedUnitText === null ||
                        (commentarySefarim.commentary[item][selectedUnitText] &&
                          commentarySefarim.commentary[item][selectedUnitText]
                            .length > 0) ? (
                        <label
                          title={commentarySefarim.commentaryDic[item].replace('שטיינזלץ', '').replace('(מאת שמואל צבי גליק)', '')}
                          className="commentary-he-name"
                        >
                          <input
                            className="commentary-he-name-input"
                            type="checkbox"
                            onChange={handleChange}
                            id={item}
                            name="commentary"
                            value={setValue(
                              item,
                              commentarySefarim.commentaryDic[item]
                            )}
                            defaultChecked={check}
                          />
                          {commentarySefarim.commentaryDic[item].length > 30
                            ? commentarySefarim.commentaryDic[item].substring(
                              0,
                              30
                            ) + "..."
                            : commentarySefarim.commentaryDic[item].replace('שטיינזלץ', '').replace('(מאת שמואל צבי גליק)', '')}
                        </label>
                      ) : (
                        <label
                          title={commentarySefarim.commentaryDic[item]}
                          className="commentary-he-name-none-select"
                        >
                          <input
                            name="commentary"
                            className="commentary-he-name-input"
                            type="checkbox"
                            onChange={handleChange}
                            id={item}
                            value={setValue(
                              item,
                              commentarySefarim.commentaryDic[item]
                            )}
                            defaultChecked={check}
                          />
                          {commentarySefarim.commentaryDic[item].length > 30
                            ? commentarySefarim.commentaryDic[item].substring(
                              0,
                              30
                            ) + "..."
                            : commentarySefarim.commentaryDic[item].replace('שטיינזלץ', '').replace('(מאת שמואל צבי גליק)', '')}
                        </label>
                      )}
                      <br />
                    </div>
                  );
                })}
            </div>
            <div className={!(isMobile().phone || isMobile().tablet) ? "sub-sfarim" : "sub-sfarim-mobile"}>
              <div className="sub-sfarim-title-container-sfarim-nosafim">
                <span className="sub-sfarim-title">ספרים נוספים</span>
              </div>
              <hr className="sub-sfarim-open-line" />
            </div>
            <div>
              {commentarySefarim &&
                Object.keys(commentarySefarim.extraBooks).length > 0 ? (
                <div className="choose-all-commentary-container">
                  <label className="container-checkbox">
                    <input
                      className="choose-all-commentary-input"
                      type="checkbox"
                      onChange={checkSelectedAllExtraBooks}
                      // @ts-ignore
                      checked={isAllExtraBooks()}
                    />
                    <span className="checkmark" />
                    בחר הכל
                  </label>
                </div>
              ) : (
                <p className="no-relevant-kishurim">
                  לא קיימים קישורים לעמוד זה
                </p>
              )}
              {commentarySefarim &&
                commentarySefarim.extraBooks &&
                commentarySefarim.extraBooksHe &&
                Object.keys(commentarySefarim.extraBooks).map((item: any) => {
                  let check = handleChecked(
                    item,
                    commentarySefarim.extraBooksHe[item]
                  );

                  if (commentarySefarim.commentaryDic[item].includes('כסף משנה על משנה תורה, הלכות')) {
                    commentarySefarim.commentaryDic[item] = commentarySefarim.commentaryDic[item].replace('על משנה תורה, הלכות', '-')
                  }
                  return (
                    <div key={item} className="commentary-he-name-container 3">
                      {selectedUnitText === null ||
                        (commentarySefarim.extraBooks[item][selectedUnitText] &&
                          commentarySefarim.extraBooks[item][selectedUnitText]
                            .length > 0) ? (
                        <label
                          title={commentarySefarim.extraBooksHe[item].replace('שטיינזלץ', '').replace('(מאת שמואל צבי גליק)', '')}
                          className="commentary-he-name"
                        >
                          <input
                            name="extraBooks"
                            className="commentary-he-name-input"
                            type="checkbox"
                            onChange={handleChange}
                            id={item}
                            value={setValue(
                              item,
                              commentarySefarim.extraBooksHe[item]
                            )}
                            defaultChecked={check}
                          />
                          {commentarySefarim.extraBooksHe[item].length > 30
                            ? commentarySefarim.extraBooksHe[item].substring(
                              0,
                              30
                            ) + "..."
                            : commentarySefarim.extraBooksHe[item].replace('שטיינזלץ', '').replace('(מאת שמואל צבי גליק)', '')}
                        </label>
                      ) : (
                        <label
                          title={commentarySefarim.extraBooksHe[item].replace('שטיינזלץ', '').replace('(מאת שמואל צבי גליק)', '')}
                          className="commentary-he-name-none-select"
                        >
                          <input
                            name="extraBooks"
                            className="commentary-he-name-input"
                            type="checkbox"
                            onChange={handleChange}
                            id={item}
                            value={setValue(
                              item,
                              commentarySefarim.extraBooksHe[item]
                            )}
                            defaultChecked={check}
                          />
                          {commentarySefarim.extraBooksHe[item].length > 30
                            ? commentarySefarim.extraBooksHe[item].substring(
                              0,
                              30
                            ) + "..."
                            : commentarySefarim.extraBooksHe[item].replace('שטיינזלץ', '').replace('(מאת שמואל צבי גליק)', '')}
                        </label>
                      )}
                      <br />
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="submit-container">
            {false && <p className="submit-txt-none-selected">בחר מהרשימה תוכן להצגה</p>}
            <Tooltip
              classes={classes}
              title={selectedCommentary.length < 1 ? 'בחר מהרשימה תוכן להצגה' : ''}
              placement="bottom"
              arrow
            >
              {selectedCommentary.length > 0 ? (
                <button className="submit-btn-selected">פתיחה</button>
              ) : (
                <button className="submit-btn-none-selected">פתיחה</button>
              )}

            </Tooltip>

          </div>
        </form>
      </div>
    </>
  );
};

export default ChooseSfarim;
